import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from 'styled-components';
import { IChartApi } from 'lightweight-charts';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useStartConnection, useTradingCentral } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';
import { ConfigurationMenu } from '@trader/components';
import { IBarFE, renderCharts } from '@trader/utils';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';
import { EChartLayouts } from '@trader/types';

import { Main, Symbol, Root } from './styled';

interface ICandleChart {
  pipSize?: number;
  symbol?: string;
  staticHeight?: boolean;
  layoutNumber: EChartLayouts;
}

export const CandleChart: React.FC<ICandleChart> = observer(
  ({ layoutNumber, staticHeight, ...rest }) => {
    const store = useMst();
    const theme = useTheme();
    useTradingCentral();

    const { connection } = useStartConnection(
      EConnectionHub.Quotes,
      EConnectionSubscription.Instrument,
      'quotes'
    );

    const idToken = store.auth.tokens.idToken;

    const symbol =
      rest.symbol || store.pages.trading.getInstrumentSymbolByLayout() || '';
    const pipSize =
      rest.pipSize ||
      store.entities.instruments.get<TInstrumentEntity>(symbol)?.pipSize ||
      0;

    const selectedInstrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);

    const activeTradingAccount = store.user.activeTradingAcc();

    const period =
      store.pages.trading.layout.layouts[layoutNumber].configuration.period;
    const isDisplayIndicators = store.user.settings.isDisplayedByUser;

    const [refState, setRefState] = useState<HTMLDivElement | null>(null);
    const chartInstance = useRef<IChartApi | null>(null);

    const getBars = async (fromTime = 0) => {
      return (await selectedInstrument?.getInstrumentBarsAsync.run({
        symbol,
        period,
        fromTime,
      })) as Array<IBarFE>;
    };

    // rendering chart
    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (refState) {
        const { chartObject, remove } = renderCharts({
          htmlElement: refState,
          getBars,
          tradingCentral: selectedInstrument?.tradingCentral,
          period,
          theme,
          connection,
          symbol,
          activeTradingAccount,
          pipSize,
        });

        if (!chartInstance.current) {
          chartInstance.current = chartObject;
        }
        return () => {
          remove();
          chartInstance.current = null;
        };
      }
    }, [symbol, isDisplayIndicators, period, refState, idToken]);

    useEffect(() => {
      chartInstance.current &&
        chartInstance.current.applyOptions({
          grid: {
            vertLines: {
              color: theme.palette.background.default,
            },
            horzLines: {
              color: theme.palette.background.default,
            },
          },
          rightPriceScale: {
            borderColor: theme.palette.background.paper,
          },
          timeScale: {
            borderColor: theme.palette.background.paper,
          },
          layout: {
            background: {
              color: theme.palette.background.paper,
            },
            textColor: theme.palette.text.primary,
          },
        });
    }, [store.app.themeMode]);

    return (
      <Root>
        <ConfigurationMenu
          layoutNumber={EChartLayouts.FirstLayout}
          shouldHideActions
          instrument={selectedInstrument}
        />
        <AutoSizer>
          {({ width, height }) => (
            <Main
              $width={width}
              $height={height}
              $staticHeight={staticHeight}
              id={symbol}
              ref={(ref: HTMLDivElement) => {
                setRefState(ref);
              }}
            >
              {staticHeight && <Symbol>{symbol}</Symbol>}
            </Main>
          )}
        </AutoSizer>
      </Root>
    );
  }
);
