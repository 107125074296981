import { useMemo } from 'react';

import { TScheduledOrderEntity, useMst } from '@trader/store';
import { EScheduledOrderStatus } from '@trader/types';

export const useScheduledOrders = () => {
  const store = useMst();

  const allScheduledOrders =
    store.entities.scheduledOrders.getAll<TScheduledOrderEntity>();

  const filteredScheduledOrders = useMemo(() => {
    const active: TScheduledOrderEntity[] = [];
    const historical: TScheduledOrderEntity[] = [];

    for (const order of allScheduledOrders) {
      if (order.status === EScheduledOrderStatus.Created) {
        active.push(order);
      } else {
        historical.push(order);
      }
    }

    return {
      all: allScheduledOrders,
      active,
      historical,
    };
  }, [allScheduledOrders, allScheduledOrders.length]);

  return filteredScheduledOrders;
};
