import { ReactNode } from 'react';
import { useTheme } from '@mui/material';

import { Stack } from '@trader/components';

export const Summaries = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      spacing='10px'
      padding='12px'
      borderBottom={`1px solid ${theme.palette.background.default}`}
    >
      {children}
    </Stack>
  );
};
