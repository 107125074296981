import { useCallback, useEffect, useState } from 'react';

import { SmartLookService } from '@trader/services';

export const useGetPageVisibility = (): boolean => {
  const [isVisible, setIsVisible] = useState(true);

  const handleChange = useCallback((value: boolean) => {
    SmartLookService.track(
      value ? 'Page_visibility_true_event' : 'Page_visibility_false_event'
    );
    setIsVisible(value);
  }, []);

  useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      handleChange(document.visibilityState === 'visible');
    });

    return () => {
      window.removeEventListener('visibilitychange', () => {
        handleChange(document.visibilityState === 'visible');
      });
    };
  }, []);

  return isVisible;
};
