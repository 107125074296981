import styled, { css } from 'styled-components';

export const Filters = styled('div')`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 8px;
    padding: 0 8px 12px 8px;
    border-bottom: 1px solid ${theme.palette.gray.light};
  `}
`;
