import styled, { css } from 'styled-components';

import { Stack, Wrapper, Typography } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Bundles = styled(Stack)`
  ${({ theme }) => css`
    padding: 4px;
    background-color: ${theme.palette.gray.light};
    border-radius: 5px;
    margin-top: 4px;
  `}
`;

export const Bundle = styled(Wrapper)<{
  $selected: boolean;
  $withAsterisk: boolean;
}>`
  ${({ theme, $selected, $withAsterisk }) => css`
    width: 100%;
    height: 36px;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${theme.palette.gray.main};
    border-radius: 5px;
    background-color: ${theme.palette.white.main};
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    text-align: center;
    color: ${theme.palette.black.main};

    ${conditionApply(
      [$selected],
      css`
        border: 1px solid ${theme.palette.green['800']};
        color: ${theme.palette.green['400']};
        background-color: ${theme.palette.green['200']};
      `
    )};

    ${conditionApply(
      [$withAsterisk],
      css`
        &::after {
          content: '*';
          color: ${theme.palette.red.main};
          position: absolute;
          top: 0;
          right: 4px;
          font-size: ${theme.typography.xl.fontSize};
        }
      `
    )};
  `}
`;

export const BundleAddition = styled(Typography)`
  ${({ theme }) => css`
    position: relative;
    display: inline-block;
    padding-left: 10px;

    &::before {
      content: '*';
      color: ${theme.palette.red.main};
      position: absolute;
      top: 0;
      left: 0;
      font-size: ${theme.typography.xl.fontSize};
    }
  `}
`;
