import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import {
  ChangePercentage,
  IScrollableListItemDetail,
  ProfitValue,
  PurchaseType,
  ScrollableList,
  ScrollableListItem,
} from '@trader/components';
import { TPositionMetricEntity } from '@trader/store';
import { iconSizes } from '@trader/constants';
import { useI18next } from '@trader/services';
import { formatTableRowValueByPipSize } from '@trader/utils';

import { IPositionsList, SymbolWithIcon } from '../index';
import { usePositionsListMetrics } from './hooks/usePositionsListMetrics';
import { PositionActions } from './components/positionActions';

export const MobilePositionsList: FC<IPositionsList> = observer(
  ({ symbol }) => {
    const { translate } = useI18next();
    const { positionsMetrics } = usePositionsListMetrics(symbol);

    return (
      <ScrollableList<TPositionMetricEntity>
        data={positionsMetrics}
        renderItem={p => <PositionListItem position={p} />}
        emptyMessage={translate('COMMON.LABELS.NO_POSITIONS')}
      />
    );
  }
);

const PositionListItem: FC<{ position: TPositionMetricEntity }> = observer(
  ({ position }) => {
    const { translate } = useI18next();

    const detailsItems: IScrollableListItemDetail[] = [
      {
        id: 'positionId',
        header: translate('COMMON.LABELS.POSITION_ID'),
        value: position.positionId,
      },
      {
        id: 'side',
        header: translate('COMMON.LABELS.TYPE'),
        value: <PurchaseType value={position.side as string} />,
      },
      {
        id: 'quantity',
        header: translate('COMMON.LABELS.VOLUME'),
        value: position.quantity,
      },
      {
        id: 'pl',
        header: translate('COMMON.LABELS.P_L'),
        value: <ProfitValue value={position.pl.toFixed(2)} />,
      },
      {
        id: 'netPl',
        header: translate('COMMON.LABELS.NET_PL'),
        value: <ProfitValue value={position.netPl.toFixed(2)} />,
      },
      {
        id: 'openPrice',
        header: translate('COMMON.LABELS.OPEN_PRICE'),
        value: formatTableRowValueByPipSize(
          position.openPrice,
          position.pipSize
        ),
      },
      {
        id: 'currentPrice',
        header: translate('COMMON.LABELS.CURRENT_PRICE'),
        value: formatTableRowValueByPipSize(position.price, position.pipSize),
      },
      {
        id: 'swap',
        header: translate('COMMON.LABELS.SWAP'),
        value: position.swap,
      },
      {
        id: 'value',
        header: translate('COMMON.LABELS.VALUE'),
        value: Number((position.price * position.quantity).toFixed(1)),
      },
      {
        id: 'stopLoss',
        header: translate('COMMON.LABELS.SL'),
        value: formatTableRowValueByPipSize(
          position.stopLoss.stopPrice,
          position.pipSize
        ),
      },
      {
        id: 'takeProfit',
        header: translate('COMMON.LABELS.TP'),
        value: formatTableRowValueByPipSize(
          position.takeProfit.limitPrice,
          position.pipSize
        ),
      },
      {
        id: 'change',
        header: translate('COMMON.LABELS.CHANGE'),
        value: <ChangePercentage change={position.change} />,
      },
    ];

    return (
      <ScrollableListItem
        leadingContent={
          <SymbolWithIcon
            symbol={position.symbol}
            iconUrl={position.iconUrl}
            iconSize={iconSizes.small}
            textVariant='medium'
          />
        }
        trailingContent={<PositionActions position={position} />}
        detailsItems={detailsItems}
      />
    );
  }
);
