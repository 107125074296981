import { Instance, types } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

import { createTableModel } from '../../utils/tableModel';
import { positionHistoryModel } from '../../entities/positionsHistory';
import { getMoreItemsAsync } from './actions';

export const positionsHistoryTableModel = types
  .model('positionsHistoryTableModel', {
    table: createTableModel(positionHistoryModel, {}),
    getMoreItemsAsync,
  })
  .actions(() => ({
    runInAction,
  }));

export const positionsHistoryTable = types.optional(
  positionsHistoryTableModel,
  {}
);

export type TPositionsHistoryTableModel = Instance<
  typeof positionsHistoryTableModel
>;
