import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { IAnyStateTreeNode } from 'mobx-state-tree';

import { TCreateTableModelInstance } from '@trader/store';

import { MuiPagination } from '../../styled';

interface IPagination {
  store: IAnyStateTreeNode;
  hasPagination?: boolean;
}

export const Pagination: FC<IPagination> = observer(
  ({ store, hasPagination }) => {
    const tableStore = store.table as TCreateTableModelInstance;

    if (!hasPagination || !tableStore.data().length) {
      return null;
    }

    return (
      <MuiPagination
        component='div'
        size='small'
        disabled={store?.getMoreItemsAsync.inProgress}
        page={tableStore.currentPage}
        count={
          tableStore.filtering.value
            ? tableStore.data().length
            : tableStore.totalCount
        }
        rowsPerPage={tableStore.pageSize}
        rowsPerPageOptions={tableStore.pageSizeOptions}
        onPageChange={(_event: unknown, page: number) => {
          store?.getMoreItemsAsync.run({ pageNumber: page });
        }}
        onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          tableStore.changeCurrentPage(0);
          store?.getMoreItemsAsync.run({
            pageSize: Number(event.target.value),
          });
        }}
      />
    );
  }
);
