import { api, IPositionParamsBE, IPositionsHistoryBE } from '@trader/api';
import { devLoggerService } from '@trader/services';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { positionsHistorySchema } from './schemas';
import { getPositionChange } from '@trader/utils';

export const getPositionsHistoryAsync = createThunk<
  IPositionParamsBE,
  Omit<IPositionsHistoryBE, 'positions'> | void
>(
  params =>
    async function getPositionsHistory(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const pageNumber =
        params?.pageNumber ||
        root.tables.positionsHistory.table.currentPage + 1;

      const shouldClearBeforeMerge =
        params?.shouldClearBeforeMerge || pageNumber === 1;

      try {
        const filters =
          root.tables.positionsHistory.table.getCustomFilter('closeTime');

        const res = await api.Trading.getPositionsHistory(
          {
            completedFrom: params?.completedFrom || filters?.['completedFrom'],
            completedTo: params?.completedTo || filters?.['completedTo'],
            pageSize:
              params?.pageSize || root.tables.positionsHistory.table.pageSize,
            pageNumber: pageNumber,
            shouldClearBeforeMerge,
          },
          _options
        );

        const { positions, ...rest } = res;
        const normalized = root.entities.normalizeMerge(
          positions.map(p => ({
            ...p,
            change: getPositionChange(p.entryPrice, p.exitPrice, p.side),
          })),
          positionsHistorySchema,
          shouldClearBeforeMerge
        );

        root.tables.positionsHistory.runInAction(() => {
          root.tables.positionsHistory.table.totalCount = res?.totalCount || 0;
        });

        root.tables.positionsHistory.table.setAdditionalFields({
          totalPl: rest?.pl,
        });

        shouldClearBeforeMerge
          ? root.tables.positionsHistory.table.list.set(normalized)
          : root.tables.positionsHistory.table.list.append(normalized);

        return rest;
      } catch (e) {
        devLoggerService.error('Error in getPositionsHistoryAsync', e);
      }
    }
);
