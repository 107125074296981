import { getServerGroupMap } from './subscribedInstruments';
import { IHandleSubscriptionParams, TInstrumentsIds } from './types';

export const beforeSubscribe = ({
  onSubscribe,
  subscription,
  instrumentsIds,
  serverName,
}: IHandleSubscriptionParams) => {
  const instrumentsToSubscribe: TInstrumentsIds = [];

  const serverGroup = getServerGroupMap(serverName);

  for (const instrument of instrumentsIds) {
    if (!instrument) continue;

    let item = serverGroup.instruments.get(instrument);

    if (!item) {
      item = { subscriptionsAmount: new Map() };
      serverGroup.instruments.set(instrument, item);
    }

    const currentCount = item.subscriptionsAmount.get(subscription) || 0;

    if (currentCount > 0) {
      item.subscriptionsAmount.set(subscription, currentCount + 1);
    } else {
      instrumentsToSubscribe.push(instrument);
      item.subscriptionsAmount.set(subscription, 1);
    }
  }

  if (instrumentsToSubscribe.length) {
    onSubscribe(instrumentsToSubscribe);
  }
};
