import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { DateFilter, SelectFilter, Stack } from '@trader/components';
import { TPaymentStatus, TTransferType } from '@trader/api';
import { useI18next } from '@trader/services';
import { TDateFilterInterval } from '@trader/types';

import { useFilterOptions } from '../../../hooks/useFilterOptions';
import * as Styled from './styled';

interface IFilters {
  onFiltersChange: (
    statuses: TPaymentStatus[],
    transferTypes: TTransferType[],
    interval: TDateFilterInterval | null
  ) => void;
}

export const TransferFilters: React.FC<IFilters> = ({ onFiltersChange }) => {
  const { translate } = useI18next();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { selectStatusOptions, selectTransferTypeOptions } = useFilterOptions();

  const [selectedStatuses, setSelectedStatuses] = useState<TPaymentStatus[]>([
    'Success',
  ]);
  const [selectedTransferType, setSelectedTransferType] = useState<
    TTransferType[]
  >([]);
  const [selectedInterval, setSelectedInterval] =
    useState<TDateFilterInterval | null>(null);

  useEffect(() => {
    const statuses = !selectedStatuses.length
      ? selectStatusOptions.map(o => o.value)
      : selectedStatuses;

    onFiltersChange(statuses, selectedTransferType, selectedInterval);
  }, [selectedStatuses, selectedTransferType, selectedInterval]);

  if (isMobile) {
    return (
      <Styled.Root direction='column' spacing='16px'>
        <Stack direction='row' spacing='8px'>
          <SelectFilter
            label={translate('COMMON.LABELS.STATUS')}
            options={selectStatusOptions}
            onChange={values => setSelectedStatuses(values as TPaymentStatus[])}
            noAllOption
          />
          <SelectFilter
            label={translate('COMMON.LABELS.TYPE')}
            options={selectTransferTypeOptions}
            onChange={values =>
              setSelectedTransferType(values as TTransferType[])
            }
          />
        </Stack>
        <DateFilter onApply={setSelectedInterval} />
      </Styled.Root>
    );
  }

  return (
    <Styled.Root direction='row' spacing='16px'>
      <SelectFilter
        label={translate('COMMON.LABELS.STATUS')}
        options={selectStatusOptions}
        onChange={values => setSelectedStatuses(values as TPaymentStatus[])}
        noAllOption
      />
      <SelectFilter
        label={translate('COMMON.LABELS.TYPE')}
        options={selectTransferTypeOptions}
        onChange={values => setSelectedTransferType(values as TTransferType[])}
      />
      <DateFilter onApply={setSelectedInterval} />
    </Styled.Root>
  );
};
