import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Virtuoso } from 'react-virtuoso';

import { EConnectionSubscription, useI18next } from '@trader/services';
import { defaultPageSize } from '@trader/constants';
import { Typography } from '@trader/components';
import { useInstruments } from '@trader/hooks';
import { useMst } from '@trader/store';

import { Instrument } from './Instrument';

import * as Styled from './styled';

export const Instruments: React.FC = observer(() => {
  const { translate } = useI18next();

  const store = useMst();
  const isLoading = store.entities.instruments.getInstrumentsAsync.inProgress;
  const category = store.pages.trading.selectedCategory;
  const subCategory = store.pages.trading.selectedSubCategory;
  const instruments = store.pages.trading.getInstrumentsByCategory(
    category?.name || '',
    subCategory
  );

  useInstruments({
    instrumentsIds: [...instruments.map(i => i.symbol)],
    subscription: EConnectionSubscription.Instrument,
  });

  const loadMore = useCallback(async () => {
    await store.entities.instruments.getInstrumentsAsync.run({
      pageNumber: Number((instruments.length / defaultPageSize + 1).toFixed()),
    });
  }, [instruments.length]);

  return (
    <Styled.Box>
      {!instruments.length && !isLoading ? (
        <Typography padding='8px' variant='medium'>
          {translate('COMMON.LABELS.NO_CATEGORY_INSTRUMENTS', {
            categoryName: category?.name || '',
          })}
        </Typography>
      ) : (
        <Virtuoso
          style={{ height: '100%' }}
          data={instruments}
          endReached={(index: number) => {
            if (
              index + 1 >= defaultPageSize &&
              index + 1 < store.pages.trading.instrumentsAmount
            ) {
              loadMore();
            }
          }}
          increaseViewportBy={{
            top: 300,
            bottom: 650,
          }}
          itemContent={(_index, instrument) => {
            return (
              <Instrument
                key={instrument.symbol}
                symbol={instrument.symbol}
                miniChartCoordinates={instrument.miniChartCoordinates}
              />
            );
          }}
        />
      )}
    </Styled.Box>
  );
});
