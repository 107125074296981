import {
  api,
  IAssetOrderItemsBE,
  IEditOrderParams,
  IGetAssetOrderMetricsParams,
  IOrderItemsBE,
} from '@trader/api';
import { EOrderSide, IOrderMessage, TPlaceOrderSide } from '@trader/types';
import { devLoggerService, SmartLookService } from '@trader/services';
import { hasCopierFunctionality } from '@trader/constants';
import { returnTypedThis } from '@trader/utils';

import {
  TOrdersMetricsEntity,
  TOrderMetricEntity,
  TOrderMetricIn,
} from './index';
import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { ordersMetricsSchema } from './schemas';
import { TInstrumentEntity, TStripM } from '@trader/store';

export const getOrdersMetricsAsync = createThunk<
  {
    symbol?: IGetAssetOrderMetricsParams['symbol'];
    shouldClearBeforeMerge?: boolean;
  },
  void
>(
  options =>
    async function getOrdersMetrics(this: unknown, _options, _flow) {
      try {
        const { symbol, shouldClearBeforeMerge = false } = options || {};
        const that = returnTypedThis<TOrdersMetricsEntity>(this);
        const root = getRootInstance();

        const res = symbol
          ? await api.Trading.getAssetOrdersMetrics({ symbol })
          : await api.Trading.getOrdersMetrics();

        const orders = symbol
          ? [(res as IAssetOrderItemsBE).order]
          : (res as IOrderItemsBE).orders;

        const metrics = (orders || []).map(o =>
          o.orderMetrics.map(m => {
            const existsMetric = that.get<TOrderMetricEntity>(m.orderId);

            return {
              ...m,
              symbol: o.symbol,
              pipSize: o.pipSize,
              iconUrl: o.iconUrl,
              showOnTradingViewChart: existsMetric
                ? existsMetric.showOnTradingViewChart
                : true,
            };
          })
        );

        const ordersMetrics = metrics.flat(1);
        const normalized = root.entities.normalizeMerge(
          ordersMetrics,
          ordersMetricsSchema,
          shouldClearBeforeMerge
        );

        root.tables.orders.runInAction(() => {
          root.tables.orders.table.totalCount = res?.totalCount || 0;
        });
        root.tables.orders.table.list.set(normalized);
      } catch (e) {
        devLoggerService.error('Error in getOrdersMetricsAsync', e);
      }
    }
);

export const editOrderAsync = createThunk<IEditOrderParams, void>(
  params =>
    async function editOrder(this: unknown, _options, _flow) {
      const that = returnTypedThis<TOrderMetricEntity>(this);
      const root = getRootInstance();
      const muliBands = root.pages.muliBands;

      if (that?.strategyId) {
        await muliBands.editMuliBandsOrderAsync.run({
          orderId: params.orderId,
          price: params.body.price,
          stopLoss: params.body.stopLoss,
          takeProfit: params.body.takeProfit,
        });
        root.entities.ordersMetrics.update(params.orderId, {
          strategyId: null,
        });
      } else {
        if (hasCopierFunctionality) {
          await api.Trading.editChallengeOrder(params, _options);
        } else {
          await api.Trading.editOrder(params, _options);
        }
      }

      SmartLookService.track('Edit_order_event');

      root.ui.modal.close();
    }
);

export const cancelOrderAsync = createThunk<string, void>(
  orderId =>
    async function cancelOrder(this: unknown, _options, _flow) {
      if (hasCopierFunctionality) {
        await api.Trading.cancelChallengeOrder(orderId, _options);
      } else {
        await api.Trading.cancelOrder(orderId, _options);
      }
      SmartLookService.track('Cancel_order_event');
    }
);

export const cancelAllOrdersAsync = createThunk<void, void>(
  () =>
    async function cancelAllOrders(this: unknown, _options, _flow) {
      const root = getRootInstance();

      try {
        if (hasCopierFunctionality) {
          await api.Trading.cancelAllChallengeOrders(_options);
        } else {
          await api.Trading.cancelAllOrders(_options);
        }
        SmartLookService.track('Cancel_all_orders_event');

        root.ui.modal.close();
      } catch (e) {
        devLoggerService.error('catch cancelAllOrdersAsync error', e);
      }
    }
);

export const orderOptimisticCreateAsync = createThunk<IOrderMessage, void>(
  message =>
    async function orderOptimisticCreate(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const instrument = root.entities.instruments.get<TInstrumentEntity>(
        message.symbol
      );

      if (!instrument) {
        await root.entities.instruments.getInstrumentSpecificationAsync.run(
          message.symbol
        );
      }

      const strips = Array.from<TStripM>(root.pages.muliBands.strips.values());
      const isPartOfStrategy = strips.find(
        ({ orderId }) => orderId === message.id
      );

      const order: TOrderMetricIn = {
        symbol: message.symbol,
        iconUrl: instrument.iconUrl,
        pipSize: instrument.pipSize,
        orderId: String(message.id),
        strategyId: isPartOfStrategy ? root.pages.muliBands.id : null,
        type: 'Market',
        side: EOrderSide[message.side] as TPlaceOrderSide,
        quantity: message.volume,
        margin: 0,
        limitPrice: message.limitPrice,
        stopPrice: message.stopPrice,
        takeProfit: message.takeProfit || null,
        stopLoss: message.stopLoss || null,
        showOnTradingViewChart: true,
      };

      root.entities.ordersMetrics.add(order.orderId, order);
      root.tables.orders.table.list.addToBegin(order.orderId);
    }
);

export const orderOptimisticUpdateAsync = createThunk<IOrderMessage, void>(
  message =>
    // eslint-disable-next-line require-await
    async function orderOptimisticUpdate(this: unknown, _options, _flow) {
      const root = getRootInstance();

      root.entities.ordersMetrics.update(message.id, {
        limitPrice: message.limitPrice,
        stopPrice: message.stopPrice,
        side: EOrderSide[message.side] as TPlaceOrderSide,
        stopLoss: message.stopLoss,
        takeProfit: message.takeProfit,
        quantity: message.volume,
      });
    }
);

export const orderOptimisticDeleteAsync = createThunk<string, void>(
  id =>
    // eslint-disable-next-line require-await
    async function orderOptimisticDelete(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const order = root.entities.ordersMetrics.get(id);

      if (!order) return;

      root.tables.orders.table.list.removeById(id);
      root.entities.ordersMetrics.delete(id);
    }
);
