import React, { useCallback, useEffect } from 'react';
import { IChartingLibraryWidget } from '../../charting_library';
import { useMst } from '@trader/store';
import { EStripName } from '@trader/types';

interface ICreateStripLabelParams {
  price: number;
  id: EStripName;
  text: string;
  color: string;
}

type TCreateStripLabel = (params: ICreateStripLabelParams) => void;

const lineLength = 0.2;
const strips = new Map();

export const useStrips = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();

  const indicatorValues = Array.from(store.pages.muliBands.strips.values());
  const createStripLabel: TCreateStripLabel = ({ price, id, text, color }) => {
    const ref = widget?.current
      ?.activeChart()
      ?.createOrderLine()
      .setText(text)
      .setQuantity('')
      .setExtendLeft(false)
      .setLineStyle(0)
      .setLineColor(color)
      .setBodyTextColor('white')
      .setBodyBorderColor(color)
      .setBodyBackgroundColor(color)
      .setLineLength(lineLength)
      .setPrice(price);

    strips.set(id, ref);
  };

  const clearStrips = useCallback(() => {
    strips.clear();
  }, []);

  useEffect(() => {
    if (store.ui.tradingView.isChartLoaded) {
      widget?.current?.onChartReady(() => {
        indicatorValues.forEach(strip => {
          if (!strips.get(strip.id)) {
            strip.value &&
              createStripLabel({
                price: strip.value,
                id: strip.id,
                text: `${strip.change}; Cross: ${strip.cross}`,
                color: strip.color,
              });
          } else {
            strips.get(strip.id).setPrice(strip.value);
            strips
              .get(strip.id)
              .setText(`${strip.change}; Cross: ${strip.cross}`);
          }
        });
      });
    }
  }, [JSON.stringify(indicatorValues), store.ui.tradingView.isChartLoaded]);

  return { clearStrips };
};
