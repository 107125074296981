import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { ActionButtons, Tooltip, Wrapper, Stack } from '@trader/components';
import { useResponsiveDesign } from '@trader/hooks';
import { getInstrumentDifference } from '@trader/utils';
import { defaultIcon } from '@trader/constants';
import { IInstrumentDetailsHeader } from '@trader/containers';

import { RightSideActionBar } from './components/rightSideActionBar';
import { InstrumentSymbolTooltipTitle } from './components/instrumentSymbolTooltipTitle';
import * as Styled from './styled';

export const DesktopInstrumentDetailsHeader: FC<IInstrumentDetailsHeader> =
  observer(
    ({ instrument, isMuliBandsStrategy, onChangeSymbol, searchCategory }) => {
      const {
        instrumentDetailsHeader: {
          shouldApplyColumnDesign,
          shouldMinimizeRightSideActionBar,
        },
      } = useResponsiveDesign();

      if (!instrument?.symbol) {
        return null;
      }

      const { isGrow, openCloseDifferenceInPercent, openCloseDifference } =
        getInstrumentDifference(
          instrument.ask,
          instrument.close,
          instrument.pipSize
        );

      return (
        <Styled.Header
          $shouldApplyColumnDesign={shouldApplyColumnDesign}
          $isMuliBands={!!isMuliBandsStrategy}
        >
          <Wrapper>
            <Stack
              direction={shouldApplyColumnDesign ? 'column' : 'row'}
              alignItems={shouldApplyColumnDesign ? undefined : 'center'}
            >
              <Wrapper alignItems='flex-start'>
                <img
                  src={instrument.iconUrl || defaultIcon}
                  alt='instrument icon'
                  width='30px'
                  height='30px'
                />
                <Tooltip
                  title={
                    <InstrumentSymbolTooltipTitle instrument={instrument} />
                  }
                >
                  <Styled.Symbol>
                    {instrument.displayName || instrument.symbol}
                  </Styled.Symbol>
                </Tooltip>
              </Wrapper>
              <Styled.Difference $isAboveZero={isGrow}>
                {openCloseDifference} ({openCloseDifferenceInPercent}%)
              </Styled.Difference>
            </Stack>
            <ActionButtons
              direction={shouldApplyColumnDesign ? 'column' : 'row'}
              symbol={instrument.symbol}
              ask={instrument.ask}
              bid={instrument.bid}
              pipSize={instrument.pipSize}
            />
          </Wrapper>
          <RightSideActionBar
            instrument={instrument}
            isMinimized={shouldMinimizeRightSideActionBar}
            isMuliBandsStrategy={isMuliBandsStrategy}
            isColumnDesign={shouldApplyColumnDesign}
            searchCategory={searchCategory}
            onChangeSymbol={onChangeSymbol}
          />
        </Styled.Header>
      );
    }
  );
