import styled, { css } from 'styled-components';

import { Wrapper, Typography } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 408px;

    ${theme.breakpoints.down('sm')} {
      min-width: 100%;
    }
  `}
`;

export const SelectWrapper = styled('div')`
  ${() => css`
    width: 100%;
    padding: 0 18px;
    margin: 8px 0;

    .MuiInputBase-root {
      height: 45px;
    }
  `}
`;

export const MaxLeverageText = styled(Typography)`
  ${() => css`
    padding: 0 18px;
    white-space: normal;
  `}
`;
