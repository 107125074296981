import React, { ReactNode } from 'react';

import { TIconsName } from '@trader/assets';
import { Icon } from '@trader/components';

import * as Styled from './styled';

interface ITradingIsClosed {
  iconTypeName?: TIconsName;
  title?: string;
  content?: string | ReactNode;
  buttonContent?: string;
  onClick?: () => void;
}

export const TradingIsClosed: React.FC<ITradingIsClosed> = ({
  iconTypeName,
  title,
  content,
  buttonContent,
  onClick,
}) => {
  return (
    <Styled.Root>
      {!!iconTypeName && <Icon iconType={iconTypeName} />}
      {!!title && <Styled.Title>{title}</Styled.Title>}
      {!!content && <Styled.Content>{content}</Styled.Content>}
      {!!buttonContent && (
        <Styled.Submit onClick={onClick}>{buttonContent}</Styled.Submit>
      )}
    </Styled.Root>
  );
};
