import * as yup from 'yup';

import {
  highMultiplier,
  lowMultiplier,
  multiplierStep,
} from '@trader/constants';
import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';

const minStopLoss = 1;

export const useBackTestingFormValidation = () => {
  const store = useMst();
  const { translate } = useI18next();

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    backTesting.getSymbol()
  );

  const trading = store.trading.getTrading('createStrategyBand');

  const SlValidation = (isRequired: boolean, value?: number) => {
    if (!isRequired) {
      return true;
    }

    if (!value) {
      return false;
    }

    return value >= minStopLoss;
  };

  return yup.object().shape({
    lowestMultiplier: yup
      .number()
      .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD'))
      .min(
        lowMultiplier,
        translate('COMMON.ERRORS.SHOULD_BE_BIGGER_THAN', {
          amount: lowMultiplier,
        })
      )
      .max(
        highMultiplier,
        translate('COMMON.ERRORS.SHOULD_BE_LESS_THAN', {
          amount: highMultiplier,
        })
      )
      .test(
        'check-multiplier',
        translate('COMMON.ERRORS.REQUIRED_FIELD'),
        value => {
          return !!value || !!Number(value);
        }
      ),
    highestMultiplier: yup
      .number()
      .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD'))
      .min(
        lowMultiplier,
        translate('COMMON.ERRORS.SHOULD_BE_BIGGER_THAN', {
          amount: lowMultiplier,
        })
      )
      .max(
        highMultiplier,
        translate('COMMON.ERRORS.SHOULD_BE_LESS_THAN', {
          amount: highMultiplier,
        })
      )
      .when(['lowestMultiplier'], ([lowestMultiplier], schema) => {
        return schema.test(
          'check-lowestMultiplier',
          translate('COMMON.ERRORS.SHOULD_BE_BIGGER_THAN', {
            amount: lowMultiplier,
          }),
          value => {
            if (!value) {
              return false;
            }

            if (lowestMultiplier) {
              return value >= lowestMultiplier;
            }
          }
        );
      }),
    multiplierStep: yup
      .number()
      .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD'))
      .min(
        multiplierStep,
        translate('COMMON.ERRORS.SHOULD_BE_BIGGER_THAN', {
          amount: multiplierStep,
        })
      )
      .max(
        highMultiplier,
        translate('COMMON.ERRORS.SHOULD_BE_LESS_THAN', {
          amount: highMultiplier,
        })
      )
      .test(
        'check-multiplier',
        translate('COMMON.ERRORS.REQUIRED_FIELD'),
        value => {
          return !!value || !!Number(value);
        }
      ),
    from: yup
      .date()
      .typeError('Invalid date')
      .required(translate('COMMON.ERRORS.REQUIRED_FIELD')),
    to: yup
      .date()
      .typeError('Invalid date')
      .when(['from'], ([from], schema) => {
        return schema.test('dates-diff', 'Invalid date', value => {
          if (!value) {
            return false;
          }

          if (from) {
            const fromTimestamp = new Date(from).getTime();
            const toTimestamp = new Date(value).getTime();
            return toTimestamp > fromTimestamp;
          }
        });
      })
      .required(translate('COMMON.ERRORS.REQUIRED_FIELD')),
    sessionFrom: yup
      .date()
      .typeError('Invalid time')
      .when(['isSessionRange'], ([isSessionRange], schema) => {
        return schema.test('sessionFrom', 'Invalid time', value => {
          if (!isSessionRange) {
            return true;
          }

          return !!value;
        });
      }),
    sessionTo: yup
      .date()
      .typeError('Invalid time')
      .when(
        ['sessionFrom', 'isSessionRange'],
        ([sessionFrom, isSessionRange], schema) => {
          return schema.test('sessionTo', 'Invalid time', value => {
            if (!isSessionRange) {
              return true;
            }

            if (!sessionFrom) {
              return true;
            }

            if (!value) {
              return false;
            }

            if (sessionFrom) {
              const fromTimestamp = new Date(sessionFrom).getTime();
              const toTimestamp = new Date(value).getTime();
              return toTimestamp > fromTimestamp;
            }
          });
        }
      ),
    quantity: yup
      .number()
      .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD'))
      .min(
        trading.minAmount(),
        translate('COMMON.ERRORS.SHOULD_BE_BIGGER_THAN', {
          amount: trading.minAmount(),
        })
      )
      .max(
        trading.maxAmount(instrument?.maxOrderSize),
        translate('COMMON.ERRORS.SHOULD_BE_LESS_THAN', {
          amount: trading.maxAmount(instrument?.maxOrderSize),
        })
      )
      .required(translate('COMMON.ERRORS.REQUIRED_FIELD')),
    sl: yup
      .number()
      .typeError('Must be number')
      .when(['isSl'], ([isSl], schema) => {
        return schema.test('sl', 'Invalid value', value =>
          SlValidation(isSl, value)
        );
      }),
    slDay: yup
      .number()
      .typeError('Must be number')
      .when(['isSlDay'], ([isSlDay], schema) => {
        return schema.test('slDay', 'Invalid value', value =>
          SlValidation(isSlDay, value)
        );
      }),
  });
};
