import { Instance, types } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

import { createTableModel } from '../../utils/tableModel';
import { scheduledOrderModel } from '../../entities/scheduledOrders';

export const scheduledOrdersTableModel = types
  .model('scheduledOrdersTableModel', {
    table: createTableModel(scheduledOrderModel, {}),
  })
  .actions(() => ({
    runInAction,
  }));

export const scheduledOrdersTable = types.optional(
  scheduledOrdersTableModel,
  {}
);

export type TScheduledOrdersTableModel = Instance<
  typeof scheduledOrdersTableModel
>;
