import React from 'react';
import { observer } from 'mobx-react-lite';
import { UseFormReset } from 'react-hook-form';

import { useChangePasswordSchema } from '@trader/hooks';
import { Controller, Form } from '@trader/components';
import { IChangePasswordParams } from '@trader/api';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import * as Styled from './styled';
import { SaveButton } from '../../desktop.styled';

type TInitialState = {
  password: string;
  proposedPassword: string;
  matchProposedPassword: string;
};

type THandleSubmit = (
  reset: UseFormReset<TInitialState>,
  data: TInitialState
) => void;

const defaultValues = {
  password: '',
  proposedPassword: '',
  matchProposedPassword: '',
};

export const ChangePasswordTab: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { validationSchema } = useChangePasswordSchema();

  const handleSubmit: THandleSubmit = async (reset, data) => {
    const body: IChangePasswordParams = {
      previousPassword: data.password,
      proposedPassword: data.proposedPassword,
    };
    await store.auth.changePasswordAsync.run(body);
    reset(defaultValues);
  };

  return (
    <Styled.Root>
      <Form<TInitialState>
        mode='all'
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ control, formState, trigger }) => (
          <Styled.FormContent>
            <Styled.Inputs>
              <Styled.Field>
                <Styled.Label>
                  {translate('COMMON.LABELS.CURRENT_PASSWORD')}
                </Styled.Label>
                <Controller
                  type='passwordField'
                  keyboardType='password'
                  label={translate('COMMON.LABELS.CURRENT_PASSWORD')}
                  control={control}
                  name='password'
                />
              </Styled.Field>
              <Styled.Field>
                <Styled.Label>
                  {translate('COMMON.LABELS.NEW_PASSWORD')}
                </Styled.Label>
                <Controller
                  type='passwordField'
                  keyboardType='password'
                  label={translate('COMMON.LABELS.NEW_PASSWORD')}
                  control={control}
                  name='proposedPassword'
                  onCustomChange={() => {
                    trigger('matchProposedPassword');
                    trigger('proposedPassword');
                  }}
                />
              </Styled.Field>
              <Styled.Field>
                <Styled.Label>
                  {translate('COMMON.LABELS.RETYPE_PASSWORD')}
                </Styled.Label>
                <Controller
                  type='passwordField'
                  keyboardType='password'
                  label={translate('COMMON.LABELS.RETYPE_PASSWORD')}
                  control={control}
                  name='matchProposedPassword'
                  onCustomChange={() => {
                    trigger('matchProposedPassword');
                    trigger('proposedPassword');
                  }}
                />
              </Styled.Field>
            </Styled.Inputs>
            <SaveButton
              loading={store.auth.changePasswordAsync.inProgress}
              disabled={
                !formState.isDirty ||
                store.auth.changePasswordAsync.inProgress ||
                !formState.isValid
              }
              type='submit'
            >
              {translate('COMMON.LABELS.SAVE_CHANGES')}
            </SaveButton>
          </Styled.FormContent>
        )}
      </Form>
    </Styled.Root>
  );
});
