import { ILibrarySymbolInfo } from './types';
import {
  DatafeedErrorCallback,
  HistoryCallback,
  PeriodParams,
  ResolutionString,
  SubscribeBarsCallback,
} from './charting_library';
import {
  rootStore,
  TInstrumentEntity,
  TTradingAccountEntity,
} from '@trader/store';
import {
  defaultPipSize,
  emptyFn,
  convertTimeToUtc,
  allBarsXStrategy,
} from '@trader/constants';
import {
  EConnectionHub,
  EConnectionSubscription,
  webSocketsService,
  webSocketsUrls,
  devLoggerService,
  TWebSocketsService,
} from '@trader/services';
import {
  lastBarResolutions,
  resetLastBarResolutions,
  resolutions,
  invertedResolutions,
  TKeyResolutions,
  IResolution,
} from './utils';
import {
  getHistoricalBars,
  handleMuliBandsRealTimeBar,
  handleRealTimeBar,
} from './utils/handleBars';

export { resolutions, invertedResolutions };

interface IDatafeed {
  getSymbolData: (symbol: string) => TInstrumentEntity;
  activeTradingAccount: TTradingAccountEntity;
  idToken: string;
  isMuliBandsStrategy?: boolean;
}

export let resetCacheNeededCallback = emptyFn;

// DatafeedConfiguration implementation
const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: Object.keys(resolutions) as Array<ResolutionString>,
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [{ value: '' }],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ value: '' }],
};
let fromTime = 0;

let connection: TWebSocketsService | null = null;

export default ({
  getSymbolData,
  activeTradingAccount,
  idToken,
  isMuliBandsStrategy,
}: IDatafeed) => ({
  onReady: (callback: (config: typeof configurationData) => void) => {
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: emptyFn,
  resolveSymbol: (
    symbol: string,
    onSymbolResolvedCallback: (symbolInfo: ILibrarySymbolInfo) => void
  ) => {
    if (isMuliBandsStrategy) {
      connection = webSocketsService({
        hub: EConnectionHub.Events,
        subscription: EConnectionSubscription.MuliBands,
        url: webSocketsUrls.events,
        logout: rootStore.auth.logOut,
        refreshToken: async () => {
          await rootStore.auth.authRefreshTokenAsync.run();
        },
      });
    } else {
      connection = webSocketsService({
        hub: EConnectionHub.Quotes,
        subscription: EConnectionSubscription.Instrument,
        url: webSocketsUrls.quotes,
        logout: rootStore.auth.logOut,
        refreshToken: async () => {
          await rootStore.auth.authRefreshTokenAsync.run();
        },
      });
    }

    allBarsXStrategy.clear();

    const data = getSymbolData(symbol);

    const pricescale = (data?.pipSize || defaultPipSize)
      .toString()
      .split('.')
      .join('')
      .split('')
      .reverse()
      .join('');

    const symbolInfo: ILibrarySymbolInfo = {
      ticker: symbol as string,
      name: symbol as string,
      description: (data?.description as string) || '',
      pipSize: data?.pipSize || defaultPipSize,
      supported_resolutions: configurationData.supported_resolutions,
      type: data?.category || '',
      session: '24x7',
      timezone: 'Etc/UTC',
      exchange: '',
      minmov: 1,
      pricescale: Number(pricescale),
      has_intraday: true,
      visible_plots_set: 'ohlc',
      has_weekly_and_monthly: true,
      volume_precision: 2,
      data_status: 'streaming',
    };

    setTimeout(() => onSymbolResolvedCallback(symbolInfo));
  },

  getBars: async (
    symbolInfo: ILibrarySymbolInfo,
    resolutionKey: TKeyResolutions,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: DatafeedErrorCallback
  ) => {
    try {
      if (isMuliBandsStrategy && !rootStore.pages.muliBands.id) {
        return;
      }

      const response = await getHistoricalBars({
        symbol: symbolInfo.name,
        strategyId: rootStore.pages.muliBands.id as number,
        isFirstRequest: periodParams.firstDataRequest,
        isMuliBandsStrategy: isMuliBandsStrategy,
        resolution: resolutions[resolutionKey],
        fromTime,
        upFloatMultiplier: Number(rootStore.pages.muliBands.floatTopMultiplier),
        downFloatMultiplier: Number(
          rootStore.pages.muliBands.floatBottomMultiplier
        ),
      });

      if (response?.length === 0) {
        onHistoryCallback([], { noData: true });
        return;
      }

      const bars = response.map(bar => {
        if (!isMuliBandsStrategy) {
          bar.t = bar.t * convertTimeToUtc;
        }

        isMuliBandsStrategy && allBarsXStrategy.set(bar.t, bar);

        return {
          time: bar.t,
          low: bar.l,
          high: bar.h,
          open: bar.o,
          close: bar.c,
          volume: bar.v,
        };
      });

      // set last bar time for next fetching
      // eslint-disable-next-line require-atomic-updates
      fromTime = bars[0].time;

      if (periodParams.firstDataRequest) {
        resetLastBarResolutions();

        const lastBar = bars[bars.length - 1];

        lastBarResolutions.set(resolutionKey, {
          ...(lastBarResolutions.get(resolutionKey) as IResolution),
          symbol: symbolInfo.name,
          low: lastBar.low,
          close: lastBar.close,
          high: lastBar.high,
          open: lastBar.open,
          volume: lastBar.volume,
          time: lastBar.time,
        });

        if (isMuliBandsStrategy) {
          rootStore.pages.muliBands.setPrice(
            (lastBarResolutions.get(resolutionKey) as IResolution).close
          );
        }

        setTimeout(() => onHistoryCallback(bars, { noData: false }));
      } else {
        // fetching more bars while scrolling
        setTimeout(() => onHistoryCallback(bars, { noData: false }));
      }
    } catch (error) {
      devLoggerService.error('getBarsError', error);
      onErrorCallback(error as string);
    }
  },

  subscribeBars: (
    symbolInfo: ILibrarySymbolInfo,
    resolutionKey: TKeyResolutions,
    onRealtimeCallback: SubscribeBarsCallback,
    _listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ) => {
    resetCacheNeededCallback = onResetCacheNeededCallback;
    rootStore.ui.tradingView.toggleIsChartLoaded(true);

    if (!connection) {
      return;
    }

    const resolution = lastBarResolutions.get(resolutionKey) as IResolution;

    connection.start(idToken);

    if (isMuliBandsStrategy) {
      connection.subscribe(hub => {
        hub.on('OnStrategy', message =>
          handleMuliBandsRealTimeBar({
            message,
            onRealtimeCallback,
          })
        );
      });
    } else {
      connection.subscribe(async hub => {
        await hub.send(
          'SubscribeOnQuotes',
          activeTradingAccount.serverName,
          activeTradingAccount.platformLogin,
          [symbolInfo.name]
        );
        hub.on('onQuote', message =>
          handleRealTimeBar({
            symbol: symbolInfo.name,
            pipSize: symbolInfo.pipSize,
            message,
            resolution: resolution,
            onRealtimeCallback,
          })
        );
      });
    }
  },
  unsubscribeBars: emptyFn,
});
