import styled, { css } from 'styled-components';

import { Icon, Typography } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { layoutMargin } from '@trader/constants';

export const Paragraph = styled(Typography)<{ $color?: string }>`
  ${({ theme, $color }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.small.fontSize};
    line-height: 20px;
    white-space: pre-wrap;
    text-overflow: clip;
    margin-top: 6px;
    color: ${$color};

    > span {
      font-weight: 600;
    }

    .resistance {
      color: rgb(49, 146, 26);
    }

    .support {
      color: rgb(219, 46, 39);
    }

    .pivot {
      color: rgb(101, 67, 186);
    }

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.large.fontSize};
    }
  `};
`;

export const Title = styled(Typography)`
  ${() => css`
    font-weight: 600;
    margin-top: 20px;
    white-space: pre-wrap;
    text-overflow: clip;
  `};
`;

const zoomedImageHeight = 629;
const zoomedImageWidth = 929;
const heightDividerOn900px = 1.35;
const heightDividerOn800px = 1.75;
export const TradingCentralChartImage = styled('img')<{ $isZoomed: boolean }>`
  ${({ $isZoomed }) => css`
    @media (min-height: 600px) {
      cursor: zoom-in;
      margin-top: 6px;
      margin-bottom: -2px;

      ${conditionApply(
        [$isZoomed],
        css`
          cursor: zoom-out;
          position: fixed;
          z-index: 9999;
          right: ${layoutMargin}px;
          width: ${zoomedImageWidth}px;
          height: ${zoomedImageHeight}px;

          @media (max-height: 900px) {
            height: ${zoomedImageHeight / heightDividerOn900px}px;
            width: ${zoomedImageWidth / heightDividerOn900px}px;
          }

          @media (max-height: 800px) {
            height: ${zoomedImageHeight / heightDividerOn800px}px;
            width: ${zoomedImageWidth / heightDividerOn800px}px;
          }
        `
      )};
    }
  `};
`;

const zoomedImageOffset = 10;
export const Information = styled('div')<{ $isZoomed: boolean }>`
  ${({ $isZoomed }) => css`
    @media (min-height: 600px) {
      margin-top: ${$isZoomed
        ? `${zoomedImageHeight + zoomedImageOffset}px`
        : undefined};

      @media (max-height: 900px) {
        margin-top: ${$isZoomed
          ? `${zoomedImageHeight / heightDividerOn900px + zoomedImageOffset}px`
          : undefined};
      }

      @media (max-height: 800px) {
        margin-top: ${$isZoomed
          ? `${zoomedImageHeight / heightDividerOn800px + zoomedImageOffset}px`
          : undefined};
      }
    }
  `};
`;

export const AboveBelowIcon = styled(Icon)`
  ${() => css`
    margin: 0 3px -1px 0;
  `};
`;
