import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    padding: 0 ${modalHorizontalPadding}px;
    width: 100%;
  `}
`;

export const ConfirmButton = styled(Button)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};
    padding: 12px;
    margin-top: 4px;
    border-radius: 5px;

    svg {
      color: ${theme.palette.common.white};
    }

    ${theme.breakpoints.down('sm')} {
      margin-top: 0;
    }
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.common.white};
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    text-wrap: wrap;
  `}
`;
