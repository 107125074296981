import { useMediaQuery, useTheme } from '@mui/material';

import { DesktopAccountInformation } from './desktop';
import { MobileAccountInformation } from './mobile';

export const AccountInformation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobileAccountInformation />
  ) : (
    <DesktopAccountInformation />
  );
};
