import styled, { css } from 'styled-components';

export const Root = styled('div')`
  ${({ theme }) => css`
    margin: 10px 0;
    min-width: 230px;
    max-height: 229px;
    overflow: auto;
    padding: 0 10px;

    &::-webkit-scrollbar {
      border-radius: 5px;
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }

    ${theme.breakpoints.down('sm')} {
      margin: 10px 0 -10px 0;
    }
  `}
`;
