import { Stack as MuiStack, StackProps as MuiStackProps } from '@mui/material';
import styled, { css } from 'styled-components';

import { conditionApply } from '@trader/utils';

interface IProps extends MuiStackProps {
  $spacing: MuiStackProps['spacing'];
  $margin: MuiStackProps['margin'];
  $marginTop: MuiStackProps['marginTop'];
  $marginRight: MuiStackProps['marginRight'];
  $marginBottom: MuiStackProps['marginBottom'];
  $marginLeft: MuiStackProps['marginLeft'];
  $padding: MuiStackProps['marginTop'];
  $paddingTop: MuiStackProps['marginTop'];
  $paddingRight: MuiStackProps['marginTop'];
  $paddingBottom: MuiStackProps['marginTop'];
  $paddingLeft: MuiStackProps['marginTop'];
}

// Custom Stack component to handle spacing correctly in RTL mode.
export const Stack = styled(MuiStack)<IProps>`
  ${({
    theme,
    direction,
    $spacing,
    $margin,
    $marginTop,
    $marginRight,
    $marginBottom,
    $marginLeft,
    $padding,
    $paddingTop,
    $paddingRight,
    $paddingBottom,
    $paddingLeft,
  }) => css`
    margin: ${typeof $margin === 'number' ? theme.spacing($margin) : $margin};
    margin-right: ${typeof $marginRight === 'number'
      ? theme.spacing($marginRight)
      : $marginRight};
    margin-bottom: ${typeof $marginBottom === 'number'
      ? theme.spacing($marginBottom)
      : $marginBottom};
    margin-left: ${typeof $marginLeft === 'number'
      ? theme.spacing($marginLeft)
      : $marginLeft};
    margin-top: ${typeof $marginTop === 'number'
      ? theme.spacing($marginTop)
      : $marginTop};

    padding: ${typeof $padding === 'number'
      ? theme.spacing($padding)
      : $padding};
    padding-right: ${typeof $paddingRight === 'number'
      ? theme.spacing($paddingRight)
      : $paddingRight};
    padding-bottom: ${typeof $paddingBottom === 'number'
      ? theme.spacing($paddingBottom)
      : $paddingBottom};
    padding-left: ${typeof $paddingLeft === 'number'
      ? theme.spacing($paddingLeft)
      : $paddingLeft};
    padding-top: ${typeof $paddingTop === 'number'
      ? theme.spacing($paddingTop)
      : $paddingTop};

    ${conditionApply(
      [$spacing && direction === 'row'],
      css`
        & > *:not(:first-child) {
          margin-left: ${typeof $spacing === 'number'
            ? theme.spacing($spacing)
            : $spacing};
        }
      `
    )}

    ${conditionApply(
      [$spacing && direction === 'column'],
      css`
        & > *:not(:first-child) {
          margin-top: ${typeof $spacing === 'number'
            ? theme.spacing($spacing)
            : $spacing};
        }
      `
    )}
  `};
`;
