import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material';

import { formatByPipSize, getMaxDigitWithDot } from '@trader/utils';
import { TAlertEntity, TInstrumentEntity, useMst } from '@trader/store';
import { TradingInput } from '@trader/trading';
import {
  Wrapper,
  ModalTitle,
  Progress,
  Select,
  InfoChip,
  Typography,
  Stack,
} from '@trader/components';
import { defaultIcon } from '@trader/constants';
import {
  inAppMessagingService,
  TTranslationKeys,
  useI18next,
} from '@trader/services';
import { ICreateAlertBody } from '@trader/api';

import * as Styled from '../styled';

type TPriceAlertSide = 'sellPrice' | 'buyPrice';
type TPriceAlertType = 'Above' | 'Below';

const targetPricePipSizeMultiplier = 3;

const CreateEditPriceAlert: React.FC = observer(() => {
  const { translate } = useI18next();
  const theme = useTheme();

  const store = useMst();
  const alertId = store.ui.modal.options.get('alertId');
  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  const [targetPrice, setTargetPrice] = useState<string>(
    formatByPipSize(
      instrument.ask + instrument.pipSize * targetPricePipSizeMultiplier,
      instrument.pipSize
    )
  );
  const [selectedType, setSelectedType] = useState<TPriceAlertType>('Above');
  const [selectedSide, setSelectedSide] =
    useState<TPriceAlertSide>('sellPrice');
  const [validationError, setValidationError] = useState('');

  const currentPrice =
    selectedSide === 'sellPrice' ? instrument.ask : instrument.bid;
  const isEditingMode = !!alertId;
  const sideSelectionOptions = [
    { title: translate('COMMON.LABELS.FOR_SELL_PRICE'), value: 'sellPrice' },
    { title: translate('COMMON.LABELS.FOR_BUY_PRICE'), value: 'buyPrice' },
  ];

  /**
   * Initializing the Edit alert state.
   */
  useEffect(() => {
    const alert = store.entities.alerts.get<TAlertEntity>(alertId);
    if (alert) {
      setTargetPrice(alert.conditions[0].rightExpression.parameters.Number);
      setSelectedType(
        alert.conditions[0].compareType === 'BiggerThen' ? 'Above' : 'Below'
      );
    }
  }, [instrument.symbol]);

  /**
   * Handle the price alert validation.
   */
  useEffect(() => {
    const amount = formatByPipSize(currentPrice, instrument.pipSize);
    const currency = instrument.currencySymbol;
    const isComparePrice = Number(targetPrice) <= Number(currentPrice);
    let translationErrorKey = '';

    if (selectedType === 'Below' && !isComparePrice) {
      translationErrorKey = 'COMMON.ERRORS.SHOULD_BE_LESS_THAN';
    }

    if (selectedType === 'Above' && isComparePrice) {
      translationErrorKey = 'COMMON.ERRORS.SHOULD_BE_BIGGER_THAN';
    }

    const error = translate(translationErrorKey as TTranslationKeys, {
      currency,
      amount,
    });
    setValidationError(translationErrorKey ? error : '');
  }, [selectedType, targetPrice, currentPrice]);

  const handleCreatePriceAlert = () => {
    const body: ICreateAlertBody = {
      name: new Date().toISOString(),
      conditions: [
        {
          compareType: selectedType === 'Above' ? 'BiggerThen' : 'LessThen',
          rightExpression: {
            indicatorType: 'Number',
            parameters: {
              Number: targetPrice,
            },
          },
          leftExpression: {
            indicatorType: selectedSide === 'sellPrice' ? 'Ask' : 'Bid',
            parameters: {},
          },
          instrument: {
            symbol: instrument.symbol,
          },
        },
      ],
      autoStart: true,
    };

    isEditingMode
      ? store.entities.alerts.editAlertAsync.run({ ...body, id: alertId })
      : store.entities.alerts.createAlertAsync.run(body);
  };

  return (
    <Styled.Root>
      <ModalTitle
        bottomMargin='0'
        title={
          alertId
            ? translate('COMMON.LABELS.EDIT_ALERT')
            : translate('COMMON.LABELS.CREATE_NEW_ALERT')
        }
      />
      {!inAppMessagingService.isPermissionGranted() && (
        <InfoChip
          iconType='warning'
          iconSize='30px'
          text={translate('WARNINGS.NOTIFICATION_DISABLED')}
          textColor={theme.palette.yellow.light}
          backgroundColor={theme.palette.yellow.main}
          margin='8px 18px'
        />
      )}
      <Styled.InstrumentInfo>
        <Wrapper alignItems='center'>
          <img
            src={instrument?.iconUrl || defaultIcon}
            alt='instrument icon'
            width={30}
            height={30}
          />
          <Wrapper flexDirection='column' marginLeft='8px'>
            <Styled.Symbol>{instrument.symbol}</Styled.Symbol>
            <Typography>{instrument.description}</Typography>
          </Wrapper>
        </Wrapper>
        <Wrapper alignItems='flex-end' flexDirection='column'>
          <Styled.Label>
            {translate('COMMON.LABELS.CURRENT_PRICE')}
          </Styled.Label>
          <Typography>
            {instrument.currencySymbol}
            {formatByPipSize(currentPrice, instrument.pipSize)}
          </Typography>
        </Wrapper>
      </Styled.InstrumentInfo>
      <Styled.Selection>
        <Stack direction='row' spacing='12px' marginBottom='12px'>
          <Styled.SideSelectionButton
            $isSelected={selectedType === 'Above'}
            onClick={() => setSelectedType('Above')}
          >
            {translate('COMMON.LABELS.ABOVE')}
          </Styled.SideSelectionButton>
          <Styled.SideSelectionButton
            $isSelected={selectedType === 'Below'}
            onClick={() => setSelectedType('Below')}
          >
            {translate('COMMON.LABELS.BELOW')}
          </Styled.SideSelectionButton>
        </Stack>
        <Select
          options={sideSelectionOptions}
          value={sideSelectionOptions.filter(o => o.value === selectedSide)}
          onChange={v =>
            setSelectedSide(v.target.value[0].value as TPriceAlertSide)
          }
          sx={{
            '& .MuiSelect-select': {
              padding: '10px 32px 10px 14px',
            },
          }}
          fullWidth
        />
      </Styled.Selection>
      <Styled.Input>
        <TradingInput
          customLabel={translate('COMMON.LABELS.TARGET_PRICE')}
          value={targetPrice}
          step={instrument.pipSize}
          onChange={event => setTargetPrice(event.target.value)}
          fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
          minValue={0}
          helperText={validationError}
          isError={!!validationError}
        />
      </Styled.Input>
      <Styled.ConfirmButton
        disabled={
          !!validationError ||
          store.entities.alerts.createAlertAsync.inProgress ||
          store.entities.alerts.editAlertAsync.inProgress
        }
        loading={
          store.entities.alerts.createAlertAsync.inProgress ||
          store.entities.alerts.editAlertAsync.inProgress
        }
        onClick={handleCreatePriceAlert}
      >
        {isEditingMode
          ? translate('COMMON.LABELS.EDIT_ALERT')
          : translate('COMMON.LABELS.CREATE_ALERT')}
      </Styled.ConfirmButton>
    </Styled.Root>
  );
});

export const CreateEditPriceAlertWrapper: React.FC = observer(() => {
  const store = useMst();

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  if (!instrument || !instrument.symbol) {
    return (
      <Styled.Root height='320px'>
        <Progress />
      </Styled.Root>
    );
  }

  return <CreateEditPriceAlert />;
});
