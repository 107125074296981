import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    flex-direction: column;
  `}
`;

export const Note = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.default.fontSize};
    color: ${theme.palette.gray.dark};

    ${theme.breakpoints.down('sm')} {
      width: 350px;
      text-wrap: auto;
    }
  `}
`;
