import { api } from '@trader/api';
import { devLoggerService } from '@trader/services';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { scheduledOrdersSchema } from './schemas';
import { EScheduledOrderStatus } from '@trader/types';

export const getScheduledOrdersAsync = createThunk<void, void>(
  () =>
    async function getScheduledOrders(this: unknown, _options, _flow) {
      try {
        const root = getRootInstance();
        const res = await api.BandStrategy.getScheduledOrders(_options);

        const scheduledOrders = res.filter(
          order => order.status === EScheduledOrderStatus.Created
        );

        const normalized = root.entities.normalizeMerge(
          scheduledOrders,
          scheduledOrdersSchema,
          true
        );

        root.tables.scheduledOrders.runInAction(() => {
          root.tables.scheduledOrders.table.totalCount = scheduledOrders.length;
        });
        root.tables.scheduledOrders.table.list.set(normalized);
      } catch (e) {
        devLoggerService.error('Error in getScheduledOrdersAsync', e);
      }
    }
);
