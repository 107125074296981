import styled, { css } from 'styled-components';

import { Typography } from '@trader/components';

export const Title = styled(Typography)`
  ${({ theme }) => css`
    background-color: ${theme.palette.common.white};
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    padding: 16px 20px;
    border-bottom: 1px solid ${theme.palette.gray.light};
    border-radius: 5px;
  `};
`;
