import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { usePriceAlertsCurrentPrice } from '@trader/hooks';

import { MobileAlertsList } from './mobile';
import { DesktopAlertsList } from './desktop';

export const AlertsList: FC = () => {
  usePriceAlertsCurrentPrice();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? <MobileAlertsList /> : <DesktopAlertsList />;
};
