import { FC, MouseEvent } from 'react';

import { MODAL_TYPES } from '@trader/constants';
import { TAlertEntity, useMst } from '@trader/store';
import { ScrollableItemActions } from '@trader/components';

interface IAlertActions {
  alert: TAlertEntity;
}

export const AlertActions: FC<IAlertActions> = ({ alert }) => {
  const store = useMst();

  const handleEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    store.ui.modal.open(MODAL_TYPES.editPriceAlert, {
      symbol: alert.conditions[0].instrument.symbol,
      alertId: alert.id,
    });
  };

  const handleRemoveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    store.ui.modal.open(MODAL_TYPES.deletePriceAlert, {
      symbol: alert.conditions[0].instrument.symbol,
      alertId: alert.id,
    });
  };

  return (
    <ScrollableItemActions
      onEditClick={handleEditClick}
      onRemoveClick={handleRemoveClick}
    />
  );
};
