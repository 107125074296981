import { useCallback, useEffect, useState } from 'react';

import {
  TTradingAccountEntity,
  TConversionRateInstrumentsFE,
  useMst,
} from '@trader/store';
import { IMessage } from '@trader/types';
import { IGetExchangeRateInstrumentsBody } from '@trader/api';

import { calcPositionNetPl, calcPositionPl } from '@trader/utils';
import { useInstruments } from './useInstruments';
import { EConnectionSubscription } from '@trader/services';

type TUpdatedPositions = {
  positions: { positionId: string; conversionRate: number }[];
  platformLogin: string;
};

interface IParams {
  subscription?: EConnectionSubscription;
  account: TTradingAccountEntity;
  onUpdate: (updatedPositions: TUpdatedPositions) => void;
}

export const usePositionsConversionRate = (params?: IParams) => {
  const store = useMst();

  const [positionsConversionRate, setPositionsConversionRate] =
    useState<TConversionRateInstrumentsFE>([]);

  const positionSymbols = positionsConversionRate.map(per => per.rateSymbol);
  const uniqueSymbols = new Set(positionSymbols);

  const isAuth = store.auth.isAuth;

  const tradingAccount = (params?.account ||
    store.user.tradingAccount) as TTradingAccountEntity;

  const platformLogin = tradingAccount?.platformLogin;
  const portfolioCurrency = tradingAccount?.currency;
  const positions = tradingAccount?.positions;

  const fetchPositionsExchangeRate = async () => {
    const body: IGetExchangeRateInstrumentsBody[] = [];

    for (const position of positions) {
      const isPortfolioCurrency = position.currency === portfolioCurrency;
      const isSymbolAdded = !!body.find(i => i.symbol === position.symbol);
      const isSymbolRateFetched = !!positionsConversionRate.find(
        ers => ers.positionSymbol === position.symbol
      );

      if (!isPortfolioCurrency && !isSymbolAdded && !isSymbolRateFetched) {
        body.push({
          currencyBase: portfolioCurrency,
          calcCurrency: position.currency,
          symbol: position.symbol,
        });
      }
    }

    if (body.length > 0) {
      const resp =
        await store.entities.instruments.getExchangeRateInstrumentsAsync.run(
          body
        );
      resp && setPositionsConversionRate(prev => [...prev, ...resp]);
    }
  };

  const handleMessage = useCallback(
    (message: IMessage) => {
      if (!uniqueSymbols.has(message.s)) return;

      const positionExchangeRate = positionsConversionRate.find(
        per => per.rateSymbol === message.s
      );
      const positionSymbolToUpdate = positionExchangeRate?.positionSymbol;
      const isReversed = positionExchangeRate?.isReversed;
      const updatedPositions: TUpdatedPositions['positions'] = [];

      for (const position of positions) {
        if (position.symbol === positionSymbolToUpdate) {
          const conversionRateValue =
            position.side === 'Buy' ? message.b : message.a;
          const conversionRate = isReversed
            ? 1 / conversionRateValue
            : conversionRateValue;

          if (params?.onUpdate) {
            updatedPositions.push({
              positionId: position.positionId,
              conversionRate,
            });
          } else {
            store.entities.positionsMetrics.update(position.positionId, {
              conversionRate: conversionRate,
              pl: calcPositionPl({ ...position, conversionRate }),
              netPl: calcPositionNetPl({ ...position, conversionRate }),
            });
          }
        }
      }

      params?.onUpdate?.({
        positions: updatedPositions,
        platformLogin: tradingAccount.platformLogin || '',
      });
    },
    [uniqueSymbols]
  );

  /**
   * Handle fetch position exchange rates.
   */
  useEffect(() => {
    if (
      isAuth &&
      portfolioCurrency &&
      platformLogin &&
      positions &&
      positions.length > 0
    ) {
      fetchPositionsExchangeRate();
    }
  }, [(positions || []).length, portfolioCurrency, platformLogin]);

  useInstruments({
    instrumentsIds: [...uniqueSymbols],
    onUpdate: handleMessage,
    tradingAccount: params?.account,
    subscription:
      params?.subscription || EConnectionSubscription.PositionsConversionRate,
  });
};
