import { Instance, types } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

import { createTableModel } from '../../utils/tableModel';
import { orderMetricModel } from '../../entities/ordersMetrics';

export const ordersTableModel = types
  .model('ordersTableModel', {
    table: createTableModel(orderMetricModel, { idAttribute: 'orderId' }),
  })
  .actions(() => ({
    runInAction,
  }));

export const ordersTable = types.optional(ordersTableModel, {});

export type TOrdersTableModel = Instance<typeof ordersTableModel>;
