import React, { forwardRef } from 'react';

import { EOrderSide, IPositionMessage } from '@trader/types';
import { TTranslationKeys, useI18next } from '@trader/services';

import { INotificationProps } from '../types';

import * as Styled from '../styled';

export const PositionModified: React.FC<INotificationProps<IPositionMessage>> =
  forwardRef<HTMLDivElement, INotificationProps<IPositionMessage>>(
    ({ closeSnackbar, message }, ref) => {
      const { translate } = useI18next();

      const side = EOrderSide[message?.side];
      const translatedSide = side
        ? translate(`COMMON.LABELS.${side.toUpperCase()}` as TTranslationKeys)
        : side;

      return (
        <Styled.NotificationRoot ref={ref}>
          <Styled.Close onClick={closeSnackbar} iconType='remove' />
          <Styled.SuccessIcon iconType='challengeCompleted' />
          <Styled.Content>
            <Styled.Title>
              {translate('NOTIFICATIONS.POSITION_MODIFIED')}
            </Styled.Title>
            <Styled.Text>
              {translatedSide} {message?.volume} {message?.symbol}{' '}
              {translate('COMMON.LABELS.AT')} {message?.openPrice}
            </Styled.Text>
            <Styled.Text>
              <span>{translate('COMMON.LABELS.TAKE_PROFIT')}</span>{' '}
              <span>{message?.takeProfit || '-'}</span>
            </Styled.Text>
            <Styled.Text>
              <span>{translate('COMMON.LABELS.STOP_LOSS')}</span>{' '}
              <span>{message?.stopLoss || '-'}</span>
            </Styled.Text>
          </Styled.Content>
        </Styled.NotificationRoot>
      );
    }
  );
