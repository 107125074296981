import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    height: 100%;
    padding: 6px 18px 18px 18px;
    flex-direction: column;

    ${theme.breakpoints.down('sm')} {
      padding: 0;

      .failed-challenge-info-chip {
        margin: 0 4px 8px 4px;
      }
    }
  `}
`;
