import { useCallback } from 'react';

import { TTradingAccountEntity, useMst } from '@trader/store';
import { EConnectionSubscription } from '@trader/services';
import { IMessage } from '@trader/types';
import {
  calcPositionNetPl,
  calcPositionPl,
  getPositionChange,
  getSpreadDifference,
} from '@trader/utils';

import { useInstruments } from './useInstruments';

type TUpdatedPositions = {
  positions: { positionId: string; price: number }[];
  platformLogin: string;
};

interface IParams {
  subscription?: EConnectionSubscription;
  account: TTradingAccountEntity;
  onUpdate: (updatedPositions: TUpdatedPositions) => void;
}

export const usePositionsCurrentPrice = (params?: IParams) => {
  const store = useMst();

  const tradingAccount = (params?.account ||
    store.user.tradingAccount) as TTradingAccountEntity;

  const positions = tradingAccount?.positions;

  const uniqueSymbols = new Set(positions?.map(p => p.symbol));

  const handleMessage = useCallback(
    (message: IMessage) => {
      if (!uniqueSymbols.has(message.s)) return;

      const positionsBySymbol = positions.filter(
        ({ symbol }) => symbol === message.s
      );

      if (!positionsBySymbol.length) return;

      const updatedPositions: TUpdatedPositions['positions'] = [];

      for (const position of positionsBySymbol) {
        const { ask, bid } = getSpreadDifference(
          position.spreadDiff,
          message.a,
          message.b,
          position.spreadDiffBalance,
          position.pipSize
        );
        const price = position.side === 'Buy' ? ask : bid;

        if (params?.onUpdate) {
          updatedPositions.push({
            positionId: position.positionId,
            price,
          });
        } else {
          store.entities.positionsMetrics.update(position.positionId, {
            price,
            pl: calcPositionPl({ ...position, price }),
            netPl: calcPositionNetPl({ ...position, price }),
            change: getPositionChange(position.openPrice, price, position.side),
          });
        }
      }

      params?.onUpdate?.({
        positions: updatedPositions,
        platformLogin: tradingAccount.platformLogin || '',
      });
    },
    [uniqueSymbols]
  );

  useInstruments({
    instrumentsIds: [...uniqueSymbols],
    onUpdate: handleMessage,
    tradingAccount: params?.account,
    subscription:
      params?.subscription || EConnectionSubscription.PositionsCurrentPrice,
  });
};
