import {
  adjustByTickSize,
  formatByPipSize,
  getAmplitudeBasedOnTickSize,
} from '@trader/utils';
import { defaultPipSize } from '@trader/constants';
import {
  getAmplitudeFromModel,
  getModelPips,
  getRealPipsFromModel,
} from './pips';
import { TPlaceOrderSide } from '@trader/types';
import { getOrderBasePrice } from './common';

export const getSellProfitRateWithAmplitude = (
  stopsLevel: number | undefined,
  tickSize: number,
  pipSize: number,
  askPrice: number | string | undefined,
  formattingPipSize: number | undefined,
  category: string | undefined
) => {
  const amplitude = getAmplitudeFromModel(stopsLevel, pipSize, category);

  return formatByPipSize(
    Number(askPrice ?? 0) - getAmplitudeBasedOnTickSize(amplitude, tickSize),
    formattingPipSize ?? defaultPipSize
  );
};

export const getSellLossRateWithAmplitude = (
  stopsLevel: number | undefined,
  tickSize: number,
  pipSize: number,
  askPrice: number | string | undefined,
  formattingPipSize: number | undefined,
  category: string | undefined
) => {
  const amplitude = getAmplitudeFromModel(
    stopsLevel,
    pipSize ?? defaultPipSize,
    category
  );

  return formatByPipSize(
    Number(askPrice ?? 0) + getAmplitudeBasedOnTickSize(amplitude, tickSize),
    formattingPipSize ?? defaultPipSize
  );
};

export const getLossPipsFromRate = (
  rate: string,
  tickSize: number,
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number,
  pipSize: number,
  category: string | undefined,
  price?: string
) => {
  const loss =
    (price ? +price : getOrderBasePrice(side, bidPrice, askPrice)) - +rate;

  if (tickSize) {
    const pips = loss / tickSize;

    return formatByPipSize(
      getModelPips(pips * (side === 'Buy' ? 1 : -1), category),
      0
    );
  }
  return formatByPipSize(
    getModelPips((loss * (side === 'Buy' ? 1 : -1)) / +pipSize, category),
    0
  );
};
export const getLossRateFromPips = (
  pips: string,
  tickSize: number,
  side: TPlaceOrderSide,
  bidPrice: number,
  askPrice: number,
  pipSize: number,
  category: string | undefined,
  formattingPipSize?: number,
  price?: string
) => {
  const basePrice = Number(
    adjustByTickSize(
      price ? price : getOrderBasePrice(side, bidPrice, askPrice).toString(),
      pipSize,
      tickSize,
      side,
      'price'
    )
  );

  if (tickSize) {
    const pipsWithTicks = +pips * tickSize;

    return formatByPipSize(
      basePrice +
        getRealPipsFromModel(pipsWithTicks, category) *
          (side === 'Buy' ? -1 : 1),
      formattingPipSize ?? pipSize
    );
  }
  return formatByPipSize(
    basePrice +
      +pipSize *
        getRealPipsFromModel(pips, category) *
        (side === 'Buy' ? -1 : 1),
    formattingPipSize ?? pipSize
  );
};
