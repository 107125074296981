import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { BottomBar, Modals, TopBar } from '@trader/containers';
import { Typography, Icon } from '@trader/components';
import { NAVIGATE_TO } from '@trader/constants';
import { useNavigation } from '@trader/hooks';

import { UserDetailsLayoutLeftBar } from './leftBar';
import * as Styled from './desktop.styled';

export const DesktopUserDetailsLayout: React.FC<{
  titles: Record<string, string>;
}> = ({ titles }) => {
  const location = useLocation();
  const { navigateTo } = useNavigation();

  return (
    <>
      <Styled.Root>
        <TopBar />
        <Styled.Main>
          <UserDetailsLayoutLeftBar />
          <Styled.Content>
            <Styled.Title>
              <Styled.GoHomeButton
                onClick={() => navigateTo(NAVIGATE_TO.main.dashboard)}
              >
                <Icon iconType='arrowLeft' />
              </Styled.GoHomeButton>
              <Typography variant='heading'>
                {titles[location.pathname]}
              </Typography>
            </Styled.Title>
            <Styled.Outlet>
              <Outlet />
            </Styled.Outlet>
          </Styled.Content>
        </Styled.Main>
        <BottomBar />
      </Styled.Root>
      <Modals />
    </>
  );
};
