import { FC } from 'react';

import { useI18next } from '@trader/services';
import { useDownloadAccountStatementReport } from '@trader/hooks';

import { IAccountStatementHtmlReport } from '../htmlReport';
import * as Styled from './styled';

export const DownloadReportButton: FC<IAccountStatementHtmlReport> = ({
  ...pdfReportProps
}) => {
  const { translate } = useI18next();
  const { isReportDownloading, downloadAccountStatementReport } =
    useDownloadAccountStatementReport({
      pdfReportProps,
    });

  return (
    <Styled.DownloadButton
      disabled={isReportDownloading}
      onClick={downloadAccountStatementReport}
    >
      {isReportDownloading
        ? translate('COMMON.LABELS.GENERATING_DOCUMENT')
        : translate('COMMON.LABELS.DOWNLOAD_PDF')}
    </Styled.DownloadButton>
  );
};
