import { devLoggerService } from '@trader/services';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { TPositionsHistoryTableModel } from './index';
import { returnTypedThis } from '@trader/utils';
import { IPagination } from '@trader/types';

export const getMoreItemsAsync = createThunk<Required<IPagination>, void>(
  params =>
    async function getMoreItems(this: unknown, _options, _flow) {
      const that = returnTypedThis<TPositionsHistoryTableModel>(this);

      const root = getRootInstance();

      try {
        await root.entities.positionsHistory.getPositionsHistoryAsync.run({
          pageNumber: params?.pageNumber + 1,
          pageSize: params?.pageSize,
        });

        that.table.changePageSize(params?.pageSize);
        that.table.changeCurrentPage(params?.pageNumber);
      } catch (e) {
        devLoggerService.error('Error in getMoreItemsAsync', e);
      }
    }
);
