import { FC, useCallback, useState } from 'react';
import { IAnyStateTreeNode } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';

import { TCreateTableModelInstance } from '@trader/store';
import { calculateDateInterval } from '@trader/utils';
import { EDateFilterTypes } from '@trader/types';
import { useI18next } from '@trader/services';

import { CustomDateSelection } from './components/customDateSelection';
import { FilterTrigger } from './components/filterTrigger';
import { FilterList } from './components/filterList';

import * as Styled from './styled';

interface IDateFilter {
  store: IAnyStateTreeNode;
  columnId: string;
  dateFilterNames: {
    from: string;
    to: string;
  };
  label: string;
}

export const TableColumnDateFilter: FC<IDateFilter> = observer(
  ({ store, dateFilterNames, label, columnId }) => {
    const { translate } = useI18next();

    const tableStore = store.table as TCreateTableModelInstance;

    const customFilter = tableStore.getCustomFilter(columnId);

    const initialValues = {
      from: customFilter && customFilter[dateFilterNames.from],
      to: customFilter && customFilter[dateFilterNames.to],
      filterType: (customFilter && customFilter['filterType']) || null,
    };

    const [selectedFilterType, setSelectedFilterType] =
      useState<EDateFilterTypes | null>(initialValues.filterType);

    const isFilterApplied = Boolean(
      customFilter &&
        (!!customFilter[dateFilterNames.from] ||
          !!customFilter[dateFilterNames.to])
    );

    const handleReset = () => {
      tableStore.clearCustomFilterKey(columnId);
      tableStore.changeCurrentPage(0);
      setSelectedFilterType(null);
      store?.getMoreItemsAsync.run();
    };

    const handleCustomFilterApply = (from: Date | null, to: Date | null) => {
      tableStore.setCustomFilters({
        [columnId]: {
          [dateFilterNames.from]: !from ? '' : from.toISOString(),
          [dateFilterNames.to]: !to ? '' : to.toISOString(),
          filterType: EDateFilterTypes.CUSTOM,
        },
      });
      tableStore.changeCurrentPage(0);
      store?.getMoreItemsAsync.run();
    };

    const handleChange = useCallback(
      (filterType: EDateFilterTypes) => {
        if (filterType === selectedFilterType) {
          handleReset();
          return;
        }

        if (filterType === 'custom') {
          setSelectedFilterType(filterType);
          tableStore.setCustomFilters({
            [columnId]: {
              [dateFilterNames.from]: '',
              [dateFilterNames.to]: '',
              filterType: EDateFilterTypes.CUSTOM,
            },
          });
          return;
        }

        const dateInterval = calculateDateInterval(filterType);
        tableStore.setCustomFilters({
          [columnId]: {
            [dateFilterNames.from]: dateInterval.from.toISOString(),
            [dateFilterNames.to]: dateInterval.to.toISOString(),
            filterType,
          },
        });
        tableStore.changeCurrentPage(0);
        store?.getMoreItemsAsync.run();
        setSelectedFilterType(filterType);
      },
      [selectedFilterType]
    );

    return (
      <Styled.FilterContent>
        <div>
          <FilterTrigger
            label={label}
            isFilterApplied={isFilterApplied}
            isStatic
          />
          <FilterList
            selectedFilterType={selectedFilterType}
            onFilterTypeClick={handleChange}
          />
          <Styled.ResetButton onClick={handleReset}>
            {translate('FILTERS.RESET')}
          </Styled.ResetButton>
        </div>
        {selectedFilterType === EDateFilterTypes.CUSTOM && (
          <CustomDateSelection
            initialValues={{
              from: initialValues.from ? new Date(initialValues.from) : null,
              to: initialValues.to ? new Date(initialValues.to) : null,
            }}
            onApply={handleCustomFilterApply}
          />
        )}
      </Styled.FilterContent>
    );
  }
);
