import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Progress, Stack } from '@trader/components';
import { Modals } from '@trader/containers';
import { TAvailableChallengeEntity, useMst } from '@trader/store';
import { EChallengeEvaluationType } from '@trader/types';

import { PurchaseList } from './components/purchaseList';
import * as Styled from './styled';

export const PurchaseChallenges: React.FC = observer(() => {
  const store = useMst();
  const evaluationChallenges = store.entities.availableChallenges
    .getAll<TAvailableChallengeEntity>()
    .filter(ch => ch.evaluationType === EChallengeEvaluationType.Evaluation);
  const instantChallenges = store.entities.availableChallenges
    .getAll<TAvailableChallengeEntity>()
    .filter(ch => ch.evaluationType === EChallengeEvaluationType.Instant);
  const isLoading =
    store.entities.availableChallenges.getAvailableChallengesAsync.inProgress;

  useEffect(() => {
    store.entities.availableChallenges.getAvailableChallengesAsync.run();
  }, []);

  return (
    <Styled.Root>
      <Styled.Body>
        {isLoading ? (
          <Progress size={25} color='primary' />
        ) : (
          <Stack direction='column' spacing='75px'>
            {!!evaluationChallenges.length && (
              <PurchaseList
                purchaseType='evaluationChallenges'
                items={evaluationChallenges}
              />
            )}
            {!!instantChallenges.length && (
              <PurchaseList
                purchaseType='instantChallenges'
                items={instantChallenges}
              />
            )}
          </Stack>
        )}
      </Styled.Body>
      <Modals />
    </Styled.Root>
  );
});
