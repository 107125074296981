import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import {
  TradingIsClosed,
  ModalTitle,
  Progress,
  Form,
  Controller,
  Typography,
} from '@trader/components';
import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
} from '@trader/hooks';
import { useI18next } from '@trader/services';
import { IInitialTradingFormValues } from '@trader/types';
import { ConfirmButton, SideButtons } from '@trader/trading';
import { getTradingAmountTranslationKey } from '@trader/utils';

import {
  IPositionMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
import { useClosePositionFormValidation } from './formValidation';

import * as Styled from './styled';

const Content: React.FC = observer(() => {
  const { translate } = useI18next();

  const store = useMst();
  const modal = store.ui.modal;
  const positionId = modal.options.get('positionId');
  const trading = store.trading.getTrading('modifyOrder');

  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  const { validationSchema } = useClosePositionFormValidation(
    trading.minAmount(metric?.quantity)
  );

  useEffect(() => {
    if (metric) {
      return () => {
        metric.runInAction(() => {
          metric.closePositionAsync.hasEverBeenRun = false;
        });
      };
    }
  }, []);

  const handleClosePosition = (data: IInitialTradingFormValues) => {
    if (metric) {
      const body = {
        positionId,
        quantity: trading.getQuantity(data.amount),
      };
      metric.closePositionAsync.run(body);
    }
  };

  return (
    <Styled.Root>
      <React.Fragment>
        <ModalTitle title={translate('COMMON.LABELS.CLOSE_POSITION')} />
        <Form<IInitialTradingFormValues>
          onSubmit={(_reset, data) => {
            handleClosePosition(data);
          }}
          mode='all'
          defaultValues={{
            amount: String(trading.minAmount(metric?.quantity)),
          }}
          validationSchema={validationSchema}
        >
          {({ formState, control, register }) => (
            <Styled.Position>
              <TradingModalHeader<IPositionMetricStore>
                type='position'
                metric={metric}
              />
              <SideButtons type='modifyOrder' disabled />
              <Styled.Amount>
                <Controller
                  type='tradingInput'
                  shouldCheckActivityOfButtons
                  customLabel={translate(
                    getTradingAmountTranslationKey(trading.tradingType)
                  )}
                  disabled={metric?.quantity === trading.step()}
                  minValue={trading.step()}
                  maxValue={trading.minAmount(metric?.quantity)}
                  fixDigitAfterDot={trading.amountDigitsAfterDot}
                  step={trading.step()}
                  control={control}
                  fullWidth
                  {...register('amount')}
                />
              </Styled.Amount>
              <ConfirmButton
                isDisabled={!!formState.errors.amount}
                isLoading={
                  metric?.closePositionAsync.inProgress ||
                  metric?.closePositionAsync.hasEverBeenRun
                }
                title={translate('COMMON.LABELS.CLOSE_POSITION')}
              />
            </Styled.Position>
          )}
        </Form>
      </React.Fragment>
    </Styled.Root>
  );
});

export const ClosePosition: React.FC = observer(() => {
  useCloseModalForAlreadyDeletedInstance('positionId');

  const store = useMst();
  const { translate } = useI18next();
  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;

  const positionId = store.ui.modal.options.get('positionId');
  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  if (!metric) {
    return null;
  }

  if (applyConditionThird().status === 'failed') {
    return (
      <Styled.Root paddingTop='16px'>
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_IS_DISABLED')}
          content={
            <div>
              <Typography>
                {translate('WARNINGS.PLEASE_CONTACT_SUPPORT')}
              </Typography>
              <a
                href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
                style={{ color: 'inherit' }}
              >
                {import.meta.env.VITE_SUPPORT_EMAIL}
              </a>
            </div>
          }
          buttonContent={translate('COMMON.LABELS.PROCEED')}
          onClick={applyConditionThird()?.action}
        />
      </Styled.Root>
    );
  }

  if (instrument.tradingAvailability.isUnavailable) {
    return (
      <Styled.Root paddingTop='28px'>
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.CURRENTLY_OUT_OF_TRADING_HOURS')}
        />
      </Styled.Root>
    );
  }

  return <Content />;
});
