import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { tradingHorizontalSpace } from '@trader/constants';
import { conditionApply } from '@trader/utils';
import { TLanguageKey } from '@trader/services';

interface IBorder {
  $isBorderVisible?: boolean;
}

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  `}
`;

export const Content = styled(Wrapper)<IBorder>`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  `}
`;

export const TakeProfit = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;
    padding: ${tradingHorizontalSpace}px;
    border-top: 1px solid ${theme.palette.background.default};

    .MuiCheckbox-root {
      padding: 4px;
    }

    .MuiFormControlLabel-root {
      margin-left: -5px;
    }

    > div .MuiFormControlLabel-label {
      font-size: ${theme.typography.large.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
    }

    ${conditionApply(
      [theme.direction === 'rtl'],
      `
        align-items: flex-start;
      `
    )}
  `}
`;
export const StopLoss = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;
    border-top: 1px solid ${theme.palette.background.default};
    border-bottom: 1px solid ${theme.palette.background.default};
    padding: ${tradingHorizontalSpace}px;

    .MuiCheckbox-root {
      padding: 4px;
    }

    .MuiFormControlLabel-root {
      margin-left: -5px;
    }

    > div .MuiFormControlLabel-label {
      font-size: ${theme.typography.large.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
    }

    ${conditionApply(
      [theme.direction === 'rtl'],
      `
        align-items: flex-start;
      `
    )}
  `}
`;

export const InputWrapper = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin-top: 5px;
  `}
`;

export const Select = styled(Wrapper)<{ $currentLng?: TLanguageKey }>`
  ${({ theme, $currentLng }) => css`
    flex-direction: column;
    height: 45px;
    min-width: 60px;
    background: ${theme.palette.gray[500]};

    svg {
      color: ${theme.palette.primary.main};
    }

    fieldset {
      border: 0;
      border-left: 1px solid ${theme.palette.background.paper};
      border-right: 1px solid ${theme.palette.background.paper};
      border-radius: 0;
    }

    .MuiSelect-select {
      min-width: 60px;
      padding: 0;
      min-height: 45px !important;
      position: relative;

      > span {
        color: ${theme.palette.primary.main};
        position: absolute;
        top: calc(50% - 1em);
        left: ${$currentLng === 'sk' ? '2px' : '10px'};
      }
    }

    .MuiInputBase-root {
      border: none;
      border-radius: 0;
    }

    ${theme.breakpoints.down('sm')} {
      height: 60px;

      .MuiSelect-select {
        min-height: 60px !important;
      }
    }
  `}
`;

export const InputLabel = styled(Typography)`
  ${({ theme }) => css`
    text-align: right;
    margin-right: 14px;
    font-size: ${theme.typography.large.fontSize};
    min-width: 105px;
    max-width: 105px;
  `}
`;

export const Label = styled(Wrapper)`
  ${() => css``}
`;

export const Protection = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.large.fontSize};
  `}
`;
