import { Instance, types } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

import { createTableModel } from '../../utils/tableModel';
import { alertModel } from '../../entities/alerts';

export const alertsTableModel = types
  .model('alertsTableModel', {
    table: createTableModel(alertModel, {}),
  })
  .actions(() => ({
    runInAction,
  }));

export const alertsTable = types.optional(alertsTableModel, {});

export type TAlertsTableModel = Instance<typeof alertsTableModel>;
