import { useState } from 'react';

import {
  ITransfersHistoryBE,
  TPaymentStatus,
  TTransferType,
} from '@trader/api';
import { TDateFilterInterval } from '@trader/types';
import { useMst } from '@trader/store';

interface IState {
  isFetchingMore: boolean;
  isLoading: boolean;
  data: ITransfersHistoryBE['transfers'];
  totalCount: number;
}

export const useTransfersHistory = () => {
  const store = useMst();
  const [state, setState] = useState<IState>({
    isFetchingMore: false,
    isLoading: false,
    data: [],
    totalCount: 0,
  });

  const fetchTransfersHistory = async (
    statuses?: TPaymentStatus[],
    transferTypes?: TTransferType[],
    interval?: TDateFilterInterval | null
  ) => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const response = await store.user.getTransfersHistoryAsync.run({
        statuses,
        transferTypes,
        completedFrom: interval?.from?.toISOString() || undefined,
        completedTo: interval?.to?.toISOString() || undefined,
      });
      if (response) {
        setState(prev => ({
          ...prev,
          totalCount: response.totalCount,
          isLoading: false,
          data: response.transfers,
        }));
      }
    } catch (e) {
      setState(prev => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const fetchMoreHistory = async (pageNumber: number) => {
    setState(prev => ({ ...prev, isFetchingMore: true }));
    try {
      const response = await store.user.getTransfersHistoryAsync.run({
        pageNumber,
      });
      if (response) {
        setState(prev => ({
          ...prev,
          transfers: [...prev.data, ...response.transfers],
          isFetchingMore: false,
        }));
      }
    } catch (e) {
      setState(prev => ({
        ...prev,
        isFetchingMore: false,
      }));
    }
  };

  return {
    transfers: state,
    fetchTransfersHistory,
    fetchMoreHistory,
  };
};
