import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { TableVirtuoso } from 'react-virtuoso';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { IAccountStatementBE } from '@trader/api';
import {
  appConfigUtils,
  accountStatementUtils,
  formatDate,
  TDealsTableDataItem,
} from '@trader/utils';
import { Icon } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import { DownloadReportButton } from '../downloadReportButton';

import * as Styled from './styled';

export interface IAccountStatementHtmlReport {
  from: string;
  to: string;
  platformLogin: string;
  currency: string;
  product: string;
  accountType: string;
  accountStatement: IAccountStatementBE;
}

export const AccountStatementHtmlReport: React.FC<IAccountStatementHtmlReport> =
  observer(
    ({
      from,
      to,
      platformLogin,
      currency,
      product,
      accountType,
      accountStatement,
    }) => {
      const { translate } = useI18next();
      const store = useMst();

      const { formatDecimal, getChartData } = accountStatementUtils;

      return (
        <Styled.Root>
          <Styled.Header>
            <div id='logo'>
              <Icon iconType={store.app.getAppIconBasedOnTheme()} />
            </div>
            <DownloadReportButton
              from={from}
              to={to}
              platformLogin={platformLogin}
              currency={currency}
              product={product}
              accountType={accountType}
              accountStatement={accountStatement}
            />
          </Styled.Header>
          <Styled.Divider />
          <div>
            <Styled.Title>
              {translate('COMMON.LABELS.TRADE_HISTORY_REPORT')}
            </Styled.Title>
            <Styled.Subtitle>
              {formatDate(new Date(from), 'dd.mm.yyyy', {
                shouldUseUTC: false,
              })}{' '}
              -{' '}
              {formatDate(new Date(to), 'dd.mm.yyyy', { shouldUseUTC: false })}
            </Styled.Subtitle>
          </div>
          <Styled.Divider />
          <table style={{ width: '380px' }}>
            <tbody>
              <tr>
                <Styled.Label>{translate('COMMON.LABELS.NAME')}</Styled.Label>
                <Styled.Value>
                  {accountStatement.customerInfo.firstName}{' '}
                  {accountStatement.customerInfo.lastName}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.ACCOUNT')}
                </Styled.Label>
                <Styled.Value>
                  {platformLogin} ({currency}, {product}, {accountType})
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.COMPANY')}
                </Styled.Label>
                <Styled.Value>{appConfigUtils.getCurrentBrand()}</Styled.Value>
              </tr>
              <tr>
                <Styled.Label>{translate('COMMON.LABELS.DATE')}</Styled.Label>
                <Styled.Value>
                  {formatDate(new Date(), 'dd.mm.yyyy hh:mm:ss', {
                    shouldUseUTC: false,
                  })}
                </Styled.Value>
              </tr>
            </tbody>
          </table>
          <Styled.Divider />
          <Styled.SectionTitle>
            {translate('COMMON.LABELS.SUMMARY')}
          </Styled.SectionTitle>
          <table style={{ width: '680px' }}>
            <tbody>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.BALANCE')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.balance)}
                </Styled.Value>
                <Styled.Label>
                  {translate('COMMON.LABELS.USED_MARGIN')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.usedMargin)}
                </Styled.Value>
                <Styled.Label>
                  {translate('COMMON.LABELS.DEPOSIT')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.deposit)}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>{translate('COMMON.LABELS.EQUITY')}</Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.equity)}
                </Styled.Value>
                <Styled.Label>
                  {translate('COMMON.LABELS.USABLE_MARGIN')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.marginFree?.value)}
                </Styled.Value>
                <Styled.Label>
                  {translate('COMMON.LABELS.WITHDRAWAL')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.withdrawal)}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>{translate('COMMON.LABELS.P_L')}</Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.grossPl)}
                </Styled.Value>
                <Styled.Label>
                  {translate('COMMON.LABELS.USABLE_MARGIN')}, %
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.marginFree?.percent)}%
                </Styled.Value>
                <Styled.Label>
                  {translate('COMMON.LABELS.ADJUSTMENT')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.adjustment)}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.REALIZED_TOTAL_PL')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.realizedPl)}
                </Styled.Value>
                <Styled.Label>
                  {translate('COMMON.LABELS.EQUITY_MC_LEVEL')}, %
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.mcLevel)}%
                </Styled.Value>
                <Styled.Label>{translate('COMMON.LABELS.NET')}</Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.summary.net)}
                </Styled.Value>
              </tr>
            </tbody>
          </table>
          <Styled.Divider />
          <Styled.SectionTitle>
            {translate('COMMON.LABELS.COSTS_OVERVIEW')}
          </Styled.SectionTitle>
          <Styled.Subtitle marginTop='12px'>
            {translate('COMMON.LABELS.TRADING_COSTS')}
          </Styled.Subtitle>
          <table style={{ width: '650px' }}>
            <thead>
              <tr>
                <th></th>
                <Styled.Label>
                  {translate('COMMON.LABELS.ACTIVE_TRADING_COSTS')}
                </Styled.Label>
                <Styled.Label>
                  {translate('COMMON.LABELS.CLOSED_TRADING_COSTS')}
                </Styled.Label>
                <Styled.Label>
                  {translate('COMMON.LABELS.TOTAL_TRADING_COSTS')}
                </Styled.Label>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.OPEN_COMMISSIONS')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.activeTrading
                      ?.openCommissions
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.closeTrading
                      ?.openCommissions
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.totalTrading
                      ?.openCommissions
                  )}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.CLOSE_COMMISSIONS')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.activeTrading
                      ?.closeCommissions
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.closeTrading
                      ?.closeCommissions
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.totalTrading
                      ?.closeCommissions
                  )}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>{translate('COMMON.LABELS.SPREAD')}</Styled.Label>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.activeTrading?.spread
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.closeTrading?.spread
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.totalTrading?.spread
                  )}
                </Styled.Value>
              </tr>
            </tbody>
          </table>
          <Styled.Subtitle style={{ marginTop: '12px' }}>
            {translate('COMMON.LABELS.OTHER_ADJUSTMENTS')}
          </Styled.Subtitle>
          <table style={{ width: '750px' }}>
            <thead>
              <tr>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.ACTIVE_OTHER_ADJUSTMENTS')}
                </Styled.Label>
                <Styled.Label>
                  {translate('COMMON.LABELS.CLOSED_OTHER_ADJUSTMENTS')}
                </Styled.Label>
                <Styled.Label>
                  {translate('COMMON.LABELS.TOTAL_OTHER_ADJUSTMENTS')}
                </Styled.Label>
              </tr>
            </thead>
            <tbody>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.OVERNIGHT_SWAP')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.activeAdjustments?.swap
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.closeAdjustments?.swap
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.totalAdjustments?.swap
                  )}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.DIVIDENDS')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.activeAdjustments?.dividends
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.closeAdjustments?.dividends
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.totalAdjustments?.dividends
                  )}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>{translate('COMMON.LABELS.WHT')}</Styled.Label>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.activeAdjustments?.wht
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.closeAdjustments?.wht
                  )}
                </Styled.Value>
                <Styled.Value>
                  {formatDecimal(
                    accountStatement.costsOverview?.totalAdjustments?.wht
                  )}
                </Styled.Value>
              </tr>
            </tbody>
          </table>
          <div>
            {accountStatement.balanceChart.length > 0 && (
              <>
                <Styled.Divider />
                <Styled.Chart>
                  <Styled.SectionTitle>
                    {translate('COMMON.LABELS.BALANCE_CHART')}
                  </Styled.SectionTitle>
                  <ResponsiveContainer>
                    <LineChart
                      id='balanceChart'
                      data={getChartData(accountStatement)}
                      margin={{ top: 10, right: 20, left: 20, bottom: 30 }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name'>
                        <Label position='insideBottom' />
                      </XAxis>
                      <YAxis
                        padding={{ top: 20 }}
                        label={{
                          value: 'Balance',
                          position: 'insideLeft',
                          angle: -90,
                          offset: 0,
                        }}
                      ></YAxis>
                      <Legend />
                      <Line
                        strokeWidth={2}
                        dot={false}
                        isAnimationActive={false}
                        type='monotone'
                        dataKey='value'
                        name='Transactions (#)'
                        stroke='rgb(1, 120, 250)'
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Styled.Chart>
              </>
            )}
          </div>
          <Styled.Divider />
          <Positions accountStatement={accountStatement} />
          <Deals accountStatement={accountStatement} />
          <Styled.SectionTitle style={{ textTransform: 'capitalize' }}>
            {translate('COMMON.LABELS.RESULTS')}
          </Styled.SectionTitle>
          <table>
            <tbody>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.TOTAL_NET_PROFIT')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.netProfit)}
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.GROSS_PROFIT')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.grossProfit)}
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.GROSS_LOSS')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.grossLoss)}
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.PROFIT_FACTOR')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.profitFactor)}
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.EXPECTED_PAYOFF')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.expectedPayoff)}
                </Styled.Value>
                <td></td>
                <td>
                  <br />
                </td>
                <td></td>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.RECOVERY_FACTOR')}
                </Styled.Label>
                <Styled.Value>{translate('COMMON.LABELS.N_A')}</Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.SHARPE_RATIO')}
                </Styled.Label>
                <Styled.Value>{translate('COMMON.LABELS.N_A')}</Styled.Value>
                <td></td>
                <td>
                  <br />
                </td>
                <td></td>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.BALANCE_DRAWDOWN')}
                </Styled.Label>
                <Styled.Value>{translate('COMMON.LABELS.N_A')}</Styled.Value>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <br />
                </td>
                <td></td>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.BALANCE_DRAWDOWN_ABSOLUTE')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.absoluteDrawdown)}
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.BALANCE_DRAWDOWN_MAXIMAL')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.maxDrawdown.value)} (
                  {formatDecimal(accountStatement.maxDrawdown.percent)}
                  %)
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.BALANCE_DRAWDOWN_RELATIVE')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.relativeDrawdown.value)} (
                  {formatDecimal(accountStatement.relativeDrawdown.percent)}
                  %)
                </Styled.Value>
              </tr>
              <tr>
                <Styled.Label>
                  {translate('COMMON.LABELS.TOTAL_TRADES')}
                </Styled.Label>
                <Styled.Value>{accountStatement.totalTrades}</Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.SHORT_TRADES_WON')}
                </Styled.Label>
                <Styled.Value>
                  {accountStatement.shortTrades.value} (
                  {formatDecimal(accountStatement.shortTrades.percent)}
                  %)
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.LONG_TRADES_WON')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.longTrades.value)} (
                  {formatDecimal(accountStatement.longTrades.percent)}
                  %)
                </Styled.Value>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.PROFIT_TRADES_OF_TOTAL')}
                </Styled.Label>
                <Styled.Value>
                  {accountStatement.profitTrades.value} (
                  {formatDecimal(accountStatement.profitTrades.percent)}
                  %)
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.LOSS_TRADES_OF_TOTAL')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.lossTrades.value)} (
                  {formatDecimal(accountStatement.lossTrades.percent)}
                  %)
                </Styled.Value>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.LARGEST_PROFIT_TRADE')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.largestTrade)}
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.LARGEST_LOSS_TRADE')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.largestLossTrade)}
                </Styled.Value>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.AVERAGE_PROFIT_TRADE')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.avgProfitTrade)}
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.AVERAGE_LOSS_TRADE')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.avgLossTrade)}
                </Styled.Value>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_WINS', {
                    currency: currency,
                  })}
                </Styled.Label>
                <Styled.Value>
                  {accountStatement.maxConsecutiveProfit} (
                  {formatDecimal(accountStatement.maxConsecutiveWins)})
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_LOSSES', {
                    currency: currency,
                  })}
                </Styled.Label>
                <Styled.Value>
                  {accountStatement.maxConsecutiveLoss} (
                  {accountStatement.maxConsecutiveLosses})
                </Styled.Value>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.MAXIMAL_CONSECUTIVE_WIN_COUNT')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.maxConsecutiveWins)} (
                  {accountStatement.maxConsecutiveProfit})
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_LOSSES_COUNT')}
                </Styled.Label>
                <Styled.Value>
                  {formatDecimal(accountStatement.maxConsecutiveLosses)} (
                  {accountStatement.maxConsecutiveLoss})
                </Styled.Value>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.AVERAGE_CONSECUTIVE_WINS')}
                </Styled.Label>
                <Styled.Value>
                  {accountStatement.avgConsecutiveWins}
                </Styled.Value>
                <td></td>
                <Styled.Label>
                  {translate('COMMON.LABELS.AVERAGE_CONSECUTIVE_LOSSES')}
                </Styled.Label>
                <Styled.Value>
                  {accountStatement.avgConsecutiveLosses}
                </Styled.Value>
              </tr>
            </tbody>
          </table>
        </Styled.Root>
      );
    }
  );

export const Positions = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();
  const { formatDecimal, getTranslatedDirection, formatQuantityDisplay } =
    accountStatementUtils;

  const renderTableRow = useCallback((index: number) => {
    const row = accountStatement.openPositions[index];
    return (
      <React.Fragment key={index}>
        <Styled.Value>{row.positionId}</Styled.Value>
        <Styled.Value>
          {formatDate(
            new Date(row.entryTime as unknown as string),
            'dd.mm.yyyy hh:mm'
          )}
        </Styled.Value>
        <Styled.Value>{row.symbol}</Styled.Value>
        <Styled.Value>
          {getTranslatedDirection(row.direction, translate)}
        </Styled.Value>
        <Styled.Value>
          {formatQuantityDisplay(row.quantity, row.remainingQuantity)}
        </Styled.Value>
        <Styled.Value>{row.entryPrice}</Styled.Value>
        <Styled.Value>{row.stopLoss}</Styled.Value>
        <Styled.Value>{row.takeProfit}</Styled.Value>
        <Styled.Value>{row.swap}</Styled.Value>
        <Styled.Value>{row.openCommission}</Styled.Value>
        <Styled.Value>{row.closeCommission}</Styled.Value>
        <Styled.Value>{row.price}</Styled.Value>
        <Styled.Value>{row.fee}</Styled.Value>
        <Styled.Value>{formatDecimal(row.pl)}</Styled.Value>
        <Styled.Value>{row.comment || '-'}</Styled.Value>
      </React.Fragment>
    );
  }, []);

  if (!accountStatement.openPositions.length) {
    return (
      <>
        <Styled.SectionTitle>
          {translate('ACCOUNT_STATEMENT.OPENED_POSITIONS')}
        </Styled.SectionTitle>
        <p>{translate('COMMON.LABELS.NO_TRANSACTIONS')}</p>
        <Styled.Divider />
      </>
    );
  }

  return (
    <>
      <Styled.SectionTitle>
        {translate('ACCOUNT_STATEMENT.OPENED_POSITIONS')}
      </Styled.SectionTitle>
      <TableVirtuoso
        data={accountStatement.openPositions}
        totalCount={accountStatement.openPositions.length}
        fixedHeaderContent={PositionsFixedHeaderContent}
        itemContent={renderTableRow}
        overscan={10}
        useWindowScroll
      />
      <Styled.Divider />
    </>
  );
};
const PositionsFixedHeaderContent = () => {
  const { translate } = useI18next();

  return (
    <tr>
      <Styled.Label>{translate('COMMON.LABELS.POSITION_ID')}</Styled.Label>
      <Styled.Label $width={130}>
        {translate('COMMON.LABELS.ENTRY_TIME')}
      </Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.SYMBOL')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.DIRECTION')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.QUANTITY')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.ENTRY_PRICE')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.SL')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.TP')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.SWAP')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.OPEN_COMMISSION')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.CLOSE_COMMISSION')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.PRICE')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.FEE')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.P_L')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.COMMENT')}</Styled.Label>
    </tr>
  );
};

export const Deals = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();

  const {
    getDealsTableData,
    getActionDescription,
    formatDecimal,
    formatQuantityDisplay,
  } = accountStatementUtils;

  const tableData = getDealsTableData(accountStatement);

  const renderTableSubRow = useCallback(
    (item: TDealsTableDataItem, index: number) => {
      return (
        <React.Fragment key={index}>
          <Styled.Value>
            <Styled.SubRowIcon iconType='tableSubRowIndicator' />
            {item.positionId}
          </Styled.Value>
          <Styled.Value>{item.deal?.orderId || '-'}</Styled.Value>
          <Styled.Value>-</Styled.Value>
          <Styled.Value>
            {typeof item.deal?.time === 'string'
              ? formatDate(
                  new Date(item.deal?.time as unknown as string),
                  'dd.mm.yyyy hh:mm'
                )
              : '-'}
          </Styled.Value>
          <Styled.Value>-</Styled.Value>
          <Styled.Value>
            {getActionDescription(item.deal?.action || 0, translate)}
          </Styled.Value>
          <Styled.Value>{item.deal?.quantity}</Styled.Value>
          <Styled.Value>{item.deal?.entry}</Styled.Value>
          <Styled.Value>-</Styled.Value>
          <Styled.Value>-</Styled.Value>
          <Styled.Value>-</Styled.Value>
          <Styled.Value>-</Styled.Value>
          <Styled.Value>-</Styled.Value>
          <Styled.Value>-</Styled.Value>
          <Styled.Value>{formatDecimal(item.deal?.pl)}</Styled.Value>
          <Styled.Value>{item.deal?.comment || '-'}</Styled.Value>
        </React.Fragment>
      );
    },
    []
  );

  const renderTableRow = useCallback((index: number) => {
    const row = tableData[index];
    const isNextRowSubRow = tableData[index + 1]?.isSubRow;
    const MainRowValue = isNextRowSubRow ? Styled.DealsValue : Styled.Value;

    const mainRow = (
      <React.Fragment>
        <MainRowValue>{row.positionId || '-'}</MainRowValue>
        <MainRowValue>{row.positionId || '-'}</MainRowValue>
        <MainRowValue>
          {typeof row.entryTime === 'string'
            ? formatDate(
                new Date(row.entryTime as unknown as string),
                'dd.mm.yyyy hh:mm'
              )
            : '-'}
        </MainRowValue>
        <MainRowValue>
          {typeof row.exitTime === 'string'
            ? formatDate(
                new Date(row.exitTime as unknown as string),
                'dd.mm.yyyy hh:mm'
              )
            : '-'}
        </MainRowValue>
        <MainRowValue>{row.symbol || '-'}</MainRowValue>
        <MainRowValue>
          {getActionDescription(row.action, translate)}
        </MainRowValue>
        <MainRowValue>
          {formatQuantityDisplay(row.quantity, row.remainingQuantity, {
            dealsFormatting: true,
          })}
        </MainRowValue>
        <MainRowValue>{row.entryPrice}</MainRowValue>
        <MainRowValue>{row.exitPrice}</MainRowValue>
        <MainRowValue>{row.stopLoss}</MainRowValue>
        <MainRowValue>{row.takeProfit}</MainRowValue>
        <MainRowValue>{row.fee}</MainRowValue>
        <MainRowValue>{row.commission}</MainRowValue>
        <MainRowValue>{row.swap}</MainRowValue>
        <MainRowValue>{formatDecimal(row.pl)}</MainRowValue>
        <MainRowValue>{row.comment || '-'}</MainRowValue>
      </React.Fragment>
    );

    return (
      <React.Fragment key={index}>
        {row.isSubRow ? renderTableSubRow(row, index) : mainRow}
      </React.Fragment>
    );
  }, []);

  if (!tableData.length) {
    return (
      <>
        <Styled.SectionTitle>
          {translate('ACCOUNT_STATEMENT.CLOSED_POSITIONS')}
        </Styled.SectionTitle>
        <p>{translate('COMMON.LABELS.NO_TRANSACTIONS')}</p>
        <Styled.Divider />
      </>
    );
  }

  return (
    <>
      <Styled.SectionTitle>
        {translate('ACCOUNT_STATEMENT.CLOSED_POSITIONS')}
      </Styled.SectionTitle>
      <TableVirtuoso
        data={tableData}
        totalCount={accountStatement.balanceHistory.length}
        fixedHeaderContent={DealsFixedHeaderContent}
        itemContent={renderTableRow}
        overscan={10}
        useWindowScroll
      />
      <Styled.Divider />
    </>
  );
};
const DealsFixedHeaderContent = () => {
  const { translate } = useI18next();

  return (
    <tr>
      <Styled.Label>{translate('COMMON.LABELS.POSITION_ID')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.ORDER_ID')}</Styled.Label>
      <Styled.Label $width={130}>
        {translate('COMMON.LABELS.ENTRY_TIME')}
      </Styled.Label>
      <Styled.Label $width={130}>
        {translate('COMMON.LABELS.EXIT_TRANSFER_TIME')}
      </Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.SYMBOL')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.DIRECTION')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.QUANTITY')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.ENTRY_PRICE')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.EXIT_PRICE')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.SL')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.TP')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.FEE')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.COMMISSION')}</Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.SWAP')}</Styled.Label>
      <Styled.Label>
        {translate('COMMON.LABELS.PROFIT_LOSS_BALANCE')}
      </Styled.Label>
      <Styled.Label>{translate('COMMON.LABELS.COMMENT')}</Styled.Label>
    </tr>
  );
};
