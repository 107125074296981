import { api } from '@trader/api';
import { devLoggerService } from '@trader/services';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { EScheduledOrderStatus } from '@trader/types';
import { scheduledOrdersHistorySchema } from './schemas';

export const getScheduledOrdersHistoryAsync = createThunk<void, void>(
  () =>
    async function getScheduledOrdersHistory(this: unknown, _options, _flow) {
      try {
        const root = getRootInstance();
        const res = await api.BandStrategy.getScheduledOrders(_options);

        const history = res.filter(
          order => order.status !== EScheduledOrderStatus.Created
        );

        const normalized = root.entities.normalizeMerge(
          history,
          scheduledOrdersHistorySchema,
          true
        );

        root.tables.scheduledOrdersHistory.runInAction(() => {
          root.tables.scheduledOrdersHistory.table.totalCount = history.length;
        });
        root.tables.scheduledOrdersHistory.table.list.set(normalized);
      } catch (e) {
        devLoggerService.error('Error in getScheduledOrdersHistoryAsync', e);
      }
    }
);
