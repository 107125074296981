import React from 'react';
import { observer } from 'mobx-react-lite';

import { TCreateTableModelInstance } from '@trader/store';

import * as Styled from './styled';
import { ESortDirection } from '@trader/types';

interface ISortingButton {
  columnId: string;
  isSortable?: boolean;
  tableStore: TCreateTableModelInstance;
}

const ActiveSortingButton: React.FC<Omit<ISortingButton, 'isSortable'>> =
  observer(({ tableStore, columnId }) => {
    if (tableStore.sorting.direction === ESortDirection.ASC) {
      return (
        <Styled.SortingButton
          $isActive
          size='small'
          iconType='sorting'
          $sortOrder={ESortDirection.ASC}
          onClick={() =>
            tableStore.changeSortingDirection(columnId, ESortDirection.DESC)
          }
        />
      );
    }

    return (
      <Styled.SortingButton
        $isActive
        size='small'
        iconType='sorting'
        $sortOrder={ESortDirection.DESC}
        onClick={() => tableStore.changeSortingDirection(columnId, null)}
      />
    );
  });

export const SortingButton: React.FC<ISortingButton> = observer(
  ({ columnId, isSortable, tableStore }) => {
    if (!isSortable) {
      return null;
    }

    if (tableStore.sorting?.columnId === columnId) {
      return (
        <ActiveSortingButton tableStore={tableStore} columnId={columnId} />
      );
    }

    return (
      <Styled.SortingButton
        className='Sort_Filter_Button_Hover'
        size='small'
        iconType='sorting'
        $sortOrder={ESortDirection.ASC}
        onClick={() =>
          tableStore.changeSortingDirection(columnId, ESortDirection.ASC)
        }
      />
    );
  }
);
