import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }
  `}
`;

export const StickyOrder = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: 100%;
    padding-left: 20px;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const CloseAllHeader = styled('span')`
  ${({ theme }) => css`
    font-size: ${theme.typography.tiny.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.palette.red.light};
    cursor: pointer;
    user-select: none;
  `}
`;
