import styled, { css } from 'styled-components';

import { Button } from '@trader/components';

export const SymbolButton = styled(Button)`
  ${() => css`
    justify-content: flex-start;
    align-items: center;
    border-radius: 0;
    height: 100%;

    > span {
      display: flex;
      align-items: center;
    }
  `};
`;
