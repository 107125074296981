import styled, { css } from 'styled-components';

import {
  Typography,
  Wrapper,
  Select as ComponentsSelect,
} from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    height: 100%;
    padding: 6px 18px 18px 18px;
    flex-direction: column;
    justify-content: space-between;

    @media (max-height: 700px) {
      height: unset;
    }
  `}
`;

export const Content = styled(Wrapper)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `}
`;

export const Skin = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    margin-right: 120px;

    ${theme.breakpoints.down('md')} {
      margin-bottom: 18px;
    }

    ${theme.breakpoints.down('sm')} {
      margin-right: 0;
    }
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.xl.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    text-transform: uppercase;
    margin-bottom: 15px;
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    width: 120px;
    font-size: ${theme.typography.large.fontSize};
  `}
`;

export const Select = styled(ComponentsSelect)`
  ${({ theme }) => css`
    width: 290px;
    height: 45px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const Item = styled(Wrapper)`
  ${() => css`
    width: 100%;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const ExtraFeatures = styled(Wrapper)`
  ${({ theme }) => css`
    width: 240px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .switcher-left-label {
      width: 140px;
      font-weight: ${theme.typography.fontWeightRegular};
      display: inline-block;
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-bottom: 18px;
    }
  `}
`;
