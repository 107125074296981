import { IGetPspsParams } from './models';

export const paymentRoutes = {
  makeDeposit: '/payment/deposit',
  getPsps: ({ amount, tradingAccountId }: IGetPspsParams) =>
    `/payment/psps?Amount=${amount}&TradingAccountId=${tradingAccountId}`,
  getTransactions: '/payment/transactions',
  getTransfersHistory: '/payment/transfers-history',
  getPurchaseHistory: '/payment/purchase-history',
  getAvailablePaymentMethods: '/payment/content-link/psps',
  getDepositLink: '/payment/content-link/deposit',
  validateCouponCode: '/payment/coupon/validate',
} as const;
