import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { TScheduledOrderEntity, useMst } from '@trader/store';
import { ModalTitle, Typography, Wrapper } from '@trader/components';
import { useI18next } from '@trader/services';
import { EStripName } from '@trader/types';
import { formatDate } from '@trader/utils';
import { defaultIcon } from '@trader/constants';

import { ConfirmButton } from '../components/confirmButton';
import * as Styled from './styled';

export const CancelScheduledOrder: FC = observer(() => {
  const { translate, currentLng } = useI18next();

  const store = useMst();
  const modal = store.ui.modal;
  const orderId = modal.options.get('orderId');
  const scheduledOrder =
    store.entities.scheduledOrders.get<TScheduledOrderEntity>(orderId);
  const isOrderProcessing =
    store.pages.muliBands.deleteMuliBandScheduledOrderAsync.inProgress;

  const handleCancelOrder = () => {
    store.pages.muliBands.deleteMuliBandScheduledOrderAsync.run({
      bandId: scheduledOrder.bandId as unknown as EStripName,
      strategyId: scheduledOrder.strategyId,
    });
  };

  if (!scheduledOrder) {
    return null;
  }

  return (
    <Styled.Root>
      <ModalTitle title={translate('MULI_BANDS.CANCEL_BAND')} />
      <Wrapper justifyContent='space-between' width='100%' padding='0 16px'>
        <Wrapper alignItems='center'>
          <Styled.Image
            src={defaultIcon}
            alt='instrument icon'
            width={30}
            height={30}
          />
          <Typography variant='medium'>{scheduledOrder.symbol}</Typography>
        </Wrapper>
        <Wrapper flexDirection='column'>
          <Typography variant='medium' textAlign='right'>
            {scheduledOrder.bandId} {scheduledOrder.quantity}
          </Typography>
          <Typography variant='medium'>
            {formatDate(scheduledOrder.placeDate, 'Mm dd, yyyy hh:mm:ss', {
              locale: currentLng,
            })}
          </Typography>
        </Wrapper>
      </Wrapper>
      <ConfirmButton
        isDisabled={isOrderProcessing}
        isLoading={isOrderProcessing}
        onClick={handleCancelOrder}
        title={
          isOrderProcessing
            ? translate('COMMON.LABELS.PROCESSING')
            : translate('MULI_BANDS.CANCEL_BAND')
        }
      />
    </Styled.Root>
  );
});
