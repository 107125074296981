import { useCallback } from 'react';
import i18next from 'i18next';
import I18nHttpBackend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';

import { TOptions } from '@trader/types';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import { localStorageService } from '@trader/services';

import { TTranslationKeys } from './translationKeys';

export type TLanguageKey =
  | 'en'
  | 'es'
  | 'it'
  | 'de'
  | 'pl'
  | 'nl'
  | 'sk'
  | 'ar'
  | 'ku';

const defaultLng: TLanguageKey = 'en';

i18next
  .use(I18nHttpBackend)
  .use(initReactI18next)
  .init({
    lng: defaultLng,
    fallbackLng: false,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: true,
    },
  });

const getSupportedLanguages = (): TLanguageKey[] => {
  return import.meta.env.VITE_LANGUAGES.split(',');
};

const getCurrentLanguage = () => {
  return (
    (localStorageService.get(LOCAL_STORAGE_KEYS.language) as TLanguageKey) ||
    defaultLng
  );
};

export const getLanguageOptions = (): TOptions => {
  const options: TOptions = [];
  const supportedLanguages = getSupportedLanguages();

  for (const supportedLng of supportedLanguages) {
    options.push({
      value: supportedLng,
      title: i18next.t(`LANGUAGES.${supportedLng}`),
    });
  }

  return options;
};

async function changeLanguage(lng?: string): Promise<void> {
  const languageCode = lng || getCurrentLanguage();
  await i18next.changeLanguage(languageCode);
}

changeLanguage();

export const useI18next = () => {
  const { t } = useTranslation();

  const changeLng = useCallback(async (lng: string) => {
    localStorageService.set(LOCAL_STORAGE_KEYS.language, lng);
    await changeLanguage(lng);
  }, []);

  return {
    currentLng: getCurrentLanguage(),
    translate: (
      key: TTranslationKeys,
      options?: Record<string, string | number>
    ) => t(key, options),
    changeLng,
  };
};

export default i18next;
