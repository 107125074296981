import { useEffect, useMemo } from 'react';

import { TInstrumentEntity, useMst } from '@trader/store';

export const useTradingCentral = () => {
  const store = useMst();

  const isTradingCentral = store.user.settings.isDisplayedByUser;

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  const instrument = useMemo(
    () => store.entities.instruments.get<TInstrumentEntity>(symbol),
    [symbol]
  );

  useEffect(() => {
    if (isTradingCentral && instrument?.symbol) {
      instrument.getInstrumentTradingCentralAsync.run();
    }
  }, [isTradingCentral, instrument?.symbol]);
};
