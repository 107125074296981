import styled, { css } from 'styled-components';
import { Typography as MuiTypography, TypographyProps } from '@mui/material';

interface IProps extends TypographyProps {
  $margin: TypographyProps['margin'];
  $marginTop: TypographyProps['marginTop'];
  $marginRight: TypographyProps['marginRight'];
  $marginBottom: TypographyProps['marginBottom'];
  $marginLeft: TypographyProps['marginLeft'];
  $padding: TypographyProps['marginTop'];
  $paddingTop: TypographyProps['marginTop'];
  $paddingRight: TypographyProps['marginTop'];
  $paddingBottom: TypographyProps['marginTop'];
  $paddingLeft: TypographyProps['marginTop'];
}

// Custom Typography component to handle spacing correctly in RTL mode.
export const Typography = styled(MuiTypography)<IProps>`
  ${({
    theme,
    $margin,
    $marginTop,
    $marginRight,
    $marginBottom,
    $marginLeft,
    $padding,
    $paddingTop,
    $paddingRight,
    $paddingBottom,
    $paddingLeft,
  }) => css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${theme.palette.common.black};

    margin: ${typeof $margin === 'number' ? theme.spacing($margin) : $margin};
    margin-right: ${typeof $marginRight === 'number'
      ? theme.spacing($marginRight)
      : $marginRight};
    margin-bottom: ${typeof $marginBottom === 'number'
      ? theme.spacing($marginBottom)
      : $marginBottom};
    margin-left: ${typeof $marginLeft === 'number'
      ? theme.spacing($marginLeft)
      : $marginLeft};
    margin-top: ${typeof $marginTop === 'number'
      ? theme.spacing($marginTop)
      : $marginTop};

    padding: ${typeof $padding === 'number'
      ? theme.spacing($padding)
      : $padding};
    padding-right: ${typeof $paddingRight === 'number'
      ? theme.spacing($paddingRight)
      : $paddingRight};
    padding-bottom: ${typeof $paddingBottom === 'number'
      ? theme.spacing($paddingBottom)
      : $paddingBottom};
    padding-left: ${typeof $paddingLeft === 'number'
      ? theme.spacing($paddingLeft)
      : $paddingLeft};
    padding-top: ${typeof $paddingTop === 'number'
      ? theme.spacing($paddingTop)
      : $paddingTop};
  `}
`;
