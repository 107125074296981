import { useCallback, useEffect } from 'react';

import {
  IChallengeEntitySnapshotOut,
  TTradingAccountEntity,
  useMst,
} from '@trader/store';
import { MODAL_TYPES } from '@trader/constants';
import { ESocketUpdateAction } from '@trader/types';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { useChallengesLogic } from '../challengesLogic';
import { useNavigation } from '../useNavigation';
import { useStartConnection } from './core';

export const useChallenges = () => {
  const store = useMst();
  const { navigateTo } = useNavigation();
  const { challengesLogic } = useChallengesLogic();

  const { connection } = useStartConnection(
    EConnectionHub.Events,
    EConnectionSubscription.Challenges,
    'events'
  );

  const idToken = store.auth.tokens.idToken;

  const handleMessage = useCallback(message => {
    if (message.updateAction === ESocketUpdateAction.Create) {
      store.entities.challenges.getChallengesAsync.run();
    }
    if (message.updateAction === ESocketUpdateAction.Update) {
      challengesLogic(message);

      if (store.user.profile.tradingId === message.accountId) {
        store.user.runInAction(() => {
          store.user.profile.blockedDueToDailyTarget =
            message.blockedDueToDailyTarget;
        });
      }

      store.entities.challenges.update(message.id, {
        status: message.status,
        result: message.result,
        blockedDueToDailyTarget: message.blockedDueToDailyTarget,
      });
    }
    if (message.updateAction === ESocketUpdateAction.Delete) {
      // you are on deleted account right now and account must be changed
      if (message.accountId === store.user.tradingAccount?.tradingId) {
        const newChallenge = store.entities.challenges
          .getAll<IChallengeEntitySnapshotOut>()
          ?.find(
            challenge =>
              challenge.status !== 'DISABLED' && challenge.id !== message.id
          );

        if (!newChallenge) {
          store.ui.modal.open(MODAL_TYPES.resultChallenge, {
            isActiveChallenge: false,
            shouldHideCloseButton: true,
            result: 'EmptyAccounts',
          });
          return;
        }

        if (newChallenge) {
          const newAccount =
            store.entities.tradingAccounts.get<TTradingAccountEntity>(
              newChallenge.accountId
            );

          store.entities.tradingAccounts.changeTradingAccountAsync.run({
            tradingId: newAccount?.tradingId || 0,
            navigation: navigateTo,
          });
        }
      }

      store.entities.challenges.delete(message.id);
    }
  }, []);

  useEffect(() => {
    if (store.auth.isAuth) {
      connection.subscribe(hub => {
        hub.on('OnChallenge', handleMessage);
      });
      return () => {
        connection.unsubscribe(hub => {
          hub.off('OnChallenge', handleMessage);
        });
      };
    }
  }, [store.auth.isAuth, idToken]);
};
