import { Instance, types } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

import { createTableModel } from '../../utils/tableModel';
import { scheduledOrderHistoryModel } from '../../entities/scheduledOrdersHistory';

export const scheduledOrdersHistoryTableModel = types
  .model('scheduledOrdersHistoryTableModel', {
    table: createTableModel(scheduledOrderHistoryModel, {}),
  })
  .actions(() => ({
    runInAction,
  }));

export const scheduledOrdersHistoryTable = types.optional(
  scheduledOrdersHistoryTableModel,
  {}
);

export type TScheduledOrdersHistoryTableModel = Instance<
  typeof scheduledOrdersHistoryTableModel
>;
