import {
  IChallengeEntitySnapshotOut,
  TChallengeEntity,
  useMst,
} from '@trader/store';
import { useEffect, useMemo } from 'react';
import { EChallenge } from '@trader/types';
import { IChallenges } from '../index';

export const useChallengesList = (
  props: Pick<IChallenges, 'dividedChallenges' | 'shouldTriggerGetChallenges'>
) => {
  const { dividedChallenges, shouldTriggerGetChallenges } = props;

  const store = useMst();

  const challenges =
    dividedChallenges ||
    store.entities.challenges.getAll<IChallengeEntitySnapshotOut>();

  const sortedChallenges = useMemo(() => {
    const masters = challenges.filter(
      ch => ch.hierarchyType === EChallenge.Master
    );

    if (!masters.length) {
      return challenges;
    }

    const sorted: TChallengeEntity[] = [];

    masters.forEach(master => {
      sorted.push(master);
      const slavesToMaster = challenges.filter(
        ch => ch.hierarchyType === EChallenge.Slave && ch.masterId === master.id
      );
      slavesToMaster.length && sorted.push(...slavesToMaster);
    });

    const nonAssigned = challenges.filter(
      ch => ch.hierarchyType === EChallenge.NonAssigned
    );

    sorted.push(...nonAssigned);

    return sorted;
  }, [challenges.length]);

  useEffect(() => {
    if (shouldTriggerGetChallenges) {
      store.entities.tradingAccounts.getTradingAccountsAsync.run();
      store.entities.challenges.getChallengesAsync.run();
    }
  }, [shouldTriggerGetChallenges]);

  return {
    challenges,
    sortedChallenges,
  };
};
