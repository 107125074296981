import { getIconBaseProps } from './utils';

export const gift = {
  ...getIconBaseProps('gift'),
  width: '16px',
  height: '16px',
  viewBox: '0 0 16 16',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M7.31348 4.49796H8.68848V7.99796H13.8135C13.9958 7.99796 14.1707 7.92553 14.2996 7.79659C14.4285 7.66766 14.501 7.49279 14.501 7.31046V5.18546C14.501 5.00313 14.4285 4.82825 14.2996 4.69932C14.1707 4.57039 13.9958 4.49796 13.8135 4.49796H11.9441C12.1972 3.97659 12.2562 3.3822 12.1104 2.8211C11.9646 2.26002 11.6235 1.7696 11.1485 1.43753C10.6735 1.10545 10.0956 0.953634 9.51873 1.00932C8.94183 1.06502 8.40369 1.32458 8.00098 1.74139C7.59756 1.32687 7.05989 1.06931 6.48404 1.01475C5.9082 0.96002 5.33172 1.1122 4.85764 1.4436C4.38355 1.77499 4.04277 2.26418 3.89662 2.82372C3.74996 3.38232 3.80681 3.9767 4.05754 4.49796H2.18848C2.00614 4.49796 1.83127 4.57039 1.70234 4.69932C1.57341 4.82825 1.50098 5.00313 1.50098 5.18546V7.31046C1.50098 7.49279 1.57341 7.66766 1.70234 7.79659C1.76618 7.86043 1.84197 7.91108 1.92538 7.94563C2.00879 7.98018 2.09819 7.99796 2.18848 7.99796H7.31348V4.49796Z'
        fill='currentColor'
      />
      <path
        d='M8.68848 3.43546C8.68848 3.22532 8.75079 3.01989 8.86754 2.84516C8.98429 2.67044 9.15023 2.53425 9.34437 2.45384C9.53852 2.37342 9.75215 2.35238 9.95826 2.39337C10.1644 2.43437 10.3537 2.53556 10.5023 2.68416C10.6509 2.83275 10.7521 3.02207 10.7931 3.22817C10.8341 3.43428 10.813 3.64791 10.7326 3.84206C10.6522 4.03621 10.516 4.20215 10.3413 4.31889C10.1665 4.43564 9.96112 4.49796 9.75098 4.49796H8.68848V3.43546Z'
        fill='currentColor'
      />
      <path
        d='M5.18848 3.43546C5.18848 3.15367 5.30042 2.88341 5.49968 2.68416C5.69893 2.4849 5.96918 2.37296 6.25098 2.37296C6.53278 2.37296 6.80303 2.4849 7.00228 2.68416C7.20153 2.88341 7.31348 3.15367 7.31348 3.43546V4.49796H6.25098C5.96918 4.49796 5.69893 4.38602 5.49968 4.18677C5.30042 3.98751 5.18848 3.71726 5.18848 3.43546Z'
        fill='currentColor'
      />
      <path
        d='M8.68848 14.998H12.8135C12.9958 14.998 13.1707 14.9256 13.2996 14.7967C13.4285 14.6678 13.501 14.4929 13.501 14.3105V8.99805H8.68848V14.998Z'
        fill='currentColor'
      />
      <path
        d='M2.49951 14.3105C2.49951 14.4929 2.57194 14.6678 2.70088 14.7967C2.82981 14.9256 3.00468 14.998 3.18701 14.998H7.31201V8.99805H2.49951V14.3105Z'
        fill='currentColor'
      />
    </>
  ),
};
