import { getIconBaseProps } from './utils';

export const hot = {
  ...getIconBaseProps('hot'),
  width: '11px',
  height: '14px',
  viewBox: '0 0 11 14',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
  body: (
    <>
      <path
        d='M5.50004 14C8.51945 14 11 11.716 11 8.78434C11 8.06463 10.9617 7.29312 10.5438 6.09406C10.1258 4.89503 10.0416 4.74026 9.59973 3.99977C9.41093 5.51089 8.40075 6.14068 8.14412 6.32891C8.14412 6.13308 7.53303 3.96756 6.6065 2.67186C5.69694 1.4 4.45994 0.565557 3.73463 0C3.73463 1.07442 3.41805 2.67186 2.96467 3.48579C2.51128 4.29972 2.42614 4.32936 1.85982 4.93507C1.29354 5.54078 1.0336 5.72785 0.56005 6.46278C0.0866519 7.19775 0 8.17663 0 8.89633C0 11.828 2.48063 14 5.50004 14Z'
        fill='currentColor'
      />
    </>
  ),
};
