import { Grid } from '@mui/material';

import { TAvailableChallengeEntity, useMst } from '@trader/store';
import { MODAL_TYPES } from '@trader/constants';

import { PurchaseListCard } from './purchaseListCard';
import { PurchaseListDescription } from './purchaseListDescription';
import * as Styled from './styled';

export interface IPurchaseList {
  purchaseType: 'evaluationChallenges' | 'instantChallenges';
  items: TAvailableChallengeEntity[];
}

export const PurchaseList = ({ purchaseType, items }: IPurchaseList) => {
  const store = useMst();

  const handlePurchaseButtonClick = (contentLinkId: number) => {
    store.ui.modal.open(MODAL_TYPES.purchaseChallenge, {
      contentLinkId,
    });
  };

  return (
    <Styled.Root>
      <PurchaseListDescription purchaseType={purchaseType} />
      <Grid container rowSpacing='55px' columnSpacing='40px'>
        {items.map((item, index) => (
          <Styled.GridItem
            item
            key={item.contentLinkId}
            xs={12}
            sm='auto'
            md='auto'
            lg='auto'
          >
            <PurchaseListCard
              challengeAmount={item.challengeAmount}
              purchaseAmount={item.purchaseAmount}
              discountPurchaseAmount={item.discountPurchaseAmount}
              frequencyChoice={item.frequencyChoice}
              purchaseType={purchaseType}
              withChartUpBackground={index % 2 === 0}
              onPurchaseButtonClick={() =>
                handlePurchaseButtonClick(item.contentLinkId)
              }
            />
          </Styled.GridItem>
        ))}
      </Grid>
    </Styled.Root>
  );
};
