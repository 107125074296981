export const tradingRoutes = {
  placeOrder: () => 'v3/trading/place-order',
  placeChallengeOrder: () => 'v3/trading/place-order/challenge',
  cancelOrder: (orderId: string) => `v3/trading/cancel-order/${orderId}`,
  cancelChallengeOrder: (orderId: string) =>
    `v3/trading/cancel-order/${orderId}/challenge`,
  editOrder: (orderId: string) => `v3/trading/order/${orderId}`,
  editChallengeOrder: (orderId: string) =>
    `v3/trading/order/${orderId}/challenge`,
  closePosition: (positionId: string) =>
    `v3/trading/close-position/${positionId}`,
  closeChallengePosition: (positionId: string) =>
    `v3/trading/close-position/${positionId}/challenge`,
  editPosition: (positionId: string) => `v3/trading/position/${positionId}`,
  editChallengePosition: (positionId: string) =>
    `v3/trading/position/${positionId}/challenge`,
  getOrdersMetrics: 'v3/trading/orders-metrics',
  getAssetOrderMetrics: 'v3/trading/asset-orders-metrics?pageSize=500',
  getPositionsMetrics: 'v3/trading/positions-metrics',
  getRequiredOpenCost: 'v3/trading/required-open-cost',
  getPositionsHistory: 'v3/trading/positions-history',
  getCostAndCharges: 'v3/trading/cost-and-charges',
  getAccountStatement: 'v3/trading/reports/account-statements',
  closeAllPositions: 'v3/trading/close-all-positions',
  cancelAllOrders: 'v3/trading/cancel-all-orders',
  closeAllChallengePositions: 'v3/trading/close-all-positions/challenge',
  cancelAllChallengeOrders: 'v3/trading/cancel-all-orders/challenge',
  changeLeverage: 'v3/trading/leverage',
} as const;
