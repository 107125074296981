import { useCallback, useEffect } from 'react';

import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
  useI18next,
} from '@trader/services';
import { useMst } from '@trader/store';
import { ITradeResultMessage } from '@trader/types';
import { tradingCodeMessages, tradingCodes } from '@trader/constants';

import { useStartConnection } from './core';

/**
 * Custom hook that manages trade result updates by subscribing to a hub and handling incoming messages to update the store.
 */
export const useTradeResults = () => {
  const store = useMst();
  const { translate } = useI18next();
  const { connection } = useStartConnection(
    EConnectionHub.Events,
    EConnectionSubscription.TradeResults,
    'events'
  );

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const serverName = activeTradingAccount?.serverName;

  const handleMessage = useCallback((message: ITradeResultMessage) => {
    const code = message.retCode?.toString();
    if (tradingCodes.error.includes(code)) {
      store.ui.modal.close();

      const errorMessageKey = tradingCodeMessages.error[code]
        ? tradingCodeMessages.error[code]
        : 'WARNINGS.TRADING_ACTION_FAILED';

      store.notifications.add({
        message: translate(errorMessageKey),
        options: {
          variant: 'warning',
        },
      });
    }
  }, []);

  useEffect(() => {
    if (platformLogin && serverName) {
      connection.subscribe(async (hub: HubConnection) => {
        await hub.send('SubscribeOnTrade', serverName, platformLogin);
        hub.on('OnTradeRequest', handleMessage);
      });

      return () => {
        connection.unsubscribe(async hub => {
          hub.off('OnTradeRequest', handleMessage);
          await hub?.send('UnsubscribeFromTrade', serverName, platformLogin);
        });
      };
    }
  }, [platformLogin, idToken]);
};
