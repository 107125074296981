import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { InstrumentDetailsHeader } from '@trader/containers';
import { EChartLayouts, ETradingView } from '@trader/types';
import { TInstrumentEntity, useMst } from '@trader/store';
import { useInstruments } from '@trader/hooks';

import { CandleChart } from '../../../candleChart';
import { AdvanceChart } from '../../../advanceChart';

import * as Styled from './styled';
import { EConnectionSubscription } from '@trader/services';

interface IMultipleChart {
  layoutNumber: EChartLayouts;
}

export const MultipleChart: React.FC<IMultipleChart> = observer(
  ({ layoutNumber }) => {
    const store = useMst();

    const layoutType = store.pages.trading.layout.layoutType;
    const isAdvancedChart = store.app.chartType === ETradingView.Advanced;

    const symbol =
      store.pages.trading.getInstrumentSymbolByLayout(layoutNumber);
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);

    useInstruments({
      instrumentsIds: [symbol],
      subscription: EConnectionSubscription.Instrument,
    });

    useEffect(() => {
      symbol &&
        store.pages.trading.layout.layouts[
          layoutNumber
        ].getInstrumentSpecificationAsync.run(symbol);
    }, [symbol]);

    if (!instrument) {
      return <Styled.Child />;
    }

    const changeActiveLayoutNumber = () => {
      store.pages.trading.layout.changeLayoutNumber(layoutNumber);
    };

    return (
      <Styled.Child
        $selectedLayout={store.pages.trading.layout.selectedLayoutNumber}
        $instrumentLayout={layoutNumber}
        onClick={changeActiveLayoutNumber}
        id={`${layoutType}-details`}
        $layout={layoutType}
      >
        <InstrumentDetailsHeader instrument={{ ...instrument, layoutNumber }} />
        {isAdvancedChart ? (
          <AdvanceChart
            layoutNumber={layoutNumber}
            instrumentSymbol={instrument.symbol}
          />
        ) : (
          <CandleChart
            layoutNumber={layoutNumber}
            symbol={instrument.symbol}
            pipSize={instrument.pipSize}
          />
        )}
      </Styled.Child>
    );
  }
);
