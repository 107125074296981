import React, { forwardRef } from 'react';

import { EOrderSide } from '@trader/types';

import * as Styled from '../styled';
import { TTranslationKeys, useI18next } from '@trader/services';

interface IPositionPlaced {
  closeSnackbar: () => void;
  message: {
    id: number;
    symbol: string;
    comment: string;
    brand: number;
    platformLogin: number;
    openPrice: number;
    side: number;
    price: number;
    profit: number;
    stopLoss: number;
    takeProfit: number;
    volume: number;
  };
}

export const PositionPlaced: React.FC<IPositionPlaced> = forwardRef<
  HTMLDivElement,
  IPositionPlaced
>(({ closeSnackbar, message }, ref) => {
  const { translate } = useI18next();

  const side = EOrderSide[message?.side];
  const translatedSide = side
    ? translate(`COMMON.LABELS.${side.toUpperCase()}` as TTranslationKeys)
    : side;

  return (
    <Styled.NotificationRoot ref={ref}>
      <Styled.Close onClick={closeSnackbar} iconType='remove' />
      <Styled.SuccessIcon iconType='challengeCompleted' />
      <Styled.Content>
        <Styled.Title>
          {translate('NOTIFICATIONS.POSITION_OPENED')}
        </Styled.Title>
        <Styled.Text>
          {translatedSide} {message?.volume} {message?.symbol}{' '}
          {translate('COMMON.LABELS.AT')} {message?.openPrice}
        </Styled.Text>
      </Styled.Content>
    </Styled.NotificationRoot>
  );
});
