import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IHttpClientResponseError,
  responseStatusCodes,
  useI18next,
} from '@trader/services';
import { Button, TextField, Typography } from '@trader/components';
import { useMst } from '@trader/store';

import { calculateCouponDiscount } from '../../../utils/calculateCouponDiscount';
import * as Styled from './styled';

interface ICouponCodeEntry {
  contentLinkId: number;
  purchaseAmount: number;
  onCouponUpdate: (code: string, discount: number) => void;
}

export const CouponCodeEntry: FC<ICouponCodeEntry> = observer(
  ({ contentLinkId, purchaseAmount, onCouponUpdate }) => {
    const store = useMst();
    const { translate } = useI18next();

    const [couponCode, setCouponCode] = useState('');
    const [invalidCouponCode, setInvalidCouponCode] = useState('');
    const [validationText, setValidationText] = useState('');
    const [discount, setDiscount] = useState(0);

    const handleApply = async () => {
      if (!couponCode) {
        setDiscount(0);
        setInvalidCouponCode('');
        setValidationText('');
        onCouponUpdate('', 0);
        return;
      }

      try {
        const res = await store.user.validateCouponCodeAsync.run({
          contentLinkId,
          couponCode,
        });

        if (!res?.discount) {
          return;
        }

        const isPercentageDiscount = res.discount.includes('%');
        const couponDiscount = calculateCouponDiscount(
          purchaseAmount,
          isPercentageDiscount
            ? Number(res.discount.replace('%', ''))
            : Number(res.discount),
          isPercentageDiscount ? 'percentage' : 'amount'
        );

        setDiscount(couponDiscount);
        onCouponUpdate(couponCode, couponDiscount);
      } catch (e) {
        setInvalidCouponCode(couponCode);
        setDiscount(0);
        onCouponUpdate('', 0);

        const isCouponInvalid =
          (e as IHttpClientResponseError).status === responseStatusCodes['400'];
        if (isCouponInvalid) {
          setValidationText(translate('PURCHASE_CHALLENGES.COUPON_INVALID'));
        } else {
          setValidationText(
            translate('PURCHASE_CHALLENGES.COUPON_VALIDATION_FAILED')
          );
        }
      }
    };

    return (
      <Styled.Root>
        <Typography variant='large' marginBottom='4px'>
          {translate('PURCHASE_CHALLENGES.ADD_COUPON_CODE')}
        </Typography>
        <Styled.CouponEntry>
          <TextField
            fullWidth
            value={couponCode}
            placeholder={translate(
              'PURCHASE_CHALLENGES.COUPON_CODE_PLACEHOLDER'
            )}
            onChange={event => setCouponCode(event.target.value)}
            shouldHideControlInfo
          />
          <Button
            size='small'
            variant='outlined'
            loading={store.user.validateCouponCodeAsync.inProgress}
            disabled={store.user.validateCouponCodeAsync.inProgress}
            onClick={handleApply}
          >
            {translate('COMMON.LABELS.APPLY')}
          </Button>
        </Styled.CouponEntry>
        {!!discount && (
          <Styled.ValidCoupon>
            <Typography variant='medium'>
              {translate('COMMON.LABELS.SUCCESS')}
            </Typography>
            <Typography variant='medium'>-${discount}</Typography>
          </Styled.ValidCoupon>
        )}
        {invalidCouponCode && invalidCouponCode === couponCode && (
          <Styled.InvalidCoupon>
            <Typography variant='medium'>{validationText}</Typography>
          </Styled.InvalidCoupon>
        )}
      </Styled.Root>
    );
  }
);
