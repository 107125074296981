import React from 'react';
import { observer } from 'mobx-react-lite';

import { MODAL_TYPES } from '@trader/constants';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import * as Styled from './desktop.styled';

export const AccountStatementButton: React.FC<{
  account: TTradingAccountEntity;
}> = observer(({ account }) => {
  const store = useMst();
  const { translate } = useI18next();

  const handleOpenAccountStatementModal = () => {
    store.ui.modal.open(MODAL_TYPES.accountStatement, {
      platformLogin: account.platformLogin,
      tradingAccountCurrency: account.currency,
      product: account.product,
      accountType: account.accountType,
    });
  };

  return (
    <Styled.OutlinedButton onClick={handleOpenAccountStatementModal}>
      {translate('COMMON.LABELS.ACCOUNT_STATEMENT')}
    </Styled.OutlinedButton>
  );
});
