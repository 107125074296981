import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  Progress,
  ScrollingList,
} from '@trader/components';
import { ITransferHistoryBE, TPaymentStatus, TTransferType } from '@trader/api';
import { useMst } from '@trader/store';
import { formatDate, thousand } from '@trader/utils';
import { useI18next } from '@trader/services';

import { useTranslationMaps } from '../../hooks/useTranslationMaps';
import { useTransfersHistory } from '../../hooks/useTransfersHistory';
import { TransferFilters } from './filters';
import * as Styled from './desktop.styled';

export const DesktopTransfersHistory: React.FC = observer(() => {
  const store = useMst();
  const { currentLng, translate } = useI18next();
  const { statusTranslationMap, transferTypesTranslationMap } =
    useTranslationMaps();
  const { transfers, fetchTransfersHistory, fetchMoreHistory } =
    useTransfersHistory();

  const renderRowItemsCallBacks: IRenderRowItemsCallBacks<ITransferHistoryBE> =
    useMemo(
      () => ({
        transferId: item => item.value,
        currencySymbol: item => item.value,
        value: item => item.value,
        status: item => statusTranslationMap[item.value as TPaymentStatus],
        transferType: item =>
          transferTypesTranslationMap[item.value as TTransferType],
        'date.seconds': item =>
          formatDate(
            new Date((item?.value as number) * thousand),
            'Mm dd, yyyy',
            { locale: currentLng }
          ),
      }),
      []
    );

  const cells: Array<IHeadCell> = [
    {
      id: 'transferId',
      label: translate('COMMON.LABELS.TRANSFER'),
      minWidth: 60,
      align: 'start',
    },
    {
      id: 'currencySymbol',
      label: translate('COMMON.LABELS.SYMBOL'),
      minWidth: 40,
      align: 'center',
    },
    {
      id: 'value',
      label: translate('COMMON.LABELS.AMOUNT'),
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 120,
      align: 'center',
    },
    {
      id: 'transferType',
      label: translate('COMMON.LABELS.TYPE'),
      minWidth: 70,
      align: 'start',
    },
    {
      id: 'date.seconds',
      label: translate('COMMON.LABELS.DATE'),
      minWidth: 120,
      align: 'start',
    },
  ];

  const renderTransfersList = () => {
    if (
      !transfers.isFetchingMore &&
      store.user.getTransfersHistoryAsync.inProgress
    ) {
      return <Progress />;
    }

    return (
      <ScrollingList<ITransferHistoryBE>
        rows={transfers.data}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCallBacks}
        totalCount={transfers.totalCount}
        fetchMore={fetchMoreHistory}
        emptyMessage={translate('COMMON.LABELS.NO_TRANSFERS')}
      />
    );
  };

  return (
    <Styled.Root>
      <TransferFilters onFiltersChange={fetchTransfersHistory} />
      {renderTransfersList()}
    </Styled.Root>
  );
});
