/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  types,
  IAnyModelType,
  getIdentifier,
  IOptionalIType, // IMapType,
  SnapshotIn,
  IMSTMap,
  detach,
} from 'mobx-state-tree';

type TId = string | number;

type TMergeFunction = (
  item: IAnyModelType,
  value: SnapshotIn<IAnyModelType>
) => void;

export type TMergeStrategyType = 'assign' | 'replace' | TMergeFunction;

export const createCollectionModel = <
  T extends IAnyModelType,
  TActions extends object
>(
  modelForCollection: T,
  asyncModels?: TActions,
  defaultValueOrFunction?: Partial<T> | (() => Partial<T>)
) => {
  type TCurrentCollection = IMSTMap<T>;

  const collectionModel = types
    .model('collection', {
      collection: types.map(modelForCollection),
      ...(asyncModels as TActions),
    })
    .views(self => ({
      get<TResult>(id: TId): TResult {
        return self.collection.get(String(id)) as TResult;
      },
      getAllById<TResult>(filterId: string, filterValue: TId): Array<TResult> {
        return Array.from(self.collection.values()).filter(
          item => item[filterId] === filterValue
        );
      },
      getAll<TResult>(): Array<TResult> {
        return Array.from(self.collection.values());
      },
      has(id: TId): ReturnType<TCurrentCollection['has']> {
        return self.collection.has(String(id));
      },
    }))
    .actions(self => ({
      add(id: TId, value: Partial<SnapshotIn<T>>) {
        self.collection.set(String(id), value);
      },
      delete(id: TId) {
        const entity = self.collection.get(id);
        detach(entity);
        self.collection.delete(String(id));
      },
      destroy(item: T) {
        const id = getIdentifier(item);

        if (id === null) {
          throw new Error(
            "CollectionModel: Couldn't destroy the item. Identifier is not resolved"
          );
        }

        self.collection.delete(String(item));
      },
      update(
        id: TId,
        value: Partial<SnapshotIn<T>>, // SnapshotIn<T>,
        mergeStrategy?: TMergeStrategyType
      ) {
        const item = self.collection.get(String(id));
        if (mergeStrategy === 'replace') {
          self.collection.set(String(id), value);
        } else if (
          typeof mergeStrategy === 'function' &&
          typeof item !== 'undefined'
        ) {
          mergeStrategy(item, value);
        } else {
          if (typeof item !== 'undefined') {
            Object.assign(item, value);
          }
        }
      },
      find(callback: (item: unknown) => T | undefined) {
        return Array.from(self.collection.values()).find(callback); // also can use { values } from 'mobx'
      },
    }));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return types.optional<typeof collectionModel>(
    collectionModel,
    (defaultValueOrFunction as any) ||
      ({} as IOptionalIType<typeof collectionModel, [undefined]>)
  );
};
