import { useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { imageGenerationService, useI18next } from '@trader/services';
import { useMst } from '@trader/store';
import { IAccountStatementBE, IGetAccountStatementParams } from '@trader/api';
import { AccountStatementPdfReport } from '@trader/components';

interface IOptions {
  pdfReportProps: {
    from: string;
    to: string;
    platformLogin: string;
    currency: string;
    product: string;
    accountType: string;
    accountStatement?: IAccountStatementBE;
  };
  shouldFetchAccountStatement?: boolean;
}

export const useDownloadAccountStatementReport = (options: IOptions) => {
  const { pdfReportProps, shouldFetchAccountStatement } = options;

  const [isDownloading, setIsDownloading] = useState(false);

  const store = useMst();
  const { translate } = useI18next();

  const downloadReport = async () => {
    try {
      setIsDownloading(true);

      let accountStatement: IAccountStatementBE | undefined =
        options.pdfReportProps.accountStatement;

      if (shouldFetchAccountStatement) {
        const params: IGetAccountStatementParams = {
          from: pdfReportProps.from,
          to: pdfReportProps.to,
          platformLogin: pdfReportProps.platformLogin,
          accountType: pdfReportProps.accountType,
        };
        const res = await store.pages.trading.getAccountStatementAsync.run(
          params
        );
        accountStatement = res;
      }

      if (!accountStatement) {
        throw new Error('Account statement is not presented.');
      }

      const chartEl = document.querySelector('.recharts-responsive-container');
      const chart = chartEl
        ? await imageGenerationService.fromHtmlToDataUrl({
            element: chartEl as HTMLElement,
          })
        : null;

      const component = (
        <AccountStatementPdfReport
          {...pdfReportProps}
          accountStatement={accountStatement}
          balanceChart={chart}
        />
      );
      const blob = await pdf(component).toBlob();
      const filename = 'Account-Statement.pdf';

      saveAs(blob, filename);
    } catch (e) {
      store.notifications.add({
        message: translate('WARNINGS.FILE_GENERATION_FAILED'),
        options: {
          variant: 'error',
        },
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    isReportDownloading: isDownloading,
    downloadAccountStatementReport: downloadReport,
  };
};
