import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { MobilePositionsList } from './mobile';
import { DesktopPositionsList } from './desktop';

export interface IPositionsList {
  symbol?: string;
  withClickableSymbol?: boolean;
}

export const PositionsList: FC<IPositionsList> = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobilePositionsList {...props} />
  ) : (
    <DesktopPositionsList {...props} />
  );
};
