import { FC, MouseEvent } from 'react';

import { MODAL_TYPES } from '@trader/constants';
import { TScheduledOrderEntity, useMst } from '@trader/store';
import { ScrollableItemActions } from '@trader/components';

interface IScheduledOrderActions {
  scheduledOrder: TScheduledOrderEntity;
}

export const ScheduledOrderActions: FC<IScheduledOrderActions> = ({
  scheduledOrder,
}) => {
  const store = useMst();

  const handleRemoveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    store.ui.modal.open(MODAL_TYPES.cancelScheduledOrder, {
      orderId: scheduledOrder.id,
    });
  };

  return <ScrollableItemActions onRemoveClick={handleRemoveClick} />;
};
