import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useMediaQuery, useTheme } from '@mui/material';

import { EConnectionSubscription, useI18next } from '@trader/services';
import { useInstruments } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';
import { InstrumentDetailsHeader } from '@trader/containers';
import { EChartLayouts, EChartLayoutTypes } from '@trader/types';
import { Progress, Tabs, Typography, Wrapper } from '@trader/components';

import { Chart } from './components/chart';

import * as Styled from './styled';

const KeyStatisticsLazy = React.lazy(() =>
  import('@trader/containers').then(({ KeyStatistics }) => ({
    default: KeyStatistics,
  }))
);
const TradingCentralLazy = React.lazy(() =>
  import('@trader/containers').then(({ TradingCentral }) => ({
    default: TradingCentral,
  }))
);
const PositionsListLazy = React.lazy(() =>
  import('@trader/components').then(({ PositionsList }) => ({
    default: PositionsList,
  }))
);
const PendingOrdersListLazy = React.lazy(() =>
  import('@trader/components').then(({ PendingOrdersList }) => ({
    default: PendingOrdersList,
  }))
);

export const SingleLayout: React.FC = observer(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { translate } = useI18next();

  const store = useMst();
  const symbol = store.pages.trading.getInstrumentSymbolByLayout();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);
  const isLoading =
    store.entities.instruments.getInstrumentSpecificationAsync.inProgress ||
    store.pages.trading.getPrefetchInformationAsync.inProgress;

  useInstruments({
    instrumentsIds: [symbol],
    subscription: EConnectionSubscription.Instrument,
  });

  useEffect(() => {
    symbol &&
      store.trading.getTrading('createOrder').getInstrumentReferenceAsync.run({
        symbol,
        side: null,
        shouldTriggerSpecification: true,
      });
  }, []);

  return (
    <Styled.Root>
      {!instrument && !isLoading ? (
        <Typography padding='8px' variant='large' textAlign='center'>
          {translate('COMMON.LABELS.NO_DATA_TO_SHOW_CHART')}
        </Typography>
      ) : (
        <InstrumentDetailsHeader
          layout={EChartLayoutTypes.Single}
          layoutNumber={EChartLayouts.FirstLayout}
        />
      )}
      {isMobile ? <MobileTabs symbol={symbol} /> : <Chart />}
    </Styled.Root>
  );
});

const MobileTabs: React.FC<{ symbol: string }> = observer(({ symbol }) => {
  const { translate } = useI18next();

  const store = useMst();
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
  const shouldShowTradingCentral = store.user.settings.isDisplayedByUser;

  const tabs = () => {
    const items = [
      {
        label: translate('COMMON.LABELS.CHART'),
        value: 'chart',
        children: <Chart />,
      },
      {
        label: translate('COMMON.LABELS.KEY_STATISTICS'),
        value: 'key-statistics',
        children: (
          <React.Suspense fallback={<Progress />}>
            <KeyStatisticsLazy />
          </React.Suspense>
        ),
      },
      {
        label: translate('COMMON.LABELS.POSITIONS'),
        value: 'open-positions',
        children: (
          <React.Suspense fallback={<Progress />}>
            <Styled.List>
              <PositionsListLazy symbol={symbol} />
            </Styled.List>
          </React.Suspense>
        ),
      },
    ];

    if (shouldShowTradingCentral) {
      items.splice(2, 0, {
        label: translate('COMMON.LABELS.TRADING_CENTRAL'),
        value: 'trading-central',
        children: (
          <React.Suspense fallback={<Progress />}>
            <Wrapper padding='6px 8px 0 8px' flexDirection='column'>
              <TradingCentralLazy />
            </Wrapper>
          </React.Suspense>
        ),
      });
    }

    if (!isInvestmentAccProduct) {
      items.push({
        label: translate('COMMON.LABELS.ORDERS'),
        value: 'orders',
        children: (
          <React.Suspense fallback={<Progress />}>
            <Styled.List>
              <PendingOrdersListLazy symbol={symbol} />
            </Styled.List>
          </React.Suspense>
        ),
      });
    }

    return items;
  };

  return <Tabs navigationKey='layoutView' tabs={tabs()} hasNavigationBar />;
});
