import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { PurchaseType, Table, SymbolWithIcon } from '@trader/components';
import { EScheduledOrderStatus, ITableColumn } from '@trader/types';
import { TScheduledOrderEntity, useMst } from '@trader/store';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { useI18next } from '@trader/services';
import { formatDate } from '@trader/utils';

import { ScheduledOrderActions } from './scheduledOrderActions';

import * as Styled from './styled';
import { EFilterType } from '@trader/constants';

interface IScheduledOrdersList {
  type?: 'active' | 'history';
}

export const ScheduledOrdersList: React.FC<IScheduledOrdersList> = observer(
  ({ type = 'active' }) => {
    const store = useMst();
    const { translate, currentLng } = useI18next();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();

    const entities = store.entities;

    const isHistory = type === 'history';

    useEffect(() => {
      isHistory
        ? entities.scheduledOrdersHistory.getScheduledOrdersHistoryAsync.run()
        : entities.scheduledOrders.getScheduledOrdersAsync.run();
    }, []);

    const columns: ITableColumn<TScheduledOrderEntity>[] = useMemo(
      () => [
        {
          id: 'symbol',
          align: 'left',
          header: translate('COMMON.LABELS.SYMBOL'),
          cellStyle: { paddingLeft: '15px' },
          headerStyle: { paddingLeft: '20px' },
          minWidth: 155,
          render: row => (
            <SymbolWithIcon symbol={row.symbol} iconUrl={row.iconUrl} />
          ),
        },
        {
          id: 'side',
          header: translate('COMMON.LABELS.TYPE'),
          minWidth: getExtendedWidth('120', '2', ['nl']),
          render: row => <PurchaseType value={row.side as string} />,
        },
        {
          id: 'status',
          minWidth: 100,
          header: translate('COMMON.LABELS.STATUS'),
        },
        {
          id: 'strategyId',
          minWidth: 155,
          header: translate('MULI_BANDS.STRATEGY_ID'),
        },
        {
          id: 'bandId',
          minWidth: 100,
          header: translate('MULI_BANDS.BAND_ID'),
        },
        {
          id: 'quantity',
          minWidth: 100,
          header: translate('COMMON.LABELS.VOLUME'),
        },
        {
          id: 'upFloatMultiplier',
          minWidth: 140,
          header: translate('MULI_BANDS.TOP_MULTIPLIER'),
        },
        {
          id: 'downFloatMultiplier',
          minWidth: 150,
          header: translate('MULI_BANDS.BOTTOM_MULTIPLIER'),
        },
        {
          id: 'stopLoss',
          minWidth: 140,
          header: translate('COMMON.LABELS.SL'),
          render: row => row.stopLoss || '-',
        },
        {
          id: 'placeDate',
          minWidth: 140,
          type: EFilterType.DATE,
          header: translate('COMMON.LABELS.OPEN_TIME'),
          render: row =>
            formatDate(row.placeDate, 'Mm dd, yyyy hh:mm:ss', {
              locale: currentLng,
            }),
        },
        ...(isHistory
          ? [
              {
                id: 'failedDescription',
                minWidth: 160,
                header: translate('MULI_BANDS.FAILED_DESCRIPTION'),
                render: row => row.failedDescription || '-',
              },
            ]
          : []),
        {
          id: 'actions',
          header: '',
          minWidth: getExtendedWidth('80', '1.35', ['sk']),
          render: row =>
            row.status === EScheduledOrderStatus.Created ? (
              <ScheduledOrderActions scheduledOrder={row} />
            ) : (
              ''
            ),
        },
      ],
      [currentLng, type]
    );

    return (
      <Styled.Root>
        <Table
          store={
            isHistory
              ? store.tables.scheduledOrdersHistory
              : store.tables.scheduledOrders
          }
          emptyMessage={
            isHistory
              ? translate('COMMON.LABELS.NO_SCHEDULED_ORDERS_HISTORY')
              : translate('COMMON.LABELS.NO_SCHEDULED_ORDERS')
          }
          columns={columns}
        />
      </Styled.Root>
    );
  }
);
