import { types } from 'mobx-state-tree';

import { positionsTable } from './positions';
import { positionsHistoryTable } from './positionsHistory';
import { ordersTable } from './orders';
import { alertsTable } from './alerts';
import { scheduledOrdersTable } from './scheduledOrders';
import { scheduledOrdersHistoryTable } from './scheduledOrdersHistory';

export const tables = types.model('tables', {
  positionsHistory: positionsHistoryTable,
  positions: positionsTable,
  orders: ordersTable,
  alerts: alertsTable,
  scheduledOrders: scheduledOrdersTable,
  scheduledOrdersHistory: scheduledOrdersHistoryTable,
});
