import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
} from '@trader/hooks';
import {
  ModalTitle,
  Progress,
  TradingIsClosed,
  Typography,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { ConfirmButton } from '@trader/trading';
import { TInstrumentEntity, TOrderMetricEntity, useMst } from '@trader/store';

import {
  IOrderMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';

import * as Styled from './styled';

const Content: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const modal = store.ui.modal;
  const orderId = modal.options.get('orderId');
  const metric = store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);

  const handleCancelOrder = () => {
    metric?.cancelOrderAsync.run(orderId);
  };

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.CANCEL_ORDER')} />
      <TradingModalHeader<IOrderMetricStore> type='order' metric={metric} />
      <ConfirmButton
        isDisabled={metric?.cancelOrderAsync.inProgress}
        isLoading={
          metric?.cancelOrderAsync.inProgress ||
          metric?.cancelOrderAsync.hasEverBeenRun
        }
        onClick={handleCancelOrder}
        title={translate('COMMON.LABELS.CANCEL_ORDER')}
      />
    </Styled.Root>
  );
});

export const CancelOrder: React.FC = observer(() => {
  useCloseModalForAlreadyDeletedInstance('orderId');

  const { translate } = useI18next();
  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const store = useMst();
  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;
  const orderId = store.ui.modal.options.get('orderId');
  const metric = store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  if (!metric) {
    return null;
  }

  if (applyConditionThird().status === 'failed') {
    return (
      <Styled.Root paddingTop='16px'>
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_IS_DISABLED')}
          content={
            <div>
              <Typography>
                {translate('WARNINGS.PLEASE_CONTACT_SUPPORT')}
              </Typography>
              <a
                href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
                style={{ color: 'inherit' }}
              >
                {import.meta.env.VITE_SUPPORT_EMAIL}
              </a>
            </div>
          }
          buttonContent={translate('COMMON.LABELS.PROCEED')}
          onClick={applyConditionThird()?.action}
        />
      </Styled.Root>
    );
  }

  return <Content />;
});
