import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';

import {
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '../../charting_library';
import { getNetPl } from '@trader/utils';
import { useGetAdapterDiff } from './useGetAdapterDiff';
import { devLoggerService } from '@trader/services';

interface IPosition {
  key: string;
  ref: IPositionLineAdapter | undefined;
}

const positionLineLength = 22;
const positions = new Map<string, IPosition>();

export const usePositions = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { typeColor, tooltip } = useGetAdapterDiff('Position');

  const positionsMetrics =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();
  const strips = Array.from(store.pages.muliBands.strips.values());
  const symbol =
    store.pages.muliBands.symbol ||
    store.pages.trading.getInstrumentSymbolByLayout();

  const symbolPositionsMetrics = positionsMetrics.filter(
    metric => metric.symbol === symbol && metric.showOnTradingViewChart
  );

  const getInstrumentPl = (pl: number) => {
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);
    return getNetPl(pl, instrument?.currencySymbol);
  };

  const createPosition = (id: string) => {
    const position =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(id);

    const currentPl = getInstrumentPl(position.pl);

    const tp = position?.takeProfit?.limitPrice
      ? `TP: ${position?.takeProfit?.limitPrice}`
      : '';
    const sl = position?.stopLoss?.stopPrice
      ? `SL: ${position?.stopLoss?.stopPrice}`
      : '';

    const ref = widget?.current
      ?.activeChart()
      .createPositionLine()
      .setText(`${currentPl.stringValue} ${tp} ${sl}`)
      .setBodyTextColor(currentPl.textColor)
      .setBodyBorderColor(typeColor(id, position.side))
      .setTooltip(tooltip(id))
      .setLineColor(typeColor(id, position.side))
      .setBodyBackgroundColor(theme.palette.white.main)
      .setLineLength(positionLineLength)
      .setQuantity(`${position?.side}: ${position?.quantity}`)
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .setCloseButtonBorderColor(theme.palette.tab.light)
      .setCloseButtonBackgroundColor(theme.palette.tab.light)
      .setCloseButtonIconColor(theme.palette.white.main)

      .onClose(async function () {
        try {
          const metric =
            store.entities.positionsMetrics.get<TPositionMetricEntity>(id);

          if (metric) {
            await metric.closePositionAsync.run({
              positionId: id,
              quantity: metric?.quantity,
            });
            this.remove();
            positions.delete(id);
          }
        } catch (e) {
          devLoggerService.error('onCancel called', e);
        }
      })
      .setPrice(position?.openPrice);

    positions.set(id, {
      ...positions.get(id),
      key: id,
      ref,
    });
  };

  const clearPositions = useCallback(() => {
    positions.clear();
  }, []);

  useEffect(() => {
    if (store.ui.tradingView.isChartLoaded) {
      widget.current?.onChartReady(() => {
        if (!strips.length) {
          return;
        }

        symbolPositionsMetrics.forEach(metric => {
          if (!metric) {
            return;
          }

          if (!positions.get(metric.positionId)) {
            createPosition(metric.positionId);
          } else {
            const pl = metric.pl;

            const currentPl = getInstrumentPl(pl);
            const positionRef = positions.get(metric.positionId)?.ref;

            const tp = metric?.takeProfit?.limitPrice
              ? `TP: ${metric?.takeProfit?.limitPrice}`
              : '';
            const sl = metric?.stopLoss?.stopPrice
              ? `SL: ${metric?.stopLoss?.stopPrice}`
              : '';

            positionRef?.setText(`${currentPl.stringValue} ${tp} ${sl}`);
            positionRef?.setBodyTextColor(currentPl.textColor);
            positionRef?.setTooltip(tooltip(metric.positionId));
            positionRef?.setBodyBorderColor(
              typeColor(metric.positionId, metric.side)
            );
            positionRef?.setLineColor(
              typeColor(metric.positionId, metric.side)
            );
          }
        });
      });
    }
  }, [
    strips.length,
    positionsMetrics,
    store.ui.tradingView.isChartLoaded,
    symbol,
  ]);

  // delete position
  useEffect(() => {
    Array.from(positions.values()).forEach((position: IPosition) => {
      const matchedPosition =
        store.entities.positionsMetrics.get<TPositionMetricEntity>(
          position.key
        );
      if (!matchedPosition || !matchedPosition.showOnTradingViewChart) {
        positions.get(position.key)?.ref?.remove();
        positions.delete(position.key);
      }
    });
  }, [positionsMetrics]);

  return {
    clearPositions,
  };
};
