import React from 'react';

import { IChangeFieldEvent, TOptions } from '@trader/types';
import { Switcher } from '@trader/components';
import { TTranslationKeys, useI18next } from '@trader/services';

import * as Styled from './styled';

interface IIndicator {
  shouldDisplay: boolean;
  disabled: boolean;
  checked: boolean;
  name: string;
  leftLabel: string;
  innerHtml: string;
  onChange: (
    event: IChangeFieldEvent<TOptions> | React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export const IndicatorSwitcher: React.FC<IIndicator> = props => {
  const { translate } = useI18next();

  if (!props.shouldDisplay) {
    return null;
  }

  return (
    <Styled.Root>
      <Switcher
        name={props.name}
        disabled={props.disabled}
        value={props.checked}
        onChange={props.onChange}
        leftLabel={translate(props.leftLabel as TTranslationKeys)}
      />
      {props.disabled && (
        <Styled.Note
          dangerouslySetInnerHTML={{
            __html: translate(props.innerHtml as TTranslationKeys),
          }}
        />
      )}
    </Styled.Root>
  );
};
