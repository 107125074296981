import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';
import { conditionApply } from '@trader/utils';

interface IHide {
  $shouldHide: boolean;
  $withTopDivider: boolean;
}

interface IItem {
  $isSelected: boolean;
}

export const Root = styled(Wrapper)<IHide>`
  ${({ theme, $shouldHide, $withTopDivider }) => css`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 7px 11px 0 11px;
    margin-bottom: 7px;
    ${conditionApply(
      [$withTopDivider],
      css`
        border-top: 1px solid ${theme.palette.background.default};
      `
    )}

    .click-subcategories {
      display: none;
    }

    #mui-component-select-subcategory {
      /* @noflip */
      padding: 5.5px 32px 5.5px 14px;
    }

    ${theme.breakpoints.down('sm')} {
      overflow-y: auto;

      .select-subcategories {
        display: none;
      }

      .click-subcategories {
        display: flex;
      }
    }

    ${conditionApply(
      [$shouldHide],
      css`
        display: none;
      `
    )}
  `};
`;

export const Container = styled(Wrapper)`
  ${() => css`
    width: 100%;
  `};
`;

export const Item = styled(Wrapper)<IItem>`
  ${({ $isSelected, theme }) => css`
    padding: 6px 20px;
    border-radius: 10px;
    color: ${theme.palette.text.primary};
    font-size: ${theme.typography.default.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin-right: 5px;
    cursor: pointer;
    text-transform: initial;
    white-space: nowrap;

    ${conditionApply(
      [$isSelected],
      css`
        background: ${theme.palette.gray.light};
      `
    )}
  `};
`;
