import { useCallback, useEffect } from 'react';

import { useMst } from '@trader/store';
import { ESocketUpdateAction } from '@trader/types';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { useStartConnection } from './core';

export const usePriceAlertsCreation = () => {
  const store = useMst();

  const { connection } = useStartConnection(
    EConnectionHub.Events,
    EConnectionSubscription.PriceAlertsCreation,
    'events'
  );

  const idToken = store.auth.tokens.idToken || '';

  const handleMessage = useCallback(
    (message: { id: number; updateAction: ESocketUpdateAction }) => {
      if (message.updateAction === ESocketUpdateAction.Delete) {
        store.entities.alerts.getAlertsAsync.run();
      } else {
        store.entities.alerts.getAlertByIdAsync.run(message.id);
      }
    },
    []
  );

  useEffect(() => {
    if (store.auth.isAuth) {
      connection.subscribe(hub => {
        hub.on('OnAlert', handleMessage);
      });
      return () => {
        connection.unsubscribe(hub => {
          hub.off('OnAlert', handleMessage);
        });
      };
    }
  }, [store.auth.isAuth, idToken]);
};
