import styled, { css } from 'styled-components';

import { BaseModal } from '@trader/components';
import { conditionApply } from '@trader/utils';

interface IStyleProps {
  $isDisablePadding: boolean;
}

export const Modal = styled(BaseModal)<IStyleProps>`
  ${({ theme, $isDisablePadding }) => css`
    .MuiDialog-paper {
      border-radius: 10px;

      > div {
        padding: 18px 0;

        ${conditionApply(
          [$isDisablePadding],
          css`
            padding: 0;
          `
        )}
      }
    }

    ${theme.breakpoints.down('sm')} {
      .MuiDialog-paper {
        margin: 11px;
        max-width: 100%;
        width: 100%;
      }
    }
  `};
`;
