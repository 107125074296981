import styled, { css } from 'styled-components';

import { Button, Typography, Wrapper } from '@trader/components';
import { footerHeight, headerHeight, layoutMargin } from '@trader/constants';

const daysHeldOpacity = 0.5;

export interface IBoldElement {
  $isBold?: boolean;
}

export interface IActiveElement {
  $isActive?: boolean;
}

export const Root = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  `}
`;

export const Content = styled(Wrapper)<IActiveElement>`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
  `}
`;

export const Main = styled(Wrapper)`
  ${() => css`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    cursor: pointer;
  `}
`;
export const TitleRow = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0;
  `}
`;
export const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    text-align: center;
    margin-right: 7px;
  `}
`;

const getCostAndChargesHeight = (
  isTakeProfitOpen: boolean,
  isStopLossOpen: boolean,
  type: 'desktop' | 'mobile' = 'desktop'
) => {
  const offset = 8;
  const footerHeaderPaddingCount = 4;

  const tradeHeaderHeight = {
    desktop: 42,
    mobile: 50,
  };
  const tradeBodyHeight = {
    desktop: 404,
    mobile: 411,
  };
  const protectionHeight = {
    desktop: 62,
    mobile: 70,
  };

  const tradingSectionHeight =
    headerHeight +
    footerHeight +
    layoutMargin * footerHeaderPaddingCount +
    tradeHeaderHeight[type] +
    tradeBodyHeight[type];

  return (
    tradingSectionHeight +
    offset +
    (isTakeProfitOpen ? protectionHeight[type] : 0) +
    (isStopLossOpen ? protectionHeight[type] : 0)
  );
};

export const CostAndCharges = styled(Wrapper)<{
  $isTakeProfitOpen: boolean;
  $isStopLossOpen: boolean;
}>`
  ${({ theme, $isTakeProfitOpen, $isStopLossOpen }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 7px;
    margin-top: 6px;
    min-height: 120px;
    height: calc(
      100vh - ${getCostAndChargesHeight($isTakeProfitOpen, $isStopLossOpen)}px
    );
    overflow: auto;

    ${theme.breakpoints.down('sm')} {
      height: calc(
        100vh -
          ${getCostAndChargesHeight(
            $isTakeProfitOpen,
            $isStopLossOpen,
            'mobile'
          )}px
      );
    }
  `}
`;
export const Row = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    width: 100%;
    border-bottom: 1px dotted ${theme.palette.background.default};
  `}
`;

export const Label = styled(Typography)<IBoldElement>`
  ${({ theme, $isBold = false }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.small.fontSize};
    font-weight: ${$isBold ? '500' : 'normal'};
    text-wrap: wrap;
  `}
`;
export const Value = styled(Typography)<IBoldElement>`
  ${({ theme, $isBold = false }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.small.fontSize};
    font-weight: ${$isBold ? '500' : 'normal'};
    text-wrap: wrap;
  `}
`;
export const DaysHeld = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 170px;
  `}
`;
export const DaysHeldValue = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
  `}
`;

export const DaysHeldButton = styled(Button)<IActiveElement>`
  ${({ theme, $isActive }) => css`
    border: 1px solid ${theme.palette.background.default};
    border-radius: 5px;
    width: 25px;
    min-width: 25px;
    padding: 0;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${$isActive ? 1 : daysHeldOpacity};
  `}
`;
export const TooltipRow = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 5px 0;
    width: 100%;
    border-bottom: 1px dotted ${theme.palette.background.default};
  `}
`;
export const TooltipUL = styled(Typography)`
  ${({ theme }) => css`
    list-style-type: decimal;
    padding-left: 10px;
    color: ${theme.palette.primary.dark};
    width: 100%;
    white-space: normal;
    margin-top: 10px;
  `}
`;
export const TooltipLI = styled(Typography)`
  ${({ theme }) => css`
    list-style-type: decimal;
    padding: 0 0 10px 10px;
    color: ${theme.palette.primary.dark};
    float: left;
    width: 100%;
    white-space: normal;
  `}
`;
export const TooltipTitle = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    padding: 10px 0 0 10px;
  `}
`;
export const TooltipLabel = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-weight: ${theme.typography.fontWeightMedium};
    margin-left: 10px;
    text-wrap: wrap;
  `}
`;
