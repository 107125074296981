import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { MobilePositionsHistory } from './mobile';
import { DesktopPositionsHistory } from './desktop';

export interface IPositionsHistory {
  shouldShowFooter?: boolean;
}

export const PositionsHistory: FC<IPositionsHistory> = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobilePositionsHistory />
  ) : (
    <DesktopPositionsHistory {...props} />
  );
};
