import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
  `}
`;

export const CouponEntry = styled(Wrapper)`
  ${({ theme }) => css`
    input {
      padding: 8px;
      font-size: ${theme.typography.large.fontSize};

      &::placeholder {
        font-size: ${theme.typography.medium.fontSize};
      }
    }

    button {
      width: 120px;
      margin-left: 8px;
      color: ${theme.palette.green['800']};
      border: 1px solid ${theme.palette.gray.main};
      font-weight: ${theme.typography.fontWeightBold};

      &:hover {
        border: 1px solid ${theme.palette.black.main};
        background-color: transparent;
      }
    }
  `}
`;

export const InvalidCoupon = styled('div')`
  ${({ theme }) => css`
    padding: 4px;
    background-color: ${theme.palette.red[300]};
    border-radius: 5px;
    margin-top: 8px;
    text-align: center;

    p {
      color: ${theme.palette.red.light};
    }
  `}
`;

export const ValidCoupon = styled(Wrapper)`
  ${({ theme }) => css`
    padding: 4px;
    background-color: ${theme.palette.green['800']};
    justify-content: space-between;
    border-radius: 5px;
    margin-top: 8px;

    p {
      color: ${theme.palette.white.main};
    }
  `}
`;
