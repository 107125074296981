import React, { useState, useEffect } from 'react';
import { TooltipProps } from '@mui/material';

import * as Styled from './styled';

interface ITooltip
  extends Omit<TooltipProps, 'children' | 'isOpen' | 'onClose' | 'onOpen'> {
  children: string | React.ReactElement;
  maxWidth?: number;
  behavior?: 'hover' | 'click';
  autoCloseDelay?: number;
}

const autoCloseOnClickDelay = 1500; // 1.5s

export const Tooltip: React.FC<ITooltip> = ({
  title,
  children,
  placement = 'bottom-start',
  maxWidth,
  behavior = 'hover',
  autoCloseDelay = autoCloseOnClickDelay,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTooltipOpen = () => setIsOpen(true);
  const handleTooltipClose = () => setIsOpen(false);

  const isClickBehavior = behavior === 'click';

  // Auto-close logic for 'click' behavior
  useEffect(() => {
    if (isClickBehavior && isOpen) {
      const timer = setTimeout(handleTooltipClose, autoCloseDelay);
      return () => clearTimeout(timer);
    }
  }, [isOpen, isClickBehavior, autoCloseDelay]);

  return (
    <Styled.Tooltip
      $maxWidth={maxWidth}
      title={title}
      placement={placement}
      arrow
      {...rest}
      open={isClickBehavior ? isOpen : undefined}
      disableHoverListener={isClickBehavior}
      disableFocusListener={isClickBehavior}
      disableTouchListener={!isClickBehavior}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                // eslint-disable-next-line no-magic-numbers
                offset: [-20, -10],
              },
            },
          ],
        },
      }}
    >
      <div onClick={isClickBehavior ? handleTooltipOpen : undefined}>
        {children}
      </div>
    </Styled.Tooltip>
  );
};
