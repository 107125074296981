export const calculateCouponDiscount = (
  value: number,
  discountValue: number,
  discountType: 'percentage' | 'amount'
) => {
  if (!value) {
    return 0;
  }

  if (!discountValue) {
    return value;
  }

  if (discountType === 'amount') {
    return discountValue;
  }

  if (discountType === 'percentage') {
    // eslint-disable-next-line no-magic-numbers
    return (value * discountValue) / 100;
  }

  return value;
};
