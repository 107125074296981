import { Instance, types } from 'mobx-state-tree';

import {
  EChallengeEvaluationType,
  EChallengeFrequencyChoice,
  EChallengeStatus,
} from '@trader/types';

import { createCollectionModel } from '../../utils/collectionModel';

import { getAvailableChallengesAsync } from './actions';

const availableChallengeBundleModel = types.model(
  'availableChallengeBundleModel',
  {
    quantity: types.number,
    price: types.number,
    isXStrategyPurchase: types.boolean,
  }
);

export const availableChallengeModel = types.model('availableChallengeModel', {
  contentLinkId: types.identifierNumber,
  challengeStatus: types.union(
    types.literal(EChallengeStatus.Active),
    types.literal(EChallengeStatus.Disable)
  ),
  text: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  maxLeverage: types.maybeNull(types.string),
  currency: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  contractLimit: types.maybeNull(types.string),
  evaluationType: types.union(
    types.literal(EChallengeEvaluationType.Evaluation),
    types.literal(EChallengeEvaluationType.Instant)
  ),
  name: types.maybeNull(types.string),
  sort: types.number,
  profitTarget: types.maybeNull(types.string),
  maxDailyDrawdown: types.maybeNull(types.string),
  maxTotalDrawdown: types.maybeNull(types.string),
  minTradingDays: types.maybeNull(types.string),
  purchaseAmount: types.number,
  challengeAmount: types.number,
  profitShare: types.maybeNull(types.string),
  tradingPeriod: types.maybeNull(types.string),
  discountPercent: types.maybeNull(types.number),
  discountPurchaseAmount: types.maybeNull(types.number),
  frequencyChoice: types.maybeNull(
    types.union(
      types.literal(EChallengeFrequencyChoice.Offer),
      types.literal(EChallengeFrequencyChoice.Popular),
      types.literal(EChallengeFrequencyChoice.Hot)
    )
  ),
  bundle: types.maybeNull(types.boolean),
  bundles: types.array(availableChallengeBundleModel),
});

export const availableChallenges = createCollectionModel<
  typeof availableChallengeModel,
  { getAvailableChallengesAsync: typeof getAvailableChallengesAsync }
>(availableChallengeModel, {
  getAvailableChallengesAsync,
});

export type TAvailableChallengeEntity = Instance<
  typeof availableChallengeModel
>;

export type TAvailableChallengeBundle = Instance<
  typeof availableChallengeBundleModel
>;
