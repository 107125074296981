import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IAvailablePaymentMethodBE } from '@trader/api';
import {
  TAvailableChallengeBundle,
  TAvailableChallengeEntity,
  useMst,
} from '@trader/store';

import { PurchaseDetails } from './components/purchaseDetails';
import { PaymentMethods } from './components/paymentMethods';
import * as Styled from './styled';

export const PurchaseChallenge: React.FC = observer(() => {
  const [appliedCoupon, setAppliedCoupon] = useState({
    code: '',
    discount: 0,
  });
  const [paymentMethods, setPaymentMethods] = useState<
    IAvailablePaymentMethodBE[] | undefined
  >();
  const [selectedBundle, setSelectedBundle] =
    useState<TAvailableChallengeBundle>();

  const store = useMst();
  const contentLinkId = store.ui.modal.options.get('contentLinkId');
  const challenge =
    store.entities.availableChallenges.get<TAvailableChallengeEntity>(
      contentLinkId
    );
  const purchaseAmount =
    selectedBundle?.price ||
    challenge?.discountPurchaseAmount ||
    challenge?.purchaseAmount;

  const handlePurchaseButtonClick = async () => {
    const response = await store.user.getAvailablePaymentMethodsAsync.run({
      amount: purchaseAmount - appliedCoupon.discount,
      tradingAccountId: store.user.activeTradingAcc().tradingId,
      contentLinkId,
    });
    setPaymentMethods(response);
  };

  if (!challenge) {
    return null;
  }

  return (
    <Styled.Root>
      {!paymentMethods ? (
        <PurchaseDetails
          challenge={challenge}
          contentLinkId={contentLinkId}
          purchaseAmount={purchaseAmount - appliedCoupon.discount}
          isPurchaseButtonLoading={
            store.user.getAvailablePaymentMethodsAsync.inProgress
          }
          selectedBundle={selectedBundle}
          onBundleSelect={setSelectedBundle}
          onPurchaseButtonClick={handlePurchaseButtonClick}
          onCouponUpdate={(code, discount) =>
            setAppliedCoupon({ code, discount })
          }
        />
      ) : (
        <PaymentMethods
          paymentMethods={paymentMethods}
          contentLinkId={contentLinkId}
          purchaseAmount={purchaseAmount - appliedCoupon.discount}
          couponCode={appliedCoupon.code ? appliedCoupon.code : undefined}
          selectedBundle={selectedBundle}
        />
      )}
    </Styled.Root>
  );
});
