/* eslint-disable no-magic-numbers */
import {
  IAnyStateTreeNode,
  IOptionalIType,
  Instance,
  types,
  cast,
} from 'mobx-state-tree';

import {
  EFilterType,
  allOperatorsCallbacks,
  filterNumberOperators,
  filterStringOperators,
} from '@trader/constants';
import { runInAction } from '@trader/utils';
import { ESortDirection, IOption, TOptions } from '@trader/types';

import { createListModel, TListModelOptions } from '../listModel';

type TCustomFiltersValues =
  | string
  | number
  | boolean
  | object
  | IOption
  | TOptions;
type TCustomFilters = Record<string, TCustomFiltersValues>;

const initOption: IOption = {
  value: '',
  title: '',
  id: '',
};

const initState = {
  pageSizeOptions: [10, 25, 50, 100],
  totalCount: 0,
  currentPage: 0,
  pageSize: 50,
  sorting: {
    direction: null,
    columnId: '',
  },
  filtering: {
    column: initOption,
    value: '',
    operator: initOption,
  },
  customFilters: null,
};

export function createTableModel<T extends IAnyStateTreeNode>(
  modelForList: T,
  optionsForListModel: TListModelOptions<T>
): IOptionalIType<typeof tableModel, [undefined]> {
  const tableModel = types
    .model('createTableModel', {
      columns: types.optional(
        types.array(
          types.model('tableColumnModel', {
            option: types.frozen<IOption>(),
            type: types.union(
              types.literal(EFilterType.NUMBER),
              types.literal(EFilterType.STRING),
              types.literal(EFilterType.DATE)
            ),
          })
        ),
        []
      ),
      operators: types.frozen<TOptions>([]),
      // Sorting
      sorting: types.model('tableSortingModel', {
        direction: types.maybeNull(types.frozen<ESortDirection>()),
        columnId: types.string,
      }),
      // Filtering
      filtering: types.model('tableFilteringModel', {
        column: types.frozen<IOption>(),
        operator: types.frozen<IOption>(),
        value: types.string,
      }),
      customFilters: types.frozen<TCustomFilters | null>(),
      // Pagination
      totalCount: types.number,
      currentPage: types.number,
      pageSize: types.number,
      pageSizeOptions: types.array(types.number),
      // List
      list: createListModel(modelForList, optionsForListModel),
      additionalFields: types.map(types.frozen()),
    })
    .views(store => ({
      data(hasPagination?: boolean) {
        let array = store.list.array.slice();

        if (
          store.filtering.column.id &&
          store.filtering.operator.id &&
          store.filtering.value
        ) {
          array = array
            .map(item => {
              if (item) {
                const column = store.columns.find(
                  c => c.option.id === store.filtering.column.value
                );
                let fieldValue = item[store.filtering.column.value];

                if (column && column.type === EFilterType.DATE) {
                  fieldValue = new Date(
                    item[store.filtering.column.value]
                  ).toUTCString();
                }

                const cb =
                  allOperatorsCallbacks[store.filtering.operator.value];
                if (cb?.(fieldValue, store.filtering.value)) {
                  return item;
                }
              }
              return undefined;
            })
            .filter(item => !!item);
        }

        if (store.sorting.columnId && store.sorting.direction) {
          array = array.sort((a, b) => {
            if (!a || !b) {
              return [];
            }

            const valueA = a[store.sorting.columnId];
            const valueB = b[store.sorting.columnId];

            if (typeof valueA === 'string') {
              return store.sorting.direction === ESortDirection.ASC
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
            }
            return store.sorting.direction === ESortDirection.ASC
              ? valueA - valueB
              : valueB - valueA;
          });
        }

        if (hasPagination) {
          const startIndex = store.currentPage * store.pageSize;
          const endIndex = startIndex + store.pageSize;

          array = array.slice(startIndex, endIndex);
        }

        return array;
      },
      getAdditionalFields<TValue extends number | string | boolean>(
        key: string
      ): TValue | undefined {
        return store.additionalFields.get(key);
      },
      getCustomFilter(key: string) {
        if (store.customFilters) {
          return store.customFilters[key];
        }
      },
    }))
    .actions(store => ({
      runInAction,
      changeSortingDirection(column: string, direction: ESortDirection | null) {
        store.sorting.direction = direction;
        store.sorting.columnId = direction === null ? '' : column;
      },
      openFiltering(columnId: string) {
        const column = store.columns.find(c => c.option.id === columnId);

        if (store.filtering.column.id === columnId) {
          return;
        }

        if (column) {
          const operators =
            column.type === EFilterType.NUMBER
              ? filterNumberOperators
              : filterStringOperators;

          store.filtering.value = '';
          store.filtering.column = column.option;
          store.operators = operators;
          store.filtering.operator = operators[0];
        }
      },
      changeFiltering(key: string, value: string | TOptions) {
        store.filtering[key] = typeof value === 'object' ? value[0] : value;
      },
      setColumns(columns: Array<{ option: IOption; type: EFilterType }>) {
        store.columns = cast(columns);
      },
      changeCurrentPage(value?: number) {
        store.currentPage = value !== undefined ? value : store.currentPage;
      },
      changePageSize(value?: number) {
        store.pageSize = value !== undefined ? value : store.pageSize;
        store.currentPage = initState.currentPage;
      },
      setAdditionalFields(params: object) {
        for (const key in params) {
          store.additionalFields.set(key, params[key]);
        }
      },
      setCustomFilters(filters: TCustomFilters) {
        store.customFilters = filters;
      },
      updateCustomFilters(key: string, value: TCustomFiltersValues) {
        store.customFilters = {
          ...(store.customFilters ? store.customFilters : {}),
          [key]: value,
        };
      },
      clearFiltering() {
        store.filtering = initState.filtering;
      },
      clearFilteringValue() {
        store.filtering.value = initState.filtering.value;
      },
      clearSorting() {
        store.sorting = initState.sorting;
      },
      clearCustomFilterKey(key: string) {
        if (store.customFilters && store.customFilters[key]) {
          const obj = { ...store.customFilters };
          delete obj[key];
          store.customFilters = obj;
        }
      },
      clearAllCustomFilters() {
        store.customFilters = initState.customFilters;
      },
      clearPagination() {
        store.totalCount = initState.totalCount;
        store.currentPage = initState.currentPage;
        store.pageSize = initState.pageSize;
      },
      reset() {
        this.clearPagination();
        this.clearFiltering();
        this.clearSorting();
        this.clearAllCustomFilters();
        store.list.clear();
        store.additionalFields.clear();
      },
    }));

  return types.optional<typeof tableModel>(tableModel, initState);
}

type TCreateTableModelInternal = ReturnType<typeof createTableModel>;
export type TCreateTableModelInstance = Instance<TCreateTableModelInternal>;
