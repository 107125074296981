import { Grid } from '@mui/material';
import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    padding: 0 27px;

    ${theme.breakpoints.down('sm')} {
      padding: 0 16px;
    }
  `}
`;

export const GridItem = styled(Grid)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;
