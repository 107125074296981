import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { TPlaceOrderSide, TProtectionType } from '@trader/types';
import { conversionRate } from '@trader/constants';
import { runInAction } from '@trader/utils';

import {
  getPositionsMetricsAsync,
  editPositionAsync,
  closePositionAsync,
  closeAllPositionsAsync,
  getPositionTradingSessionsAsync,
  positionOptimisticCreateAsync,
  positionOptimisticUpdateAsync,
  positionOptimisticDeleteAsync,
} from './actions';
import { tradingSessionsModel } from '../instruments';
import { createCollectionModel } from '../../utils/collectionModel';

const tradingHourModel = types.model('tradingHourModel', {
  from: types.string,
  to: types.string,
});

export const positionMetricModel = types
  .model('positionMetricModel', {
    symbol: types.string,
    iconUrl: types.string,
    currency: types.string,
    spreadDiff: types.number,
    spreadDiffBalance: types.number,
    swap: types.number,
    value: types.number,
    positionId: types.identifier,
    strategy: types.maybeNull(
      types.model({
        id: types.number,
        closesTradesAfter: types.maybeNull(types.string),
      })
    ),
    side: types.frozen<TPlaceOrderSide>('Buy'),
    quantity: types.number,
    pipSize: types.number,
    openPrice: types.number,
    currentPrice: types.number,
    price: types.number,
    accruedInterest: types.number,
    calcMode: types.number,
    faceValue: types.number,
    tickSize: types.number,
    tickValue: types.number,
    contractSize: types.number,
    pl: types.number,
    takeProfit: types.optional(
      types.model({
        type: types.frozen<TProtectionType>('TakeProfit'),
        limitPrice: types.number,
      }),
      { type: 'TakeProfit', limitPrice: 0 }
    ),
    stopLoss: types.optional(
      types.model({
        type: types.frozen<TProtectionType>('StopLoss'),
        stopPrice: types.number,
      }),
      { type: 'StopLoss', stopPrice: 0 }
    ),

    // FE only
    conversionRate: types.optional(types.number, conversionRate),
    sessions: tradingSessionsModel,
    showOnTradingViewChart: true,
    netPl: types.optional(types.number, 0),
    change: types.optional(types.number, 0),
    holidays: types.optional(types.map(types.array(tradingHourModel)), {}),

    editPositionAsync,
    closePositionAsync,
  })
  .views(() => ({}))
  .actions(store => ({
    runInAction,
    toggleShowOnChart() {
      store.showOnTradingViewChart = !store.showOnTradingViewChart;
    },
  }));

interface IPositionsMetricsAsync {
  getPositionsMetricsAsync: typeof getPositionsMetricsAsync;
  closeAllPositionsAsync: typeof closeAllPositionsAsync;
  getPositionTradingSessionsAsync: typeof getPositionTradingSessionsAsync;
  positionOptimisticCreateAsync: typeof positionOptimisticCreateAsync;
  positionOptimisticUpdateAsync: typeof positionOptimisticUpdateAsync;
  positionOptimisticDeleteAsync: typeof positionOptimisticDeleteAsync;
}

export const positionsMetrics = createCollectionModel<
  typeof positionMetricModel,
  IPositionsMetricsAsync
>(positionMetricModel, {
  getPositionsMetricsAsync,
  closeAllPositionsAsync,
  getPositionTradingSessionsAsync,
  positionOptimisticCreateAsync,
  positionOptimisticUpdateAsync,
  positionOptimisticDeleteAsync,
});

export type TPositionsMetricsEntity = Instance<typeof positionsMetrics>;
export type TPositionMetricEntity = Instance<typeof positionMetricModel>;
export type TPositionMetricIn = SnapshotIn<typeof positionMetricModel>;
