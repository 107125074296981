import { getPercentOfSecondNumber } from '../numberFormats';
import { TPlaceOrderSide } from '@trader/types';

export const getPositionChange = (
  entryPrice: number,
  exitPrice: number,
  side: TPlaceOrderSide
) =>
  Number(
    side === 'Buy'
      ? getPercentOfSecondNumber(exitPrice, entryPrice)
      : getPercentOfSecondNumber(entryPrice, exitPrice)
  );
