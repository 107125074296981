import React from 'react';
import { BoxProps } from '@mui/material';

import * as Styled from './styled';

export const Wrapper = React.forwardRef<HTMLDivElement | null, BoxProps>(
  (props, ref) => {
    const { children, display = 'flex', ...rest } = props;
    return (
      // We manually apply spacing to ensure correct alignment in both LTR & RTL.
      <Styled.Box
        {...rest}
        ref={ref}
        display={display}
        $margin={props.margin}
        $marginTop={props.marginTop}
        $marginRight={props.marginRight}
        $marginBottom={props.marginBottom}
        $marginLeft={props.marginLeft}
        $padding={props.padding}
        $paddingTop={props.paddingTop}
        $paddingRight={props.paddingRight}
        $paddingBottom={props.paddingBottom}
        $paddingLeft={props.paddingLeft}
        margin={undefined}
        marginTop={undefined}
        marginRight={undefined}
        marginBottom={undefined}
        marginLeft={undefined}
        padding={undefined}
        paddingTop={undefined}
        paddingRight={undefined}
        paddingBottom={undefined}
        paddingLeft={undefined}
      >
        {children}
      </Styled.Box>
    );
  }
);
