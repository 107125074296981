import styled, { css } from 'styled-components';

import { Button, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    height: 100%;
  `}
`;

export const SaveButton = styled(Button)`
  ${({ theme }) => css`
    width: fit-content;
    border-radius: 5px;
    padding: 10px 35px;
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};

    span {
      text-transform: capitalize;
    }

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;
