import { useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  calcEquity,
  calcFreeMargin,
  calcMarginLevel,
  formatAccountCurrencyValue,
  formatAccountPercentageValue,
} from '@trader/utils';
import {
  Icon,
  Wrapper,
  Popover,
  Tooltip,
  Typography,
  Checkbox,
} from '@trader/components';
import { TPortfolioUIItemModelIn, useMst } from '@trader/store';
import { usePositionMetricsAssets, usePositionMetricsPl } from '@trader/hooks';
import { TTranslationKeys, useI18next } from '@trader/services';

import * as Styled from './styled';

type TPortfolioItemWithValue = TPortfolioUIItemModelIn & { value: string };

export const BalanceInformation = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { positionsPl } = usePositionMetricsPl();
  const { positionsAssets } = usePositionMetricsAssets();

  const [isPortfolioDetailsOpen, setIsPortfolioDetailsOpen] = useState(false);

  const portfolio = store.user.portfolio();
  const tradingAccount = store.user.activeTradingAcc();
  const selectedPortfolioItems = store.ui.portfolio.getSelectedItems();
  const visiblePortfolioItems = store.ui.portfolio.getVisibleItems();

  const currencySymbol = tradingAccount.currencySymbol;
  const equity = calcEquity(positionsPl, portfolio.balance, portfolio.credit);

  const values = {
    equity: formatAccountCurrencyValue(equity, currencySymbol),
    dailyPl: formatAccountCurrencyValue(positionsPl, currencySymbol),
    startDailyPl: formatAccountCurrencyValue(
      portfolio.startDailyPl,
      currencySymbol
    ),
    balance: formatAccountCurrencyValue(portfolio.balance, currencySymbol),
    profit: formatAccountCurrencyValue(portfolio.profit, currencySymbol),
    freeMargin: formatAccountCurrencyValue(
      calcFreeMargin(equity, portfolio.usedMargin),
      currencySymbol
    ),
    usedMargin: formatAccountCurrencyValue(
      portfolio.usedMargin,
      currencySymbol
    ),
    availableForWithdrawal: formatAccountCurrencyValue(
      portfolio.availableForWithdrawal,
      currencySymbol
    ),
    marginLevel: formatAccountPercentageValue(
      calcMarginLevel(equity, portfolio.usedMargin)
    ),
    maintenanceMargin: formatAccountCurrencyValue(
      portfolio.maintenanceMargin,
      currencySymbol
    ),
    credit: formatAccountCurrencyValue(portfolio.credit, currencySymbol),
    assets: formatAccountCurrencyValue(positionsAssets, currencySymbol),
  };

  const visibleItemsWithValue: TPortfolioItemWithValue[] =
    visiblePortfolioItems.map(item => ({
      ...item,
      value: values[item.id],
    }));

  const getValueWidth = ({ id, title }: TPortfolioUIItemModelIn): number => {
    const oneValueCharacterSize = 12;
    const oneTitleCharacterSize = 7;

    if (id === 'assets') {
      return positionsAssets.toFixed(2).length * oneValueCharacterSize;
    }

    const value = values[id];
    const isLengthBasedOnValue =
      value.length > translate(title as TTranslationKeys).length;
    if (isLengthBasedOnValue) {
      return value.length * oneValueCharacterSize;
    }

    return translate(title as TTranslationKeys).length * oneTitleCharacterSize;
  };

  return (
    <Wrapper marginRight='12px'>
      {selectedPortfolioItems.map(item => (
        <PortfolioValue
          key={item.id}
          value={values[item.id]}
          title={item.title}
          valueWidth={getValueWidth(item)}
          isOpen={isPortfolioDetailsOpen}
          shouldDisplayOpenIcon={false}
        />
      ))}
      <Popover
        behavior='click'
        elevation={4}
        trigger={
          <PortfolioValue
            valueWidth={getValueWidth(store.ui.portfolio.getBalanceItem())}
            value={values.balance}
            title='COMMON.LABELS.BALANCE'
            isOpen={isPortfolioDetailsOpen}
          />
        }
        content={<PortfolioDetails items={visibleItemsWithValue} />}
        transformOrigin={{
          vertical: -5,
          horizontal: 130,
        }}
        onOpen={() => setIsPortfolioDetailsOpen(true)}
        onClose={() => setIsPortfolioDetailsOpen(false)}
      />
    </Wrapper>
  );
});

const PortfolioValue = ({
  value,
  title,
  isOpen,
  valueWidth,
  shouldDisplayOpenIcon = true,
}: {
  value: string;
  title: string;
  isOpen: boolean;
  valueWidth: number;
  shouldDisplayOpenIcon?: boolean;
}) => {
  const { translate } = useI18next();
  return (
    <Styled.BalanceContainer
      $width={valueWidth}
      $isBalanceEmpty={value === '--'}
    >
      <Typography variant='tiny'>
        {translate(title as TTranslationKeys)}
      </Typography>
      <Wrapper alignItems='center'>
        <Tooltip title={<Styled.Value>{value}</Styled.Value>}>
          <Styled.Value>{value}</Styled.Value>
        </Tooltip>
        {shouldDisplayOpenIcon && (
          <Icon iconType={isOpen ? 'arrowUp' : 'arrowDown'} />
        )}
      </Wrapper>
    </Styled.BalanceContainer>
  );
};

const PortfolioDetails = observer(
  ({ items }: { items: TPortfolioItemWithValue[] }) => {
    const { translate } = useI18next();
    const store = useMst();

    return (
      <Styled.Portfolio>
        <Styled.PortfolioHeader>
          <Styled.PortfolioTitle>
            {translate('COMMON.LABELS.PORTFOLIO')}
          </Styled.PortfolioTitle>
        </Styled.PortfolioHeader>
        <Styled.PortfolioContent>
          {items.map(item => (
            <Styled.PortfolioItem key={item.id}>
              <Checkbox
                size='small'
                isDisabled={item.id === 'balance'}
                checked={store.ui.portfolio.getIsSelected(item.id)}
                onChange={() => store.ui.portfolio.toggleItem(item.id)}
                label={
                  <Wrapper alignItems='center'>
                    <Tooltip
                      title={translate(item.tooltip as TTranslationKeys)}
                    >
                      <Styled.Info iconType='info' />
                    </Tooltip>
                    <Typography variant='medium'>
                      {translate(item.title as TTranslationKeys)}
                    </Typography>
                  </Wrapper>
                }
                shouldHideControlInfo
              />
              <Typography variant='medium' fontWeight='medium'>
                {item.value}
              </Typography>
            </Styled.PortfolioItem>
          ))}
        </Styled.PortfolioContent>
      </Styled.Portfolio>
    );
  }
);
