import {
  api,
  ICloseAllPositionsParams,
  IClosePositionParams,
  IEditPositionParams,
} from '@trader/api';
import {
  calcPositionNetPl,
  getPositionChange,
  returnTypedThis,
} from '@trader/utils';
import { devLoggerService, SmartLookService } from '@trader/services';
import { conversionRate, hasCopierFunctionality } from '@trader/constants';

import {
  TPositionMetricEntity,
  TPositionMetricIn,
  TPositionsMetricsEntity,
} from './index';
import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { positionsMetricsSchema } from './schemas';
import { cast, clone } from 'mobx-state-tree';
import { EOrderSide, IPositionMessage, TPlaceOrderSide } from '@trader/types';
import { TInstrumentEntity, TStripM } from '@trader/store';

export const getPositionsMetricsAsync = createThunk<void, void>(
  () =>
    async function getPositionsMetrics(this: unknown, _options, _flow) {
      try {
        const that = returnTypedThis<TPositionsMetricsEntity>(this);
        const root = getRootInstance();

        const response = await api.Trading.getPositionsMetrics();

        const positions = response?.positions || [];
        const metrics = positions.map(p =>
          p.positionsMetrics.map(m => {
            const existsMetric = that.get<TPositionMetricEntity>(m.positionId);

            return {
              ...m,
              symbol: p.symbol,
              pipSize: p.pipSize,
              currency: p.currency,
              spreadDiff: p.spreadDiff,
              spreadDiffBalance: p.spreadDiffBalance,
              iconUrl: p.iconUrl || '',
              swap: m?.swap || 0,
              price: m?.currentPrice || 0,
              value: Number((m.openPrice * m.quantity).toFixed(1)),
              conversionRate: p.conversionRate || conversionRate,
              pl: m.pl,
              netPl: calcPositionNetPl({
                side: m.side,
                price: m?.currentPrice || 0,
                currentPrice: m?.currentPrice || 0,
                openPrice: m.openPrice,
                quantity: m.quantity,
                pipSize: p.pipSize,
                tickValue: p.tickValue,
                tickSize: p.tickSize,
                contractSize: p.contractSize,
                calcMode: p.calcMode,
                conversionRate: Number(p.conversionRate),
                swap: m?.swap,
              }),
              change: getPositionChange(m.openPrice, m.currentPrice, m.side),
              accruedInterest: p.accruedInterest,
              calcMode: p.calcMode,
              faceValue: p.faceValue,
              tickSize: p.tickSize,
              tickValue: p.tickValue,
              contractSize: p.contractSize,
              showOnTradingViewChart: existsMetric
                ? existsMetric.showOnTradingViewChart
                : true,
              takeProfit: {
                type: 'TakeProfit',
                limitPrice: m.takeProfit?.limitPrice || 0,
              },
              stopLoss: {
                type: 'StopLoss',
                stopPrice: m.stopLoss?.stopPrice || 0,
              },
            };
          })
        );
        const positionsMetrics = metrics.flat(1);

        const normalized = root.entities.normalizeMerge(
          positionsMetrics,
          positionsMetricsSchema,
          true
        );

        root.tables.positions.runInAction(() => {
          root.tables.positions.table.totalCount = response?.totalCount || 0;
        });
        root.tables.positions.table.list.set(normalized);
      } catch (e) {
        devLoggerService.error('Error in getPositionsMetricsAsync', e);
      }
    }
);

export const getPositionTradingSessionsAsync = createThunk<
  TPositionMetricEntity[],
  void
>(
  (positions: TPositionMetricEntity[]) =>
    async function getPositionsMetrics(this: unknown, _options, _flow) {
      try {
        const root = getRootInstance();

        if (!positions.length) {
          return;
        }

        const positionSymbols = positions.map(p => p.symbol);
        const uniqueSymbols = [...new Set(positionSymbols)];
        const requests = uniqueSymbols.map(symbol =>
          api.Instrument.getInstrumentSpecification(symbol, _options)
        );
        const results = await Promise.allSettled(requests);

        for (const [index, result] of results.entries()) {
          const symbol = uniqueSymbols[index];

          for (const position of positions) {
            if (position.symbol === symbol) {
              root.entities.positionsMetrics.update(position.positionId, {
                sessions:
                  result.status === 'fulfilled'
                    ? result.value.sessions
                    : undefined,
                holidays:
                  result.status === 'fulfilled'
                    ? result.value.holidays
                    : undefined,
              });
            }
          }

          if (result.status === 'rejected') {
            devLoggerService.warn(
              `Failed to fetch instrument specification for ${symbol}`,
              result.reason
            );
          }
        }
      } catch (e) {
        devLoggerService.error('Unexpected error in getPositionsMetrics', e);
      }
    }
);

export const editPositionAsync = createThunk<IEditPositionParams, void>(
  params =>
    async function editPosition(this: unknown, _options, _flow) {
      const that = returnTypedThis<TPositionMetricEntity>(this);
      const root = getRootInstance();
      const muliBands = root.pages.muliBands;

      if (that.strategy?.id) {
        await muliBands.editMuliBandsPositionAsync.run({
          positionId: params.positionId,
          takeProfit: params.body.limitPrice,
          stopLoss: params.body.stopPrice,
        });
        root.entities.positionsMetrics.update(params.positionId, {
          strategy: null,
        });
      } else {
        if (hasCopierFunctionality) {
          await api.Trading.editChallengePosition(params, _options);
        } else {
          await api.Trading.editPosition(params, _options);
        }
      }

      SmartLookService.track('Edit_position_event');

      root.ui.modal.close();
    }
);

export const closePositionAsync = createThunk<IClosePositionParams, void>(
  body =>
    async function closePosition(this: unknown, _options, _flow) {
      if (hasCopierFunctionality) {
        await api.Trading.closeChallengePosition(body, _options);
      } else {
        await api.Trading.closePosition(body, _options);
      }
      SmartLookService.track('Close_position_event');
    }
);

export const closeAllPositionsAsync = createThunk<
  ICloseAllPositionsParams | undefined,
  void
>(
  params =>
    async function closeAllPositions(this: unknown, _options, _flow) {
      const root = getRootInstance();

      try {
        if (hasCopierFunctionality) {
          await api.Trading.closeAllChallengePositions(params, _options);
        } else {
          await api.Trading.closeAllPositions(params, _options);
        }
        SmartLookService.track('Close_all_positions_event');
        root.ui.modal.close();
      } catch (e) {
        devLoggerService.error('catch closeAllPositionsAsync error', e);
      }
    }
);

export const positionOptimisticCreateAsync = createThunk<
  Omit<IPositionMessage, 'currency' | 'updateAction'>,
  void
>(
  message =>
    async function positionOptimisticCreate(this: unknown, _options, _flow) {
      const root = getRootInstance();
      const instrument = root.entities.instruments.get<TInstrumentEntity>(
        message.symbol
      );

      if (!instrument.conversionRateSell || !instrument.conversionRateBuy) {
        await root.entities.instruments.getInstrumentSpecificationAsync.run(
          message.symbol
        );
      }

      const strips = Array.from<TStripM>(root.pages.muliBands.strips.values());

      const isPartOfStrategy = strips.find(
        ({ positionId }) => positionId === message.id
      );

      const side = EOrderSide[message.side] as TPlaceOrderSide;

      const convRate =
        side === 'Buy'
          ? instrument.conversionRateBuy
          : instrument.conversionRateSell;

      const position: TPositionMetricIn = {
        sessions: clone(instrument.sessions),
        strategy:
          isPartOfStrategy && root.pages.muliBands.id
            ? { id: root.pages.muliBands.id, closesTradesAfter: null }
            : null,
        side,
        positionId: String(message.id),
        symbol: message.symbol,
        pipSize: instrument.pipSize,
        currency: instrument.currency,
        spreadDiff: instrument.spreadDiff,
        spreadDiffBalance: instrument.spreadDiffBalance,
        iconUrl: instrument.iconUrl || '',
        swap: 0,
        quantity: message.volume,
        price: message.price,
        currentPrice: message.price,
        openPrice: message.openPrice,
        value: Number((message.openPrice * message.volume).toFixed(1)),
        conversionRate: convRate || conversionRate,
        pl: message.profit,
        netPl: calcPositionNetPl({
          side,
          price: message.price,
          currentPrice: message.price,
          openPrice: message.openPrice,
          quantity: message.volume,
          pipSize: instrument.pipSize,
          tickValue: instrument.tickValue,
          tickSize: instrument.tickSize,
          contractSize: instrument.contractSize,
          calcMode: instrument.calcMode,
          conversionRate: convRate || conversionRate,
          swap: 0,
        }),
        change: getPositionChange(
          message.openPrice,
          message.price,
          EOrderSide[message.side] as TPlaceOrderSide
        ),
        accruedInterest: 0,
        calcMode: instrument.calcMode,
        faceValue: 0,
        tickSize: instrument.tickSize,
        tickValue: instrument.tickValue,
        contractSize: instrument.contractSize,
        showOnTradingViewChart: true,
        takeProfit: {
          type: 'TakeProfit',
          limitPrice: message.takeProfit || 0,
        },
        stopLoss: {
          type: 'StopLoss',
          stopPrice: message.stopLoss || 0,
        },
      };

      root.entities.positionsMetrics.add(position.positionId, position);
      root.tables.positions.table.list.addToBegin(position.positionId);

      const tradingAccountStore = root.user.tradingAccount;

      if (tradingAccountStore) {
        tradingAccountStore.runInAction(() => {
          tradingAccountStore.positions = cast([
            ...tradingAccountStore.positions,
            {
              positionId: String(message.id),
              symbol: message.symbol,
              currency: instrument.currency,
              quantity: message.volume,
              side,
              openPrice: message.openPrice,
              currentPrice: message.price,
              spreadDiff: instrument.spreadDiff,
              spreadDiffBalance: instrument.spreadDiffBalance,
              swap: 0,
              conversionRate: convRate || conversionRate,
              accruedInterest: 0,
              calcMode: instrument.calcMode,
              faceValue: 0,
              tickSize: instrument.tickSize,
              tickValue: instrument.tickValue,
              contractSize: instrument.contractSize,
              pipSize: instrument.pipSize,
            },
          ]);
        });
      }
    }
);

export const positionOptimisticUpdateAsync = createThunk<
  Omit<IPositionMessage, 'currency' | 'updateAction'>,
  void
>(
  message =>
    // eslint-disable-next-line require-await
    async function positionOptimisticUpdate(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const tradingAccountStore = root.user.tradingAccount;

      const side = EOrderSide[message.side] as TPlaceOrderSide;

      if (tradingAccountStore) {
        tradingAccountStore.runInAction(() => {
          tradingAccountStore.positions = cast(
            tradingAccountStore.positions.map(p => {
              if (p.positionId === String(message.id)) {
                p.quantity = message.volume;
                p.side = side;
                p.openPrice = message.openPrice;
                p.currentPrice = message.price;
              }
              return p;
            })
          );
        });
      }

      root.entities.positionsMetrics.update(message.id, {
        openPrice: message.openPrice,
        side,
        price: message.price,
        pl: message.profit,
        takeProfit: {
          type: 'TakeProfit',
          limitPrice: message.takeProfit || 0,
        },
        stopLoss: {
          type: 'StopLoss',
          stopPrice: message.stopLoss || 0,
        },
        quantity: message.volume,
      });
    }
);

export const positionOptimisticDeleteAsync = createThunk<string, void>(
  id =>
    // eslint-disable-next-line require-await
    async function positionOptimisticDelete(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const position = root.entities.positionsMetrics.get(id);

      if (!position) return;

      const tradingAccountStore = root.user.tradingAccount;

      if (tradingAccountStore) {
        tradingAccountStore.runInAction(() => {
          tradingAccountStore.positions = cast(
            tradingAccountStore.positions.filter(p => p.positionId !== id)
          );
        });
      }

      root.tables.positions.table.list.removeById(id);
      root.entities.positionsMetrics.delete(id);
    }
);
