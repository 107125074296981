import * as yup from 'yup';

import { useI18next } from '@trader/services';
import { TCommonOrderType } from '@trader/types';
import { isValueTimesStep } from '@trader/utils';
import { useMst } from '@trader/store';

export const useAmountSchema = (type: TCommonOrderType, maxValue?: number) => {
  const { translate } = useI18next();
  const store = useMst();

  const trading = store.trading.getTrading(type);

  const amountSchema = yup
    .number()
    .test(
      'increment',
      translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
        amount: trading.step(),
      }),
      value => !!value && isValueTimesStep(value, trading.step())
    )
    .required(
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: trading.minAmount(),
      })
    )
    .max(
      maxValue || trading.maxAmount(),
      translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
        amount: maxValue || trading.maxAmount(),
      })
    )
    .min(
      trading.minAmount(),
      translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
        amount: trading.minAmount(),
      })
    )
    .typeError(
      translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
        amount: trading.step(),
      })
    );

  return { amountSchema };
};
