/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  destroy,
  getRoot,
  IAnyComplexType,
  Instance,
  IOptionalIType,
  isIdentifierType,
  types,
} from 'mobx-state-tree';
import { normalize, Schema } from 'normalizr';

import { runInAction } from '@trader/utils';
import { push, reduceNormalize, unshift } from './utils';

/**
 * Returns MST Model with array property and full of useful methods to work
 * with that array.
 *
 * Provides normalization using normalizr or reduce function.
 * If no schema or entityName specified, normalization won't be used.
 * @param {string} name Name of the model
 * @param {object} options options to be passed
 * @property {MSTModel} options.of – The type to be used in array model
 * @property {string} [options.id=id] – Property name to be used to resolve
 * an identifier value
 * @property {Object} [options.schema] – normalizr schema to be used to normalize
 * results
 * @property {number} [options.pageSize] - Amount of elements to be counted as single
 * page. Used to determine if the list has more items to fetch.
 * @property {string} [options.entityName] - Property to be used to identify
 * collection in entities
 * @property {boolean} [options.reversed] - Determines if the list is reversed list.
 * Useful for chats and other reversed lists.
 *
 * @returns {MSTModel} – mobx state tree model
 */

type TModelAttribute<
  TModel extends IAnyComplexType,
  TKeys = keyof Instance<TModel>
> = ((item: Instance<TModel>) => string) | TKeys;

export type TListModelOptions<TModel extends IAnyComplexType> = {
  idAttribute?: Extract<keyof Instance<TModel>, string>;
  entityName?: string;
  pageSize?: number;
  isReversed?: boolean;
  optional?: boolean;
  fromAttribute?: TModelAttribute<TModel>;
  schema?: Schema<any>;
};

export function createListModel<
  TModel extends IAnyComplexType,
  TOptions extends TListModelOptions<TModel>
>(
  model: TModel,
  options: TOptions
): IOptionalIType<typeof listModel, [undefined]> {
  const {
    schema,
    entityName,
    idAttribute = 'id',
    pageSize,
    isReversed,
    fromAttribute,
  } = options;

  const listModel = types
    .model('listModel', {
      array: types.array(types.safeReference(model)),
      hasNoMore: false,
    })
    .views(self => ({
      get count() {
        return self.array.length;
      },
      get pageSize() {
        return pageSize;
      },
      get isEmpty() {
        return self.array.length === 0;
      },
      get first() {
        return this.isEmpty ? undefined : self.array[0];
      },
      get last() {
        return this.isEmpty ? undefined : self.array[this.count - 1];
      },
      get pageNumber() {
        if (typeof pageSize === 'undefined') {
          return undefined;
        }

        const pages = this.count / pageSize;

        if (Number.isInteger(pages)) {
          return pages + 1;
        }

        return undefined;
      },
      get offset() {
        if (this.count === 0) {
          return undefined;
        }
        return this.count;
      },
      get from() {
        const item = isReversed ? this.first : this.last;

        if (!item) {
          return undefined;
        }

        if (typeof fromAttribute === 'string') {
          return item[fromAttribute]; // item?.[fromAttribute];
        }

        if (typeof fromAttribute === 'function') {
          return fromAttribute(item);
        }

        return typeof item[idAttribute] !== 'undefined'
          ? item[idAttribute]
          : item;
      },
      byIndex(index: number) {
        return self.array[index];
      },
      includes(item: TModel) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return self.array.includes(item);
      },
      findIndex(item: TModel) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return self.array.findIndex(i => i === item);
      },
      findIndexById(id: number | string) {
        // @ts-expect-error Type 'Extract<keyof Instance<TModel>, string> | "id"' cannot be used to index type 'TModel["Type"]'
        return self.array.findIndex(i => i[idAttribute] === id);
      },
      findById(id: number | string) {
        // @ts-expect-error Type 'Extract<keyof Instance<TModel>, string> | "id"' cannot be used to index type 'TModel["Type"]'
        return self.array.find(i => i[idAttribute] === id);
      },
    }))
    .actions(self => ({
      runInAction,
      checkIfHasMore(data: any[]) {
        self.hasNoMore =
          typeof pageSize !== 'undefined' && data.length < pageSize;
      },
      setHasNoMore(value: boolean) {
        self.hasNoMore = value;
      },
      /**
       * Initialize list with an array
       * @param {array} data Array of items
       */
      set(data: any) {
        const { result } = this._processData(data);
        if (isReversed) {
          self.array.replace(result.reverse());
        } else {
          self.array.replace(result);
        }

        this.checkIfHasMore(data);
      },
      /**
       * Add element to end of list
       * @param {any} item
       */
      add(item: TModel) {
        const { result } = this._processData([item]);

        push({ model: this, isReversed }, self.array, result);
      },
      /**
       * Add element to beginning of list
       * @param {string | number} itemReference
       */
      addToBegin(itemReference: string | number) {
        const { result } = this._processData([itemReference]);

        unshift({ model: this, isReversed }, self.array, result);
      },
      /**
       * Add each element of the given array to the end of the list
       * @param {array} item Array of elements to add
       */
      append(items: TModel[]) {
        const { result } = this._processData(items);

        result.forEach((i: any) =>
          push({ model: this, isReversed }, self.array, i)
        );

        this.checkIfHasMore(items);
      },
      /**
       * Add each element of the given array to the beginning of the list
       * @param {array} item Array of elements to add
       */
      prepend(items: TModel[]) {
        const { result } = this._processData(items);

        result.forEach((i: any) =>
          unshift({ model: this, isReversed }, self.array, i)
        );

        this.checkIfHasMore(items);
      },
      replace(id: string | number, newItem: TModel) {
        const index = self.findIndexById(id);

        if (index < 0) {
          return;
        }

        const { result } = this._processData([newItem]);

        const [newId] = result;

        self.array[index] = newId;
      },
      remove(item: TModel, shouldDestroy = false) {
        const index = self.findIndex(item);

        if (index < 0) {
          return;
        }

        self.array.splice(index, 1);

        if (shouldDestroy) {
          destroy(item);
        }
      },
      removeById(id: string | number, shouldDestroy = false) {
        const index = self.findIndexById(id);

        if (index < 0) {
          return;
        }
        self.array.splice(index, 1);

        if (shouldDestroy) {
          const item = self.findById(id);

          if (typeof item !== 'undefined') {
            destroy(item);
          }
        }
      },
      removeManyByIds(ids: number[] | string[]) {
        ids.forEach((id: string | number) => this.removeById(id));
      },
      clear() {
        self.array.replace([]);
        self.hasNoMore = false; // TODO: check do we need call this
      },
      /**
       * Should we normalize the data? Universal method which is used
       * to answer to that question and return object with result field.
       * `result` is the normalized result or the raw data depends if we really need
       * to normalize the data.
       * @param {array} data array of elements
       */
      _processData(data: any) {
        if (
          (!isIdentifierType(data[0]) && typeof entityName !== 'undefined') ||
          typeof schema !== 'undefined'
        ) {
          const { result, entities } = this._normalize(data);

          (getRoot(this) as any).entities.merge(entities);

          return { result };
        }

        return { result: data };
      },
      /**
       * Normalizes data using normalizr or reduceNormalize strategy
       * @param {array} items Data to be normalized
       */
      _normalize(items: any) {
        if (
          typeof schema === 'undefined' ||
          typeof entityName === 'undefined'
        ) {
          throw new Error(
            'ListModel: Cannot run normalize if neither schema or entityName is provided'
          );
        }

        if (typeof entityName === 'string') {
          return reduceNormalize(items, idAttribute, entityName);
        }

        return normalize(items, schema);
      },
    }));
  return types.optional<typeof listModel>(listModel, {
    array: [],
    hasNoMore: false,
  });
}
