import styled, { css } from 'styled-components';

import { Stack } from '@trader/components';

export const Root = styled(Stack)`
  ${({ theme }) => css`
    width: 540px;
    padding-bottom: 12px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-top: 8px;
      padding: 0 8px 12px 8px;
      border-bottom: 1px solid ${theme.palette.gray.light};
    }
  `}
`;
