import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)<{ $isInvestmentAccProduct: boolean }>`
  ${({ $isInvestmentAccProduct }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${$isInvestmentAccProduct ? '360px' : '408px'};
    min-height: 300px;
  `}
`;

export const Header = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    align-items: center;
    margin-bottom: 35px;
    padding: 0 10px 20px 10px;
    border-bottom: 1px solid ${theme.palette.gray.main};
  `}
`;

export const VolumeContainer = styled(Wrapper)`
  ${({ theme }) => css`
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    width: 100%;
    margin: 25px 0;
    flex-direction: column;
    text-align: center;
    display: inline;
  `}
`;

export const Success = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.green.main};
    margin-top: 20px;
  `}
`;

export const InProgress = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.blue.main};
    display: inline;
    font-weight: ${theme.typography.fontWeightBold};
  `}
`;

export const Fail = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.red.main};
    display: inline;
    font-weight: ${theme.typography.fontWeightBold};
  `}
`;
