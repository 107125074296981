import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';

import { MODAL_TYPES } from '@trader/constants';
import { TOrderMetricEntity, useMst } from '@trader/store';
import { Checkbox, ScrollableItemActions } from '@trader/components';

import { StickyOrder } from '../../desktop.styled';

interface IStickyOrderColumn {
  pendingOrder: TOrderMetricEntity;
  withClickableSymbol?: boolean;
}

export const StickyOrderColumn: FC<IStickyOrderColumn> = observer(
  ({ pendingOrder, withClickableSymbol }) => {
    const store = useMst();

    const symbol = !withClickableSymbol
      ? store.pages.muliBands.symbol
      : store.pages.trading.getInstrumentSymbolByLayout();

    const handleEditClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      store.ui.modal.open(MODAL_TYPES.editOrder, {
        symbol: pendingOrder.symbol,
        orderId: pendingOrder.orderId,
        side: pendingOrder.side,
      });
    };

    const handleRemoveClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      store.ui.modal.open(MODAL_TYPES.cancelOrder, {
        symbol: pendingOrder.symbol,
        orderId: pendingOrder.orderId,
        side: pendingOrder.side,
      });
    };

    return (
      <StickyOrder>
        <Checkbox
          isDisabled={pendingOrder.symbol !== symbol}
          size='small'
          checked={pendingOrder.showOnTradingViewChart}
          onChange={pendingOrder.toggleShowOnChart}
        />
        <ScrollableItemActions
          onEditClick={handleEditClick}
          onRemoveClick={handleRemoveClick}
        />
      </StickyOrder>
    );
  }
);
