import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 408px;

    ${theme.breakpoints.down('sm')} {
      min-width: 100%;
    }
  `}
`;
