import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { ITableColumn } from '@trader/types';
import { TCreateTableModelInstance } from '@trader/store';

import { Body, Row, Cell, NoData } from '../../styled';

interface ITableBody {
  tableStore: TCreateTableModelInstance;
  columns: ITableColumn[];
  emptyMessage: string;
  hasPagination?: boolean;
  isLastItemSticky?: boolean;
  isFirstItemSticky?: boolean;
}

const TableRow: FC<ITableBody> = observer(
  ({ tableStore, columns, emptyMessage, hasPagination }) => {
    const data = tableStore.data(hasPagination);

    if (!data.length) {
      return (
        <Row>
          <NoData>
            {/*// TODO: add correct icon later*/}
            {/*<Icon iconType='warning' />*/}
            {emptyMessage}
          </NoData>
        </Row>
      );
    }

    return data.map((item, index) => (
      <Row key={item?.positionId || item?.orderId || item?.id}>
        {columns.map(column => (
          <Cell
            key={column.id}
            align={column.align || 'center'}
            style={column.cellStyle}
          >
            {column.render ? column.render(item, index) : item[column.id]}
          </Cell>
        ))}
      </Row>
    ));
  }
);

export const TableBody: FC<ITableBody> = props => (
  <Body>
    <TableRow {...props} />
  </Body>
);
