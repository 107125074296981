import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { MobilePendingOrdersList } from './mobile';
import { DesktopPendingOrdersList } from './desktop';

export interface IPendingOrdersList {
  symbol?: string;
  withClickableSymbol?: boolean;
}

export const PendingOrdersList: FC<IPendingOrdersList> = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobilePendingOrdersList {...props} />
  ) : (
    <DesktopPendingOrdersList {...props} />
  );
};
