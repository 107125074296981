import { authRoutes } from './routes';
import {
  IGetAuthRefreshTokenBE,
  IGetAuthRefreshTokenParams,
  IGetAuthTokenBE,
  IGetAuthTokenParams,
  IGlobalSignOutBEParams,
  ISignUpBody,
  IChangePasswordParams,
  ISsoParams,
} from './models/token';
import { requestService, TAsyncActionOptions } from '@trader/services';

export class Auth {
  routes = authRoutes;

  async getToken(
    body: IGetAuthTokenParams,
    options?: TAsyncActionOptions
  ): Promise<IGetAuthTokenBE> {
    const { captcha, ...rest } = body;
    const resp = await requestService.post<IGetAuthTokenBE>(
      this.routes.token,
      rest,
      {
        headers: {
          'g-recaptcha-response': captcha,
        },
        ...options?.cancelToken,
      }
    );

    return resp?.data;
  }

  async changePassword(
    body: IChangePasswordParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.put<void>(
      this.routes.changePassword,
      body,
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async signUp(body: ISignUpBody, options: TAsyncActionOptions): Promise<void> {
    const resp = await requestService.post<void>(this.routes.signUp, body, {
      ...options.cancelToken,
    });

    return resp?.data;
  }

  async refreshToken(
    body: IGetAuthRefreshTokenParams,
    options: TAsyncActionOptions
  ): Promise<IGetAuthRefreshTokenBE> {
    const resp = await requestService.post<IGetAuthRefreshTokenBE>(
      this.routes.refreshToken,
      body,
      {
        ...options?.cancelToken,
      }
    );

    return resp.data;
  }

  async signOut(
    body: IGlobalSignOutBEParams,
    options: TAsyncActionOptions
  ): Promise<void> {
    const resp = await requestService.post<void>(this.routes.signOut, body, {
      ...options.cancelToken,
    });

    return resp.data;
  }

  async sso(
    body: ISsoParams,
    options: TAsyncActionOptions
  ): Promise<IGetAuthTokenBE> {
    const resp = await requestService.post<IGetAuthTokenBE>(
      this.routes.sso,
      body,
      {
        ...options.cancelToken,
      }
    );

    return resp.data;
  }

  async verifyAccount(options: TAsyncActionOptions): Promise<void> {
    const resp = await requestService.get<void>(this.routes.verifyAcc, {
      ...options.cancelToken,
    });

    return resp.data;
  }
}
