import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material';

import { Button, InfoChip } from '@trader/components';
import { isChallengeAccountType, NAVIGATE_TO } from '@trader/constants';
import { useI18next } from '@trader/services';
import { useNavigation } from '@trader/hooks';
import { useMst } from '@trader/store';
import { IChallenge as IChallengeBE } from '@trader/api';

export const FailedChallengeInfoChip = observer(() => {
  const { translate } = useI18next();
  const { navigateTo } = useNavigation();
  const theme = useTheme();

  const store = useMst();
  const activeChallenge = store.user.getActiveAccountChallenge();

  const translationMap: Record<IChallengeBE['result'], string> = {
    Completed: translate('COMMON.LABELS.COMPLETED'),
    Failed: translate('COMMON.LABELS.FAILED'),
    Cancelled: translate('COMMON.LABELS.CANCELLED'),
    'In Progress': translate('COMMON.LABELS.IN_PROGRESS'),
  };

  if (!activeChallenge) {
    return null;
  }

  return (
    <InfoChip
      className='failed-challenge-info-chip'
      text={
        <>
          {translate('WARNINGS.CHALLENGE_STATUS', {
            status: translationMap[activeChallenge?.result]?.toLowerCase(),
          })}{' '}
          <Button
            onClick={() =>
              isChallengeAccountType
                ? navigateTo(NAVIGATE_TO.main.purchaseChallenges)
                : window.open(import.meta.env.VITE_REGISTRATION_LINK)
            }
            size='small'
            variant='text'
            style={{ height: '12px', marginBottom: '1px' }}
          >
            <span style={{ fontWeight: 600 }}>
              {translate('COMMON.LABELS.PURCHASE').toLowerCase()}
            </span>
          </Button>{' '}
          {translate('COMMON.LABELS.A_NEW_ONE')}.
        </>
      }
      textColor={theme.palette.red.main}
      backgroundColor={theme.palette.red['200'] || ''}
      iconType='challengeFailed'
      marginBottom='12px'
    />
  );
});
