import { useCallback, useEffect } from 'react';

import { ESocketUpdateAction } from '@trader/types';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { isChallengeAccountType, MODAL_TYPES } from '@trader/constants';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { useNavigation } from '../useNavigation';
import { useStartConnection } from './core';

/**
 * Custom hook that manages trading account updates by subscribing to a hub and handling incoming messages to update the store.
 */
export const useTradingAccounts = () => {
  const store = useMst();
  const { navigateTo } = useNavigation();

  const { connection } = useStartConnection(
    EConnectionHub.Events,
    EConnectionSubscription.TradingAccount,
    'events'
  );

  const idToken = store.auth.tokens.idToken;

  const handleMessage = useCallback(message => {
    if (message.updateAction === ESocketUpdateAction.Create) {
      store.entities.tradingAccounts.getTradingAccountsAsync.run();
      store.auth.authRefreshTokenAsync.run();
    }
    if (message.updateAction === ESocketUpdateAction.Update) {
      const activeAcc = store.user.activeTradingAcc();

      if (activeAcc.tradingId === message.tradingId) {
        store.user.runInAction(() => {
          store.user.profile.isDepositAllowed = message.isDepositAllowed;
          store.user.profile.isTradingAllowed = message.isTradingAllowed;
        });
      }
    }
    if (message.updateAction === ESocketUpdateAction.Delete) {
      // you are on deleted account right now and account must be changed
      if (message.tradingId === store.user.tradingAccount?.tradingId) {
        const allAccounts =
          store.entities.tradingAccounts.getAll<TTradingAccountEntity>();

        const activeAccounts = isChallengeAccountType
          ? allAccounts.filter(
              account => account?.challenge?.status !== 'DISABLED'
            )
          : allAccounts;

        const newAccount = activeAccounts.find(
          account => account.tradingId !== message.tradingId
        );

        if (!newAccount) {
          store.ui.modal.open(MODAL_TYPES.resultChallenge, {
            isActiveChallenge: false,
            shouldHideCloseButton: true,
            result: 'EmptyAccounts',
          });
          return;
        }

        store.entities.tradingAccounts.changeTradingAccountAsync.run({
          tradingId: newAccount?.tradingId || 0,
          navigation: navigateTo,
        });
      }

      store.entities.tradingAccounts.delete(message.tradingId);
    }
  }, []);

  useEffect(() => {
    if (store.auth.isAuth) {
      connection.subscribe(hub => {
        hub.on('OnTradingAccount', handleMessage);
      });
      return () => {
        connection.unsubscribe(hub => {
          hub.off('OnTradingAccount', handleMessage);
        });
      };
    }
  }, [store.auth.isAuth, idToken]);
};
