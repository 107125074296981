import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  ChangePercentage,
  DateFilter,
  IScrollableListItemDetail,
  ProfitValue,
  PurchaseType,
  ScrollableList,
  ScrollableListItem,
  SymbolWithIcon,
  Typography,
} from '@trader/components';
import { TPositionHistoryEntity, useMst } from '@trader/store';
import { iconSizes } from '@trader/constants';
import { useI18next } from '@trader/services';
import { formatDate, formatTableRowValueByPipSize } from '@trader/utils';
import { TDateFilterInterval } from '@trader/types';

import * as Styled from './mobile.styled';

export const MobilePositionsHistory: FC = observer(() => {
  const { translate } = useI18next();
  const [selectedInterval, setSelectedInterval] =
    useState<TDateFilterInterval | null>(null);

  const store = useMst();
  const positionsHistory =
    store.entities.positionsHistory.getAll<TPositionHistoryEntity>();
  const sortedHistory = useMemo(
    () =>
      positionsHistory.slice().sort((a, b) => {
        const timeA = a.closeTime ? new Date(a.closeTime).getTime() : 0;
        const timeB = b.closeTime ? new Date(b.closeTime).getTime() : 0;
        return timeB - timeA;
      }),
    [positionsHistory, positionsHistory.length]
  );
  const isLoading =
    store.entities.positionsHistory.getPositionsHistoryAsync.inProgress;

  useEffect(() => {
    store.entities.positionsHistory.getPositionsHistoryAsync.run({
      completedFrom: selectedInterval?.from?.toISOString(),
      completedTo: selectedInterval?.to?.toISOString(),
    });
  }, [selectedInterval]);

  return (
    <>
      <Styled.Filters>
        <DateFilter onApply={setSelectedInterval} />
      </Styled.Filters>
      <ScrollableList<TPositionHistoryEntity>
        data={sortedHistory}
        renderItem={p => <PositionHistoryItem position={p} />}
        isLoading={isLoading}
        totalCount={positionsHistory.length}
        emptyMessage={translate('COMMON.LABELS.NO_POSITIONS_HISTORY')}
      />
    </>
  );
});

const PositionHistoryItem: FC<{ position: TPositionHistoryEntity }> = observer(
  ({ position }) => {
    const { translate, currentLng } = useI18next();

    const detailsItems: IScrollableListItemDetail[] = [
      {
        id: 'positionId',
        header: translate('COMMON.LABELS.POSITION_ID'),
        value: position.positionId,
      },
      {
        id: 'openTime',
        header: translate('COMMON.LABELS.OPEN_DATE'),
        value: formatDate(new Date(position.openTime), 'Mm dd, yyyy hh:mm:ss', {
          locale: currentLng,
        }),
      },
      {
        id: 'side',
        header: translate('COMMON.LABELS.TYPE'),
        value: <PurchaseType value={position.side as string} />,
      },
      {
        id: 'quantity',
        header: translate('COMMON.LABELS.VOLUME'),
        value: position.quantity,
      },
      {
        id: 'entryPrice',
        header: translate('COMMON.LABELS.OPEN_PRICE'),
        value: formatTableRowValueByPipSize(
          position.entryPrice,
          position.pipSize
        ),
      },
      {
        id: 'commission',
        header: translate('COMMON.LABELS.COMMISSION'),
        value: position.commission,
      },
      {
        id: 'fee',
        header: translate('COMMON.LABELS.FEE'),
        value: position.fee,
      },
      {
        id: 'swap',
        header: translate('COMMON.LABELS.SWAP'),
        value: position.swap,
      },
      {
        id: 'stopLoss',
        header: translate('COMMON.LABELS.SL'),
        value: formatTableRowValueByPipSize(
          position.stopLoss,
          position.pipSize
        ),
      },
      {
        id: 'takeProfit',
        header: translate('COMMON.LABELS.TP'),
        value: formatTableRowValueByPipSize(
          position.takeProfit,
          position.pipSize
        ),
      },
      {
        id: 'exitPrice',
        header: translate('COMMON.LABELS.MARKET_PRICE'),
        value: formatTableRowValueByPipSize(
          position.exitPrice,
          position.pipSize
        ),
      },
      {
        id: 'change',
        header: translate('COMMON.LABELS.CHANGE'),
        value: <ChangePercentage change={position.change} />,
      },
    ];

    return (
      <ScrollableListItem
        leadingContent={
          <SymbolWithIcon
            symbol={position.symbol}
            iconUrl={position.iconUrl}
            iconSize={iconSizes.small}
            textVariant='medium'
          />
        }
        trailingContent={
          <div>
            <Typography textAlign='right'>
              <ProfitValue value={position.pl.toFixed(2)} />
            </Typography>
            <Typography>
              {formatDate(
                new Date(position.closeTime),
                'Mm dd, yyyy hh:mm:ss',
                {
                  locale: currentLng,
                }
              )}
            </Typography>
          </div>
        }
        detailsItems={detailsItems}
      />
    );
  }
);
