import React, { useEffect } from 'react';
import { Slide } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { TransitionProps } from '@mui/material/transitions';

import { MODAL_TYPES } from '@trader/constants';
import { useMst } from '@trader/store';
import {
  AddFunds,
  AccountStatement,
  DeletePriceAlert,
  Statistics,
  ResultChallenge,
  ChangingMuliBandsMultiplier,
  OrderResponse,
  LogOutWithNoActivity,
  ConnectMaster,
  RemoveMasterAccount,
  RemoveAllMetrics,
  DailyTargetProfitReached,
  TradingDisabledDueToMarketNews,
  AddProtection,
  PurchaseChallenge,
  CreateOrderWrapper,
  ClosePosition,
  EditOrder,
  CancelOrder,
  CreateEditPriceAlertWrapper,
  MuliBandsDisabled,
  SwitchingToBetaDesign,
  ExpiredPassword,
  RemoveCloseTimeMuliBands,
  CancelScheduledOrder,
  ChangeLeverage,
} from '@trader/modals';

import { useInstruments } from '@trader/hooks';
import { TPlaceOrderSide } from '@trader/types';

import * as Styled from './styled';
import { EConnectionSubscription } from '@trader/services';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction='right' ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

const RealTimeUpdating = observer(
  ({ component }: { component: React.ReactNode }) => {
    const store = useMst();

    const modal = store.ui.modal;
    const symbol = modal.options.get('symbol');
    const side: TPlaceOrderSide = modal.options.get('side');

    const trading = store.trading.getTrading('modifyOrder');

    useInstruments({
      instrumentsIds: [symbol],
      subscription: EConnectionSubscription.Instrument,
    });

    useEffect(() => {
      if (symbol) {
        (async () => {
          await trading.getInstrumentReferenceAsync.run({
            symbol,
            side: side || 'Buy',
            shouldTriggerSpecification: true,
          });
        })();
      }
    }, [symbol]);

    useEffect(() => {
      return () => trading.clear();
    }, []);

    return component;
  }
);

export const Modals: React.FC = observer(() => {
  const store = useMst();
  const modal = store.ui.modal;

  const shouldHideCloseButton = modal.options.get('shouldHideCloseButton');

  useEffect(() => {
    if (
      modal.isOpen &&
      (modal.id === MODAL_TYPES.placeOrder ||
        modal.id === MODAL_TYPES.addProtection ||
        modal.id === MODAL_TYPES.closePosition ||
        modal.id === MODAL_TYPES.cancelOrder ||
        modal.id === MODAL_TYPES.editOrder)
    ) {
      (!store.user.profile.isRegistrationCompleted ||
        !store.user.profile.isTradingAllowed) &&
        store.user.getProfileDemoAsync.run();
    }

    if (modal.isOpen && modal.id === MODAL_TYPES.addFunds) {
      !store.user.profile.isDepositAllowed &&
        store.user.getProfileDemoAsync.run();
    }
  }, [modal.isOpen, store.user.profile]);

  return (
    <Styled.Modal
      $isDisablePadding={false}
      isOpen={modal.isOpen}
      shouldHideCloseButton={shouldHideCloseButton}
      TransitionComponent={Transition}
    >
      {modal.id === MODAL_TYPES.placeOrder && (
        <RealTimeUpdating component={<CreateOrderWrapper />} />
      )}
      {modal.id === MODAL_TYPES.addProtection && (
        <RealTimeUpdating component={<AddProtection />} />
      )}
      {modal.id === MODAL_TYPES.closePosition && (
        <RealTimeUpdating component={<ClosePosition />} />
      )}
      {modal.id === MODAL_TYPES.editOrder && (
        <RealTimeUpdating component={<EditOrder />} />
      )}
      {modal.id === MODAL_TYPES.cancelOrder && (
        <RealTimeUpdating component={<CancelOrder />} />
      )}
      {modal.id === MODAL_TYPES.cancelScheduledOrder && (
        <CancelScheduledOrder />
      )}
      {(modal.id === MODAL_TYPES.createPriceAlert ||
        modal.id === MODAL_TYPES.editPriceAlert) && (
        <RealTimeUpdating component={<CreateEditPriceAlertWrapper />} />
      )}
      {modal.id === MODAL_TYPES.addFunds && <AddFunds />}
      {modal.id === MODAL_TYPES.accountStatement && <AccountStatement />}
      {modal.id === MODAL_TYPES.deletePriceAlert && <DeletePriceAlert />}
      {modal.id === MODAL_TYPES.statistics && <Statistics />}
      {modal.id === MODAL_TYPES.resultChallenge && <ResultChallenge />}
      {modal.id === MODAL_TYPES.orderResponse && <OrderResponse />}
      {modal.id === MODAL_TYPES.connectMaster && <ConnectMaster />}
      {modal.id === MODAL_TYPES.removeMasterAccount && <RemoveMasterAccount />}
      {modal.id === MODAL_TYPES.dailyTargetProfitReached && (
        <DailyTargetProfitReached />
      )}
      {modal.id === MODAL_TYPES.logOutWithNoActivity && (
        <LogOutWithNoActivity />
      )}
      {modal.id === MODAL_TYPES.changingMuliBandsMultiplier && (
        <ChangingMuliBandsMultiplier />
      )}
      {modal.id === MODAL_TYPES.removeAllMetrics && <RemoveAllMetrics />}
      {modal.id === MODAL_TYPES.tradingDisabledDueToMarketNews && (
        <TradingDisabledDueToMarketNews />
      )}
      {modal.id === MODAL_TYPES.purchaseChallenge && <PurchaseChallenge />}
      {modal.id === MODAL_TYPES.muliBandsDisabled && <MuliBandsDisabled />}
      {modal.id === MODAL_TYPES.removeCloseTimeMuliBands && (
        <RemoveCloseTimeMuliBands />
      )}
      {modal.id === MODAL_TYPES.switchingToBetaDesign && (
        <SwitchingToBetaDesign />
      )}
      {modal.id === MODAL_TYPES.expiredPassword && <ExpiredPassword />}
      {modal.id === MODAL_TYPES.changeLeverage && <ChangeLeverage />}
    </Styled.Modal>
  );
});
