import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material';

import { images, TIconsName } from '@trader/assets';
import { Icon, Typography } from '@trader/components';
import { addSeparatorToIntegerNumber } from '@trader/utils';
import { useI18next } from '@trader/services';
import { EChallengeFrequencyChoice } from '@trader/types';

import { IPurchaseList } from '../index';
import * as Styled from './styled';

interface IPurchaseListCard {
  challengeAmount: number;
  purchaseAmount: number;
  discountPurchaseAmount: number | null;
  frequencyChoice: EChallengeFrequencyChoice | null;
  purchaseType: IPurchaseList['purchaseType'];
  withChartUpBackground?: boolean;
  onPurchaseButtonClick: () => void;
}

type TFrequencyChoiceLabel = Record<
  EChallengeFrequencyChoice,
  { text: string; iconType: TIconsName; bgColor: string }
>;

export const PurchaseListCard = ({
  challengeAmount,
  purchaseAmount,
  discountPurchaseAmount,
  purchaseType,
  frequencyChoice,
  withChartUpBackground,
  onPurchaseButtonClick,
}: IPurchaseListCard) => {
  const theme = useTheme();
  const { translate } = useI18next();

  const frequencyChoiceLabel: TFrequencyChoiceLabel = {
    [EChallengeFrequencyChoice.Popular]: {
      text: translate('PURCHASE_CHALLENGES.POPULAR'),
      iconType: 'favouriteOff',
      bgColor: theme.palette.yellow['200'] as string,
    },
    [EChallengeFrequencyChoice.Offer]: {
      text: translate('PURCHASE_CHALLENGES.SPECIAL_OFFER'),
      iconType: 'gift',
      bgColor: theme.palette.green.dark as string,
    },
    [EChallengeFrequencyChoice.Hot]: {
      text: translate('PURCHASE_CHALLENGES.HOT'),
      iconType: 'hot',
      bgColor: theme.palette.red.main as string,
    },
  };

  return (
    <Styled.Root>
      <Styled.Body>
        <Styled.Profit>
          ${addSeparatorToIntegerNumber(challengeAmount, ',')}
        </Styled.Profit>
        <Styled.Price $withDiscount={!!discountPurchaseAmount}>
          <Typography>{translate('COMMON.LABELS.PRICE')}:</Typography>{' '}
          <Typography>
            ${addSeparatorToIntegerNumber(purchaseAmount, ',')}
          </Typography>
          {discountPurchaseAmount && (
            <Typography>
              ${addSeparatorToIntegerNumber(discountPurchaseAmount, ',')}
            </Typography>
          )}
        </Styled.Price>
      </Styled.Body>
      {withChartUpBackground ? (
        <LazyLoadImage
          alt='background'
          src={images.purchaseChallengeChartUpBgImage}
        />
      ) : (
        <LazyLoadImage
          alt='background'
          src={images.purchaseChallengeChartDownBgImage}
        />
      )}
      <Styled.PurchasedButton onClick={onPurchaseButtonClick}>
        {translate('COMMON.LABELS.PURCHASE')}
      </Styled.PurchasedButton>
      {purchaseType === 'instantChallenges' && (
        <Styled.InstantChallengeIcon>
          <Icon iconType='lightning' />
        </Styled.InstantChallengeIcon>
      )}
      {frequencyChoice && (
        <Styled.FrequencyChoiceLabel
          $bg={frequencyChoiceLabel[frequencyChoice].bgColor}
        >
          <Icon iconType={frequencyChoiceLabel[frequencyChoice].iconType} />
          <Typography>{frequencyChoiceLabel[frequencyChoice].text}</Typography>
        </Styled.FrequencyChoiceLabel>
      )}
    </Styled.Root>
  );
};
