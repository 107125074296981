import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    unicode-bidi: plaintext;
  }

  html,
  body,
  .root {
    margin: 0;
  }

  html {
    font-size: 16px;
  }

  body {
    ${({ theme }) => css`
      height: 100vh;
      background-color: ${theme.palette.background.default};

      ${theme.breakpoints.down('sm')} {
        // calculate height for the mobiles including top and bottom bars
        height: calc(var(--vh, 1vh) * 100);
      }
    `};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    ${({ theme }) => css`
      -webkit-box-shadow: 0 0 0 30px ${theme.palette.background.default} inset !important;
    `};
  }

  #root {
    height: 100%;
    padding: 11px;
    box-sizing: border-box;
  }

  .hidden {
    display: none;
  }

  .image-svg-base,
  img {
    /* display: default(inline) */
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  *::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .grecaptcha-badge {
    z-index: 1;
  }

`;
