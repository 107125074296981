import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useTheme } from '@mui/material';

import { Typography, Wrapper, Stack } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const SummaryChart = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    padding: 12px;
    background-color: ${theme.palette.gray.light};
    border-radius: 5px;
  `};
`;

export const ProfitSummaryChart = styled(Wrapper)`
  ${() => css`
    width: 100%;
    padding: 12px 12px 0 12px;
    border-radius: 5px;
    flex-direction: column;
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.gray.dark};
    text-transform: capitalize;
    margin-bottom: 8px;
  `};
`;

export type TLegendColorType = 'total' | 'max';
export const LegendColor = styled(Wrapper)<{ $type: TLegendColorType }>`
  ${({ theme, $type }) => css`
    width: 12px;
    height: 12px;
    border-radius: 2px;

    ${conditionApply(
      [$type === 'total'],
      css`
        background-color: ${theme.palette.yellow.dark};
      `
    )}
    ${conditionApply(
      [$type === 'max'],
      css`
        background-color: ${theme.palette.blue['300']};
      `
    )}
  `};
`;

export const ChartNumber = styled(Typography)<{
  $width?: string;
  $withBorder?: boolean;
}>`
  ${({ theme, $width, $withBorder }) => css`
    width: ${$width};
    height: fit-content;
    text-align: center;
    padding: 2px 0;
    border-radius: 2px;
    font-size: ${theme.typography.default.fontSize};
    font-weight: 600;
    color: ${theme.palette.black.main};
    background-color: ${theme.palette.white.main};

    ${conditionApply(
      [$withBorder],
      css`
        padding: 2px 6px;
        border: 1px solid ${theme.palette.grey[300]};
      `
    )}
  `};
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
  `};
`;

export const DataRow = ({ children }: { children: ReactNode }) => (
  <Stack
    alignItems='center'
    direction='row'
    justifyContent='space-between'
    spacing='8px'
    width='100%'
  >
    {children}
  </Stack>
);

export const Summary = styled(Wrapper)<{ $profit?: number }>`
  ${({ theme, $profit }) => css`
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 12px 0;
    width: 200px;
    background-color: ${theme.palette.gray.light};

    ${conditionApply(
      [$profit && $profit > 0],
      css`
        background-color: ${theme.palette.green['600']};
      `
    )}

    ${conditionApply(
      [$profit && $profit < 0],
      css`
        background-color: ${theme.palette.red['300']};
        p {
          color: ${theme.palette.black.main};
        }
      `
    )}

    p:nth-child(1) {
      font-size: ${theme.typography.xl.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
      margin-bottom: 20px;
    }

    p:nth-child(2) {
      font-size: 1.25rem;
      font-weight: 600;

      ${conditionApply(
        [$profit && $profit > 0],
        css`
          color: ${theme.palette.green.dark};
        `
      )}

      ${conditionApply(
        [$profit && $profit < 0],
        css`
          color: ${theme.palette.red.dark};
        `
      )}
    }
  `}
`;

export const Summaries = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      spacing='10px'
      padding='12px'
      borderBottom={`1px solid ${theme.palette.background.default}`}
    >
      {children}
    </Stack>
  );
};
