import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import {
  getAmountDigitsAfterDot,
  getTradingAmountTranslationKey,
} from '@trader/utils';
import { useI18next } from '@trader/services';
import { Controller } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';

import { IBackTestingInitialValues } from './index';

export const Quantity = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const trading = store.trading.getTrading('createStrategyBand');

  const backTesting = store.pages.muliBands.backTesting;
  const symbol = backTesting.getSymbol();

  const { setValue, control } = useFormContext<IBackTestingInitialValues>();

  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  useEffect(() => {
    if (!symbol) {
      return setValue('quantity', 0);
    }

    if (instrument) {
      setValue('quantity', trading.minAmount(instrument?.minOrderSize));
    }
  }, [symbol, trading.minAmount(instrument?.minOrderSize)]);

  return (
    <Controller
      type='tradingInput'
      shouldCheckActivityOfButtons
      minValue={trading.minAmount(instrument?.minOrderSize)}
      maxValue={trading.maxAmount(instrument?.maxOrderSize)}
      step={trading.step(instrument?.minOrderSizeIncrement)}
      fixDigitAfterDot={getAmountDigitsAfterDot(
        trading.step(instrument?.minOrderSizeIncrement)
      )}
      control={control}
      name='quantity'
      disabled={!symbol}
      customLabel={translate(
        getTradingAmountTranslationKey(trading.tradingType)
      )}
    />
  );
});
