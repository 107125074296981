import {
  localStorageService,
  requestService,
  TAsyncActionOptions,
} from '@trader/services';
import { marketDataRoutes } from './routes';
import { ICandleBarBE, IIndicators } from './models';
import { LOCAL_STORAGE_KEYS } from '@trader/constants';

export class Historical {
  routes = marketDataRoutes;

  async getCandleBars(
    symbol: string,
    period: string,
    fromTime: number,
    options?: TAsyncActionOptions
  ): Promise<Array<ICandleBarBE>> {
    const resp = await requestService.get<Array<ICandleBarBE>>(
      this.routes.getCandleBars(fromTime),
      {
        ...options?.cancelToken,
        params: { symbol, period },
      }
    );

    return resp?.data;
  }

  async getIndicators(
    symbol: string,
    options?: TAsyncActionOptions
  ): Promise<IIndicators> {
    const themeName = localStorageService.get(LOCAL_STORAGE_KEYS.theme) as
      | string
      | null;

    const resp = await requestService.get<IIndicators>(
      this.routes.getIndicators,
      {
        ...options?.cancelToken,
        params: {
          symbol,
          isDarkTheme: !!themeName && themeName !== 'light',
        },
      }
    );

    return resp?.data;
  }
}
