import { calcAllPositionsPl } from '@trader/utils';
import { TTradingAccountEntity, useMst } from '@trader/store';

import { usePositionsCurrentPrice } from './usePositionsCurrentPrice';
import { usePositionsConversionRate } from './usePositionsConversionRate';
import { EConnectionSubscription } from '@trader/services';

export const useTradingAccountPositions = (accountId: number) => {
  const store = useMst();

  const currentAccount =
    store.entities.tradingAccounts.get<TTradingAccountEntity>(accountId);
  const pl = calcAllPositionsPl(currentAccount.positions, {
    withoutSwap: true,
  });

  usePositionsCurrentPrice({
    subscription: EConnectionSubscription.TradingAccountPositionsCurrentPrice,
    account: currentAccount,
    onUpdate: updatedPositions => {
      if (updatedPositions.platformLogin === currentAccount.platformLogin) {
        const currentPositions =
          store.entities.tradingAccounts.get<TTradingAccountEntity>(
            accountId
          ).positions;
        store.entities.tradingAccounts.update(accountId, {
          positions: currentPositions.map(p => {
            const updated = updatedPositions.positions.find(
              up => up.positionId === p.positionId
            );
            return {
              ...p,
              currentPrice: updated ? updated.price : p.currentPrice,
            };
          }),
        });
      }
    },
  });

  usePositionsConversionRate({
    subscription: EConnectionSubscription.TradingAccountPositionsConversionRate,
    account: currentAccount,
    onUpdate: updatedPositions => {
      if (updatedPositions.platformLogin === currentAccount.platformLogin) {
        const currentPositions =
          store.entities.tradingAccounts.get<TTradingAccountEntity>(
            accountId
          ).positions;
        store.entities.tradingAccounts.update(accountId, {
          positions: currentPositions.map(p => {
            const updated = updatedPositions.positions.find(
              up => up.positionId === p.positionId
            );
            return {
              ...p,
              conversionRate: updated
                ? updated.conversionRate
                : p.conversionRate,
            };
          }),
        });
      }
    },
  });

  return {
    positionsPl: pl,
  };
};
