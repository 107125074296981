import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { getOrderBasePrice } from '@trader/utils';
import { adapterLineStyle, emptyFn } from '@trader/constants';

import {
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '../../charting_library';

const positionLineLength = 20;
let positionLineAdapter: IPositionLineAdapter | undefined = undefined;

export const useDisplayNewPosition = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { translate } = useI18next();

  const idToken = store.auth.tokens.idToken;

  const trading = store.trading.getTrading('createOrder');

  const side = trading.side;

  const symbol = trading.instrument?.symbol as string;
  const ask = trading.instrument?.ask as number;
  const bid = trading.instrument?.bid as number;

  const price = getOrderBasePrice(side, bid, ask);
  const color =
    side === 'Sell'
      ? theme.palette.purchaseButtons.red
      : theme.palette.purchaseButtons.green;

  const text =
    side === 'Buy'
      ? translate('TRADING.NEW_BUY_ORDER')
      : translate('TRADING.NEW_SELL_ORDER');

  const shouldDisplayPosition =
    !!side &&
    trading.orderType === 'Market' &&
    !trading.instrument?.tradingAvailability?.isUnavailable;

  const createPosition = () => {
    positionLineAdapter = widget?.current
      ?.activeChart()
      .createPositionLine()
      .setText(text)
      .setBodyTextColor(theme.palette.white.main)
      .setBodyBorderColor(color)
      .setBodyBackgroundColor(color)
      .setLineColor(color)
      .setLineLength(positionLineLength)
      .setLineStyle(adapterLineStyle)
      .setQuantity('')
      .setCloseButtonBackgroundColor(color)
      .setCloseButtonBorderColor(color)
      .setCloseButtonIconColor(theme.palette.white.main)
      .onClose(function () {
        trading.runInAction(() => {
          trading.isTakeProfit = false;
          trading.isStopLoss = false;
          trading.side = null;
        });
      })
      .setPrice(price);
  };

  const clearPosition = useCallback(() => {
    positionLineAdapter?.remove();
    positionLineAdapter = undefined;
  }, []);

  // initialize position
  useEffect(() => {
    if (shouldDisplayPosition) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady) {
          createPosition();
        }
      });
    }
    return () => clearPosition();
  }, [symbol, shouldDisplayPosition, idToken]);

  // update position
  useEffect(() => {
    if (shouldDisplayPosition) {
      widget.current?.onChartReady(() => {
        const isDataReady = widget.current?.activeChart().dataReady(emptyFn);
        if (isDataReady && positionLineAdapter) {
          positionLineAdapter.setText(text);
          positionLineAdapter.setBodyBorderColor(color);
          positionLineAdapter.setBodyBackgroundColor(color);
          positionLineAdapter.setLineColor(color);
          positionLineAdapter.setCloseButtonBackgroundColor(color);
          positionLineAdapter.setCloseButtonBorderColor(color);
          positionLineAdapter.setPrice(price);
        }
      });
    }
  }, [shouldDisplayPosition, price, side]);

  useEffect(() => {
    if (!shouldDisplayPosition) {
      clearPosition();
    }
  }, [shouldDisplayPosition]);
};
