import { types, Instance, cast } from 'mobx-state-tree';

import { defMultiplier, defSl } from '@trader/constants';
import { getTime, runInAction } from '@trader/utils';
import {
  createBandOrderAsync,
  deleteMuliBandAsync,
  deleteMuliBandOrdersAsync,
  deleteMuliBandSingleOrderAsync,
  deleteMuliBandScheduledOrderAsync,
  editMuliBandsOrderAsync,
  getExistingDataAsync,
  getInitialDataAsync,
  pingMuliBandAsync,
  editMuliBandsAsync,
  editMuliBandsPositionAsync,
  getHistoricalDataAsync,
} from './actions';
import { EMultiplier, EStripName, TStripMap } from '@trader/types';

import { bandModel } from './bands';
import { stripModel } from './strip';
import { indicatorsModel } from './indicators';
import { backTesting } from './backTesting';
import { TPositionMetricEntity } from '../../entities/positionsMetrics';

const dataBoxModel = types.model('dataBoxModel', {
  isOpen: false,
  positionTop: types.maybeNull(types.number),
  positionLeft: types.maybeNull(types.number),
});

const stripM = types.model('stripM', {
  id: types.frozen<EStripName>(),
  color: types.optional(types.string, ''),
  value: types.optional(types.number, 0),
  cross: types.optional(types.number, 0),
  change: types.optional(types.string, '-'),
  orderId: types.maybeNull(types.number),
  orderSide: types.maybeNull(types.string),
  positionId: types.maybeNull(types.number),
  positionPrice: types.maybeNull(types.number),
  positionPl: types.maybeNull(types.number),
});

export type TStripM = Instance<typeof stripM>;

export const muliBandModel = types
  .model('muliBandModel', {
    id: types.maybeNull(types.number),
    symbol: types.optional(types.string, ''),
    chartPeriod: types.optional(types.string, 'Minute2'),
    orderAmount: types.optional(types.string, '1000'),
    topMultiplierBE: types.optional(types.number, defMultiplier),
    bottomMultiplierBE: types.optional(types.number, defMultiplier),
    floatTopMultiplier: types.optional(types.string, String(defMultiplier)),
    floatBottomMultiplier: types.optional(types.string, String(defMultiplier)),
    sl: types.optional(types.string, defSl),
    isSl: false,
    isCloseTime: false,
    closeTime: types.maybeNull(types.Date),
    isOpenTime: false,
    openTime: types.maybeNull(types.Date),
    firstOpenedPositionTime: types.maybeNull(types.string),
    multiplier: types.optional(
      types.union(
        types.literal(EMultiplier.base),
        types.literal(EMultiplier.us)
      ),
      EMultiplier.base
    ),
    strip: types.optional(stripModel, {}),
    price: types.optional(types.number, 0),
    dataBox: types.optional(dataBoxModel, {}),
    bandsButtons: types.array(bandModel),
    strips: types.map(stripM),
    indicators: indicatorsModel,
    backTesting: backTesting,
    createBandOrderAsync,
    getInitialDataAsync,
    getExistingDataAsync,
    deleteMuliBandAsync,
    pingMuliBandAsync,
    deleteMuliBandOrdersAsync,
    editMuliBandsOrderAsync,
    editMuliBandsPositionAsync,
    deleteMuliBandSingleOrderAsync,
    deleteMuliBandScheduledOrderAsync,
    editMuliBandsAsync,
    getHistoricalDataAsync,
  })
  .views(store => ({
    getCloseTime: (
      positionId: string,
      strategy: TPositionMetricEntity['strategy']
    ): string | undefined => {
      if (strategy && strategy.closesTradesAfter) {
        return strategy.closesTradesAfter;
      }

      const strips = Array.from<TStripM>(store.strips.values());

      if (store.isCloseTime && store.closeTime) {
        const isPosition = strips.find(
          strip => strip.positionId === Number(positionId)
        );

        return isPosition && getTime(store.closeTime);
      }
    },
  }))
  .actions(store => ({
    runInAction,
    setPrice: (price: number) => {
      store.price = price;
    },
    mergeStrips: (strips: TStripM) => {
      store.strips.merge(strips);
    },
    updateStripOrder: (
      key: EStripName,
      orderId: number | null,
      orderSide?: string
    ) => {
      const strip = store.strips.get(key);
      if (strip) {
        store.strips.set(key, {
          ...strip,
          orderId,
          orderSide: orderSide || strip.orderSide,
        });
      }
    },
    clearBands: () => {
      const strips = Array.from<TStripM>(store.strips.values());

      strips.forEach(s => {
        s.orderId = null;
        s.positionId = null;
      });
    },
    updateStripPosition: (
      key: EStripName,
      positionId: number | null,
      openPrice?: number,
      pl?: number
    ) => {
      const strip = store.strips.get(key);
      if (strip) {
        store.strips.set(key, {
          ...strip,
          positionId,
          positionPrice: openPrice || null,
          positionPl: pl || null,
        });
      }
    },
    clear: () => {
      store.bandsButtons = cast([]);
      store.sl = defSl;
      store.isSl = false;
      store.id = null;
      store.isCloseTime = false;
      store.closeTime = null;
      store.isOpenTime = false;
      store.openTime = null;
      store.orderAmount = '0';
      store.price = 0;
      store.topMultiplierBE = defMultiplier;
      store.bottomMultiplierBE = defMultiplier;
      store.floatTopMultiplier = String(defMultiplier);
      store.floatBottomMultiplier = String(defMultiplier);
      store.firstOpenedPositionTime = null;
    },
    resetOpenTime: () => {
      store.isOpenTime = false;
      store.openTime = null;
    },
    updateStripsValues: (strips: TStripMap) => {
      Array.from(strips.values()).forEach(strip => {
        store.strips.set(strip.id, strip);
      });
    },
    updateDataBoxPosition: (top: number, left: number) => {
      store.dataBox.positionTop = top;
      store.dataBox.positionLeft = left;
    },
    toggleDataBox: () => {
      store.dataBox.isOpen = !store.dataBox.isOpen;
    },
  }));

export const muliBands = types.optional(muliBandModel, {});

export type TMuliBandsStore = Instance<typeof muliBandModel>;
