import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { ICommonOrderType, IInitialTradingFormValues } from '@trader/types';

import * as Styled from './styled';
import { Tooltip } from '@trader/components';

interface ILoss extends ICommonOrderType {
  openPrice?: string;
}

export const Loss: React.FC<ILoss> = observer(({ type, openPrice }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { formState, getValues } = useFormContext<IInitialTradingFormValues>();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const shouldRecalculateLoss =
    formState.dirtyFields.stopLoss || formState.dirtyFields.stopLossPips;

  const calculateLoss = () => {
    trading.updateLoss({ ...getValues(), openPrice });
  };

  useEffect(() => {
    if (!shouldRecalculateLoss) {
      calculateLoss();
    }
  }, [
    shouldRecalculateLoss,
    trading.conversionRate,
    getValues().amount,
    getValues().stopLoss,
  ]);

  useEffect(() => {
    if (shouldRecalculateLoss) {
      calculateLoss();
    }
  }, [
    shouldRecalculateLoss,
    instrument.ask,
    instrument.bid,
    getValues().amount,
    getValues().stopLoss,
  ]);

  useEffect(() => {
    calculateLoss();
  }, [getValues().side, getValues().orderType]);

  return (
    <Tooltip title={trading.loss}>
      <Styled.InputLabel>
        {translate('COMMON.LABELS.LOSS')}: {trading.loss}
      </Styled.InputLabel>
    </Tooltip>
  );
});
