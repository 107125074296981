import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import i18next from 'i18next';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

import { emptyFn } from '@trader/constants';
import {
  devLoggerService,
  // keepAliveInterval,
  // serverTimeout,
  SmartLookService,
  timeToCloseSnackbar,
} from '@trader/services';
import { appConfigUtils, urlHelpers } from '@trader/utils';

import {
  amountOfRetries,
  autoHideSnackbar,
  reconnectTimeout,
  retryDelays,
  webSocketsUrls,
} from './constants';
import {
  EConnectionHub,
  EConnectionSubscription,
  IConnectionMap,
  IParams,
  ISubscription,
  TConnectionCache,
  TRenderSnackBar,
  TSubscribe,
} from './types';

export { EConnectionHub, HubConnection, EConnectionSubscription };
export * from './constants';

const cache: TConnectionCache = (() => {
  const map = new Map<EConnectionHub, IConnectionMap>();

  for (const key of Object.keys(EConnectionHub)) {
    map.set(EConnectionHub[key], {
      key: EConnectionHub[key],
      hub: null,
      subscriptions: new Map(),
    });
  }
  return map;
})();

let retries = 0;
let shouldUseReconnection = true;

let reconnections = 0;
let timeout: null | NodeJS.Timeout = null;

const renderSnackBar: TRenderSnackBar = ({ msg, variant, autoHide }) => {
  enqueueSnackbar(msg, {
    variant,
    autoHideDuration: autoHide === null ? null : autoHide || autoHideSnackbar,
    preventDuplicate: true,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  });
};

class WebSocketsService {
  private _subscribe: TSubscribe | typeof emptyFn;
  private _idToken: string;
  private readonly _url: IParams['url'];
  private readonly _hub: IParams['hub'];
  private readonly _subscription: IParams['subscription'];
  private readonly _refreshToken?: IParams['refreshToken'];
  private readonly _logout?: IParams['logout'];

  constructor(params?: IParams) {
    this._url = params?.url || webSocketsUrls.quotes;
    this._hub = params?.hub || EConnectionHub.Quotes;
    this._subscription =
      params?.subscription || EConnectionSubscription.Instrument;
    this._refreshToken = params?.refreshToken;
    this._logout = params?.logout;
    this._subscribe = emptyFn;
    this._idToken = '';
  }

  private _getCurrentConnection() {
    return cache.get(this._hub) as IConnectionMap;
  }

  private _getCurrentHub() {
    const currentConnection = this._getCurrentConnection();
    return currentConnection?.hub;
  }

  private _getCurrentSubscriptions() {
    const currentConnection = this._getCurrentConnection();
    return currentConnection
      ? currentConnection.subscriptions
      : new Map<EConnectionSubscription, ISubscription>();
  }

  private _getCurrentSubscriptionsValues() {
    return this._getCurrentSubscriptions()?.size
      ? Array.from(this._getCurrentSubscriptions()?.values())
      : [];
  }

  private _createConnection() {
    if (!this._idToken) {
      return;
    }

    shouldUseReconnection = true;

    const baseUrl = urlHelpers.getBaseUrlWithApiType(
      import.meta.env.VITE_SIGNALR_URL,
      appConfigUtils.getCurrentApiType()
    );

    if (!this._getCurrentHub()) {
      try {
        const hub = new HubConnectionBuilder()
          .withUrl(baseUrl + this._url, {
            accessTokenFactory: () => this._idToken,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          })
          .withAutomaticReconnect(retryDelays)
          .configureLogging(LogLevel.None)
          .build();

        // hub.serverTimeoutInMilliseconds = serverTimeout;
        // hub.keepAliveIntervalInMilliseconds = keepAliveInterval;

        cache.set(this._hub, {
          ...this._getCurrentConnection(),
          hub: hub,
        });
      } catch (e) {
        devLoggerService.log(`${this._hub} Build connection error`, e);

        renderSnackBar({
          msg: i18next.t('NOTIFICATIONS.CREATE_CONNECTION_FAILED'),
          variant: 'error',
        });
      }

      this._getCurrentHub()?.onreconnected(async () => {
        devLoggerService.log(`${this._hub} Reconnected successfully.`);

        const subscriptions = this._getCurrentSubscriptionsValues();

        for (const subscription of subscriptions) {
          if (subscription.status !== 'subscribed') {
            SmartLookService.track('Websockets_reconnected_successfully', {
              subscription: subscription.key,
              hub: this._hub,
            });

            await this.subscribe(subscription.start, subscription.key);
          }
        }
        timeout && clearTimeout(timeout);

        timeout = setTimeout(() => {
          closeSnackbar();
          timeout && clearTimeout(timeout);
          timeout = null;
          reconnections = 0;
        }, timeToCloseSnackbar);

        const activeHubs = Array.from(cache.values()).filter(
          value => !!value.hub
        );

        if (!reconnections || reconnections === activeHubs.length) {
          closeSnackbar();
          timeout && clearTimeout(timeout);
          timeout = null;
          reconnections = 0;
        }
      });

      this._getCurrentHub()?.onreconnecting(error => {
        devLoggerService.log(`${this._hub} onreconnecting error`, error);

        reconnections += 1;

        SmartLookService.track('Websockets_reconnecting', {
          subscription: this._subscription,
          hub: this._hub,
        });

        renderSnackBar({
          msg: i18next.t('NOTIFICATIONS.PLATFORM_RECONNECTING'),
          variant: 'warning',
          autoHide: null,
        });

        const subscriptions = this._getCurrentSubscriptionsValues();

        subscriptions.forEach((subscription: ISubscription) => {
          this._getCurrentSubscriptions().set(subscription.key, {
            ...subscription,
            status: 'unsubscribed',
          });
        });
      });

      this._getCurrentHub()?.onclose(error => {
        devLoggerService.log(`${this._hub} Connection closed.`, error);

        if (shouldUseReconnection) {
          this._customReconnection();
        }
      });

      this._startConnection();
    } else {
      this._startConnection();
    }
  }

  private async _startConnection(isReconnecting = false) {
    const hubState = this._getCurrentHub()?.state;

    try {
      hubState === HubConnectionState.Disconnected &&
        (await this._getCurrentHub()?.start());

      if (isReconnecting) {
        await this.subscribe(this._subscribe as TSubscribe);

        retries = 0;
      }
    } catch (err) {
      if (!this._getCurrentHub()) {
        return;
      }

      devLoggerService.log(`${this._hub} startConnection error`, err);

      this._customReconnection();
    }
  }

  private async _customReconnection() {
    devLoggerService.log(`${this._hub} customReconnection retried failed`);

    if (retries < amountOfRetries) {
      setTimeout(() => this._startConnection(true), reconnectTimeout);
    }

    if (retries === amountOfRetries) {
      try {
        await this._refreshToken?.();
      } catch (err) {
        devLoggerService.log(`${this._hub} customReconnection failed`, err);
      }
    }

    if (retries > amountOfRetries) {
      this._logout?.();
      return;
    }

    retries += 1;
  }

  public async subscribe(
    subscribe?: TSubscribe,
    key?: EConnectionSubscription
  ) {
    if (!this._idToken) {
      return;
    }

    subscribe && (this._subscribe = subscribe);

    const subscriptionKey = key || this._subscription;
    const subscriptionMap = this._getCurrentSubscriptions();

    subscriptionMap &&
      subscriptionMap.set(subscriptionKey, {
        key: subscriptionKey,
        status: 'subscribed',
        start: this._subscribe,
      });

    const hub = this._getCurrentHub();

    try {
      if (hub?.state === HubConnectionState.Connected) {
        await this._subscribe(hub);
      } else {
        throw Error("Connection is not in the 'Connected' State.");
      }
    } catch (err) {
      if (err) {
        setTimeout(() => this.subscribe(this._subscribe), reconnectTimeout);
      }
    }
  }

  public async unsubscribe(unsubscribe: TSubscribe) {
    const hub = this._getCurrentHub();
    if (hub?.state === HubConnectionState.Connected) {
      await unsubscribe(hub);
    }
  }

  public async closeAll() {
    shouldUseReconnection = false;

    const allConnections: IConnectionMap[] = cache.size
      ? Array.from(cache.values())
      : [];

    for (const connection of allConnections) {
      await connection?.hub?.stop();
      cache.set(connection.key, { ...connection, hub: null });
    }

    cache.clear();
    retries = 0;
    closeSnackbar();
    timeout = null;
    reconnections = 0;
  }

  public start(idToken: string) {
    this._idToken = idToken;
    this._createConnection();
  }
}

export type TWebSocketsService = WebSocketsService;

export function webSocketsService(params: IParams): WebSocketsService;
export function webSocketsService(): WebSocketsService;

export function webSocketsService(params?: IParams) {
  return new WebSocketsService(params);
}
