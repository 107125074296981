import React from 'react';
import { observer } from 'mobx-react-lite';

import { formatByPipSize, getNetPl, getTotalNetAmount } from '@trader/utils';
import { IOrderMetricsBE, IPositionMetricsBE } from '@trader/api';
import { defaultIcon } from '@trader/constants';
import { useI18next } from '@trader/services';
import { Typography } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';

import * as Styled from './styled';

export interface IPositionMetricStore extends IPositionMetricsBE {
  symbol: string;
}

export interface IOrderMetricStore extends IOrderMetricsBE {
  symbol: string;
}

const PositionMetricHeader: React.FC<IPositionMetricStore> = observer(
  ({ ...metric }) => {
    const { translate } = useI18next();
    const store = useMst();

    const trading = store.trading.getTrading('modifyOrder');
    const tradingAccount = store.user.activeTradingAcc();
    const instrument = trading.instrument as TInstrumentEntity;
    const currencySymbol = tradingAccount.currencySymbol;

    const purchaseSide =
      metric?.side === 'Buy'
        ? translate('COMMON.LABELS.BOUGHT')
        : translate('COMMON.LABELS.SOLD');

    return (
      <Styled.Root>
        <Styled.Container>
          <Styled.Image
            src={instrument?.iconUrl || defaultIcon}
            alt='instrument icon'
            width={30}
            height={30}
          />
          <Typography variant='medium'>{metric?.symbol}</Typography>
        </Styled.Container>
        <Styled.Container>
          <Styled.Title>{translate('COMMON.LABELS.P_L')}:</Styled.Title>
          <Styled.NetPlValue
            color={getNetPl(metric?.pl, currencySymbol).textColor}
          >
            {getNetPl(metric?.pl, currencySymbol).stringValue}
          </Styled.NetPlValue>
        </Styled.Container>
        <Typography variant='medium'>
          {purchaseSide}: {metric?.quantity} @{' '}
          {formatByPipSize(metric?.openPrice, instrument?.pipSize)}
        </Typography>
      </Styled.Root>
    );
  }
);

const OrderMetricHeader: React.FC<IOrderMetricStore> = observer(
  ({ ...metric }) => {
    const store = useMst();
    const { translate } = useI18next();

    const trading = store.trading.getTrading('modifyOrder');
    const instrument = trading.instrument as TInstrumentEntity;

    return (
      <Styled.Root>
        <Styled.Container>
          <Styled.Image
            src={instrument?.iconUrl || defaultIcon}
            alt='instrument icon'
            width={30}
            height={30}
          />
          <Typography variant='medium'>{metric?.symbol}</Typography>
        </Styled.Container>
        <Styled.Container>
          <Typography variant='medium'>
            {getTotalNetAmount(metric?.quantity, metric?.side, translate)} @{' '}
            {metric?.limitPrice &&
              formatByPipSize(metric.limitPrice, instrument.pipSize)}
            {metric?.stopPrice &&
              formatByPipSize(metric.stopPrice, instrument.pipSize)}
          </Typography>
        </Styled.Container>
      </Styled.Root>
    );
  }
);

const RenderMetric = {
  position: PositionMetricHeader,
  order: OrderMetricHeader,
} as const;

type TPosition = 'position';
type TOrder = 'order';

type TMetric<T> = T extends IPositionMetricStore
  ? IPositionMetricStore
  : IOrderMetricStore;

type TMetricByType<T> = T extends TPosition
  ? IPositionMetricStore
  : IOrderMetricStore;

interface IOrderModalsHeader<T> {
  type: TPosition | TOrder;
  metric?: TMetric<T>;
}

export const TradingModalHeader = observer(
  <T extends IPositionMetricStore | IOrderMetricStore>({
    type,
    metric,
  }: IOrderModalsHeader<T>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Component: React.FC<TMetricByType<typeof type>> = RenderMetric[type];

    if (!metric) {
      return null;
    }

    return (
      <Styled.Component>
        <Component {...metric} />
      </Styled.Component>
    );
  }
);
