import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Content = styled(Wrapper)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid ${theme.palette.gray.light};

    &:first-child {
      padding: 5px 20px 10px 20px;
    }

    .icon.arrowDown,
    .icon.arrowUp {
      width: 12px;
      height: 12px;
    }

    .expandable-icon-button {
      padding: 6px 9px;
    }
  `}
`;

export const Details = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    background-color: ${theme.palette.gray.light};
    padding: 10px 20px;
  `}
`;

export const DetailsItem = styled(Wrapper)`
  ${() => css`
    justify-content: space-between;
    align-items: center;
    margin: 2px 0;

    p:nth-child(1) {
      text-transform: capitalize;
    }
  `}
`;
