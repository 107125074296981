import React, { useState } from 'react';
import { isEqualWith } from 'lodash';

import { Select } from '@trader/components';
import { IOption } from '@trader/types';
import { useI18next } from '@trader/services';

interface ISelectFilter {
  label?: string;
  options: IOption[];
  noAllOption?: boolean;
  onChange: (values: string[]) => void;
}

export const SelectFilter: React.FC<ISelectFilter> = ({
  label,
  options,
  noAllOption,
  onChange,
}) => {
  const { translate } = useI18next();

  const allOption = { title: translate('ASSETS.ALL'), value: 'all' };
  const [selectedOptions, setSelectedOptions] = useState(
    noAllOption ? [options[0]] : [allOption]
  );

  const handleChange = (value: Array<IOption>) => {
    let updatedOptions: Array<IOption> = [];

    if (noAllOption) {
      updatedOptions = !value.length ? [options[0]] : value;
    } else {
      const isAllOptionSelected = !!selectedOptions.find(
        v => v.value === allOption.value
      );
      const isAllOptionSelecting = !!value.find(
        v => v.value === allOption.value
      );
      const shouldResetToAll =
        !value.length ||
        (!isAllOptionSelected && isAllOptionSelecting) ||
        (value.length === options.length && !isAllOptionSelecting);

      updatedOptions = shouldResetToAll
        ? [allOption]
        : value.filter(v => v.value !== allOption.value);
    }

    const hasDifference = !isEqualWith(
      selectedOptions,
      updatedOptions,
      (v1, v2) => v1.value !== v2.value
    );
    if (hasDifference) {
      setSelectedOptions(updatedOptions);
      if (updatedOptions.find(s => s.value === allOption.value)) {
        onChange([]);
      } else {
        onChange(
          updatedOptions
            .filter(s => s.value !== allOption.value)
            .map(s => s.value)
        );
      }
    }
  };

  return (
    <Select
      options={noAllOption ? options : [allOption, ...options]}
      label={label}
      value={selectedOptions}
      onChange={v => handleChange(v.target.value)}
      sx={{
        '& .MuiSelect-select': {
          padding: '10px 32px 10px 14px',
        },
      }}
      multiple
    />
  );
};
