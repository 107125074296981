import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { EAccountTypeNames } from '@trader/constants';
import { TPlaceOrderSide } from '@trader/types';
import { runInAction } from '@trader/utils';

import {
  getTradingAccountsAsync,
  changeTradingAccountAsync,
  changeLeverageAsync,
} from './actions';
import { createCollectionModel } from '../../utils/collectionModel';
import { initialPortfolio, portfolioModel } from './portfolio';
import { challengeModel } from '../challenges';

const accountPositionModel = types.model('accountPositionModel', {
  positionId: types.string,
  symbol: types.string,
  currency: types.string,
  quantity: types.number,
  side: types.frozen<TPlaceOrderSide>('Buy'),
  openPrice: types.number,
  currentPrice: types.number,
  spreadDiff: types.number,
  spreadDiffBalance: types.number,
  swap: types.number,
  conversionRate: types.number,
  accruedInterest: types.number,
  calcMode: types.number,
  faceValue: types.number,
  tickSize: types.number,
  tickValue: types.number,
  contractSize: types.number,
  pipSize: types.number,
});

export const tradingAccountModel = types
  .model('tradingAccountModel', {
    tradingId: types.identifierNumber,
    platformLogin: types.string,
    serverName: types.string,
    isInUse: types.boolean,
    product: types.optional(types.string, ''),
    accountType: types.union(
      types.literal(EAccountTypeNames.Live),
      types.literal(EAccountTypeNames.Demo)
    ),
    currency: types.string,
    currencySymbol: types.string,
    isManagedMaster: types.boolean,
    isTradingAllowed: types.boolean,
    isDepositAllowed: types.boolean,
    leverage: types.number,
    portfolio: portfolioModel,
    // TODO: (av) Convert to map for optimization.
    //            The example is strips field in muliBands. Use types.map.
    positions: types.array(accountPositionModel),
    challenge: types.maybeNull(
      types.late(() => types.safeReference(challengeModel))
    ),
  })
  .actions(() => ({ runInAction }));

interface ITradingAccountsAsync {
  getTradingAccountsAsync: typeof getTradingAccountsAsync;
  changeTradingAccountAsync: typeof changeTradingAccountAsync;
  changeLeverageAsync: typeof changeLeverageAsync;
}

export const tradingAccounts = createCollectionModel<
  typeof tradingAccountModel,
  ITradingAccountsAsync
>(tradingAccountModel, {
  getTradingAccountsAsync,
  changeTradingAccountAsync,
  changeLeverageAsync,
});

export type TTradingAccountEntity = Instance<typeof tradingAccountModel>;
export type TTradingAccountsEntity = Instance<typeof tradingAccounts>;

export const initialTradingAccount: SnapshotIn<typeof tradingAccountModel> = {
  tradingId: 0,
  platformLogin: '',
  serverName: '',
  isInUse: false,
  product: '',
  accountType: EAccountTypeNames.Demo,
  currency: '',
  currencySymbol: '',
  isManagedMaster: false,
  isTradingAllowed: false,
  isDepositAllowed: false,
  leverage: 0,
  portfolio: initialPortfolio,
  positions: [],
  challenge: null,
};
