import styled, { css } from 'styled-components';
import { IconButton, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const FilteringButton = styled(IconButton)<{ $isActive?: boolean }>`
  ${({ theme, $isActive }) => css`
    color: ${theme.palette.gray[100]};
    margin-left: 4px;

    > svg {
      width: 10px;
      height: 10px;
    }

    ${conditionApply(
      [$isActive],
      css`
        color: ${theme.palette.primary.main};
      `
    )};
  `}
`;

export const FilterContainer = styled(Wrapper)`
  ${() => css``}
`;

export const Root = styled(Wrapper)`
  ${() => css`
    padding: 13px 8px;
    align-items: center;

    flex-direction: row;

    > button {
      margin-right: 8px;
    }

    > div {
      width: 140px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  `}
`;
