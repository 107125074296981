import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';

interface IConfirmButton {
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  title: string;
  type?: 'submit' | 'button';
}

export const ConfirmButton: React.FC<IConfirmButton> = observer(
  ({ isDisabled, isLoading, title, onClick, type = 'submit' }) => {
    return (
      <Styled.Root>
        <Styled.ConfirmButton
          type={type}
          onClick={onClick}
          loading={isLoading}
          disabled={isLoading || isDisabled}
        >
          <Styled.Title>{title}</Styled.Title>
        </Styled.ConfirmButton>
      </Styled.Root>
    );
  }
);
