import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  Form,
  ModalTitle,
  Progress,
  TradingIsClosed,
  Typography,
} from '@trader/components';
import {
  TInstrumentEntity,
  TModalStore,
  TOrderMetricEntity,
  useMst,
} from '@trader/store';
import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
  useGetTradingDefaultFormValues,
} from '@trader/hooks';
import { useI18next } from '@trader/services';
import { IInitialTradingFormValues } from '@trader/types';
import { TradingRealTimeUpdatesInitializer, LimitOrder } from '@trader/trading';

import {
  IOrderMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
import { usePlaceOrderFormValidation } from '../createOrder/formValidation';

import * as Styled from './styled';

const Content: React.FC = observer(() => {
  const { translate } = useI18next();

  const { validationSchema } = usePlaceOrderFormValidation('modifyOrder');

  const store = useMst();
  const modal: TModalStore = store.ui.modal;
  const orderId = modal.options.get('orderId');
  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;
  const metric = store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);
  const pendingPrice = metric?.stopPrice || metric?.limitPrice;

  const defaultFormValues = useGetTradingDefaultFormValues('modifyOrder', {
    symbol: instrument.symbol,
    side: metric?.side,
    takeProfit: metric?.takeProfit,
    stopLoss: metric?.stopLoss,
    amount: trading.minAmount(metric?.quantity),
    pendingPrice,
  });

  const handleEditOrder = (data: IInitialTradingFormValues) => {
    metric?.editOrderAsync.run({
      orderId,
      body: {
        side: data.side,
        quantity: trading.getQuantity(data.amount),
        symbol: instrument.symbol,
        type: data.side === 'Sell' ? 'EntryStop' : 'EntryLimit',
        price: Number(data.price),
        stopLoss: data.isStopLoss ? +data.stopLoss : 0,
        takeProfit: data.isTakeProfit ? +data.takeProfit : 0,
      },
    });
  };

  const handleWatch = (name: string, value: string | number | boolean) => {
    trading.runInAction(() => {
      trading[name] = value;
    });
  };

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.EDIT_ORDER')} />
      <TradingModalHeader<IOrderMetricStore> type='order' metric={metric} />
      <Form<IInitialTradingFormValues>
        onSubmit={(_, data) => {
          handleEditOrder(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
        handleWatch={handleWatch}
        watchingItems={['price']}
      >
        {() => (
          <TradingRealTimeUpdatesInitializer type='modifyOrder'>
            <Styled.LimitOrder>
              <LimitOrder
                type='modifyOrder'
                isEditingOrder
                fullWidth
                buttonTitle={
                  metric?.strategyId
                    ? translate('MULI_BANDS.SWITCH_MODE_ORDER')
                    : translate('COMMON.LABELS.EDIT_ORDER')
                }
              />
            </Styled.LimitOrder>
          </TradingRealTimeUpdatesInitializer>
        )}
      </Form>
      {metric?.strategyId && (
        <Styled.SwitchStrategyModeTitle>
          {translate('MULI_BANDS.SWITCH_MODE_ORDER_INFO')}
        </Styled.SwitchStrategyModeTitle>
      )}
    </Styled.Root>
  );
});

export const EditOrder: React.FC = observer(() => {
  useCloseModalForAlreadyDeletedInstance('orderId');

  const { translate } = useI18next();
  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const store = useMst();
  const trading = store.trading.getTrading('modifyOrder');
  const orderId = store.ui.modal.options.get('orderId');
  const metric = store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);
  const instrument = trading.instrument as TInstrumentEntity;

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  if (!metric) {
    return null;
  }

  if (applyConditionThird().status === 'failed') {
    return (
      <Styled.Root paddingTop='16px'>
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_IS_DISABLED')}
          content={
            <div>
              <Typography>
                {translate('WARNINGS.PLEASE_CONTACT_SUPPORT')}
              </Typography>
              <a
                href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
                style={{ color: 'inherit' }}
              >
                {import.meta.env.VITE_SUPPORT_EMAIL}
              </a>
            </div>
          }
          buttonContent={translate('COMMON.LABELS.PROCEED')}
          onClick={applyConditionThird()?.action}
        />
      </Styled.Root>
    );
  }

  return <Content />;
});
