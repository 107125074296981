import { IDataFreeParams } from './types';
import { indexDBName } from './constants';

export const get = <TResponse>({
  key,
  version,
  storeName,
}: IDataFreeParams): Promise<TResponse | undefined> =>
  new Promise(resolve => {
    const request = indexedDB.open(indexDBName, version);

    request.onsuccess = () => {
      // Check if the store exists before performing any transaction
      if (!request.result.objectStoreNames.contains(storeName)) {
        console.error(`Store "${storeName}" not found in the DB!`);
        resolve(undefined);
        return;
      }

      try {
        const tx = request.result.transaction(storeName, 'readonly');
        const store = tx.objectStore(storeName);
        const res = !key ? store.getAll() : store.get(key);

        res.onsuccess = () => {
          resolve(res.result as TResponse);
        };

        res.onerror = err => {
          console.error(`Error fetching data from store "${storeName}"`, err);
          resolve(undefined);
        };
      } catch (error) {
        console.error(`Transaction failed for store "${storeName}"`, error);
        resolve(undefined);
      }
    };

    request.onerror = err => {
      console.error('Opening IndexedDB Error while trying to GET data:', err);
      resolve(undefined);
    };
  });
