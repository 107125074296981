import { ISubscribedInstruments, TServerName } from './types';

const alreadySubscribedInstruments = new Map<
  TServerName,
  ISubscribedInstruments
>();

export const getServerGroupMap = (serverName: string) => {
  const isServerGroup = alreadySubscribedInstruments.has(serverName);

  if (!isServerGroup) {
    alreadySubscribedInstruments.set(serverName, {
      id: serverName,
      instruments: new Map(),
    });
  }
  const serverGroup = alreadySubscribedInstruments.get(serverName);

  return serverGroup as ISubscribedInstruments;
};
