import { createEntitiesModel } from '../utils/entitiesModel';
import { categories } from './categories';
import { instruments } from './instruments';
import { ordersMetrics } from './ordersMetrics';
import { positionsMetrics } from './positionsMetrics';
import { positionsHistory } from './positionsHistory';
import { alerts } from './alerts';
import { tradingAccounts } from './tradingAccounts';
import { challenges } from './challenges';
import { availableChallenges } from './availableChallenges';
import { scheduledOrders } from './scheduledOrders';
import { scheduledOrdersHistory } from './scheduledOrdersHistory';

export const entities = createEntitiesModel({
  categories,
  instruments,
  ordersMetrics,
  positionsMetrics,
  positionsHistory,
  alerts,
  tradingAccounts,
  challenges,
  availableChallenges,
  scheduledOrders,
  scheduledOrdersHistory,
});
