import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0 0 8px;
  `}
`;

export const CloseIcon = styled(Wrapper)`
  ${() => css`
    svg {
      margin-top: 2px;
      width: 12px;
      height: 12px;
    }
  `}
`;

export const Image = styled('img')`
  ${() => css`
    margin-right: 8px;
  `}
`;
