import { maxMultiplier, minMultiplier } from '@trader/constants';
import { StudyInputType } from '../charting_library';
import { EStripName, TStripMap } from '@trader/types';

export const strategyMap: TStripMap = new Map([
  [
    EStripName.RoundedVwap,
    {
      id: EStripName.RoundedVwap,
      color: 'rgb(68,224,16)',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '0',
    },
  ],
  [
    EStripName.Strip025High,
    {
      id: EStripName.Strip025High,
      color: '#FE9CA2',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '0.25',
    },
  ],
  [
    EStripName.Strip0HalfHigh,
    {
      id: EStripName.Strip0HalfHigh,
      color: '#FF8E94',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '0.5',
    },
  ],
  [
    EStripName.Strip075High,
    {
      id: EStripName.Strip075High,
      color: '#F97F86',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '0.75',
    },
  ],
  [
    EStripName.Strip1High,
    {
      id: EStripName.Strip1High,
      color: '#F46A71',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '1',
    },
  ],
  [
    EStripName.Strip1HalfHigh,
    {
      id: EStripName.Strip1HalfHigh,
      color: '#ED575F',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '1.5',
    },
  ],
  [
    EStripName.Strip2High,
    {
      id: EStripName.Strip2High,
      color: '#E5434C',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '2',
    },
  ],
  [
    EStripName.Strip2HalfHigh,
    {
      id: EStripName.Strip2HalfHigh,
      color: '#D92F39',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '2.5',
    },
  ],
  [
    EStripName.Strip3High,
    {
      id: EStripName.Strip3High,
      color: '#B2161F',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '3',
    },
  ],
  [
    EStripName.Strip025Low,
    {
      id: EStripName.Strip025Low,
      color: '#ACDDD2',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '0.25',
    },
  ],
  [
    EStripName.Strip0HalfLow,
    {
      id: EStripName.Strip0HalfLow,
      color: '#97D4C6',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '0.5',
    },
  ],
  [
    EStripName.Strip075Low,
    {
      id: EStripName.Strip075Low,
      color: '#80CDBC',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '0.75',
    },
  ],
  [
    EStripName.Strip1Low,
    {
      id: EStripName.Strip1Low,
      color: '#71BDAC',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '1',
    },
  ],
  [
    EStripName.Strip1HalfLow,
    {
      id: EStripName.Strip1HalfLow,
      color: '#5FA999',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '1.5',
    },
  ],
  [
    EStripName.Strip2Low,
    {
      id: EStripName.Strip2Low,
      color: '#4F9384',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '2',
    },
  ],
  [
    EStripName.Strip2HalfLow,
    {
      id: EStripName.Strip2HalfLow,
      color: '#3F766A',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '2.5',
    },
  ],
  [
    EStripName.Strip3Low,
    {
      id: EStripName.Strip3Low,
      color: '#214F45',
      value: NaN,
      cross: 0,
      change: '',
      bestStripValue: '3',
    },
  ],
]);

export const muliBandsInputs = [
  {
    id: 'topMultiplier',
    name: 'Top Multiplier',
    type: 'integer' as StudyInputType.Integer,
    isHidden: true,
    defval: minMultiplier,
    min: minMultiplier,
    max: maxMultiplier,
    step: minMultiplier,
  },
  {
    id: 'bottomMultiplier',
    name: 'Bottom Multiplier',
    type: 'integer' as StudyInputType.Integer,
    isHidden: true,
    defval: minMultiplier,
    min: minMultiplier,
    max: maxMultiplier,
    step: minMultiplier,
  },
];
