import { observer } from 'mobx-react-lite';

import { Challenges } from '@trader/containers';
import { TChallengeEntity, useMst } from '@trader/store';
import { EChallenge, EChallengeStatus } from '@trader/types';

import { FailedChallengeInfoChip } from './failedChallengeInfoChip';
import * as Styled from './styled';

export const ChallengesList = observer(() => {
  const store = useMst();
  const activeChallenge = store.user.getActiveAccountChallenge();
  const isOneMasterAccount = store.entities.challenges
    .getAll<TChallengeEntity>()
    .some(ch => ch.hierarchyType === EChallenge.Master);

  return (
    <Styled.Root>
      {activeChallenge?.status === EChallengeStatus.Disable && (
        <FailedChallengeInfoChip />
      )}
      <Challenges
        shouldDisplayAccTypeIcon={isOneMasterAccount}
        shouldDisplayStatus
        shouldDisplayResult
        isFullHeight
      />
    </Styled.Root>
  );
});
