import { useMemo } from 'react';

import { TOptions } from '@trader/types';

export const useLeverageOptions = (appliedOption: number) => {
  return useMemo(() => getLeverageOptions(appliedOption), [appliedOption]);
};

export const getLeverageOptions = (appliedOption: number): TOptions => {
  const optionsList = String(import.meta.env.VITE_LEVERAGE_OPTIONS);

  const leverageOptions: TOptions = optionsList
    .split(',')
    .map(v => ({
      title: v,
      value: v,
      id: `leverage-option-${v}`,
    }))
    .filter(o => +o.value > appliedOption);

  if (!leverageOptions.some(o => +o.value === appliedOption)) {
    leverageOptions.push({
      title: `${appliedOption}`,
      value: `${appliedOption}`,
      id: `leverage-option-${appliedOption}`,
    });
  }

  const maxAvailableLeverage = Math.max(...leverageOptions.map(o => +o.value));
  const isMaxLeverageApplied = maxAvailableLeverage === appliedOption;

  return isMaxLeverageApplied ? [] : leverageOptions;
};
