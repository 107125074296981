import { TOptions } from '@trader/types';

export enum EFilterType {
  NUMBER,
  STRING,
  DATE,
}

export const filterStringOperators: TOptions = [
  { id: 'contains', value: 'contains', title: 'FILTERS.CONTAINS' },
  {
    id: 'notContain',
    value: 'notContain',
    title: 'FILTERS.NOT_CONTAIN',
  },
  { id: 'equals', value: 'equals', title: 'FILTERS.EQUALS' },
  {
    id: 'doesNotEquals',
    value: 'doesNotEquals',
    title: 'FILTERS.NOT_EQUALS',
  },
  {
    id: 'startsWith',
    value: 'startsWith',
    title: 'FILTERS.STARTS_WITH',
  },
  { id: 'endsWith', value: 'endsWith', title: 'FILTERS.ENDS_WITH' },
];

export const filterNumberOperators: TOptions = [
  { id: '=', value: '=', title: '=' },
  { id: '!=', value: '!=', title: '!=' },
  { id: '<', value: '<', title: '<' },
  { id: '>', value: '>', title: '>' },
  { id: '<=', value: '<=', title: '<=' },
  { id: '>=', value: '>=', title: '>=' },
];

const trimAndUpperCase = (value: string) => value.trim().toUpperCase();

const stringOperators = {
  contains: (fieldValue: string, filterValue: string) =>
    trimAndUpperCase(fieldValue).includes(trimAndUpperCase(filterValue)),
  notContain: (fieldValue: string, filterValue: string) =>
    !trimAndUpperCase(fieldValue).includes(trimAndUpperCase(filterValue)),
  equals: (fieldValue: string, filterValue: string) =>
    trimAndUpperCase(fieldValue) === trimAndUpperCase(filterValue),
  doesNotEquals: (fieldValue: string, filterValue: string) =>
    trimAndUpperCase(fieldValue) !== trimAndUpperCase(filterValue),
  startsWith: (fieldValue: string, filterValue: string) =>
    trimAndUpperCase(fieldValue).startsWith(trimAndUpperCase(filterValue)),
  endsWith: (fieldValue: string, filterValue: string) =>
    trimAndUpperCase(fieldValue).endsWith(trimAndUpperCase(filterValue)),
};

const numberOperators = {
  '=': (fieldValue: number, filterValue: string) =>
    fieldValue === parseFloat(filterValue),
  '!=': (fieldValue: number, filterValue: string) =>
    fieldValue !== parseFloat(filterValue),
  '<': (fieldValue: number, filterValue: string) =>
    fieldValue < parseFloat(filterValue),
  '>': (fieldValue: number, filterValue: string) =>
    fieldValue > parseFloat(filterValue),
  '<=': (fieldValue: number, filterValue: string) =>
    fieldValue <= parseFloat(filterValue),
  '>=': (fieldValue: number, filterValue: string) =>
    fieldValue >= parseFloat(filterValue),
};

export const allOperatorsCallbacks = { ...stringOperators, ...numberOperators };
