import { TChallengeEntity } from '@trader/store';
import { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { MobileChallenges } from './mobile';
import { DesktopChallenges } from './desktop';

export interface IChallenges {
  shouldTriggerGetChallenges?: boolean;
  leftAction?: (challengeId: number) => JSX.Element;
  rightAction?: (challengeId: number) => JSX.Element;
  rightActionWidth?: number;
  shouldDisplayAccTypeIcon?: boolean;
  isMasterEmpty?: boolean;
  shouldDisplayId?: boolean;
  shouldDisplayStatus?: boolean;
  shouldDisplayResult?: boolean;
  dividedChallenges?: TChallengeEntity[];
  isFullHeight?: boolean;
}

export const Challenges: FC<IChallenges> = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobileChallenges {...props} />
  ) : (
    <DesktopChallenges {...props} />
  );
};
