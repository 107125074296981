import React, { useState, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';

import {
  getTradingCentralIntraday,
  getTradingCentralPips,
  getTradingCentralValues,
  isValidDate,
} from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const TradingCentral: React.FC = observer(() => {
  const [isChartImageZoomed, setIsChartImageZoomed] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { translate } = useI18next();

  const store = useMst();
  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  const tradingCentral =
    store.entities.instruments.get<TInstrumentEntity>(symbol)?.tradingCentral;
  const tradingCentralValues = useMemo(() => {
    return getTradingCentralValues(tradingCentral);
  }, [symbol, tradingCentral]);

  const handleChartImageClick = () => {
    setIsChartImageZoomed(prev => !prev);
    store.ui.sidebar.closeRightBarOrderSection();
  };

  const renderPipsInfo = () => {
    const tcPips = getTradingCentralPips(tradingCentral);

    if (!tcPips) {
      return null;
    }

    const description = tcPips?.isRise
      ? translate('TRADING_CENTRAL.MAY_RISE')
      : translate('TRADING_CENTRAL.MAY_FALL');
    const pipsInfo = `${tcPips.pips1} - ${tcPips.pips2}`;
    const pipsLabel = translate('COMMON.LABELS.PIPS').toLowerCase();

    return (
      <Styled.Title marginTop='10px!important'>
        {`${symbol} ${description} ${pipsInfo} ${pipsLabel}`}
      </Styled.Title>
    );
  };

  const renderParagraph = (value: string, isBoldNumbers = false) => {
    const hasTitle = value.includes(':');
    const isDate = isValidDate(value);

    if (isDate) {
      return <b>{value}</b>;
    }

    if (hasTitle) {
      const [title, content] = value.split(':', 2);
      return (
        <>
          <b>{title}:</b>
          {isBoldNumbers ? renderWithBoldNumbers(content) : content}
        </>
      );
    }

    return isBoldNumbers ? renderWithBoldNumbers(value) : value;
  };

  const renderWithBoldNumbers = (text: string) => {
    return text.split(/(\d+)/).map((part, index) =>
      // eslint-disable-next-line react/no-array-index-key
      /\d+/.test(part) ? <b key={index}>{part}</b> : part
    );
  };

  const renderIntraday = () => {
    const intraday = getTradingCentralIntraday(tradingCentral);

    if (!intraday) {
      return null;
    }

    const greenColor = theme.palette.purchaseButtons.green;
    const redColor = theme.palette.purchaseButtons.red;
    const intradayColor = intraday.isUpside
      ? greenColor
      : intraday.isDownside
      ? redColor
      : undefined;

    return (
      <Styled.Paragraph $color={intradayColor}>
        {(intraday.isUpside || intraday.isDownside) && (
          <Styled.AboveBelowIcon
            iconType={intraday.isUpside ? 'abovePrice' : 'belowPrice'}
          />
        )}
        <b>{intraday.title}:</b>
        {intraday.description}
      </Styled.Paragraph>
    );
  };

  if (!tradingCentral) {
    return null;
  }

  if (!tradingCentral.feeds.length) {
    return (
      <Styled.Paragraph>
        {translate('COMMON.LABELS.INSTRUMENT_NOT_COVERED_TRADING_CENTRAL')}
      </Styled.Paragraph>
    );
  }

  return (
    <>
      {renderPipsInfo()}
      {renderIntraday()}
      <Styled.TradingCentralChartImage
        $isZoomed={isChartImageZoomed}
        src={
          isChartImageZoomed
            ? tradingCentral.feeds[0].largeImageUrl
            : tradingCentral.feeds[0].smallImageUrl
        }
        alt='trading-central-chart'
        onClick={isMobile ? undefined : handleChartImageClick}
      />
      <Styled.Information $isZoomed={isChartImageZoomed}>
        {tradingCentralValues.map((v, index) => (
          <Styled.Paragraph key={v}>
            {renderParagraph(v, index === 0)}
          </Styled.Paragraph>
        ))}
        <Styled.Title>{translate('COMMON.LABELS.LEGEND')}</Styled.Title>
        <Styled.Paragraph>
          <span className='resistance'>R1, R2, R3</span>{' '}
          {translate('CHART.LINES_REPRESENT_RESISTANCE')}
        </Styled.Paragraph>
        <Styled.Paragraph>
          <span className='support'>S1, S2, S3</span>{' '}
          {translate('CHART.LINES_REPRESENT_SUPPORT_LEVELS')}
        </Styled.Paragraph>
        <Styled.Paragraph>
          <span className='pivot'>{translate('COMMON.LABELS.PIVOT')}</span>{' '}
          {translate('CHART.PIVOT_LINE_IS_THE_PIVOT_POINT')}
        </Styled.Paragraph>
      </Styled.Information>
    </>
  );
});
