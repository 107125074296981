import { useMemo } from 'react';
import * as yup from 'yup';

import { useI18next } from '@trader/services';
import { useAmountSchema } from '@trader/hooks';
import { useMst } from '@trader/store';

export const useClosePositionFormValidation = (maxValue?: number) => {
  const store = useMst();
  const { currentLng, translate } = useI18next();

  const trading = store.trading.getTrading('modifyOrder');

  const { amountSchema } = useAmountSchema('modifyOrder', maxValue);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      amount: amountSchema.min(
        trading.step(),
        translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
          amount: trading.step(),
        })
      ),
    });
  }, [currentLng]);

  return { validationSchema };
};
