import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
  useGetTradingDefaultFormValues,
} from '@trader/hooks';
import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import {
  Form,
  ModalTitle,
  Progress,
  TradingIsClosed,
  Typography,
} from '@trader/components';
import {
  Protection,
  TradingRealTimeUpdatesInitializer,
  ConfirmButton,
  SideButtons,
} from '@trader/trading';
import { useI18next } from '@trader/services';
import { ETradeMode, IInitialTradingFormValues } from '@trader/types';

import {
  IPositionMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
import { useAddProtectionFormValidation } from './formValidation';

import * as Styled from './styled';

const Content: React.FC = observer(() => {
  const { translate } = useI18next();
  const { validationSchema } = useAddProtectionFormValidation();

  const store = useMst();
  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;
  const positionId = store.ui.modal.options.get('positionId');
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();
  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  const takeProfit = metric.takeProfit?.limitPrice || undefined;
  const stopLoss = metric.stopLoss?.stopPrice || undefined;
  const amount = metric.quantity || undefined;
  const defaultFormValues = useGetTradingDefaultFormValues('modifyOrder', {
    symbol: instrument.symbol,
    side: metric.side,
    takeProfit,
    stopLoss,
    amount,
  });

  const addProtection = (data: IInitialTradingFormValues) => {
    metric.editPositionAsync.run({
      positionId,
      body: {
        stopPrice: data.isStopLoss ? +data.stopLoss : 0,
        limitPrice: data.isTakeProfit ? +data.takeProfit : 0,
        symbol: instrument.symbol,
      },
    });
  };

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.ADD_PROTECTION')} />
      <Form<IInitialTradingFormValues>
        onSubmit={(_, data) => {
          addProtection(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
      >
        {({ formState }) => (
          <TradingRealTimeUpdatesInitializer type='modifyOrder'>
            <Styled.Position>
              <TradingModalHeader<IPositionMetricStore>
                type='position'
                metric={metric}
              />
              {!isInvestmentAccProduct && (
                <SideButtons type='modifyOrder' disabled />
              )}
              <Styled.Protection>
                <Protection
                  type='modifyOrder'
                  openPrice={String(metric.openPrice)}
                  fullWidth
                />
              </Styled.Protection>
              <ConfirmButton
                isDisabled={!formState.isValid}
                isLoading={metric?.editPositionAsync.inProgress}
                title={
                  metric.strategy?.id
                    ? translate('MULI_BANDS.SWITCH_MODE_POSITION')
                    : translate('COMMON.LABELS.ADD_PROTECTION')
                }
              />
            </Styled.Position>
          </TradingRealTimeUpdatesInitializer>
        )}
      </Form>
      {metric.strategy?.id && (
        <Styled.SwitchStrategyModeTitle>
          {translate('MULI_BANDS.SWITCH_MODE_POSITION_INFO')}
        </Styled.SwitchStrategyModeTitle>
      )}
    </Styled.Root>
  );
});

export const AddProtection: React.FC = observer(() => {
  useCloseModalForAlreadyDeletedInstance('positionId');

  const { translate } = useI18next();
  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const store = useMst();
  const trading = store.trading.getTrading('modifyOrder');
  const instrument = trading.instrument as TInstrumentEntity;
  const positionId = store.ui.modal.options.get('positionId');
  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  if (!metric) {
    return null;
  }

  if (applyConditionThird().status === 'failed') {
    return (
      <Styled.Root paddingTop='16px'>
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_IS_DISABLED')}
          content={
            <div>
              <Typography>
                {translate('WARNINGS.PLEASE_CONTACT_SUPPORT')}
              </Typography>
              <a
                href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
                style={{ color: 'inherit' }}
              >
                {import.meta.env.VITE_SUPPORT_EMAIL}
              </a>
            </div>
          }
          buttonContent={translate('COMMON.LABELS.PROCEED')}
          onClick={applyConditionThird()?.action}
        />
      </Styled.Root>
    );
  }

  if (instrument.tradeMode === ETradeMode.CloseOnly) {
    return (
      <Styled.Root paddingTop='32px'>
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_LIMITED_TO_CLOSE_ONLY_POSITIONS')}
        />
      </Styled.Root>
    );
  }

  return <Content />;
});
