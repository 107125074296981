import { IInitializeOutput } from './types';
import { indexDBName, stores } from './constants';

export const initialize = (): Promise<IInitializeOutput> =>
  new Promise(resolve => {
    const request = indexedDB.open(indexDBName);

    request.onupgradeneeded = () => {
      const db = request.result;

      stores.forEach(store => {
        if (!db.objectStoreNames.contains(store.id)) {
          db.createObjectStore(store.id, store.options);
        }
      });
    };

    request.onsuccess = () => {
      resolve({
        readyState: request.readyState,
        version: request.result.version,
      });
    };

    request.onerror = error => {
      console.error('IndexedDB Initializing Error:', error);
      resolve({
        readyState: request.readyState,
        version: request.result.version,
      });
    };
  });
