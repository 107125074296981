import React, { useState } from 'react';
import {
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { useDebounce, useNavigation } from '@trader/hooks';
import { IOption } from '@trader/types';

import * as Styled from './styled';
import { NAVIGATE_TO } from '@trader/constants';

interface ISearchInstrument {
  closeSearching?: () => void;
  onClose?: () => void;
  onChangeSymbol?: (symbol: string) => void;
  placeholder?: string;
  searchCategory?: string;
  isMuliBandsStrategy?: boolean;
}

interface IInitialState {
  value: string;
  isOpen: boolean;
  isFocused: boolean;
  options: Array<IOption>;
}

const initialState: IInitialState = {
  value: '',
  isOpen: false,
  isFocused: false,
  options: [],
};

export const SearchInstrument: React.FC<ISearchInstrument> = React.memo(
  ({
    closeSearching,
    onClose,
    placeholder,
    searchCategory,
    isMuliBandsStrategy,
    onChangeSymbol,
  }) => {
    const { translate } = useI18next();
    const store = useMst();
    const theme = useTheme();
    const { navigateTo } = useNavigation();
    const [state, setState] = useState<IInitialState>(initialState);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useDebounce(async () => {
      if (!state.isFocused) {
        return;
      }

      if (isMuliBandsStrategy || state.value || state.options.length) {
        const instruments =
          await store.entities.instruments.getInstrumentsSearchAsync.run({
            value: state.value,
            category: searchCategory,
            isStrategy: isMuliBandsStrategy,
          });

        setState(prev => ({
          ...prev,
          isOpen: true,
          options: instruments?.length
            ? instruments?.map(i => ({ title: i, value: i }))
            : [],
        }));
      }
    }, [state.value, state.isFocused]);

    const handleSearch = (value: string) => {
      setState(prev => ({ ...prev, value }));
    };

    const selectInstrument = async (symbol?: string) => {
      if (symbol) {
        onClose && onClose();

        store.ui.tradingView.toggleIsChartLoaded(false);

        const instrumentSpec =
          await store.pages.trading.getInstrumentSpecificationAsync.run(symbol);
        closeSearching && closeSearching();

        if (!instrumentSpec) {
          return;
        }

        !isMuliBandsStrategy &&
          store.pages.trading.layout.selectInstrument(instrumentSpec.symbol);
        onChangeSymbol && onChangeSymbol(symbol);

        setState(initialState);
        isMobile && navigateTo(NAVIGATE_TO.main.trading);
      }
    };

    return (
      <Styled.Root
        onClick={e => e.stopPropagation()}
        className='search-instruments'
        $isStatic={!closeSearching}
      >
        <Styled.InputWrapper $isStatic={!closeSearching}>
          {closeSearching && (
            <Styled.Close iconType='remove' onClick={closeSearching} />
          )}
          <Autocomplete<IOption>
            fullWidth
            noOptionsText={translate('COMMON.LABELS.NO_OPTIONS')}
            loadingText={translate('COMMON.LABELS.LOADING')}
            forcePopupIcon={false}
            key={
              !closeSearching
                ? String(
                    store.pages.trading.getInstrumentSpecificationAsync
                      .inProgress
                  )
                : ''
            }
            blurOnSelect
            clearOnBlur
            open={state.isOpen}
            onFocus={() => setState(prev => ({ ...prev, isFocused: true }))}
            onBlur={() => setState(prev => ({ ...prev, isFocused: false }))}
            onClose={() => {
              setState(prev => ({ ...prev, isOpen: false }));
            }}
            isOptionEqualToValue={(option, value) =>
              option.title === value.title
            }
            getOptionLabel={option => option.title}
            options={state.options}
            loading={
              store.entities.instruments.getInstrumentsSearchAsync.inProgress
            }
            onChange={(_, option) => selectInstrument(option?.value)}
            renderInput={params => (
              <TextField
                {...params}
                autoFocus={!!closeSearching}
                onChange={event => handleSearch(event.target.value)}
                label={
                  closeSearching
                    ? translate('COMMON.LABELS.SEARCH_ALL_INSTRUMENTS')
                    : ''
                }
                placeholder={
                  !closeSearching
                    ? placeholder || translate('COMMON.LABELS.SEARCH')
                    : ''
                }
                className='Mui-Autocomplete-search-field'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <Styled.Search iconType='search' />,
                }}
              />
            )}
          />
        </Styled.InputWrapper>
      </Styled.Root>
    );
  }
);
