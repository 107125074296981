import React from 'react';
import { useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { Typography, Stack } from '@trader/components';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import {
  fixToTwoDigitAfterDot,
  formatAccountCurrencyValue,
} from '@trader/utils';

import { PerformanceSummaryCard } from './performanceSummaryCard';
import { ProfitSummaryChart } from './profitSummaryChart';
import * as Styled from './styled';

const fullyLoaded = 100;

export const PerformanceSummary: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const theme = useTheme();

  const backTesting = store.pages.muliBands.backTesting;
  const summaryData = backTesting?.getSummaryDataByAllMultipliers();
  const takeProfit = backTesting?.summary?.totalProfit || 0;
  const avgProfit = backTesting?.summary?.avgProfit || 0;
  const maxProfit = backTesting?.summary?.maxProfit || 0;
  const numberOfTrades = backTesting?.summary?.numberOfTrades || 0;
  const currencySymbol = store.user.tradingAccount?.currencySymbol || '';
  const percentFinished =
    +store.pages.muliBands.backTesting.percentFinished.toFixed();

  const showCharts =
    percentFinished === fullyLoaded && !backTesting.isPending && summaryData;

  const generalSummaries = [
    {
      label: translate('COMMON.LABELS.TAKE_PROFIT'),
      value: formatAccountCurrencyValue(takeProfit, currencySymbol),
      profit: takeProfit,
    },
    {
      label: translate('MULI_BANDS.AVERAGE_PROFIT'),
      value: formatAccountCurrencyValue(avgProfit, currencySymbol),
      profit: avgProfit,
    },
    {
      label: translate('MULI_BANDS.MAX_PROFIT'),
      value: formatAccountCurrencyValue(maxProfit, currencySymbol),
      profit: maxProfit,
    },
    {
      label: translate('MULI_BANDS.NUMBER_OF_TRADES'),
      value: +fixToTwoDigitAfterDot(numberOfTrades),
    },
  ];

  return (
    <>
      <Stack
        direction='row'
        spacing={4}
        p={3}
        borderBottom={`1px solid ${theme.palette.background.default}`}
      >
        {generalSummaries.map(gm => (
          <Styled.Summary key={gm.label} $profit={gm.profit}>
            <Typography>{gm.label}</Typography>
            <Typography>{gm.value}</Typography>
          </Styled.Summary>
        ))}
      </Stack>
      {showCharts && (
        <>
          <ProfitSummaryChart
            chartLegend={[
              {
                label: translate('COMMON.LABELS.TOTAL'),
                value: takeProfit,
                type: 'total',
              },
              {
                label: translate('COMMON.LABELS.MAX'),
                value: maxProfit,
                type: 'max',
              },
            ]}
            chartData={summaryData.chartData}
          />
          <Styled.Summaries>
            <PerformanceSummaryCard
              type='days'
              items={[
                {
                  label: translate('COMMON.LABELS.PROFIT'),
                  value: summaryData.days.profitDays,
                },
                {
                  label: translate('COMMON.LABELS.LOSS'),
                  value: summaryData.days.lossDays,
                },
                {
                  label: translate('MULI_BANDS.WO_HIT'),
                  value: summaryData.days.daysWoHit,
                },
              ]}
            />
            <PerformanceSummaryCard
              type='vwap'
              items={[
                {
                  label: translate('MULI_BANDS.RETURNED'),
                  value: summaryData.vwap.returnedToVwapDays,
                },
                {
                  label: translate('MULI_BANDS.NO_RETURNED'),
                  value: summaryData.vwap.notReturnedToVwapDays,
                },
                {
                  label: translate('MULI_BANDS.RETURNED_WITH_LOSS'),
                  value: summaryData.vwap.returnedToVwapWithLoss,
                },
              ]}
            />
            <PerformanceSummaryCard
              type='consecutiveDays'
              items={[
                {
                  label: translate('COMMON.LABELS.WIN'),
                  value: summaryData.consecutiveDays.maxConsecutiveWinDays,
                },
                {
                  label: translate('COMMON.LABELS.LOSS'),
                  value: summaryData.consecutiveDays.maxConsecutiveLossDays,
                },
                {
                  label: translate('COMMON.LABELS.SL'),
                  value: summaryData.consecutiveDays.maxConsecutiveSlDays,
                },
              ]}
            />
            <PerformanceSummaryCard
              type='lowerBand'
              items={[
                {
                  label: translate('MULI_BANDS.RETURNED_WITH_LOSS'),
                  value: summaryData.lowerBand.returnedToLowerBandWithLoss,
                },
              ]}
            />
          </Styled.Summaries>
        </>
      )}
    </>
  );
});
