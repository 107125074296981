import React from 'react';

import { Typography } from '@trader/components';
import { useI18next } from '@trader/services';
import { TAvailableChallengeBundle } from '@trader/store';

import * as Styled from './styled';

interface IPurchaseChallengeBundles {
  bundles: TAvailableChallengeBundle[];
  selectedBundle?: TAvailableChallengeBundle;
  onBundleSelect: (bundle?: TAvailableChallengeBundle) => void;
}

export const PurchaseChallengeBundles: React.FC<IPurchaseChallengeBundles> = ({
  bundles,
  selectedBundle,
  onBundleSelect,
}) => {
  const { translate } = useI18next();

  const defaultBundle = {
    quantity: 1,
    price: 0,
    isXStrategyPurchase: false,
  };

  return (
    <div>
      <Typography variant='large'>
        {translate('PURCHASE_CHALLENGES.BUNDLES')}
      </Typography>
      <Styled.Bundles direction='row' spacing='6px'>
        {[defaultBundle, ...bundles].map(bundle => (
          <Styled.Bundle
            key={bundle.quantity}
            $selected={bundle.quantity === (selectedBundle?.quantity || 1)}
            $withAsterisk={bundle.isXStrategyPurchase}
            onClick={() =>
              onBundleSelect(bundle.quantity === 1 ? undefined : bundle)
            }
          >
            x{bundle.quantity}
          </Styled.Bundle>
        ))}
      </Styled.Bundles>
      {bundles.some(bundle => bundle.isXStrategyPurchase) && (
        <Styled.BundleAddition variant='medium'>
          {translate('PURCHASE_CHALLENGES.INCLUDE_TOOL', {
            toolName: 'XStrategy',
          })}
        </Styled.BundleAddition>
      )}
    </div>
  );
};
