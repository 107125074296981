import { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { ModalTitle, Select } from '@trader/components';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import { ConfirmButton } from '../components/confirmButton';
import { useLeverageOptions } from './useLeverageOptions';
import * as Styled from './styled';

export const ChangeLeverage = observer(() => {
  const { translate } = useI18next();

  const store = useMst();
  const tradingId = store.ui.modal.options.get('tradingId');
  const account =
    store.entities.tradingAccounts.get<TTradingAccountEntity>(tradingId);

  const [selectedLeverage, setSelectedLeverage] = useState<number>(
    account?.leverage || 0
  );
  const leverageOptions = useLeverageOptions(account?.leverage || 0);

  if (!account) {
    return null;
  }

  if (!leverageOptions.length) {
    return (
      <Styled.Root>
        <ModalTitle title={translate('TRADING.CHANGE_LEVERAGE')} />
        <Styled.MaxLeverageText variant='large'>
          {translate('TRADING.MAX_LEVERAGE_SELECTED')}
        </Styled.MaxLeverageText>
      </Styled.Root>
    );
  }

  const handleChangeLeverage = (value: number) => {
    setSelectedLeverage(value);
  };

  const handleApplyLeverage = async () => {
    await store.entities.tradingAccounts.changeLeverageAsync.run({
      tradingId,
      leverage: selectedLeverage,
    });
    await store.entities.tradingAccounts.getTradingAccountsAsync.run();
    store.ui.modal.close();
  };

  return (
    <Styled.Root>
      <ModalTitle title={translate('TRADING.CHANGE_LEVERAGE')} />
      <Styled.SelectWrapper>
        <Select
          label={translate('TRADING.LEVERAGE')}
          options={leverageOptions}
          value={leverageOptions.filter(o => +o.value === selectedLeverage)}
          onChange={v => handleChangeLeverage(+v.target.value[0].value)}
        />
      </Styled.SelectWrapper>
      <ConfirmButton
        isDisabled={
          store.entities.tradingAccounts.changeLeverageAsync.inProgress ||
          store.entities.tradingAccounts.getTradingAccountsAsync.inProgress ||
          selectedLeverage === account?.leverage
        }
        isLoading={
          store.entities.tradingAccounts.changeLeverageAsync.inProgress ||
          store.entities.tradingAccounts.getTradingAccountsAsync.inProgress
        }
        onClick={handleApplyLeverage}
        title={translate('COMMON.LABELS.APPLY')}
      />
    </Styled.Root>
  );
});
