import { useCallback } from 'react';
import * as yup from 'yup';

import {
  analyzeHourRanges,
  convertDateToUTC,
  getInstrumentTradingHours,
  isValueTimesStep,
} from '@trader/utils';
import { useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { maxMultiplier, maxSl, minMultiplier } from '@trader/constants';

const minStopLoss = 1;

export const useOrderFormValidation = () => {
  const store = useMst();
  const { translate } = useI18next();

  const trading = store.trading.getTrading('createStrategyBand');

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    backTesting.getSymbol()
  );

  const SlValidation = useCallback((isRequired: boolean, value?: number) => {
    if (!isRequired) {
      return true;
    }

    if (!value) {
      return false;
    }

    return +value >= minStopLoss;
  }, []);

  return yup.object().shape({
    orderAmount: yup
      .number()
      .test(
        'increment',
        translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
          amount: trading.step(),
        }),
        value => !!value && isValueTimesStep(value, trading.step())
      )
      .required(
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: trading.minAmount(),
        })
      )
      .max(
        trading.maxAmount(),
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: trading.maxAmount(),
        })
      )
      .min(
        trading.minAmount(),
        translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
          amount: trading.step(),
        })
      )
      .typeError(
        translate('COMMON.ERRORS.MUST_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
          amount: trading.step(),
        })
      ),
    topMultiplier: yup
      .number()
      .typeError(
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: minMultiplier,
        })
      )
      .min(
        minMultiplier,
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: minMultiplier,
        })
      )
      .max(
        maxMultiplier,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: maxMultiplier,
        })
      ),
    bottomMultiplier: yup
      .number()
      .typeError(
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: minMultiplier,
        })
      )
      .min(
        minMultiplier,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: minMultiplier,
        })
      )
      .max(
        maxMultiplier,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: maxMultiplier,
        })
      ),
    sl: yup
      .number()
      .typeError(
        translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
          amount: minStopLoss,
        })
      )
      .max(
        maxSl,
        translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
          amount: maxSl,
        })
      )
      .when(['isSl'], ([isSl], schema) => {
        return schema.test(
          'sl',
          translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
            amount: minStopLoss,
          }),
          value => SlValidation(isSl, value)
        );
      }),
    openTime: yup
      .date()
      .typeError('Invalid time')
      .when(['isOpenTime'], ([isOpenTime], schema) => {
        return schema.test(
          'openTime',
          translate('MULI_BANDS.OUT_OF_TRADING_HOURS'),
          value => {
            if (!isOpenTime) {
              return true;
            }

            if (!value) {
              return false;
            }

            const convertedDate = new Date(convertDateToUTC(value));
            const instrumentTradingHours = getInstrumentTradingHours(
              instrument.sessions,
              convertedDate
            );
            const analyzedTradingHours = analyzeHourRanges(
              instrumentTradingHours,
              convertedDate
            );

            return analyzedTradingHours.hasMatchedWithCurrentDate;
          }
        );
      }),
  });
};
