import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${() => css`
    height: 100%;
    padding: 6px 18px 18px 18px;
    flex-direction: column;
    justify-content: space-between;

    form {
      height: 100%;
    }
  `}
`;

export const FormContent = styled(Wrapper)`
  ${({ theme }) => css`
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const Inputs = styled('div')`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      .MuiFormControl-root {
        width: 100%;
      }

      label {
        font-size: ${theme.typography.large.fontSize};
      }
    }
  `}
`;

export const Field = styled(Wrapper)`
  ${({ theme }) => css`
    align-items: baseline;
    margin-bottom: 10px;

    > div > div:nth-child(2) {
      width: 320px;
      height: 55px;
    }

    ${theme.breakpoints.down('sm')} {
      > div > div:nth-child(2) {
        width: 100%;
        height: 55px;
      }
    }
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    width: 220px;
    font-size: ${theme.typography.large.fontSize};

    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `}
`;
