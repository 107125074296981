import React, { useEffect } from 'react';
import { useMst } from '@trader/store';

import {
  IChartingLibraryWidget,
  ResolutionString,
  TimeFramePeriodBack,
} from '../../charting_library';

import { useUpdateMultipliers } from './useUpdateMultipliers';
import { useDataBox } from './useDataBox';
import { useStrips } from './useStrips';
import { useOrders } from './useOrders';
import { usePositions } from './usePositions';
import { useListOfTrades } from './useListOfTrades';
import { invertedResolutions } from '../../datafeed';

export const useMuliBands = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>,
  isMuliBands?: boolean
) => {
  const store = useMst();
  const idToken = store.auth.tokens.idToken;
  const symbol = store.pages.muliBands.symbol;
  const indicators = store.pages.muliBands.indicators;

  const { clearStrips } = useStrips(widget);
  const { clearOrders } = useOrders(widget);
  const { clearPositions } = usePositions(widget);

  useUpdateMultipliers(widget, isMuliBands);
  useDataBox(widget, isMuliBands);
  useListOfTrades(widget);

  useEffect(() => {
    widget?.current?.onChartReady(() => {
      setTimeout(() => {
        indicators.forEach(indicator => {
          const study = widget?.current
            ?.activeChart()
            .getAllStudies()
            .find(s => s.name === indicator.name);
          if (indicator.isActive) {
            !study &&
              widget?.current?.activeChart().createStudy(indicator.name);
          } else {
            study && widget?.current?.activeChart().removeEntity(study.id);
          }
        });
      });
    });
  }, [JSON.stringify(indicators)]);

  useEffect(() => {
    widget?.current?.onChartReady(() => {
      widget?.current?.subscribe('study_event', (studyId, event) => {
        indicators.forEach(indicator => {
          const study = widget?.current
            ?.activeChart()
            .getAllStudies()
            .find(s => s.name === indicator.name);

          // set isActive to true
          if (event === 'remove' && indicator.isActive && !study) {
            indicator.toggleActive();
          }

          // set isActive to false
          if (event === 'price_scale_changed' && !indicator.isActive && study) {
            indicator.toggleActive();
          }
        });
      });
    });
  }, []);

  useEffect(() => {
    return () => {
      clearOrders();
      clearStrips();
      clearPositions();
    };
  }, [symbol, idToken]);

  useEffect(() => {
    widget?.current?.onChartReady(() => {
      widget?.current
        ?.activeChart()
        ?.onSymbolChanged()
        .subscribe(null, () => {
          widget?.current?.activeChart().setTimeFrame({
            val: {
              type: 'period-back' as TimeFramePeriodBack['type'],
              value: '1M',
            },
            res: invertedResolutions.Minute2 as ResolutionString,
          });
        });
    });
  }, []);
};
