import React from 'react';

import { IChangeFieldEvent, TOrderVariation } from '@trader/types';
import { Icon, Typography } from '@trader/components';

import * as Styled from './styled';

export interface ITradingInput {
  value?: string;
  step?: number;
  isError?: boolean;
  helperText?: string;
  name?: string;
  onChange?: (event: IChangeFieldEvent) => void;
  onFocus?: () => void;
  placeholder?: string;
  fixDigitAfterDot?: number;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  shouldCheckActivityOfButtons?: boolean;
  isDependOnDigitsStep?: boolean;
  middleComponent?: React.ReactNode;
  customLabel?: React.ReactNode | string;
  isPending?: boolean;
  fullWidth?: boolean;
}

const maxInputCharacters = 12;

export const TradingInput: React.FC<ITradingInput> = React.forwardRef<
  HTMLInputElement,
  ITradingInput
>(
  (
    {
      value,
      onChange,
      step = 1,
      disabled,
      fixDigitAfterDot = 0,
      isError,
      helperText,
      minValue = 0,
      maxValue = 0,
      placeholder,
      name = '',
      shouldCheckActivityOfButtons,
      onFocus,
      middleComponent,
      customLabel = '',
      isPending,
      fullWidth,
    },
    ref
  ) => {
    const handleChange = (v: string): void => {
      if (v.length >= maxInputCharacters) {
        return;
      }
      const replacedValue = v.replace(',', '.');
      const numberRegex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;
      const fixDigit = !fixDigitAfterDot ? 0 : fixDigitAfterDot + 1;
      if (!replacedValue) {
        onChange && onChange({ target: { value: '', name } });
      }

      if (numberRegex.test(replacedValue)) {
        if (replacedValue.includes('.')) {
          const findIndex = replacedValue.indexOf('.');
          if (replacedValue.length <= findIndex + fixDigit) {
            onChange && onChange({ target: { value: replacedValue, name } });
          }
        } else {
          onChange && onChange({ target: { value: replacedValue, name } });
        }
      }
    };

    const handlePressBtn = (variation: TOrderVariation): void => {
      onFocus && onFocus();

      const v =
        variation === 'minus' ? Number(value) - step : Number(value) + step;
      const formatValue = Number(v).toFixed(fixDigitAfterDot);
      onChange && onChange({ target: { value: formatValue, name } });
    };

    return (
      <Styled.RootInput $fullWidth={!!fullWidth}>
        <Styled.Main $isPending={isPending} $fullWidth={!!fullWidth}>
          <Styled.Content
            $isMiddleComponent={!!middleComponent}
            $fullWidth={!!fullWidth}
          >
            <Typography className='tradingInput-customLabel'>
              {customLabel}
            </Typography>
            <Styled.Input
              type='number'
              ref={ref}
              value={value}
              onFocus={onFocus}
              placeholder={placeholder}
              disabled={disabled || isPending}
              onChange={event => handleChange(event.target.value)}
            />
          </Styled.Content>
          {middleComponent && middleComponent}
          <Styled.Buttons>
            <Styled.ButtonAction
              disabled={
                disabled ||
                (shouldCheckActivityOfButtons && Number(value) >= maxValue) ||
                isPending
              }
              onClick={() => handlePressBtn('plus')}
            >
              <Icon iconType='plus' />
            </Styled.ButtonAction>
            <Styled.ButtonAction
              disabled={
                disabled ||
                (shouldCheckActivityOfButtons &&
                  (!value || Number(value) <= minValue)) ||
                isPending
              }
              onClick={() => handlePressBtn('minus')}
            >
              <Icon iconType='minus' />
            </Styled.ButtonAction>
          </Styled.Buttons>
        </Styled.Main>
        {isError && <Styled.InputError>{helperText}</Styled.InputError>}
      </Styled.RootInput>
    );
  }
);
