import { IFullParams, IUpdateOutput } from './types';
import { indexDBName } from './constants';

export const add = <T>({
  data,
  version,
  storeName,
  key,
}: IFullParams<T>): Promise<IUpdateOutput> =>
  new Promise(resolve => {
    const request = indexedDB.open(indexDBName, version);

    request.onsuccess = () => {
      // Check if the store exists before performing any transaction
      if (!request.result.objectStoreNames.contains(storeName)) {
        console.error(`Store "${storeName}" not found in the DB!`);
        resolve({
          isSuccess: false,
          message: `Store "${storeName}" not found`,
        });
        return;
      }

      try {
        const tx = request.result.transaction(storeName, 'readwrite');
        const store = tx.objectStore(storeName);

        const isExist = key ? store.get(key) : null;

        if (isExist) {
          store.put(data, key);
        } else {
          store.add(data, key);
        }

        tx.oncomplete = () => {
          resolve({ isSuccess: true });
        };

        tx.onerror = err => {
          console.error(`Transaction failed for store "${storeName}"`, err);
          resolve({ isSuccess: false, message: 'Transaction failed' });
        };
      } catch (error) {
        console.error(
          `Error in adding/updating data in store "${storeName}"`,
          error
        );
        resolve({ isSuccess: false, message: 'Error during operation' });
      }
    };

    request.onerror = err => {
      console.error('Opening IndexedDB Error while trying to ADD data:', err);
      resolve({ isSuccess: false, message: 'Error opening IndexedDB' });
    };
  });
