import { Instance, types } from 'mobx-state-tree';

import {
  EScheduledOrderStatus,
  EScheduledOrderFailedReason,
  EStripName,
  TPlaceOrderSide,
} from '@trader/types';
import { runInAction } from '@trader/utils';

import { getScheduledOrdersAsync } from './actions';
import { createCollectionModel } from '../../utils/collectionModel';

export const scheduledOrderModel = types
  .model('scheduledOrderModel', {
    id: types.identifier,
    strategyId: types.number,
    bandId: types.frozen<EStripName>(),
    quantity: types.number,
    stopLoss: types.maybeNull(types.number),
    placeDate: types.string,
    symbol: types.string,
    side: types.frozen<TPlaceOrderSide>('Buy'),
    upFloatMultiplier: types.number,
    downFloatMultiplier: types.number,
    failedReason: types.maybeNull(
      types.union(
        types.literal(EScheduledOrderFailedReason.ApiError),
        types.literal(EScheduledOrderFailedReason.InvalidSide)
      )
    ),
    failedDescription: types.maybeNull(types.string),
    status: types.union(types.literal(EScheduledOrderStatus.Created)),
    iconUrl: types.maybeNull(types.string),
  })
  .views(() => ({}))
  .actions(() => ({
    runInAction,
  }));

interface IScheduledOrderAsync {
  getScheduledOrdersAsync: typeof getScheduledOrdersAsync;
}

export const scheduledOrders = createCollectionModel<
  typeof scheduledOrderModel,
  IScheduledOrderAsync
>(scheduledOrderModel, {
  getScheduledOrdersAsync,
});

export type TScheduledOrderEntity = Instance<typeof scheduledOrderModel>;
