import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { BottomBar, Modals, TopBar } from '@trader/containers';

import * as DesktopStyled from './desktop.styled';
import * as Styled from './mobile.styled';

export const MobileUserDetailsLayout: React.FC<{
  titles: Record<string, string>;
  children?: React.ReactNode;
}> = ({ titles, children }) => {
  const location = useLocation();

  return (
    <DesktopStyled.Root>
      <TopBar />
      <DesktopStyled.Main>
        <DesktopStyled.Content>
          <Styled.Title>{titles[location.pathname]}</Styled.Title>
          <DesktopStyled.Outlet>{children || <Outlet />}</DesktopStyled.Outlet>
        </DesktopStyled.Content>
      </DesktopStyled.Main>
      <BottomBar />
      <Modals />
    </DesktopStyled.Root>
  );
};
