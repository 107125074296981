import React from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { Controller, TradingIsClosed, Wrapper } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';
import { getTradingAmountTranslationKey } from '@trader/utils';
import { ICommonOrderType } from '@trader/types';
import { useI18next } from '@trader/services';

import { PlaceOrderButton } from '../PlaceOrderButton';
import { PurchaseDetails } from '../PurchaseDetails';
import { CostAndCharges } from '../CostAndCharges';
import { SideButtons } from '../sideButtons';
import { Protection } from '../Protection';
import { BuyButton } from '../buyButton';

import * as Styled from './styled';

export const MarketOrder: React.FC<ICommonOrderType> = observer(({ type }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { control, register, formState } = useFormContext();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;
  const freeMargin = store.user.portfolio().freeMargin;
  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  if (instrument.tradingAvailability?.isUnavailable) {
    return (
      <Wrapper>
        <TradingIsClosed
          title={translate('COMMON.LABELS.MARKETS_CLOSED')}
          iconTypeName='warning'
          content={`${translate(
            'COMMON.LABELS.MARKETS_ARE_CURRENTLY_CLOSED'
          )} ${
            !isInvestmentAccProduct
              ? translate('COMMON.LABELS.YOU_CAN_PLACE_A_LIMIT_STOP_ORDER')
              : ''
          }`}
        />
      </Wrapper>
    );
  }

  return (
    <React.Fragment>
      {isInvestmentAccProduct && (
        <Styled.DmaHeader>
          <BuyButton
            type={type}
            side='Buy'
            isDisabled={!instrument.allowedOperations().buy.open}
          />
        </Styled.DmaHeader>
      )}
      <React.Fragment>
        <Styled.Root>
          {!isInvestmentAccProduct && <SideButtons type={type} />}
          <Styled.Main $isInvestmentAccProduct={isInvestmentAccProduct}>
            <Controller
              type='tradingInput'
              shouldCheckActivityOfButtons
              customLabel={translate(
                getTradingAmountTranslationKey(trading.tradingType)
              )}
              disabled={instrument.tradingAvailability.isUnavailable}
              minValue={trading.minAmount()}
              maxValue={trading.maxAmount()}
              fixDigitAfterDot={trading.amountDigitsAfterDot}
              step={trading.step()}
              onCustomChange={() =>
                !trading.side &&
                instrument.allowedOperations().buy.open &&
                trading.updateSide('Buy')
              }
              control={control}
              {...register('amount')}
            />
          </Styled.Main>
          <PurchaseDetails type={type} />
        </Styled.Root>
        <Protection type={type} />
        {isInvestmentAccProduct && (
          <Styled.FreMargin>
            {translate('COMMON.LABELS.FREE_BALANCE')}:{' '}
            {instrument.currencySymbol}
            {freeMargin}
          </Styled.FreMargin>
        )}
        <PlaceOrderButton
          type={type}
          isDisabled={!formState.isValid || trading.isRequiredOpenCostError}
        />
        {import.meta.env.VITE_INSTRUMENT_HAS_COST_AND_CHARGES &&
          !isInvestmentAccProduct && <CostAndCharges type={type} />}
      </React.Fragment>
    </React.Fragment>
  );
});
