import { EChallengeResult, EChallengeStatus } from './challenges';

export enum ESocketUpdateAction {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

export interface IPositionMessage {
  id: number;
  symbol: string;
  comment: string;
  currency: string;
  brand: number;
  platformLogin: number;
  openPrice: number;
  side: number;
  price: number;
  profit: number;
  stopLoss: number;
  takeProfit: number;
  updateAction: ESocketUpdateAction;
  volume: number;
}

export interface IOrderMessage {
  id: number;
  symbol: string;
  comment: string;
  currency: string;
  brand: number;
  platformLogin: number;
  positionId: number;
  limitPrice: number | null;
  stopPrice: number | null;
  side: number;
  stopLoss: number;
  takeProfit: number;
  updateAction: ESocketUpdateAction;
  volume: number;
  volumeCurrent: number;
}

export interface ITradingAccountMessage {
  platformLogin: string;
  product: string;
  accountType: number;
  currency: string;
  currencySymbol: string;
  tradingId: number;
  isDepositAllowed: boolean;
  isTradingAllowed: boolean;
  updateAction: ESocketUpdateAction;
}

export interface ICustomerAccountMessage {
  isRegistrationCompleted: boolean;
}

export interface IChallengeMessage {
  id: number;
  status: EChallengeStatus;
  amount: number | null;
  accountId: number;
  result: EChallengeResult;
  payout: number | null;
  tradingDays: number | null;
  minTradingDays: number | null;
  remainingTime: number | null;
  updateAction: ESocketUpdateAction;
  blockedDueToDailyTarget: boolean;
}

export interface ITradeResultMessage {
  updateAction: number;
  id: number;
  retCode: number;
  platformLogin: number;
  brand: string;
  orderId: number;
  volume: number;
  volumeCurrent: number;
  resultPrice: number;
  symbol: string;
  billType: string;
  comment: string;
}
