import { requestService, TAsyncActionOptions } from '@trader/services';
import { paymentRoutes } from './routes';
import {
  IMakeDepositBody,
  IMakeDepositResponse,
  ITransactionsBE,
  ITransferParamsBE,
  ITransfersHistoryBE,
  IPspsResponseData,
  IGetPspsParams,
  IPurchasesHistoryBE,
  IPurchaseParamsBE,
  IGetAvailablePaymentMethodsParams,
  IAvailablePaymentMethodBE,
  IGetDepositLinkBody,
  IValidateCouponParams,
  IValidateCouponResponse,
} from './models';

export class Payment {
  routes = paymentRoutes;

  async makeDeposit(
    body: IMakeDepositBody,
    options: TAsyncActionOptions
  ): Promise<IMakeDepositResponse> {
    const resp = await requestService.post<IMakeDepositResponse>(
      this.routes.makeDeposit,
      body,
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async getPsps(
    params: IGetPspsParams,
    options: TAsyncActionOptions
  ): Promise<IPspsResponseData> {
    const resp = await requestService.get<IPspsResponseData>(
      this.routes.getPsps(params),
      {
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }

  async getAvailablePaymentMethods(
    params: IGetAvailablePaymentMethodsParams,
    options: TAsyncActionOptions
  ) {
    const resp = await requestService.get<{
      providers: IAvailablePaymentMethodBE[];
    }>(this.routes.getAvailablePaymentMethods, {
      params,
      ...options.cancelToken,
    });

    return resp.data.providers;
  }

  async getDepositLink(
    body: IGetDepositLinkBody,
    options: TAsyncActionOptions
  ) {
    const resp = await requestService.post<{ returnUrl: string }>(
      this.routes.getDepositLink,
      body,
      {
        ...options.cancelToken,
      }
    );

    return resp.data.returnUrl;
  }

  async getTransactions(
    options: TAsyncActionOptions
  ): Promise<ITransactionsBE> {
    const resp = await requestService.get<ITransactionsBE>(
      this.routes.getTransactions,
      {
        ...options.cancelToken,
      }
    );
    return resp?.data;
  }

  async getTransfersHistory(
    params: ITransferParamsBE,
    options: TAsyncActionOptions
  ): Promise<ITransfersHistoryBE> {
    const resp = await requestService.get<ITransfersHistoryBE>(
      this.routes.getTransfersHistory,
      {
        ...options.cancelToken,
        params: {
          ...params,
          pageSize: 10000, // TODO: add scroll logic
          pageNumber: 1,
        },
      }
    );
    return resp?.data;
  }

  async getPurchaseHistory(
    params: IPurchaseParamsBE,
    options: TAsyncActionOptions
  ): Promise<IPurchasesHistoryBE> {
    const resp = await requestService.get<IPurchasesHistoryBE>(
      this.routes.getPurchaseHistory,
      {
        ...options.cancelToken,
        params: {
          ...params,
          pageSize: 10000, // TODO: add scroll logic
          pageIndex: 1,
        },
      }
    );
    return resp?.data;
  }

  async validateCouponCode(
    params: IValidateCouponParams,
    options: TAsyncActionOptions
  ): Promise<IValidateCouponResponse> {
    const resp = await requestService.get<IValidateCouponResponse>(
      this.routes.validateCouponCode,
      {
        params,
        ...options.cancelToken,
      }
    );

    return resp?.data;
  }
}
