import React from 'react';
import { observer } from 'mobx-react-lite';

import { ModalNotification } from '@trader/components';
import { useI18next } from '@trader/services';
import { useMst } from '@trader/store';

export const RemoveCloseTimeMuliBands: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const cancel = store.ui.modal.options.get('cancel');
  const proceed = store.ui.modal.options.get('proceed');

  return (
    <ModalNotification
      iconTypeName='warning'
      title={''}
      content={translate('MULI_BANDS.REMOVE_CLOSE_TIME')}
      confirmButton={{
        text: translate('COMMON.LABELS.PROCEED'),
        onClick: () => {
          proceed && proceed?.onProceed();
          store.pages.muliBands.editMuliBandsAsync.run();
          store.entities.positionsMetrics.getPositionsMetricsAsync.run();
          store.ui.modal.close();
        },
      }}
      refuseButton={{
        text: translate('COMMON.LABELS.CANCEL'),
        onClick: () => {
          cancel && cancel?.onCancel();
          store.ui.modal.close();
        },
      }}
    />
  );
});
