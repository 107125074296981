import styled, { css } from 'styled-components';
import { IconButton } from '@trader/components';
import { conditionApply } from '@trader/utils';
import { ESortDirection } from '@trader/types';

export const SortingButton = styled(IconButton)<{
  $isActive?: boolean;
  $sortOrder: ESortDirection;
}>`
  ${({ theme, $isActive, $sortOrder }) => css`
    color: ${theme.palette.gray[100]};
    margin-left: 4px;

    > svg {
      width: 10px;
      height: 10px;
    }

    ${conditionApply(
      [$isActive],
      css`
        color: ${theme.palette.primary.main};
      `
    )};

    ${conditionApply(
      [$sortOrder === ESortDirection.ASC],
      css`
        path:nth-child(1),
        path:nth-child(4) {
          stroke: ${theme.palette.common.black};
        }
      `
    )}

    ${conditionApply(
      [$sortOrder === ESortDirection.DESC],
      css`
        path:nth-child(2),
        path:nth-child(3) {
          stroke: ${theme.palette.common.black};
        }
      `
    )}
  `}
`;
