import React from 'react';
import { observer } from 'mobx-react-lite';

import { TableLegend } from '@trader/components';
import { useI18next } from '@trader/services';

import {
  Challenge,
  NoMastersAccount,
  NoNonAssignedAccounts,
} from './components';
import { useGetCells, useChallengesList } from './hooks';
import { IChallenges } from './index';
import * as Styled from './desktop.styled';

export const DesktopChallenges: React.FC<IChallenges> = observer(
  ({
    shouldTriggerGetChallenges = true,
    leftAction,
    rightAction,
    rightActionWidth,
    dividedChallenges,
    shouldDisplayAccTypeIcon,
    shouldDisplayId,
    shouldDisplayStatus,
    shouldDisplayResult,
    isMasterEmpty,
    isFullHeight,
  }) => {
    const { translate } = useI18next();

    const { challenges, sortedChallenges } = useChallengesList({
      dividedChallenges,
      shouldTriggerGetChallenges,
    });

    const { cells } = useGetCells(
      leftAction,
      rightActionWidth,
      shouldDisplayAccTypeIcon,
      shouldDisplayId,
      shouldDisplayStatus,
      shouldDisplayResult
    );

    return (
      <Styled.Root $isFullHeight={isFullHeight}>
        <Styled.Container>
          <Styled.Header>
            {cells.map(cell => (
              <span
                key={cell.id}
                style={{
                  textAlign: cell.align,
                  minWidth: cell.minWidth,
                }}
              >
                {cell.label}
              </span>
            ))}
          </Styled.Header>
          <Styled.Body $isFullHeight={isFullHeight}>
            {!challenges.length && isMasterEmpty && <NoMastersAccount />}
            {!challenges.length && !isMasterEmpty && <NoNonAssignedAccounts />}
            {sortedChallenges.map(challenge => (
              <Challenge
                key={challenge.id}
                challenge={challenge}
                cells={cells}
                leftAction={leftAction}
                rightAction={rightAction}
                shouldDisplayAccTypeIcon={shouldDisplayAccTypeIcon}
              />
            ))}
          </Styled.Body>
        </Styled.Container>
        <TableLegend
          items={[
            {
              iconType: 'dailyTargetProfit',
              text: translate('COMMON.LABELS.MAXIMUM_PROFIT_REACHED'),
            },
            {
              iconType: 'accountTypeMaster',
              text: translate('COPIER.MASTER_ACC'),
            },
            {
              iconType: 'accountTypeSlave',
              text: translate('COPIER.SLAVE_ACC'),
            },
            {
              iconType: 'activeAcc',
              text: translate('COPIER.ACTIVE_ACC'),
            },
          ]}
        />
      </Styled.Root>
    );
  }
);
