import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  formatDate,
  fixToTwoDigitAfterDot,
  formatByPipSize,
  formatTableRowValueByPipSize,
} from '@trader/utils';
import {
  PurchaseType,
  ChangePercentage,
  ProfitValue,
  TableColumnDateFilter,
  Table,
} from '@trader/components';
import { TPositionHistoryEntity, useMst } from '@trader/store';
import { ITableColumn } from '@trader/types';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { useI18next } from '@trader/services';
import { EFilterType } from '@trader/constants';

import { IPositionsHistory } from './index';
import * as Styled from './desktop.styled';

export const DesktopPositionsHistory: React.FC<IPositionsHistory> = observer(
  ({ shouldShowFooter }) => {
    const store = useMst();
    const { currentLng, translate } = useI18next();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();

    const accountCurrencySymbol = store.user.activeTradingAcc().currencySymbol;
    const totalPl =
      store.tables.positionsHistory.table.getAdditionalFields<number>(
        'totalPl'
      );

    useEffect(() => {
      store.entities.positionsHistory.getPositionsHistoryAsync.run();
    }, []);

    const Footer = useMemo(() => {
      return (
        <Styled.Footer>
          <p>
            {translate('COMMON.LABELS.TOTAL_PL')}:
            <span>
              {accountCurrencySymbol}
              {fixToTwoDigitAfterDot(totalPl || 0)}
            </span>
          </p>
        </Styled.Footer>
      );
    }, [totalPl, currentLng, accountCurrencySymbol]);

    const columns: ITableColumn<TPositionHistoryEntity>[] = useMemo(
      () => [
        {
          id: 'positionId',
          header: translate('COMMON.LABELS.POSITION_ID'),
          minWidth: 120,
          isSortable: false,
          isFilterable: false,
        },
        {
          id: 'symbol',
          header: translate('COMMON.LABELS.SYMBOL'),
          minWidth: 135,
        },
        {
          id: 'openTime',
          header: translate('COMMON.LABELS.OPEN_DATE'),
          minWidth: 210,
          type: EFilterType.DATE,
          render: row =>
            formatDate(new Date(row.openTime), 'Mm dd, yyyy hh:mm:ss', {
              locale: currentLng,
            }),
        },
        {
          id: 'closeTime',
          header: translate('COMMON.LABELS.CLOSE_DATE'),
          minWidth: 210,
          type: EFilterType.DATE,
          customFilter: () => (
            <TableColumnDateFilter
              store={store.tables.positionsHistory}
              columnId='closeTime'
              dateFilterNames={{ from: 'completedFrom', to: 'completedTo' }}
              label={translate('COMMON.LABELS.CLOSE_DATE')}
            />
          ),
          render: row =>
            formatDate(new Date(row.closeTime), 'Mm dd, yyyy hh:mm:ss', {
              locale: currentLng,
            }),
        },
        {
          id: 'side',
          header: translate('COMMON.LABELS.TYPE'),
          minWidth: getExtendedWidth('100', '2', ['nl']),
          render: row => <PurchaseType value={row.side as string} />,
        },
        {
          id: 'quantity',
          type: EFilterType.NUMBER,
          header: translate('COMMON.LABELS.VOLUME'),
          minWidth: 110,
        },
        {
          id: 'entryPrice',
          type: EFilterType.NUMBER,
          header: translate('COMMON.LABELS.OPEN_PRICE'),
          minWidth: getExtendedWidth('120', '1.5', ['nl']),
          render: row => +formatByPipSize(row.entryPrice, row.pipSize),
        },
        {
          id: 'commission',
          type: EFilterType.NUMBER,
          header: translate('COMMON.LABELS.COMMISSION'),
          minWidth: getExtendedWidth('130', '1.5', ['nl']),
        },
        {
          id: 'fee',
          type: EFilterType.NUMBER,
          header: translate('COMMON.LABELS.FEE'),
          minWidth: getExtendedWidth('100', '1.5', ['nl']),
        },
        {
          id: 'swap',
          type: EFilterType.NUMBER,
          header: translate('COMMON.LABELS.SWAP'),
          minWidth: getExtendedWidth('100', '1.5', ['nl']),
        },
        {
          id: 'stopLoss',
          header: translate('COMMON.LABELS.SL'),
          type: EFilterType.NUMBER,
          minWidth: 110,
          render: row =>
            formatTableRowValueByPipSize(row.stopLoss, row.pipSize),
        },
        {
          id: 'takeProfit',
          header: translate('COMMON.LABELS.TP'),
          type: EFilterType.NUMBER,
          minWidth: 110,
          render: row =>
            formatTableRowValueByPipSize(row.takeProfit, row.pipSize),
        },
        {
          id: 'exitPrice',
          header: translate('COMMON.LABELS.MARKET_PRICE'),
          type: EFilterType.NUMBER,
          minWidth: getExtendedWidth('130', '1.5', ['nl']),
          render: row => +formatByPipSize(row.exitPrice, row.pipSize),
        },
        {
          id: 'pl',
          header: translate('COMMON.LABELS.PROFIT'),
          type: EFilterType.NUMBER,
          minWidth: 110,
          render: row => <ProfitValue value={row.pl.toFixed(2)} />,
        },
        {
          id: 'change',
          header: translate('COMMON.LABELS.CHANGE'),
          type: EFilterType.NUMBER,
          minWidth: 130,
          render: row => <ChangePercentage change={row.change} />,
        },
      ],
      []
    );

    return (
      <Styled.Root>
        <Table
          columns={columns}
          store={store.tables.positionsHistory}
          emptyMessage={translate('COMMON.LABELS.NO_POSITIONS_HISTORY')}
          hasPagination
        />
        {shouldShowFooter && Footer}
      </Styled.Root>
    );
  }
);
