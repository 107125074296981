/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function reduceNormalize(
  arr: any[],
  entityName: string,
  idAttribute = 'id'
): {
  result: any;
  entities: {
    [k: string]: any;
  };
} {
  const normalized = {
    result: null,
    entities: {
      [entityName]: {},
    },
  };

  arr.reduce((acc, val) => {
    const id = val[idAttribute];

    if (arr.length === 1) {
      acc.result = id;
    } else if (!Array.isArray(acc.result)) {
      acc.result = [acc.result, val];
    } else {
      acc.result.push(id); // acc.ids.push(id);
    }

    acc.entities[entityName][id] = val;

    return acc;
  }, normalized);

  return normalized;
}

type TRunInAction = (cb: () => void) => void;
type TAnyModel = Record<string, unknown> & {
  runInAction: TRunInAction;
};
interface ISettings {
  model: TAnyModel;
  isReversed?: boolean;
}

export function push(settings: ISettings, arr: any[], item: any): void {
  const { model, isReversed = false } = settings;
  model.runInAction(() => {
    if (!Array.isArray(item)) {
      item = [item];
    }

    if (isReversed) {
      arr.unshift(...item);
    } else {
      arr.push(...item);
    }
  });
}

export function unshift(settings: ISettings, arr: any[], item: any): void {
  const { model, isReversed = false } = settings;
  model.runInAction(() => {
    if (!Array.isArray(item)) {
      item = [item];
    }

    if (!isReversed) {
      arr.unshift(...item);
    } else {
      arr.push(...item);
    }
  });
}
