import styled, { css } from 'styled-components';

import { Stack } from '@trader/components';

export const Buttons = styled(Stack)`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 12px;
    padding-top: 10px;
    border-top: 1px solid ${theme.palette.gray.main};
  `}
`;
