/* eslint-disable no-param-reassign */
/* eslint-disable no-magic-numbers */

import { TMuliBandsStore } from '@trader/store';
import { getMaxDigitWithDot } from '@trader/utils';

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
  StudyColorerPlotInfo,
  StudyLinePlotInfo,
} from '../charting_library';
import { baseDefaultsStyles, muliBandsInputs, strategyMap } from '../constants';
import { allBarsXStrategy, strategyName } from '@trader/constants';
import { EStripName, IStrip } from '@trader/types';

const defaultMuliBandsStyles = {
  ...baseDefaultsStyles,
  visible: true,
  linewidth: 2.5,
  linestyle: 1,
};

let bestStripForShort = '';
let bestStripForLong = '';

export const muliBands = (
  Pine: PineJS,
  store: TMuliBandsStore,
  pipSize = 4,
  _tickSize = 0
): CustomIndicator => ({
  name: strategyName,
  metainfo: {
    _metainfoVersion: 51,
    id: `${strategyName}@tv-basicstudies-1` as RawStudyMetaInfoId,
    description: strategyName,
    shortDescription: strategyName,
    is_price_study: true,
    isCustomIndicator: true,
    plots: [
      ...Array.from(strategyMap.values()).map(strip => ({
        id: strip.id as unknown as string,
        type: 'line' as StudyLinePlotInfo['type'],
      })),
      {
        id: 'roundedVwapColor',
        type: 'colorer' as StudyColorerPlotInfo['type'],
        target: EStripName.RoundedVwap as unknown as string,
        palette: 'roundedVwapColor',
      },
    ],
    filledAreas: [],
    palettes: {
      roundedVwapColor: {
        colors: {
          0: {
            name: 'Color 0',
          },
          1: {
            name: 'Color 1',
          },
        },
      },
    },

    defaults: {
      filledAreasStyle: {},
      inputs: {
        topMultiplier: 1,
        bottomMultiplier: 1,
      },
      palettes: {
        roundedVwapColor: {
          colors: {
            0: {
              color: 'rgba(35, 207, 63, 1)',
              width: 2,
              style: 0,
            },
            1: {
              color: 'rgba(255, 0, 0, 1)',
              width: 2,
              style: 0,
            },
          },
        },
      },
      styles: Array.from(strategyMap.values()).reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: {
            ...defaultMuliBandsStyles,
            color: curr.color,
          },
        }),
        {}
      ),
    },
    styles: Array.from(strategyMap.values()).reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: {
          histogramBase: 0,
          title: curr.id,
        },
      }),
      {}
    ),
    inputs: muliBandsInputs,
    format: {
      type: 'price',
      precision: getMaxDigitWithDot(pipSize),
    },
  },
  constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      const bar = allBarsXStrategy.get(Pine.Std.time(this._context));

      if (!bar) {
        return [];
      }
      // const floatTopMultiplier = this._input(0);
      // const floatBottomMultiplier = this._input(1);

      const vwapColor = Pine.Std.ge(
        bar.bands[EStripName.RoundedVwap].value,
        bar.c
      );

      if (context.symbol.isLastBar) {
        // update bands values in real time (cross, change, value)
        for (const band in bar.bands) {
          strategyMap.set(+band, {
            ...(strategyMap.get(+band) as IStrip),
            value: bar.bands[band].value,
            cross: bar.bands[band].cross,
            change: `${bar.bands[band].change?.toString() || 0}%`,
          });
        }

        strategyMap.get(EStripName.RoundedVwap) &&
          store.updateStripsValues(strategyMap);

        // calculate vwap trend
        const allBarsValues = Array.from(allBarsXStrategy.values());
        if (allBarsValues.length) {
          const nineBarBackVwap = allBarsValues[allBarsValues.length - 8];
          const vwapStrip = strategyMap.get(EStripName.RoundedVwap) as IStrip;

          const trend =
            vwapStrip.value ===
            nineBarBackVwap.bands[EStripName.RoundedVwap].value
              ? 'Flat'
              : vwapStrip.value >
                nineBarBackVwap.bands[EStripName.RoundedVwap].value
              ? 'Higher'
              : 'Lower';

          if (trend !== store.strip.vwapTrend) {
            store.strip.updateVwapTrend(trend);
          }
        }

        // calculate long / short strip
        const highStrips = Array.from(strategyMap.values()).filter(s =>
          EStripName[s.id].includes('High')
        );
        highStrips.forEach((strip, index) => {
          if (index === highStrips.length && strip.cross > 0) {
            bestStripForShort = strip.bestStripValue;
          }
          if (strip.cross > 0 && highStrips[index + 1]?.cross === 0) {
            bestStripForShort = strip.bestStripValue;
          }
        });

        const lowStrips = Array.from(strategyMap.values()).filter(s =>
          EStripName[s.id].includes('Low')
        );
        lowStrips.forEach((strip, index) => {
          if (index === lowStrips.length && strip.cross > 0) {
            bestStripForShort = strip.bestStripValue;
          }
          if (strip.cross > 0 && lowStrips[index + 1]?.cross === 0) {
            bestStripForLong = strip.bestStripValue;
          }
        });

        store.strip.updateLongStrip(bestStripForLong);
        store.strip.updateShortStrip(bestStripForShort);
      }

      return [
        bar.bands[EStripName.RoundedVwap].value,
        bar.bands[EStripName.Strip025High].value,
        bar.bands[EStripName.Strip0HalfHigh].value,
        bar.bands[EStripName.Strip075High].value,
        bar.bands[EStripName.Strip1High].value,
        bar.bands[EStripName.Strip1HalfHigh].value,
        bar.bands[EStripName.Strip2High].value,
        bar.bands[EStripName.Strip2HalfHigh].value,
        bar.bands[EStripName.Strip3High].value,
        bar.bands[EStripName.Strip025Low].value,
        bar.bands[EStripName.Strip0HalfLow].value,
        bar.bands[EStripName.Strip075Low].value,
        bar.bands[EStripName.Strip1Low].value,
        bar.bands[EStripName.Strip1HalfLow].value,
        bar.bands[EStripName.Strip2Low].value,
        bar.bands[EStripName.Strip2HalfLow].value,
        bar.bands[EStripName.Strip3Low].value,
        vwapColor,
      ];
    };
  },
});
