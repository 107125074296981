import { FC, MouseEvent } from 'react';

import { IconButton } from '@trader/components';

import * as Styled from './styled';

interface IScrollableItemActions {
  onEditClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onRemoveClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const ScrollableItemActions: FC<IScrollableItemActions> = ({
  onEditClick,
  onRemoveClick,
}) => {
  return (
    <Styled.Root>
      {onEditClick && <IconButton iconType='edit' onClick={onEditClick} />}
      <IconButton iconType='remove' onClick={onRemoveClick} />
    </Styled.Root>
  );
};
