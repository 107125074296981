import React, { useCallback, useRef } from 'react';

import {
  ESocketUpdateAction,
  IOrderMessage,
  IPositionMessage,
} from '@trader/types';
import {
  ClosedAll,
  OrderDeleted,
  OrderModified,
  OrderPlaced,
  PositionDeleted,
  PositionModified,
  PositionPlaced,
} from '@trader/notifications';
import { TCreateCustomNotificationContents, useMst } from '@trader/store';

interface IPositionMsg extends IPositionMessage {
  type: 'position';
  ticketId?: number;
}

interface IOrderMsg extends IOrderMessage {
  type: 'order';
  ticketId?: number;
}

type TNotificationParams<T> = T extends IPositionMsg ? IPositionMsg : IOrderMsg;

interface INotificationParams<T> {
  closeSnackbar: () => void;
  message: TNotificationParams<T>;
}

type TListMessages = Array<IPositionMsg | IOrderMsg>;
type TSortedMessages = Record<string, TListMessages>;

function isPosition(
  message: IPositionMsg | IOrderMsg
): message is IPositionMsg {
  return (message as IPositionMsg).openPrice !== undefined;
}

const positionsNotifications: Record<
  ESocketUpdateAction,
  (params: INotificationParams<IPositionMsg>) => React.ReactNode
> = {
  [ESocketUpdateAction.Create]: params => <PositionPlaced {...params} />,
  [ESocketUpdateAction.Delete]: params => <PositionDeleted {...params} />,
  [ESocketUpdateAction.Update]: params => <PositionModified {...params} />,
};

const ordersNotifications: Record<
  ESocketUpdateAction,
  (params: INotificationParams<IOrderMsg>) => React.ReactNode
> = {
  [ESocketUpdateAction.Create]: params => <OrderPlaced {...params} />,
  [ESocketUpdateAction.Delete]: params => <OrderDeleted {...params} />,
  [ESocketUpdateAction.Update]: params => <OrderModified {...params} />,
};

const singleMsgTimeout = 150;
const multipleMsgTimeout = 700;

let list: TListMessages = [];
let lastPositionId = 0;

export const useTradingNotifications = () => {
  const store = useMst();

  const timer = useRef<NodeJS.Timeout | null>(null);
  const isClosedAll = useRef(false);

  const activateIsCloseAll = () => {
    isClosedAll.current = true;
  };

  const addNotification = (content: TCreateCustomNotificationContents) => {
    store.ui.modal.close();
    clearTimer();
    isClosedAll.current = false;
    list = [];

    store.notifications.add({
      customType: {
        type: 'customType',
        options: { content },
      },
      options: {
        autoHideDuration: 3000,
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
    });
  };

  const clearTimer = () => {
    if (timer?.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  const handleSingeNotification = (message: IPositionMsg | IOrderMsg) => {
    addNotification(({ closeSnackbar }) => {
      if (isPosition(message)) {
        const renderPositions = positionsNotifications[message.updateAction];
        return renderPositions({ closeSnackbar, message });
      }
      const renderOrders = ordersNotifications[message.updateAction];
      return renderOrders({ closeSnackbar, message });
    });
  };

  // STEP 1: Store all messages in state with ticketId;
  // (ticketId is similar id for matching group of orders and position that comes through web-sockets);
  const handleSaveMessage = (message: IPositionMsg | IOrderMsg) => {
    clearTimer();

    if (isPosition(message)) {
      lastPositionId = message.id;
      message.ticketId = message.id;
    } else {
      message.ticketId = message.positionId || message.id;
    }
    list.push(message);

    if (isClosedAll.current && list.length > 1) {
      closeAll();
    } else {
      prioritize(sortByTicketId());
    }
  };

  // STEP 2: sort group of items from web-sockets by ticketId;
  // sorting them and saving in object for working easier with them later;
  const sortByTicketId = () => {
    return list.reduce((acc, item) => {
      if (!item.ticketId) {
        return acc;
      }

      if (acc[item.ticketId]) {
        return {
          ...acc,
          [item.ticketId]: [...acc[item.ticketId], item],
        };
      }

      return {
        ...acc,
        [item.ticketId]: [item],
      };
    }, {} as TSortedMessages);
  };

  // STEP 3: Prioritize messages;
  // 1. Position placed;
  // 2. Position deleted;
  // 3. Position modified;
  // 4. Order placed;
  // 5. Order deleted;
  // 6. Order modified;
  const prioritize = useCallback((messages: TSortedMessages) => {
    for (const key in messages) {
      const groupOfSortedMessages = messages[key];

      const positionInAGroup = groupOfSortedMessages.find(
        m => m.type === 'position'
      );

      if (positionInAGroup) {
        // apply positions priority and skip all orders messages if there is no position;
        const positionsOnly = groupOfSortedMessages.filter(
          m => m.type === 'position'
        );

        const placedPosition = positionsOnly.find(
          m => m.updateAction === ESocketUpdateAction.Create
        );
        const deletedPosition = positionsOnly.find(
          m => m.updateAction === ESocketUpdateAction.Delete
        );
        const modifiedPosition = positionsOnly.find(
          m => m.updateAction === ESocketUpdateAction.Update
        );

        if (placedPosition) {
          timer.current = setTimeout(() => {
            handleSingeNotification(placedPosition);
          }, singleMsgTimeout);
          return;
        }
        if (!placedPosition && deletedPosition) {
          timer.current = setTimeout(() => {
            handleSingeNotification(deletedPosition);
          }, singleMsgTimeout);
          return;
        }
        if (!placedPosition && !deletedPosition && modifiedPosition) {
          timer.current = setTimeout(() => {
            handleSingeNotification(modifiedPosition);
          }, singleMsgTimeout);
          return;
        }
      } else {
        // apply orders priority;
        const ordersOnly = groupOfSortedMessages.filter(
          m => m.type === 'order'
        );

        // eslint-disable-next-line no-loop-func
        if (ordersOnly.some(o => o.ticketId === lastPositionId)) {
          return;
        }

        const placedOrder = ordersOnly.find(
          m => m.updateAction === ESocketUpdateAction.Create
        );
        const deletedOrder = ordersOnly.find(
          m => m.updateAction === ESocketUpdateAction.Delete
        );
        const modifiedOrder = ordersOnly.find(
          m => m.updateAction === ESocketUpdateAction.Update
        );

        if (placedOrder) {
          timer.current = setTimeout(() => {
            handleSingeNotification(placedOrder);
          }, singleMsgTimeout);
          return;
        }
        if (!placedOrder && deletedOrder) {
          timer.current = setTimeout(() => {
            handleSingeNotification(deletedOrder);
          }, singleMsgTimeout);
          return;
        }
        if (!placedOrder && !deletedOrder && modifiedOrder) {
          timer.current = setTimeout(() => {
            handleSingeNotification(modifiedOrder);
          }, singleMsgTimeout);
          return;
        }
      }
    }
  }, []);

  // STEP 4: group all closed positions or orders to one message if we use close all functionality;
  const closeAll = useCallback(() => {
    const positionsMap = new Map();
    const ordersMap = new Map();

    list.forEach(item => {
      if (item.updateAction === ESocketUpdateAction.Delete) {
        if (item.type === 'position') {
          positionsMap.set(item.id, item);
        } else {
          ordersMap.set(item.id, item);
        }
      }
    });

    const isPositions = !!positionsMap.size;
    const isOrders = !positionsMap.size && !!ordersMap.size;
    const amountOfClosed = isPositions ? positionsMap.size : ordersMap.size;

    const positionsProfit = isPositions
      ? Array.from(positionsMap.values()).reduce(
          (acc, item) => acc + (item as IPositionMsg).profit,
          0
        )
      : 0;

    if (isPositions) {
      if (positionsMap.size > 1) {
        timer.current = setTimeout(() => {
          addNotification(({ closeSnackbar }) => (
            <ClosedAll
              closeSnackbar={closeSnackbar}
              amountOfClosed={amountOfClosed}
              profit={positionsProfit}
              title='positions'
            />
          ));
        }, multipleMsgTimeout);
      } else {
        timer.current = setTimeout(() => {
          handleSingeNotification(Array.from(positionsMap.values())[0]);
        }, singleMsgTimeout);
      }
    } else if (isOrders) {
      if (ordersMap.size > 1) {
        timer.current = setTimeout(() => {
          addNotification(({ closeSnackbar }) => (
            <ClosedAll
              closeSnackbar={closeSnackbar}
              amountOfClosed={amountOfClosed}
              title='orders'
            />
          ));
        }, multipleMsgTimeout);
      } else {
        timer.current = setTimeout(() => {
          handleSingeNotification(Array.from(ordersMap.values())[0]);
        }, singleMsgTimeout);
      }
    } else {
      isClosedAll.current = false;
      list = [];
    }
  }, [list]);

  return {
    handleSaveMessage,
    activateIsCloseAll,
  };
};
