import React from 'react';
import { observer } from 'mobx-react-lite';

import { useI18next } from '@trader/services';
import { fixToTwoDigitAfterDot, calculateDaysInInterval } from '@trader/utils';
import { Tooltip, Stack, Typography } from '@trader/components';
import { useMst } from '@trader/store';

import { LineChart } from '../lineChart';
import * as Styled from '../styled';

type TCardType = 'days' | 'vwap' | 'consecutiveDays' | 'lowerBand';

interface IPerformanceSummaryCard {
  type: TCardType;
  items: { label: string; value?: number }[];
  emptyMessage?: string;
}

export const PerformanceSummaryCard: React.FC<IPerformanceSummaryCard> =
  observer(({ type, items, emptyMessage }) => {
    const { translate } = useI18next();

    const store = useMst();
    const countOfSelectedDays = calculateDaysInInterval({
      from: store.pages.muliBands.backTesting.from,
      to: store.pages.muliBands.backTesting.to,
    });

    const cardData: Record<TCardType, { title: string; labelWidth?: string }> =
      {
        days: {
          title: translate('COMMON.LABELS.DAYS'),
        },
        vwap: {
          title: translate('MULI_BANDS.VWAP'),
        },
        consecutiveDays: {
          title: translate('MULI_BANDS.CONSECUTIVE_DAYS'),
        },
        lowerBand: {
          title: translate('MULI_BANDS.LOWER_BAND'),
        },
      };

    return (
      <Styled.SummaryChart>
        <Styled.Title>{cardData[type].title}</Styled.Title>
        <Stack direction='column' spacing='8px'>
          {!!emptyMessage && (
            <Typography variant='medium' width='240px'>
              {emptyMessage}
            </Typography>
          )}
          {!emptyMessage &&
            items.map(row => (
              <Styled.DataRow key={row.label}>
                <Styled.Label>{row.label}</Styled.Label>
                <Stack direction='row' alignItems='center' spacing='12px'>
                  {countOfSelectedDays !== undefined && (
                    <Tooltip
                      title={translate('TOOLTIPS.BACK_TESTING_LINE_CHART')}
                    >
                      <LineChart
                        values={[
                          ...items.map(d => d.value ?? 0),
                          countOfSelectedDays,
                        ]}
                        chartValue={row.value ?? 0}
                      />
                    </Tooltip>
                  )}
                  <Styled.ChartNumber $width='35px'>
                    {+fixToTwoDigitAfterDot(row.value ?? 0)}
                  </Styled.ChartNumber>
                </Stack>
              </Styled.DataRow>
            ))}
        </Stack>
      </Styled.SummaryChart>
    );
  });
