import React from 'react';

import { Typography } from '@trader/components';

import { IInstrumentDetailsHeader } from '../../index';

export const InstrumentSymbolTooltipTitle: React.FC<{
  instrument: IInstrumentDetailsHeader['instrument'];
}> = ({ instrument }) => {
  if (!instrument) {
    return null;
  }

  if (!instrument.displayName) {
    return instrument.description;
  }

  return (
    <Typography>
      <b>{instrument.symbol}</b>: {instrument.description}
    </Typography>
  );
};
