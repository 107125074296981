import styled, { css } from 'styled-components';
import { Typography } from '../typography';
import { Wrapper } from '../wrapper';
import { Button } from '../button';

export const Root = styled(Wrapper)`
  ${() => css`
    width: 100%;
    max-width: 408px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 30px;
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin: 10px 0;
    text-wrap: wrap;
    text-align: center;
  `};
`;

export const Content = styled(Typography)`
  ${() => css`
    white-space: normal;
    text-align: center;
  `};
`;

export const Submit = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 16px;
    margin-top: 30px;
    border-radius: 5px;
    font-size: ${theme.typography.large.fontSize};
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.common.black};
    font-weight: ${theme.typography.fontWeightBold};
  `};
`;
