import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Popover, SearchInstrument, Wrapper, Stack } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';

import * as Styled from '../../styled';
import { MarketStatusIcon } from '../marketStatusIcon';
import { FavouriteIconButton } from '../favoriteIconButton';
import { PriceAlertIconButton } from '../priceAlertIconButton';
import { ToggleLineAdaptersOnChart } from '../toggleLineAdaptersOnChart';
import { MenuButton } from './styled';

interface IRightSideActionBar {
  instrument: TInstrumentEntity;
  isMinimized?: boolean;
  isMuliBandsStrategy?: boolean;
  isColumnDesign?: boolean;
  searchCategory?: string;
  onChangeSymbol?: (symbol: string) => void;
}

export const RightSideActionBar: React.FC<IRightSideActionBar> = observer(
  ({
    instrument,
    isMinimized,
    isMuliBandsStrategy,
    isColumnDesign,
    searchCategory,
    onChangeSymbol,
  }) => {
    const { translate } = useI18next();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const store = useMst();
    const shouldShowTradingCentral = store.user.settings.isDisplayedByUser;
    const isKeyStatisticsSelected =
      store.ui.sidebar.isRightBarKeyStatisticsOpened();
    const isTradingCentralSelected =
      store.ui.sidebar.isRightBarTradingCentralOpened();

    const handleInfoSectionOpen = (
      contentType: 'keyStatistics' | 'tradingCentral'
    ) => {
      store.ui.sidebar.openRightBarInfoSection(contentType);
      isMinimized && setIsMenuOpen(false);
    };

    const renderRightSideItems = () => (
      <>
        <ToggleLineAdaptersOnChart />
        {!isMuliBandsStrategy && (
          <MarketStatusIcon
            isUnavailable={instrument.tradingAvailability.isUnavailable}
            openIn={instrument.tradingAvailability.openIn}
          />
        )}
        {isMuliBandsStrategy && (
          <Styled.InstrumentSearch>
            <SearchInstrument
              placeholder={translate('COMMON.LABELS.SEARCH_INSTRUMENT')}
              onChangeSymbol={onChangeSymbol}
              searchCategory={searchCategory}
              isMuliBandsStrategy={isMuliBandsStrategy}
            />
          </Styled.InstrumentSearch>
        )}
        <FavouriteIconButton instrument={instrument} />
        <PriceAlertIconButton symbol={instrument.symbol} />
        {!isMuliBandsStrategy && !isMinimized && (
          <Styled.InfoButton
            $isSelected={isKeyStatisticsSelected}
            onClick={() => handleInfoSectionOpen('keyStatistics')}
          >
            {translate('COMMON.LABELS.KEY_STATISTICS')}
          </Styled.InfoButton>
        )}
        {!isMuliBandsStrategy && shouldShowTradingCentral && !isMinimized && (
          <Styled.InfoButton
            $isSelected={isTradingCentralSelected}
            onClick={() => handleInfoSectionOpen('tradingCentral')}
            $withLeftSpacing
          >
            {translate('COMMON.LABELS.TRADING_CENTRAL')}
          </Styled.InfoButton>
        )}
      </>
    );

    if (isMinimized) {
      return (
        <Wrapper
          flexDirection={isColumnDesign ? 'column' : 'row-reverse'}
          alignItems='flex-end'
          justifyContent='flex-end'
        >
          {!isMuliBandsStrategy && (
            <Popover
              behavior='click'
              elevation={4}
              showContent={isMenuOpen}
              trigger={
                <MenuButton
                  iconType='menu'
                  onClick={() => setIsMenuOpen(true)}
                />
              }
              content={
                <Wrapper flexDirection='column' width='150px' padding='6px'>
                  <Styled.MenuItem
                    $isSelected={isKeyStatisticsSelected}
                    onClick={() => handleInfoSectionOpen('keyStatistics')}
                  >
                    {translate('COMMON.LABELS.KEY_STATISTICS')}
                  </Styled.MenuItem>
                  {shouldShowTradingCentral && (
                    <Styled.MenuItem
                      $isSelected={isTradingCentralSelected}
                      onClick={() => handleInfoSectionOpen('tradingCentral')}
                    >
                      {translate('COMMON.LABELS.TRADING_CENTRAL')}
                    </Styled.MenuItem>
                  )}
                </Wrapper>
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          )}
          <Stack direction='row' spacing='12px'>
            {renderRightSideItems()}
          </Stack>
        </Wrapper>
      );
    }

    return (
      <Stack direction='row' spacing='17px' alignItems='center'>
        {renderRightSideItems()}
      </Stack>
    );
  }
);
