import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { strategyMap } from '@trader/containers';

import * as Styled from './styled';
import { EStripName } from '@trader/types';

const Box = React.forwardRef<HTMLDivElement | null>((_props, ref) => {
  const store = useMst();
  const { translate } = useI18next();

  const movingValues = store.pages.muliBands.strip.movingValues;
  const dataBox = store.pages.muliBands.dataBox;

  const [state] = useState({
    top: dataBox.positionTop,
    left: dataBox.positionLeft,
  });

  return (
    <Styled.Container ref={ref} $top={state.top} $left={state.left}>
      <Styled.Header>
        <Styled.Title>{translate('MULI_BANDS.DATA_BOX')}</Styled.Title>
        <Styled.Close
          onClick={store.pages.muliBands.toggleDataBox}
          iconType='remove'
          isSingleIcon
          variant='text'
        />
      </Styled.Header>
      <Styled.Content>
        {movingValues.map(({ value, title }) => (
          <Styled.Value
            key={title}
            $background={strategyMap.get(+title)?.color}
          >
            <span>{EStripName[+title] || title}:</span> <span>{value}</span>
          </Styled.Value>
        ))}
      </Styled.Content>
    </Styled.Container>
  );
});

export const DataBox = observer(Box);
