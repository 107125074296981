import React from 'react';

import * as Styled from './styled';

interface IChangePercentage {
  change: number;
}

export const ChangePercentage: React.FC<IChangePercentage> = ({ change }) => {
  return (
    <Styled.Change $isAboveZero={change > 0} $isEqualZero={change === 0}>
      {change}%
    </Styled.Change>
  );
};
