import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';

import {
  allBarsXStrategy,
  emptyFn,
  halfSecond,
  maxMultiplier,
  minMultiplier,
} from '@trader/constants';
import { TMuliBandsStore, useMst } from '@trader/store';
import {
  getAmountDigitsAfterDot,
  getPipSizeNumber,
  getTradingAmountTranslationKey,
} from '@trader/utils';
import { Controller, Form, Typography } from '@trader/components';
import { useI18next } from '@trader/services';

import { StopLoss } from '../../../stopLoss';
import { useOrderFormValidation } from './formValidation';

import * as Styled from './styled';

const Content: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const muliBands: TMuliBandsStore = store.pages.muliBands;
  const trading = store.trading.getTrading('createStrategyBand');

  const { control, setValue } = useFormContext<IOrderInitialValues>();

  const handleAsyncChangeFloatMultiplier = debounce(
    (event: React.BaseSyntheticEvent) => {
      const upFloatMultiplier =
        event.target.name === 'floatTopMultiplier'
          ? event.target.value
          : muliBands.floatTopMultiplier;
      const downFloatMultiplier =
        event.target.name === 'floatBottomMultiplier'
          ? event.target.value
          : muliBands.floatBottomMultiplier;

      muliBands.getHistoricalDataAsync.run({
        upFloatMultiplier: Number(upFloatMultiplier),
        downFloatMultiplier: Number(downFloatMultiplier),
        take: allBarsXStrategy.size,
      });
    },
    halfSecond
  );

  useEffect(() => {
    setValue('orderAmount', muliBands.orderAmount);
    setValue('floatTopMultiplier', muliBands.floatTopMultiplier);
    setValue('floatBottomMultiplier', muliBands.floatBottomMultiplier);
    setValue('isSl', muliBands.isSl);
    setValue('sl', muliBands.sl);
    setValue('isOpenTime', muliBands.isOpenTime);
    if (!muliBands.isOpenTime) {
      setValue('openTime', undefined);
    }
  }, [
    muliBands.id,
    muliBands.orderAmount,
    muliBands.floatTopMultiplier,
    muliBands.floatBottomMultiplier,
    muliBands.isOpenTime,
  ]);

  return (
    <Styled.Root>
      <Controller
        type='tradingInput'
        shouldCheckActivityOfButtons
        customLabel={translate(
          getTradingAmountTranslationKey(trading.tradingType)
        )}
        disabled={!muliBands.id}
        minValue={trading.minAmount()}
        maxValue={trading.maxAmount()}
        step={trading.step()}
        fixDigitAfterDot={getAmountDigitsAfterDot(trading.step())}
        control={control}
        name='orderAmount'
      />
      <Controller
        type='tradingInput'
        shouldCheckActivityOfButtons
        customLabel={translate('MULI_BANDS.TOP_MULTIPLIER')}
        disabled={!muliBands.id}
        minValue={minMultiplier}
        maxValue={maxMultiplier}
        step={minMultiplier}
        fixDigitAfterDot={getPipSizeNumber(minMultiplier)}
        onCustomChange={handleAsyncChangeFloatMultiplier}
        control={control}
        isPending={muliBands.getHistoricalDataAsync.inProgress}
        name='floatTopMultiplier'
      />
      <Controller
        type='tradingInput'
        shouldCheckActivityOfButtons
        customLabel={translate('MULI_BANDS.BOTTOM_MULTIPLIER')}
        disabled={!muliBands.id}
        minValue={minMultiplier}
        maxValue={maxMultiplier}
        step={minMultiplier}
        fixDigitAfterDot={getPipSizeNumber(minMultiplier)}
        onCustomChange={handleAsyncChangeFloatMultiplier}
        control={control}
        isPending={muliBands.getHistoricalDataAsync.inProgress}
        name='floatBottomMultiplier'
      />
      <Styled.Sl>
        <StopLoss />
      </Styled.Sl>
      <Styled.OpenTime>
        <Controller
          type='switcherField'
          label={translate('MULI_BANDS.OPEN_TIME')}
          name='isOpenTime'
          control={control}
          shouldHideControlInfo
        />
        {muliBands.isOpenTime && (
          <>
            <Controller
              type='dateTimePickerField'
              label={translate('MULI_BANDS.OPEN_TIME')}
              name='openTime'
              minValue={new Date()}
              control={control}
              readonly
            />
            <Typography>
              {translate('MULI_BANDS.OPEN_TIME_GMT_LABEL')}
            </Typography>
          </>
        )}
      </Styled.OpenTime>
    </Styled.Root>
  );
});

interface IOrderInitialValues {
  orderAmount: string;
  floatTopMultiplier: string;
  floatBottomMultiplier: string;
  sl: string;
  isSl: boolean;
  openTime: Date | undefined;
  isOpenTime: boolean;
}

export const Order: React.FC = observer(() => {
  const store = useMst();
  const validationSchema = useOrderFormValidation();

  const muliBands = store.pages.muliBands;

  const defaultFormValues: IOrderInitialValues = {
    orderAmount: muliBands.orderAmount,
    floatTopMultiplier: muliBands.floatTopMultiplier,
    floatBottomMultiplier: muliBands.floatBottomMultiplier,
    isSl: muliBands.isSl,
    sl: muliBands.sl,
    isOpenTime: muliBands.isOpenTime,
    openTime: muliBands.openTime || undefined,
  };

  const handleWatch = (name: string, value: string | number | boolean) => {
    muliBands.runInAction(() => {
      muliBands[name] = value;
    });
  };

  return (
    <Form<IOrderInitialValues>
      onSubmit={emptyFn}
      mode='all'
      defaultValues={defaultFormValues}
      validationSchema={validationSchema}
      handleWatch={handleWatch}
      blackList={['floatTopMultiplier', 'floatBottomMultiplier']}
    >
      {() => <Content />}
    </Form>
  );
});
