import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import {
  Icon,
  Tooltip,
  Table,
  ObservableTableColumn,
} from '@trader/components';
import { ITableColumn } from '@trader/types';
import { useI18next } from '@trader/services';
import { EFilterType } from '@trader/constants';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { formatTableRowValueByPipSize } from '@trader/utils';

import {
  PurchaseType,
  ChangePercentage,
  ProfitValue,
  SymbolWithIconButton,
  SymbolWithIcon,
} from '../scrollingList';
import { IPositionsList } from './index';
import { CloseAllPositions } from './components/closeAllPositions';
import { StickyPositionColumn } from './components/stickyPositionColumn';
import { usePositionsListMetrics } from './hooks/usePositionsListMetrics';

import * as Styled from './desktop.styled';

export const DesktopPositionsList: React.FC<IPositionsList> = observer(
  ({ symbol, withClickableSymbol }) => {
    const store = useMst();
    const { translate, currentLng } = useI18next();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();
    const { positionsMetrics } = usePositionsListMetrics(symbol);

    const renderPlHeader = () => (
      <Styled.PlLabel>
        {translate('COMMON.LABELS.P_L')}
        <Tooltip title={translate('TOOLTIPS.PL_WITHOUT_SWAP')}>
          <Icon iconType='info' />
        </Tooltip>
      </Styled.PlLabel>
    );

    const renderSymbol = useCallback(
      (
        s: string,
        url: string,
        strategy: TPositionMetricEntity['strategy'],
        id: string
      ) => {
        const closeTime = store.pages.muliBands.getCloseTime(id, strategy);

        if (closeTime) {
          return (
            <Styled.SymbolWithTimer>
              {withClickableSymbol ? (
                <SymbolWithIconButton symbol={s} iconUrl={url} />
              ) : (
                <SymbolWithIcon symbol={s} iconUrl={url} />
              )}

              <Tooltip title={`Close time: ${closeTime}`}>
                <Icon iconType='timer' />
              </Tooltip>
            </Styled.SymbolWithTimer>
          );
        }

        return withClickableSymbol ? (
          <SymbolWithIconButton symbol={s} iconUrl={url} />
        ) : (
          <SymbolWithIcon symbol={s} iconUrl={url} />
        );
      },
      []
    );

    const columns: ITableColumn<TPositionMetricEntity>[] = useMemo(
      () => [
        {
          id: 'symbol',
          header: translate('COMMON.LABELS.SYMBOL'),
          minWidth: 140,
          align: 'left',
          cellStyle: withClickableSymbol
            ? { padding: 0 }
            : { paddingLeft: '15px' },
          headerStyle: {
            paddingLeft: withClickableSymbol ? '15px' : '20px',
          },
          render: row =>
            renderSymbol(row.symbol, row.iconUrl, row.strategy, row.positionId),
        },
        {
          id: 'positionId',
          header: translate('COMMON.LABELS.POSITION_ID'),
          isSortable: false,
          isFilterable: false,
          minWidth: 140,
        },
        {
          id: 'side',
          header: translate('COMMON.LABELS.TYPE'),
          minWidth: getExtendedWidth('100', '2', ['nl']),
          render: row => <PurchaseType value={row.side as string} />,
        },
        {
          id: 'quantity',
          header: translate('COMMON.LABELS.VOLUME'),
          minWidth: 120,
          type: EFilterType.NUMBER,
        },
        {
          id: 'pl',
          header: renderPlHeader(),
          type: EFilterType.NUMBER,
          minWidth: 150,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => <ProfitValue value={r.pl.toFixed(2)} />}
            />
          ),
        },
        {
          id: 'netPl',
          header: translate('COMMON.LABELS.NET_PL'),
          type: EFilterType.NUMBER,
          minWidth: 130,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => <ProfitValue value={r.netPl.toFixed(2)} />}
            />
          ),
        },
        {
          id: 'openPrice',
          header: translate('COMMON.LABELS.OPEN_PRICE'),
          type: EFilterType.NUMBER,
          minWidth: getExtendedWidth('140', '1.5', ['nl']),
          render: row =>
            formatTableRowValueByPipSize(row.openPrice, row.pipSize),
        },
        {
          id: 'currentPrice',
          header: translate('COMMON.LABELS.CURRENT_PRICE'),
          type: EFilterType.NUMBER,
          minWidth: getExtendedWidth('140', '1.5', ['nl']),
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => formatTableRowValueByPipSize(r.price, r.pipSize)}
            />
          ),
        },
        {
          id: 'swap',
          type: EFilterType.NUMBER,
          header: translate('COMMON.LABELS.SWAP'),
          minWidth: getExtendedWidth('100', '1.5', ['nl']),
        },
        {
          id: 'value',
          header: translate('COMMON.LABELS.VALUE'),
          type: EFilterType.NUMBER,
          minWidth: 140,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => Number((r.price * r.quantity).toFixed(1))}
            />
          ),
        },
        {
          id: 'stopLoss',
          header: translate('COMMON.LABELS.SL'),
          type: EFilterType.NUMBER,
          minWidth: 120,
          render: row =>
            formatTableRowValueByPipSize(row.stopLoss.stopPrice, row.pipSize),
        },
        {
          id: 'takeProfit',
          header: translate('COMMON.LABELS.TP'),
          type: EFilterType.NUMBER,
          minWidth: 120,
          render: row =>
            formatTableRowValueByPipSize(
              row.takeProfit.limitPrice,
              row.pipSize
            ),
        },
        {
          id: 'change',
          header: translate('COMMON.LABELS.CHANGE'),
          type: EFilterType.NUMBER,
          minWidth: 150,
          render: row => (
            <ObservableTableColumn
              row={row}
              render={r => <ChangePercentage change={r.change} />}
            />
          ),
        },
        {
          id: 'actions',
          isSortable: false,
          isFilterable: false,
          header: positionsMetrics.length ? <CloseAllPositions /> : '',
          minWidth: getExtendedWidth('140', '1.35', ['sk']),
          headerStyle: { textAlign: 'right', paddingRight: '20px' },
          render: row => (
            <StickyPositionColumn
              withClickableSymbol={withClickableSymbol}
              position={row}
            />
          ),
        },
      ],
      [positionsMetrics.length, currentLng]
    );

    return (
      <Styled.Root>
        <Table
          columns={columns}
          store={store.tables.positions}
          emptyMessage={translate('COMMON.LABELS.NO_POSITIONS')}
          isLastItemSticky
        />
      </Styled.Root>
    );
  }
);
