import { useCallback, useEffect } from 'react';

import {
  TInstrumentEntity,
  TTradingAccountEntity,
  useMst,
} from '@trader/store';
import { IMessage } from '@trader/types';
import { formatByPipSize, getSpreadDifference } from '@trader/utils';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { TInstrumentsIds } from './types';
import { useStartConnection } from '../core';
import { beforeSubscribe } from './beforeSubscribe';
import { beforeUnsubscribe } from './beforeUnsubscribe';

interface IUseInstrumentsParams {
  instrumentsIds: TInstrumentsIds;
  subscription: EConnectionSubscription;
  onUpdate?: (msg: IMessage) => void;
  tradingAccount?: TTradingAccountEntity;
}

export const useInstruments = ({
  instrumentsIds,
  subscription,
  onUpdate,
  tradingAccount,
}: IUseInstrumentsParams) => {
  const store = useMst();

  const { connection } = useStartConnection(
    EConnectionHub.Quotes,
    subscription,
    'quotes'
  );

  const activeTradingAccount: TTradingAccountEntity =
    tradingAccount || store.user.activeTradingAcc();

  const platformLogin = activeTradingAccount.platformLogin;
  const serverName = activeTradingAccount.serverName;

  const idToken = store.auth.tokens.idToken;

  const isChangingAccount =
    store.entities.tradingAccounts.changeTradingAccountAsync.inProgress;

  const handleMessage = useCallback((message: IMessage) => {
    const instrument = store.entities.instruments.get<TInstrumentEntity>(
      message.s
    );

    const { ask, bid } = getSpreadDifference(
      instrument.spreadDiff,
      message.a,
      message.b,
      instrument.spreadDiffBalance,
      instrument.pipSize
    );

    if (ask !== instrument.ask) {
      const currentAsk = +formatByPipSize(ask, instrument.pipSize);

      store.entities.instruments.update(message.s, {
        updateAskType: currentAsk > instrument.ask ? 'up' : 'down',
        ask: currentAsk,
      });
    }

    if (bid !== instrument.bid) {
      const currentAsk = +formatByPipSize(bid, instrument.pipSize);

      store.entities.instruments.update(message.s, {
        updateBidType: currentAsk > instrument.bid ? 'up' : 'down',
        bid: currentAsk,
      });
    }
  }, []);

  useEffect(() => {
    if (isChangingAccount) return;

    const params = { instrumentsIds, subscription, serverName };

    if (instrumentsIds.length && platformLogin && serverName) {
      beforeSubscribe({
        ...params,
        onSubscribe: instruments => {
          connection.subscribe(async hub => {
            await hub.send(
              'SubscribeOnQuotes',
              serverName,
              platformLogin,
              instruments
            );
            hub.on('onQuote', onUpdate || handleMessage);
          });
        },
      });
    }

    return () => {
      beforeUnsubscribe({
        ...params,
        onSubscribe: instruments => {
          connection.unsubscribe(async hub => {
            hub.off('onQuote', onUpdate || handleMessage);

            if (!instruments.length) return;

            await hub?.send(
              'UnsubscribeFromQuotes',
              serverName,
              platformLogin,
              instruments
            );
          });
        },
      });
    };
  }, [JSON.stringify(instrumentsIds), isChangingAccount, idToken]);
};
