import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.gray.dark};
      border-radius: 5px;
    }
  `}
`;

export const StickyPosition = styled(Wrapper)`
  ${() => css`
    width: 100%;
    height: 100%;
    padding-left: 20px;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const SymbolWithTimer = styled(Wrapper)`
  ${() => css`
    > div {
      display: flex;
      align-items: center;

      > svg {
        margin-left: 5px;
        width: 12px;
      }
    }
  `};
`;

export const PlLabel = styled(Wrapper)`
  ${() => css`
    align-items: center;
    justify-content: center;
    display: inline-flex;

    > div {
      align-items: center;
      justify-content: center;
      display: flex;
      margin-left: 3px;
    }
  `};
`;
