import { useCallback, useContext, useEffect } from 'react';

import {
  HubConnection,
  EConnectionHub,
  EConnectionSubscription,
} from '@trader/services';
import { useMst } from '@trader/store';
import { TradingNotificationsContext } from '@trader/contexts';
import { ESocketUpdateAction, IOrderMessage } from '@trader/types';

import { useStartConnection } from './core';

/**
 * Custom hook that handles the subscription to pending orders.
 * It processes incoming order messages, updates the store, and handles modal interactions.
 */
export const usePendingOrders = () => {
  const store = useMst();
  const { handleSaveMessage } = useContext(TradingNotificationsContext);

  const { connection } = useStartConnection(
    EConnectionHub.Events,
    EConnectionSubscription.PendingOrders,
    'events'
  );

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const serverName = activeTradingAccount?.serverName;

  const handleMessage = useCallback((message: IOrderMessage) => {
    switch (message.updateAction) {
      case ESocketUpdateAction.Create:
        if (message.stopPrice || message.limitPrice) {
          store.entities.ordersMetrics.orderOptimisticCreateAsync.run(message);
          handleSaveMessage({ type: 'order', ...message });
        }
        break;
      case ESocketUpdateAction.Delete:
        if (message.stopPrice || message.limitPrice) {
          store.entities.ordersMetrics.orderOptimisticDeleteAsync.run(
            String(message.id)
          );
          handleSaveMessage({ type: 'order', ...message });
        }
        break;
      case ESocketUpdateAction.Update:
        store.entities.ordersMetrics.orderOptimisticUpdateAsync.run(message);
        handleSaveMessage({ type: 'order', ...message });
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (platformLogin && serverName) {
      connection.subscribe(async (hub: HubConnection) => {
        await hub.send('SubscribeOnOrders', serverName, platformLogin);
        hub.on('OnOrder', handleMessage);
      });

      return () => {
        connection.unsubscribe(async hub => {
          hub.off('OnOrder', handleMessage);
          await hub?.send('UnsubscribeFromOrders', serverName, platformLogin);
        });
      };
    }
  }, [platformLogin, idToken]);
};
