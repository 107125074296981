import { api, ICandleBarBE, IStrategyHistoricalBar } from '@trader/api';
import { rootStore } from '@trader/store';
import { formatByPipSize } from '@trader/utils';
import { IMessage } from '@trader/types';
import { IResolution } from './getLastBar';
import { SubscribeBarsCallback } from '../charting_library';
import { allBarsXStrategy } from '@trader/constants';

interface IGetBars {
  strategyId: number;
  symbol: string;
  isFirstRequest: boolean;
  resolution: string;
  fromTime: number;
  isMuliBandsStrategy?: boolean;
  upFloatMultiplier?: number;
  downFloatMultiplier?: number;
}

interface IMuliBandsMessage {
  bands: IStrategyHistoricalBar['bands'];
  additionalBands: IStrategyHistoricalBar['bands'] | null;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
  time: number;
  isClosed: false;
  strategyId: number;
  cognitoId: string;
  name: 'StrategyValueChanged';
}

type THandleBar<TMsg> = TMsg extends IMuliBandsMessage
  ? {
      message: TMsg;
      onRealtimeCallback: SubscribeBarsCallback;
    }
  : {
      message: TMsg;
      symbol: string;
      pipSize: number;
      resolution: IResolution;
      onRealtimeCallback: SubscribeBarsCallback;
    };

type TGetBars = (
  params: IGetBars
) => Promise<Array<IStrategyHistoricalBar & ICandleBarBE>>;

export const getHistoricalBars: TGetBars = async ({
  strategyId,
  symbol,
  isFirstRequest,
  resolution,
  fromTime,
  isMuliBandsStrategy,
  upFloatMultiplier,
  downFloatMultiplier,
}) => {
  let result: unknown;

  if (isMuliBandsStrategy) {
    result = await api.BandStrategy.getStrategyHistoricalBars({
      id: strategyId,
      fromTime: isFirstRequest ? 0 : fromTime,
      upFloatMultiplier,
      downFloatMultiplier,
    });
  } else {
    result = await api.Historical.getCandleBars(
      symbol,
      resolution,
      isFirstRequest ? 0 : fromTime
    );
  }

  return result as Array<IStrategyHistoricalBar & ICandleBarBE>;
};

export const handleMuliBandsRealTimeBar = ({
  message,
  onRealtimeCallback,
}: THandleBar<IMuliBandsMessage>) => {
  if (message.name !== 'StrategyValueChanged') {
    return;
  }

  if (message.strategyId === rootStore.pages.muliBands.id) {
    const price = message.close;
    if (price) {
      allBarsXStrategy.set(message.time, {
        bands: message.additionalBands || message.bands,
        o: message.open,
        h: message.high,
        l: message.low,
        c: message.close,
        v: message.volume,
        t: message.time,
      });

      setTimeout(() =>
        onRealtimeCallback({
          time: message.time,
          low: message.low,
          high: message.high,
          open: message.open,
          close: message.close,
          volume: message.volume,
        })
      );

      rootStore.pages.muliBands.setPrice(price);
    }
  }
};

export const handleRealTimeBar = ({
  message,
  symbol,
  pipSize,
  resolution,
  onRealtimeCallback,
}: THandleBar<IMessage>) => {
  if (message.s === symbol) {
    const price = message.p
      ? Number(formatByPipSize(message.p, pipSize))
      : null;

    if (price) {
      const lastBar = resolution.getBar(message.t, price, message.v);

      setTimeout(() =>
        onRealtimeCallback({
          open: lastBar.open,
          high: lastBar.high,
          low: lastBar.low,
          close: lastBar.close,
          volume: lastBar.volume,
          time: lastBar.time,
        })
      );
    }
  }
};
