import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { formatByPipSize, getOrderBasePrice } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';
import { Icon, Tooltip } from '@trader/components';
import { ICommonOrderType } from '@trader/types';
import { useI18next } from '@trader/services';
import {
  IGetCostAndChargesParams,
  ICostAndChargesBE,
  ICostAndChargesRowProps,
  ICostAndChargesCommissionsModel,
} from '@trader/api';

import { getOrderCommissions } from './utils';

import * as Styled from './styled';

export interface ICostAndChargesModel {
  commissions: ICostAndChargesCommissionsModel;
  instrumentCharges: ICostAndChargesBE;
  daysHeld: number;
  hasDifferentCurrency: boolean;
  isActive: boolean;
}

const maxDaysHeld = 99;
const fiveDigits = 5;

const defaultInstrumentCharges: ICostAndChargesBE = {
  overnightSwapRate: 0,
  conversionFee: 0,
  conversionRate: 1,
  openCommission: 0,
  closeCommission: 0,
};

export const CostAndCharges: React.FC<ICommonOrderType> = observer(
  ({ type }) => {
    const store = useMst();
    const { translate } = useI18next();

    const trading = store.trading.getTrading(type);
    const instrument = trading.instrument as TInstrumentEntity;
    const instrumentSpread = Math.abs(instrument.bid - instrument.ask);

    const defaultModel: ICostAndChargesModel = useMemo(
      () => ({
        commissions: getOrderCommissions(
          instrument,
          trading.amount,
          trading.side,
          defaultInstrumentCharges,
          0,
          false,
          instrumentSpread,
          translate
        ),
        instrumentCharges: defaultInstrumentCharges,
        daysHeld: 0,
        isActive: false,
        hasDifferentCurrency:
          instrument.currency !== store.user.activeTradingAcc().currency,
      }),
      []
    );

    const [model, setModel] = useState<ICostAndChargesModel>(defaultModel);
    const [isInProgress, setIsInProgress] = useState(false);
    const [instrumentApiCharges, setInstrumentApiCharges] =
      useState<ICostAndChargesBE>(defaultInstrumentCharges);

    const entryPrice = getOrderBasePrice(
      trading.side,
      instrument.bid,
      instrument.ask
    );

    const currentPrice =
      trading.side === 'Buy' ? instrument.ask : instrument.bid;

    const handleDaysHeldChange = (isIncrement: boolean) => {
      setModel(prevModel => {
        if (isIncrement && prevModel.daysHeld >= maxDaysHeld)
          return {
            ...prevModel,
            daysHeld: maxDaysHeld,
          };

        if (!isIncrement && prevModel.daysHeld <= 0)
          return {
            ...prevModel,
            daysHeld: 0,
          };

        const updatedDaysHeld = isIncrement
          ? prevModel.daysHeld + 1
          : prevModel.daysHeld - 1;
        return {
          ...prevModel,
          daysHeld: updatedDaysHeld,
        };
      });
    };

    const getInstrumentCostAndCharges = async () => {
      if (instrument.symbol && !isInProgress) {
        try {
          setIsInProgress(true);
          const apiParams: IGetCostAndChargesParams = {
            side: trading.side,
            quantity: Number(trading.amount),
            symbol: instrument.symbol,
          };
          const apiCharges = await store.entities.instruments
            .get<TInstrumentEntity>(instrument.symbol)
            ?.getInstrumentCostAndChargesAsync.run(apiParams);

          if (apiCharges !== null) {
            if (apiCharges?.openCommission !== undefined) {
              setInstrumentApiCharges(apiCharges);
            }
          }
        } finally {
          setIsInProgress(false);
        }
      }
    };

    const handleChangeIsCostAndCharges = () => {
      const isCostAndChargesOpening = !model.isActive;
      if (isCostAndChargesOpening) {
        store.ui.sidebar.closeRightBarInfoSection();
      }

      setModel(prevModel => {
        return {
          ...prevModel,
          isActive: !prevModel.isActive,
        };
      });
    };

    useEffect(() => {
      if (trading.side && trading.amount) {
        getInstrumentCostAndCharges();
      }
    }, [instrument.symbol, trading.side, trading.amount]);

    useEffect(() => {
      if (instrumentApiCharges.openCommission !== undefined) {
        model.instrumentCharges = instrumentApiCharges;
        model.commissions = getOrderCommissions(
          instrument,
          trading.amount,
          trading.side,
          instrumentApiCharges,
          model.daysHeld,
          model.hasDifferentCurrency,
          instrumentSpread,
          translate
        );
        setModel(model);
      }
    }, [
      instrumentApiCharges,
      model.daysHeld,
      instrumentSpread,
      trading.side,
      trading.amount,
    ]);

    useEffect(() => {
      const isInfoSectionOpened =
        store.ui.sidebar.isRightBarTradingCentralOpened() ||
        store.ui.sidebar.isRightBarKeyStatisticsOpened();
      if (isInfoSectionOpened) {
        setModel(prevModel => {
          return {
            ...prevModel,
            isActive: false,
          };
        });
      }
    }, [
      store.ui.sidebar.isRightBarTradingCentralOpened(),
      store.ui.sidebar.isRightBarKeyStatisticsOpened(),
    ]);

    return (
      <Styled.Root>
        <Styled.Content>
          <Styled.Main onClick={handleChangeIsCostAndCharges}>
            <Styled.TitleRow>
              <Styled.Title>
                {translate('COMMON.LABELS.COST_AND_CHARGES')}
              </Styled.Title>
              <Icon iconType={model.isActive ? 'minimize' : 'maximize'} />
            </Styled.TitleRow>
          </Styled.Main>
          {model.isActive && (
            <Styled.CostAndCharges
              $isTakeProfitOpen={trading.isTakeProfit}
              $isStopLossOpen={trading.isStopLoss}
            >
              <CostAndChargesItem
                label={translate('COMMON.LABELS.VOLUME')}
                value={trading.amount}
              />
              <CostAndChargesItem
                label={translate('COMMON.LABELS.INSTRUMENT_SPREAD')}
                value={instrumentSpread.toFixed(fiveDigits)}
              />
              <CostAndChargesItem
                label={translate('COMMON.LABELS.ENTRY_PRICE')}
                value={formatByPipSize(entryPrice, instrument.pipSize)}
                currency={instrument.currency}
              />
              <CostAndChargesItem
                label={translate('COMMON.LABELS.CURRENT_PRICE')}
                value={formatByPipSize(currentPrice, instrument.pipSize)}
                currency={instrument.currency}
              />
              <CostAndChargesItem
                label={translate('COMMON.LABELS.END_OF_THE_DAY_PRICE')}
                value={formatByPipSize(entryPrice, instrument.pipSize)}
                currency={instrument.currency}
              />
              {model.commissions.hasCommissionCharges && (
                <CostAndChargesItem
                  label={translate('COMMON.LABELS.COMMISSION_IN_PERCENTAGE')}
                  value={model.commissions.commissionPercentage.toString()}
                  isPercentageValue={true}
                />
              )}
              <CostAndChargesItem
                label={translate('COMMON.LABELS.OVERNIGHT_SWAP_RATE')}
                value={model.commissions.overnightSwapRate.toString()}
                isPercentageValue={true}
              />
              {model.hasDifferentCurrency && (
                <>
                  <CostAndChargesItem
                    label={translate('COMMON.LABELS.CONVERSION_FEE')}
                    value={model.instrumentCharges.conversionFee.toString()}
                    isPercentageValue={true}
                  />
                  <CostAndChargesItem
                    label={translate('COMMON.LABELS.CURRENCY_CONVERSION_RATE')}
                    value={formatByPipSize(
                      1 / model.instrumentCharges.conversionRate,
                      instrument.pipSize
                    )}
                  />
                </>
              )}
              <Styled.Row>
                <Styled.Label>
                  {translate('COMMON.LABELS.DAYS_HELD')}:
                </Styled.Label>
                <Styled.DaysHeld>
                  <Styled.DaysHeldButton
                    variant='text'
                    isSingleIcon
                    onClick={() => handleDaysHeldChange(false)}
                    $isActive={model.daysHeld > 0}
                  >
                    <Icon iconType='minus' />
                  </Styled.DaysHeldButton>
                  <Styled.DaysHeldValue>{model.daysHeld}</Styled.DaysHeldValue>
                  <Styled.DaysHeldButton
                    variant='text'
                    isSingleIcon
                    onClick={() => handleDaysHeldChange(true)}
                    $isActive={model.daysHeld < maxDaysHeld}
                  >
                    <Icon iconType='plus' />
                  </Styled.DaysHeldButton>
                </Styled.DaysHeld>
              </Styled.Row>
              {model.hasDifferentCurrency && (
                <CostAndChargesItem
                  currency={instrument.currency}
                  commissionItem={model.commissions.userCurrencySpread}
                />
              )}
              <CostAndChargesItem
                currency={store.user.activeTradingAcc().currency}
                commissionItem={model.commissions.instrumentCurrencySpread}
              />
              {model.hasDifferentCurrency && (
                <CostAndChargesItem
                  currency={instrument.currency}
                  commissionItem={
                    model.commissions.overnightSwapInstrumentCurrency
                  }
                />
              )}
              <CostAndChargesItem
                currency={store.user.activeTradingAcc().currency}
                commissionItem={model.commissions.overnightSwapUserCurrency}
              />
              {model.hasDifferentCurrency && (
                <CostAndChargesItem
                  currency={store.user.activeTradingAcc().currency}
                  commissionItem={model.commissions.conversionUserCurrencyFee}
                />
              )}
              {model.commissions.hasCommissionCharges &&
                model.hasDifferentCurrency && (
                  <CostAndChargesItem
                    currency={instrument.currency}
                    commissionItem={
                      model.commissions.openCommissionInstrumentCurrency
                    }
                  />
                )}
              {model.commissions.hasCommissionCharges && (
                <CostAndChargesItem
                  currency={store.user.activeTradingAcc().currency}
                  commissionItem={model.commissions.openCommissionUserCurrency}
                />
              )}
              {model.commissions.hasCommissionCharges &&
                model.hasDifferentCurrency && (
                  <CostAndChargesItem
                    currency={instrument.currency}
                    commissionItem={
                      model.commissions.closeCommissionInstrumentCurrency
                    }
                  />
                )}
              {model.commissions.hasCommissionCharges && (
                <CostAndChargesItem
                  currency={store.user.activeTradingAcc().currency}
                  commissionItem={model.commissions.closeCommissionUserCurrency}
                />
              )}
              <CostAndChargesItem
                currency={store.user.activeTradingAcc().currency}
                commissionItem={model.commissions.total}
                isBold={true}
              />
              <Styled.TooltipRow>
                <Tooltip
                  placement='top-start'
                  title={
                    <React.Fragment>
                      <Styled.TooltipTitle>
                        {translate('DISCLAIMERS.DISCLAIMERS_ASSUMPTIONS')}:
                      </Styled.TooltipTitle>
                      <Styled.TooltipUL>
                        <Styled.TooltipLI>
                          {translate(
                            'DISCLAIMERS.PROJECTIONS_SHOULD_ONLY_BE_USED'
                          )}
                        </Styled.TooltipLI>
                        <Styled.TooltipLI>
                          {translate(
                            'DISCLAIMERS.INCLUDES_ONE_OFF_AND_ONGOING_COSTS'
                          )}
                        </Styled.TooltipLI>
                        <Styled.TooltipLI>
                          {translate(
                            'DISCLAIMERS.END_OF_DAY_PRICE_IS_THE_SAME_AS_ENTRY_PRICE'
                          )}
                        </Styled.TooltipLI>
                        <Styled.TooltipLI>
                          {translate(
                            'DISCLAIMERS.COMMISSIONS_INCLUDE_BOTH_OPEN_AND_CLOSE'
                          )}
                        </Styled.TooltipLI>
                        <Styled.TooltipLI>
                          {translate(
                            'DISCLAIMERS.OVERNIGHT_SWAP_PROJECTIONS_ASSUME'
                          )}
                        </Styled.TooltipLI>
                        <Styled.TooltipLI>
                          {translate(
                            'DISCLAIMERS.CONVERSION_FEE_IS_APPLIED_WHEN_INSTRUMENT_CURRENCY'
                          )}
                        </Styled.TooltipLI>
                        <Styled.TooltipLI>
                          {translate(
                            'DISCLAIMERS.COSTS_DISPLAYED_IN_CASH_AND_PERCENTAGE'
                          )}
                        </Styled.TooltipLI>
                      </Styled.TooltipUL>
                    </React.Fragment>
                  }
                >
                  <Icon iconType='info' />
                </Tooltip>
                <Styled.TooltipLabel>
                  {translate('DISCLAIMERS.DISCLAIMERS_ASSUMPTIONS')}
                </Styled.TooltipLabel>
              </Styled.TooltipRow>
            </Styled.CostAndCharges>
          )}
        </Styled.Content>
      </Styled.Root>
    );
  }
);

const CostAndChargesItem: React.FC<ICostAndChargesRowProps> = ({
  label,
  value,
  currency,
  isPercentageValue,
  commissionItem,
  isBold,
}) => {
  if (
    commissionItem?.value !== undefined &&
    commissionItem?.value !== null &&
    Number(commissionItem.value) === 0
  ) {
    return null;
  }

  return (
    <Styled.Row>
      <Styled.Label $isBold={isBold}>
        {label ?? commissionItem?.label}
        {currency !== undefined ? ` (${currency})` : ''}:
      </Styled.Label>
      <Styled.Value $isBold={isBold}>
        {value ?? ''}
        {isPercentageValue && '%'}
        {commissionItem && `${commissionItem.value}`}
        {commissionItem && commissionItem.inPercent !== undefined
          ? ` (${commissionItem.inPercent})`
          : ''}
      </Styled.Value>
    </Styled.Row>
  );
};
