import styled, { css } from 'styled-components';

import { Icon, Typography, Wrapper, Stack } from '@trader/components';

export const Value = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
  `};
`;

export const Portfolio = styled('div')`
  ${() => css`
    width: 256px;
    border-radius: 5px;
  `};
`;

export const PortfolioHeader = styled('div')`
  ${({ theme }) => css`
    padding: 10px 14px;
    border-bottom: 1px solid ${theme.palette.gray.main};
  `};
`;

export const PortfolioTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.large.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
  `};
`;

export const PortfolioContent = styled(Stack)`
  ${() => css`
    padding: 12px 14px;
  `};
`;

export const PortfolioItem = styled(Wrapper)`
  ${() => css`
    justify-content: space-between;
    align-items: center;
  `};
`;

export const Info = styled(Icon)`
  ${() => css`
    margin-right: 5px;
  `};
`;

export const BalanceContainer = styled(Wrapper)<{
  $width: number;
  $isBalanceEmpty?: boolean;
}>`
  ${({ $width, $isBalanceEmpty }) => css`
    margin-left: 5px;
    flex-direction: column;
    justify-content: flex-start;
    width: ${$width}px;
    max-width: ${$width}px;
    min-width: 60px;
    user-select: none;

    > div > div {
      width: ${$width}px;
      max-width: ${$width}px;
      min-width: ${$isBalanceEmpty ? '48px' : '60px'};
    }

    svg {
      margin-left: 7px;
    }
  `};
`;
