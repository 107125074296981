import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IScrollableListItemDetail,
  ScrollableList,
  ScrollableListItem,
  Typography,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { formatDate, thousand } from '@trader/utils';
import { ITransferHistoryBE, TPaymentStatus, TTransferType } from '@trader/api';

import { useTransfersHistory } from '../../hooks/useTransfersHistory';
import { useTranslationMaps } from '../../hooks/useTranslationMaps';
import { TransferFilters } from './filters';

export const MobileTransfersHistory: FC = observer(() => {
  const { translate } = useI18next();
  const { transfers, fetchTransfersHistory, fetchMoreHistory } =
    useTransfersHistory();

  return (
    <div style={{ height: '100%' }}>
      <TransferFilters onFiltersChange={fetchTransfersHistory} />
      <ScrollableList<ITransferHistoryBE>
        data={transfers.data}
        renderItem={t => <TransfersHistoryItem transfer={t} />}
        emptyMessage={translate('COMMON.LABELS.NO_TRANSFERS')}
        isLoading={transfers.isLoading}
        totalCount={transfers.totalCount}
        onLoadMore={fetchMoreHistory}
      />
    </div>
  );
});

const TransfersHistoryItem: FC<{ transfer: ITransferHistoryBE }> = observer(
  ({ transfer }) => {
    const { translate, currentLng } = useI18next();
    const { statusTranslationMap, transferTypesTranslationMap } =
      useTranslationMaps();

    const detailsItems: IScrollableListItemDetail[] = [
      {
        id: 'currencySymbol',
        header: translate('COMMON.LABELS.SYMBOL'),
        value: transfer.currencySymbol,
      },
      {
        id: 'amount',
        header: translate('COMMON.LABELS.AMOUNT'),
        value: transfer.value,
      },
      {
        id: 'status',
        header: 'Status',
        value: statusTranslationMap[transfer.status as TPaymentStatus],
      },
      {
        id: 'transferType',
        header: translate('COMMON.LABELS.TYPE'),
        value:
          transferTypesTranslationMap[transfer.transferType as TTransferType],
      },
    ];

    return (
      <ScrollableListItem
        leadingContent={<Typography>{transfer.transferId}</Typography>}
        trailingContent={
          <Typography>
            {formatDate(
              new Date((transfer.date.seconds as number) * thousand),
              'Mm dd, yyyy',
              { locale: currentLng }
            )}
          </Typography>
        }
        detailsItems={detailsItems}
      />
    );
  }
);
