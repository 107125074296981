import { calcPL, OrderSide } from '@tchtrade/trade-core';

import { TPlaceOrderSide } from '@trader/types';
import { formatByPipSize } from '@trader/utils';

// The optional params needs here to match two interfaces.
// We need to refactor to keep the same naming convention.
// TODO: (av) Refactor the naming to remove the uneeded optional params here.
type TPosition = {
  side: TPlaceOrderSide;
  price?: number;
  currentPrice?: number;
  openPrice: number;
  quantity: number;
  pipSize: number;
  tickValue: number;
  tickSize: number;
  contractSize: number;
  calcMode: number;
  conversionRate: number;
  swap?: number;
};

export const calcPositionNetPl = (position: TPosition) =>
  calcPositionPl(position) + (position?.swap || 0);

export const calcPositionPl = (position: TPosition) => {
  const normalize = (value: number) =>
    +formatByPipSize(value, position.pipSize);

  const value = calcPL({
    currentPrice: position.price || position.currentPrice || 0,
    openPrice: position.openPrice,
    side: position.side as OrderSide,
    quantity: position.quantity,
    calcMode: position.calcMode,
    tickValue: position.tickValue,
    tickSize: position.tickSize,
    contractSize: position.contractSize,
    pipSize: position.pipSize,
  });

  return normalize(value * position.conversionRate);
};

export const calcAllPositionsPl = (
  positions: (TPosition & { swap: number })[],
  options?: { withoutSwap?: boolean }
) => {
  if (!positions.length) {
    return 0;
  }

  return positions.reduce((acc, curr) => {
    const value = (options?.withoutSwap ? 0 : curr.swap) + calcPositionPl(curr);
    return acc + value;
  }, 0);
};
