import { Fragment, ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTheme } from 'styled-components';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { IChangeFieldEvent } from '@trader/types';

import { ControlInfo } from '../controlInfo';
import * as Styled from './styled';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface IDateTimePickerProps {
  value?: Date | null;
  minValue?: Date | null;
  maxValue?: Date | null;
  helperText?: string;
  onChange?: (event: IChangeFieldEvent<Date | null>) => void;
  isError?: boolean;
  label?: string | ReactNode;
  name?: string;
  shouldHideControlInfo?: boolean;
  readonly?: boolean;
}

export const DateTimePicker = ({
  value,
  minValue,
  maxValue,
  onChange,
  helperText,
  label,
  isError,
  shouldHideControlInfo,
  readonly,
  name,
}: IDateTimePickerProps) => {
  const theme = useTheme();

  const handleChange = (v: dayjs.Dayjs | null) => {
    let newValue = v ? (v as unknown as Dayjs) : null;

    if (!newValue) {
      return;
    }

    if (newValue.utc().isBefore(dayjs.utc())) {
      newValue = dayjs.utc().set('minute', dayjs.utc().minute() + 1);
    }

    onChange?.({
      target: {
        name: name || '',
        value: newValue ? newValue.toDate() : null,
      },
    });
  };

  return (
    <Fragment>
      <MuiDateTimePicker
        className='date-time-picker'
        format='DD/MM/YYYY HH:mm'
        label={label}
        timezone='UTC'
        value={value ? dayjs.utc(value) : undefined}
        minDateTime={minValue ? dayjs.utc(minValue) : undefined}
        maxDateTime={maxValue ? dayjs.utc(maxValue) : undefined}
        ampm={false}
        slots={{
          openPickerIcon: () => <Styled.CalendarIcon iconType='calendar' />,
        }}
        slotProps={{
          textField: {
            inputProps: {
              readOnly: readonly,
            },
            sx: {
              '& .MuiInputBase-input': {
                fontSize: theme.typography.medium.fontSize,
                padding: '8px',
              },
              '& .MuiInputLabel-root': {
                fontSize: theme.typography.large.fontSize,
                transform: 'translate(12px, 7px) scale(1)',

                '&.MuiInputLabel-shrink': {
                  transform: 'translate(15px, -10px) scale(0.85)',
                },
              },
            },
          },
          day: {
            sx: {
              fontSize: theme.typography.small.fontSize,

              '&.Mui-selected': {
                backgroundColor: `${theme.palette.primary.main}!important`,
              },
            },
          },
          desktopPaper: {
            sx: {
              width: '480px',
              height: '385px',

              '.MuiPickersCalendarHeader-labelContainer': {
                fontSize: theme.typography.large.fontSize,
              },

              '.MuiPickersYear-root': {
                height: '35px',

                button: {
                  fontSize: theme.typography.default.fontSize,
                },

                '.Mui-selected': {
                  backgroundColor: `${theme.palette.primary.main}!important`,
                },
              },

              '.MuiDayCalendar-weekDayLabel': {
                fontSize: theme.typography.small.fontSize,
              },
            },
          },
        }}
        onChange={handleChange}
      />
      <ControlInfo
        isError={isError}
        helperText={helperText}
        isHide={shouldHideControlInfo}
      />
    </Fragment>
  );
};
