import React from 'react';

import { TCreateTableModelInstance } from '@trader/store';
import { ITableColumn } from '@trader/types';

import { SortingButton } from './sortingButton';
import { Filtering } from './filtering';

import { Header, Row, Cell, HeaderAction } from '../../styled';

interface ITableHeader {
  tableStore: TCreateTableModelInstance;
  columns: ITableColumn[];
}

export const TableHeader: React.FC<ITableHeader> = ({
  columns,
  tableStore,
}) => {
  return (
    <Header>
      <Row>
        {columns.map(column => (
          <Cell
            key={column.id}
            align={column.align || 'center'}
            style={{ ...column.headerStyle, width: column.minWidth }}
          >
            {column.header}
            {(column.isSortable && column.isFilterable) !== false && (
              <HeaderAction>
                <SortingButton
                  columnId={column.id}
                  isSortable={column.isSortable ?? true}
                  tableStore={tableStore}
                />
                <Filtering
                  columnId={column.id}
                  columnType={column.type}
                  isFilterable={column.isFilterable ?? true}
                  tableStore={tableStore}
                  customFilter={column.customFilter}
                />
              </HeaderAction>
            )}
          </Cell>
        ))}
      </Row>
    </Header>
  );
};
