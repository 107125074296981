import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Challenges } from '@trader/containers';
import { EChallenge, EChallengeMode, EChallengeStatus } from '@trader/types';
import { useMst } from '@trader/store';
import { NAVIGATE_TO } from '@trader/constants';
import { useNavigation } from '@trader/hooks';
import { Progress } from '@trader/components';

import { useRenderCellActions } from './hooks/useRenderCellActions';
import { BulkActions } from './components/bulkActions';
import * as Styled from './desktop.styled';

export const DesktopCopier: React.FC = observer(() => {
  const store = useMst();
  const { navigateTo } = useNavigation();
  const {
    renderRightGeneralAction,
    renderRightChosenAction,
    renderLeftAction,
  } = useRenderCellActions();

  const activeChallenge = store.user.getActiveAccountChallenge();
  const nonAssignedChallenges = store.pages.copier
    .getChallengesByType(EChallenge.NonAssigned)
    ?.filter(chl => chl.mode !== EChallengeMode.Practice);
  const masterChallenges = store.pages.copier.getChallengesByType(
    EChallenge.Master
  );
  const assignedChallenges = [
    ...masterChallenges,
    ...store.pages.copier.getChallengesByType(EChallenge.Slave),
  ].filter(chl => chl.mode !== EChallengeMode.Practice);

  const shouldShowLoader =
    store.pages.copier.removeAccountAsync.inProgress ||
    store.pages.copier.assignMasterAsync.inProgress ||
    store.pages.copier.assignSlaveAsync.inProgress ||
    (store.entities.challenges.getChallengesAsync.inProgress &&
      !store.entities.challenges.getChallengesAsync.hasEverBeenRun);

  useEffect(() => {
    store.entities.tradingAccounts.getTradingAccountsAsync.run();
    store.entities.challenges.getChallengesAsync.run();
  }, []);

  useEffect(() => {
    if (activeChallenge?.status === EChallengeStatus.Disable) {
      navigateTo(NAVIGATE_TO.main.myAccount, {
        state: {
          disableMultipleLayouts: true,
          disableTradingNavigation: true,
        },
      });
    }
  }, [activeChallenge?.status]);

  return (
    <Styled.Root>
      {shouldShowLoader && (
        <Styled.ProgressWrapper>
          <Progress size={22} />
        </Styled.ProgressWrapper>
      )}
      <Styled.Body>
        {nonAssignedChallenges.length ? (
          <Challenges
            dividedChallenges={nonAssignedChallenges}
            shouldTriggerGetChallenges={false}
            rightActionWidth={320}
            leftAction={renderLeftAction}
            rightAction={renderRightGeneralAction}
          />
        ) : (
          <Challenges
            dividedChallenges={[]}
            shouldTriggerGetChallenges={false}
            rightActionWidth={0}
            isMasterEmpty={false}
          />
        )}
        <BulkActions />
        {assignedChallenges.length ? (
          <Challenges
            shouldDisplayAccTypeIcon
            shouldDisplayId
            dividedChallenges={assignedChallenges}
            shouldTriggerGetChallenges={false}
            rightActionWidth={40}
            rightAction={renderRightChosenAction}
          />
        ) : (
          <Challenges
            dividedChallenges={[]}
            shouldTriggerGetChallenges={false}
            rightActionWidth={0}
            isMasterEmpty={true}
          />
        )}
      </Styled.Body>
    </Styled.Root>
  );
});
