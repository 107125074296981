import styled, { css } from 'styled-components';
import { Box as MuiBox, BoxProps } from '@mui/material';

interface IProps extends BoxProps {
  $margin: BoxProps['margin'];
  $marginTop: BoxProps['marginTop'];
  $marginRight: BoxProps['marginRight'];
  $marginBottom: BoxProps['marginBottom'];
  $marginLeft: BoxProps['marginLeft'];
  $padding: BoxProps['marginTop'];
  $paddingTop: BoxProps['marginTop'];
  $paddingRight: BoxProps['marginTop'];
  $paddingBottom: BoxProps['marginTop'];
  $paddingLeft: BoxProps['marginTop'];
}

// Custom Box component to handle spacing correctly in RTL mode.
export const Box = styled(MuiBox)<IProps>`
  ${({
    theme,
    $margin,
    $marginTop,
    $marginRight,
    $marginBottom,
    $marginLeft,
    $padding,
    $paddingTop,
    $paddingRight,
    $paddingBottom,
    $paddingLeft,
  }) => css`
    margin: ${typeof $margin === 'number' ? theme.spacing($margin) : $margin};
    margin-right: ${typeof $marginRight === 'number'
      ? theme.spacing($marginRight)
      : $marginRight};
    margin-bottom: ${typeof $marginBottom === 'number'
      ? theme.spacing($marginBottom)
      : $marginBottom};
    margin-left: ${typeof $marginLeft === 'number'
      ? theme.spacing($marginLeft)
      : $marginLeft};
    margin-top: ${typeof $marginTop === 'number'
      ? theme.spacing($marginTop)
      : $marginTop};

    padding: ${typeof $padding === 'number'
      ? theme.spacing($padding)
      : $padding};
    padding-right: ${typeof $paddingRight === 'number'
      ? theme.spacing($paddingRight)
      : $paddingRight};
    padding-bottom: ${typeof $paddingBottom === 'number'
      ? theme.spacing($paddingBottom)
      : $paddingBottom};
    padding-left: ${typeof $paddingLeft === 'number'
      ? theme.spacing($paddingLeft)
      : $paddingLeft};
    padding-top: ${typeof $paddingTop === 'number'
      ? theme.spacing($paddingTop)
      : $paddingTop};
  `}
`;
