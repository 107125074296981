import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';
import { layoutChildrenRadius } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 100%;
  `}
`;

export const Body = styled(Wrapper)`
  ${({ theme }) => css`
    padding-bottom: 50px;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-radius: ${layoutChildrenRadius}px;
    background: ${theme.palette.background.paper};

    ${theme.breakpoints.down('sm')} {
      padding-top: 16px;
      overflow: auto;
    }
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    display: block;
    padding: 27px;
    margin-bottom: 30px;
    font-size: ${theme.typography.heading.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    border-bottom: 1px solid ${theme.palette.background.default};
  `}
`;
