import React from 'react';
import { useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import { useI18next } from '@trader/services';
import { fixToTwoDigitAfterDot } from '@trader/utils';
import { Typography, Wrapper, Stack } from '@trader/components';

import * as Styled from '../styled';
import { TLegendColorType } from '../styled';

interface IProfitSummaryChart {
  chartLegend: { label: string; value: number; type: string }[];
  chartData: { max: number; total: number; name: string }[];
}

export const ProfitSummaryChart: React.FC<IProfitSummaryChart> = ({
  chartLegend,
  chartData,
}) => {
  const { translate } = useI18next();
  const theme = useTheme();

  if (isEmpty(chartData)) {
    return null;
  }

  return (
    <Styled.ProfitSummaryChart>
      <Wrapper alignItems='center' marginBottom='24px'>
        <Typography variant='xl' fontWeight='medium' marginRight='16px'>
          {translate('COMMON.LABELS.PROFIT')}
        </Typography>
        <Stack direction='row' spacing='16px'>
          {chartLegend.map(row => (
            <Styled.DataRow key={row.label}>
              <Styled.LegendColor $type={row.type as TLegendColorType} />
              <Styled.Label>{row.label}</Styled.Label>
              <Styled.ChartNumber $withBorder>
                {+fixToTwoDigitAfterDot(row.value)}
              </Styled.ChartNumber>
            </Styled.DataRow>
          ))}
        </Stack>
      </Wrapper>
      <ResponsiveContainer width='100%' height={220}>
        <LineChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' tick={{ fontSize: 10 }} />
          <YAxis yAxisId='left' tick={{ fontSize: 10 }} />
          <YAxis yAxisId='right' orientation='right' tick={{ fontSize: 10 }} />
          <Line
            yAxisId='left'
            type='monotone'
            dataKey='max'
            stroke={theme.palette.blue['300']}
            activeDot={{ r: 8 }}
            dot={false}
          />
          <Line
            yAxisId='right'
            type='monotone'
            dataKey='total'
            stroke={theme.palette.yellow.dark}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </Styled.ProfitSummaryChart>
  );
};
