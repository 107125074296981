import { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';

import { MODAL_TYPES } from '@trader/constants';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { Checkbox, ScrollableItemActions } from '@trader/components';

import { StickyPosition } from '../../desktop.styled';

interface IStickyPositionColumn {
  position: TPositionMetricEntity;
  withClickableSymbol?: boolean;
}

export const StickyPositionColumn: FC<IStickyPositionColumn> = observer(
  ({ position, withClickableSymbol }) => {
    const store = useMst();

    const symbol = !withClickableSymbol
      ? store.pages.muliBands.symbol
      : store.pages.trading.getInstrumentSymbolByLayout();

    const handleEditClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      store.ui.modal.open(MODAL_TYPES.addProtection, {
        symbol: position.symbol,
        positionId: position.positionId,
        side: position.side,
      });
    };

    const handleRemoveClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      store.ui.modal.open(MODAL_TYPES.closePosition, {
        symbol: position.symbol,
        positionId: position.positionId,
        side: position.side,
      });
    };

    return (
      <StickyPosition>
        <Checkbox
          isDisabled={position.symbol !== symbol}
          size='small'
          checked={position.showOnTradingViewChart}
          onChange={position.toggleShowOnChart}
        />
        <ScrollableItemActions
          onEditClick={handleEditClick}
          onRemoveClick={handleRemoveClick}
        />
      </StickyPosition>
    );
  }
);
