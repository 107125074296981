import styled, { css } from 'styled-components';
import { conditionApply } from '@trader/utils';
import { Wrapper } from '@trader/components';
import {
  Paper,
  PaperProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
  TableRowProps,
} from '@mui/material';

interface IItemSticky {
  $isLastItemSticky?: boolean;
  $isFirstItemSticky?: boolean;
}

export const TablePaper = styled(Paper)<PaperProps>`
  ${({ theme }) => css`
    background: ${theme.palette.common.white};
  `};
`;
export const MuiTable = styled(Table)<IItemSticky>`
  ${({ theme, $isLastItemSticky, $isFirstItemSticky }) => css`
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;

    ${conditionApply(
      [$isFirstItemSticky],
      css`
        tbody > tr > td:first-child,
        thead > tr > th:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
          border-right: 1px solid
            ${theme.palette.mode === 'dark'
              ? 'rgb(81, 81, 81)'
              : theme.palette.gray.light};
        }
      `
    )}

    ${conditionApply(
      [$isLastItemSticky],
      css`
        tbody > tr > td:last-child,
        thead > tr > th:last-child {
          position: sticky;
          right: 0;
          z-index: 1;
          border-left: 1px solid
            ${theme.palette.mode === 'dark'
              ? 'rgb(81, 81, 81)'
              : theme.palette.gray.light};
        }

        thead > tr > th:last-child {
          z-index: 2;
        }
      `
    )}
  `};
`;

export const Body = styled(TableBody)`
  ${() => css``};
`;

export const Header = styled(TableHead)`
  ${({ theme }) => css`
    background: ${theme.palette.common.white};

    tr {
      min-height: 35px;
      max-height: 35px;
      height: 35px;
    }

    th {
      font-weight: normal;
      color: ${theme.palette.gray['400']};
      font-size: ${theme.typography.default.fontSize};
      border: none;
      border-bottom: 1px solid
        ${theme.palette.mode === 'dark'
          ? 'rgb(81, 81, 81)'
          : theme.palette.gray.light};
    }
  `};
`;

export const Row = styled(TableRow)<TableRowProps>`
  ${() => css`
    min-height: 40px;
    max-height: 40px;
    height: 40px;
  `};
`;

export const Cell = styled(TableCell)`
  ${({ theme }) => css`
    .Sort_Filter_Button_Hover {
      display: none;
    }

    &:hover {
      .Sort_Filter_Button_Hover {
        display: flex;
      }
    }

    padding: 0;
    font-size: ${theme.typography.small.fontSize};
    border: none;
    border-bottom: 1px solid
      ${theme.palette.mode === 'dark'
        ? 'rgb(81, 81, 81)'
        : theme.palette.gray.light};
    background-color: ${theme.palette.common.white};
  `};
`;

export const HeaderAction = styled(Wrapper)`
  ${() => css`
    display: inline-flex;
  `};
`;

export const NoData = styled(Wrapper)`
  ${({ theme }) => css`
    background-color: ${theme.palette.common.white};
    width: 100%;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    > svg {
      margin-bottom: 10px;
    }
  `};
`;

export const MuiPagination = styled(TablePagination)<TablePaginationProps>`
  ${({ theme }) => css`
    background-color: ${theme.palette.common.white};

    p {
      margin: 0;
    }

    button {
      padding: 0;
      margin-right: 8px;
    }

    > div {
      min-height: 35px;
      height: 35px;
    }
  `};
`;
