import { useCallback, useContext, useEffect } from 'react';
import Decimal from 'decimal.js';

import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
} from '@trader/services';
import { TPositionMetricEntity, useMst } from '@trader/store';
import { TradingNotificationsContext } from '@trader/contexts';
import { ESocketUpdateAction, IPositionMessage } from '@trader/types';

import { useStartConnection } from './core';
import { formatByPipSize } from '@tchtrade/trade-core';

/**
 * Custom hook that manages position updates by subscribing to a hub
 * and handling incoming messages to update the store.
 */
export const usePositions = () => {
  const store = useMst();
  const { handleSaveMessage } = useContext(TradingNotificationsContext);

  const { connection } = useStartConnection(
    EConnectionHub.Events,
    EConnectionSubscription.Positions,
    'events'
  );

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const serverName = activeTradingAccount?.serverName;

  const handleMessage = useCallback((message: IPositionMessage) => {
    switch (message.updateAction) {
      case ESocketUpdateAction.Create:
        store.entities.positionsMetrics.positionOptimisticCreateAsync.run(
          message
        );
        handleSaveMessage({ type: 'position', ...message });
        break;
      case ESocketUpdateAction.Delete:
        store.entities.positionsMetrics.positionOptimisticDeleteAsync.run(
          String(message.id)
        );
        handleSaveMessage({ type: 'position', ...message });
        break;
      case ESocketUpdateAction.Update: {
        const currentPosition =
          store.entities.positionsMetrics.get<TPositionMetricEntity>(
            message.id
          );

        if (!currentPosition) return;

        const decimalQuantity = new Decimal(currentPosition?.quantity);

        const isPartiallyClosed = currentPosition?.quantity > message.volume;

        message.takeProfit &&
          (message.takeProfit = +formatByPipSize(
            message.takeProfit,
            currentPosition.pipSize
          ));

        message.stopLoss &&
          (message.stopLoss = +formatByPipSize(
            message.stopLoss,
            currentPosition.pipSize
          ));

        if (isPartiallyClosed) {
          message.updateAction = ESocketUpdateAction.Delete;
          message.volume = decimalQuantity.minus(message.volume).toNumber();
        }

        store.entities.positionsMetrics.positionOptimisticUpdateAsync.run({
          ...message,
          volume: isPartiallyClosed
            ? decimalQuantity.minus(message.volume).toNumber()
            : message.volume,
        });

        handleSaveMessage({ type: 'position', ...message });
        break;
      }
      default:
        break;
    }

    store.entities.tradingAccounts.getTradingAccountsAsync.run();
  }, []);

  useEffect(() => {
    if (platformLogin && serverName) {
      connection.subscribe(async (hub: HubConnection) => {
        await hub.send('SubscribeOnPositions', serverName, platformLogin);
        hub.on('OnPosition', handleMessage);
      });

      return () => {
        connection.unsubscribe(async hub => {
          hub.off('OnPosition', handleMessage);
          await hub?.send(
            'UnsubscribeFromPositions',
            serverName,
            platformLogin
          );
        });
      };
    }
  }, [platformLogin, idToken]);
};
