import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { ITableColumn } from '@trader/types';
import { useI18next } from '@trader/services';
import { MODAL_TYPES } from '@trader/constants';
import { Table, SymbolWithIcon } from '@trader/components';
import { TOrderMetricEntity, useMst } from '@trader/store';
import { useExtendItemWidthDependsLng } from '@trader/hooks';
import { formatTableRowValueByPipSize } from '@trader/utils';

import { usePendingOrdersListMetrics } from './hooks/usePendingOrdersListMetrics';
import { PurchaseType, SymbolWithIconButton } from '../scrollingList';
import { StickyOrderColumn } from './components/stickyOrderColumn';
import { IPendingOrdersList } from './index';

import * as Styled from './desktop.styled';

export const DesktopPendingOrdersList: React.FC<IPendingOrdersList> = observer(
  ({ symbol, withClickableSymbol }) => {
    const store = useMst();
    const { translate, currentLng } = useI18next();
    const { getExtendedWidth } = useExtendItemWidthDependsLng();
    const { pendingOrdersMetrics } = usePendingOrdersListMetrics(symbol);

    const columns: ITableColumn<TOrderMetricEntity>[] = useMemo(
      () => [
        {
          id: 'symbol',
          header: translate('COMMON.LABELS.SYMBOL'),
          minWidth: getExtendedWidth('110', '1.5', ['nl']),
          align: 'left',
          headerStyle: {
            paddingLeft: withClickableSymbol ? '15px' : '20px',
          },
          render: row =>
            withClickableSymbol ? (
              <SymbolWithIconButton symbol={row.symbol} iconUrl={row.iconUrl} />
            ) : (
              <SymbolWithIcon symbol={row.symbol} iconUrl={row.iconUrl} />
            ),
        },
        {
          id: 'side',
          header: translate('COMMON.LABELS.TYPE'),
          minWidth: getExtendedWidth('120', '2', ['nl']),
          render: row => <PurchaseType value={row.side as string} />,
        },
        {
          id: 'quantity',
          minWidth: 120,
          header: translate('COMMON.LABELS.VOLUME'),
        },
        {
          id: 'stopLoss',
          header: translate('COMMON.LABELS.SL'),
          minWidth: 120,
          render: row =>
            formatTableRowValueByPipSize(row.stopLoss, row.pipSize),
        },
        {
          id: 'takeProfit',
          header: translate('COMMON.LABELS.TP'),
          minWidth: 120,
          render: row =>
            formatTableRowValueByPipSize(row.takeProfit, row.pipSize),
        },
        {
          id: 'limitPrice',
          header: translate('COMMON.LABELS.LIMIT_PRICE'),
          minWidth: 150,
          render: row =>
            formatTableRowValueByPipSize(row.limitPrice, row.pipSize),
        },
        {
          id: 'stopPrice',
          header: translate('COMMON.LABELS.STOP_PRICE'),
          minWidth: 150,
          render: row =>
            formatTableRowValueByPipSize(row.stopPrice, row.pipSize),
        },
        {
          id: 'actions',
          header: pendingOrdersMetrics.length ? (
            <Styled.CloseAllHeader
              onClick={() => {
                store.ui.modal.open(MODAL_TYPES.removeAllMetrics, {
                  shouldHideCloseButton: true,
                  removingType: 'orders',
                });
              }}
            >
              {translate('COMMON.LABELS.CLOSE_ALL')}
            </Styled.CloseAllHeader>
          ) : (
            ''
          ),
          isSortable: false,
          isFilterable: false,
          minWidth: getExtendedWidth('110', '1.35', ['sk']),
          headerStyle: { textAlign: 'right', paddingRight: '20px' },
          render: row => (
            <StickyOrderColumn
              withClickableSymbol={withClickableSymbol}
              pendingOrder={row}
            />
          ),
        },
      ],
      [pendingOrdersMetrics.length, currentLng]
    );

    return (
      <Styled.Root>
        <Table
          store={store.tables.orders}
          emptyMessage={translate('COMMON.LABELS.NO_ORDERS')}
          columns={columns}
          isLastItemSticky
        />
      </Styled.Root>
    );
  }
);
