import styled, { css } from 'styled-components';

import { Typography, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

const labelWidth = 10;

export const Title = styled(Typography)<{ $withoutTopSpacing?: boolean }>`
  ${({ theme, $withoutTopSpacing }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin: 25px 0 8px 0;

    ${conditionApply(
      [$withoutTopSpacing],
      css`
        margin: 8px 0;
      `
    )};
  `}
`;

export const Row = styled(Wrapper)<{ $withTopBorder: boolean }>`
  ${({ theme, $withTopBorder }) => css`
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed ${theme.palette.gray['200']};
    padding: 8px 0;

    > div:last-child {
      max-width: 60%;
    }

    ${conditionApply(
      [$withTopBorder],
      css`
        border-top: 1px dashed ${theme.palette.gray['200']};
      `
    )};
  `}
`;

export const Label = styled(Typography)<{ $color?: string; $width?: number }>`
  ${({ theme, $color, $width }) => css`
    font-size: ${theme.typography.tiny.fontSize};
    text-align: left;
    min-width: max-content;

    ${conditionApply(
      [$color],
      css`
        color: ${$color};
      `
    )};

    ${conditionApply(
      [$width],
      css`
        width: ${($width as number) * labelWidth}px;
      `
    )};
  `}
`;

export const Value = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.tiny.fontSize};
    text-align: right;
    white-space: wrap;
  `}
`;

interface ITrend {
  $type: 'sold' | 'bought';
  $width: number;
  $isFetched: boolean;
  $isGray?: boolean;
}

export const TradersTrendRoot = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 41px;
    box-sizing: content-box;
    border-radius: 6px;
    padding: 5px 0;
    border: 0.5px solid ${theme.palette.gray.light};
  `};
`;
export const Trend = styled(Wrapper)<ITrend>`
  ${({ theme, $type, $width, $isFetched, $isGray }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 6px;
    height: 40px;
    padding: 5px 12px;

    ${conditionApply(
      [$type === 'sold' && $isFetched],
      css`
        align-items: flex-start;
        border: ${$width
          ? `0.5px solid ${theme.palette.purchaseButtons.red}`
          : `0.5px solid ${theme.palette.purchaseButtons.green}`};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none !important;
        width: ${$width}%;
      `
    )};

    ${conditionApply(
      [$type === 'bought' && $isFetched],
      css`
        align-items: flex-end;
        border: ${$width
          ? `0.5px solid ${theme.palette.purchaseButtons.green}`
          : `0.5px solid ${theme.palette.purchaseButtons.red}`};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none !important;
        width: ${$width}%;
      `
    )};

    ${conditionApply(
      [$isGray],
      css`
        border: 0.5px solid ${theme.palette.gray.light};
      `
    )};

    .bought {
      color: ${theme.palette.purchaseButtons.green};
      text-align: end;
    }

    .sold {
      color: ${theme.palette.purchaseButtons.red};
      text-align: start;
    }
  `};
`;
export const TrendPrice = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.base.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
  `};
`;
export const TrendTitle = styled(Typography)`
  ${({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
    margin-bottom: 4px;
  `};
`;
