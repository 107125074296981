import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useMst } from '@trader/store';
import { useInstruments } from '@trader/hooks';
import { TCommonOrderType } from '@trader/types';
import { EConnectionSubscription } from '@trader/services';

interface ITradingInitializer {
  children: React.ReactNode;
  modelType?: TCommonOrderType;
}

export const TradingInitializer: React.FC<ITradingInitializer> = observer(
  ({ children, modelType = 'createOrder' }) => {
    const store = useMst();

    const trading = store.trading.getTrading(modelType);

    const tradingSymbol = store.pages.trading.getInstrumentSymbolByLayout();
    const muliBandsSymbol = store.pages.muliBands.symbol;
    const symbol =
      modelType !== 'createStrategyBand' ? tradingSymbol : muliBandsSymbol;

    useInstruments({
      instrumentsIds: [symbol],
      subscription: EConnectionSubscription.Instrument,
    });

    useEffect(() => {
      if (symbol) {
        trading.getInstrumentReferenceAsync.run({
          symbol,
          side: null,
        });
      }
    }, [symbol]);

    return children;
  }
);
