import React from 'react';

import { Typography, Wrapper } from '@trader/components';
import { defaultIcon, iconSizes } from '@trader/constants';
import { useMst } from '@trader/store';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';

interface ISymbolWithIcon {
  iconUrl?: string | null;
  iconSize?: number;
  textVariant?: 'tiny' | 'medium';
  symbol: string;
}

export const SymbolWithIcon: React.FC<ISymbolWithIcon> = ({
  iconUrl,
  iconSize = iconSizes.extraSmall,
  textVariant = 'tiny',
  symbol,
}) => {
  return (
    <Wrapper alignItems='center'>
      <img
        src={iconUrl || defaultIcon}
        alt='instrument icon'
        width={iconSize}
        height={iconSize}
      />
      <Typography variant={textVariant} fontWeight='medium' marginLeft='5px'>
        {symbol}
      </Typography>
    </Wrapper>
  );
};

export const SymbolWithIconButton: React.FC<ISymbolWithIcon> = observer(
  ({
    iconUrl,
    iconSize = iconSizes.extraSmall,
    textVariant = 'tiny',
    symbol,
  }) => {
    const store = useMst();

    const trading = store.trading.getTrading('createOrder');

    const selectInstrument = () => {
      store.pages.trading.layout.selectInstrument(symbol);
      trading.getInstrumentReferenceAsync.run({
        symbol,
        side: null,
      });
    };

    return (
      <Styled.SymbolButton fullWidth variant='text' onClick={selectInstrument}>
        <img
          src={iconUrl || defaultIcon}
          alt='instrument icon'
          width={iconSize}
          height={iconSize}
        />
        <Typography variant={textVariant} fontWeight='medium' marginLeft='5px'>
          {symbol}
        </Typography>
      </Styled.SymbolButton>
    );
  }
);
