import { IMessage } from '@trader/types';
import { TAlertEntity, useMst } from '@trader/store';
import { formatByPipSize, getSpreadDifference } from '@trader/utils';

import { useInstruments } from './useInstruments';
import { EConnectionSubscription } from '@trader/services';
import { useCallback } from 'react';

export const usePriceAlertsCurrentPrice = () => {
  const store = useMst();

  const allAlerts = store.entities.alerts.getAll<TAlertEntity>();

  const priceAlerts = store.entities.alerts.getAll<TAlertEntity>();
  const priceAlertsSymbols = priceAlerts.map(
    pa => pa.conditions[0].instrument.symbol
  );
  const uniqueSymbols = new Set(priceAlertsSymbols);

  const handleMessage = useCallback(
    (message: IMessage) => {
      if (!uniqueSymbols.has(message.s)) return;

      const alertsBySymbol = allAlerts.filter(
        alert => alert.conditions[0].instrument.symbol === message.s
      );

      if (!alertsBySymbol.length) return;

      for (const priceAlert of alertsBySymbol) {
        const { ask, bid } = getSpreadDifference(
          priceAlert.conditions[0].instrument.spreadDiff,
          message.a,
          message.b,
          priceAlert.conditions[0].instrument.spreadDiffBalance,
          priceAlert.conditions[0].instrument.pipSize
        );
        const indicatorType =
          priceAlert.conditions[0].leftExpression.indicatorType;
        const shouldSetBidPrice = indicatorType === 'Bid';
        const priceAlertEntity = store.entities.alerts.get<TAlertEntity>(
          priceAlert.id
        );
        priceAlertEntity.runInAction(() => {
          priceAlertEntity.conditions[0].instrument.currentPrice =
            shouldSetBidPrice
              ? +formatByPipSize(
                  bid,
                  priceAlert.conditions[0].instrument.pipSize
                )
              : +formatByPipSize(
                  ask,
                  priceAlert.conditions[0].instrument.pipSize
                );
        });
      }
    },
    [uniqueSymbols]
  );

  useInstruments({
    instrumentsIds: [...uniqueSymbols],
    onUpdate: handleMessage,
    subscription: EConnectionSubscription.PriceAlertsCurrentPrice,
  });
};
