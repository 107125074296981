import styled, { css } from 'styled-components';
import { Icon, Typography, Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    flex-direction: column;
    padding: 20px 25px;
    background-color: ${theme.palette.common.white};

    table {
      width: 100%;
      table-layout: fixed;
    }

    table * {
      color: ${theme.palette.common.black}!important;
    }

    p {
      color: ${theme.palette.common.black}!important;
    }

    a {
      background-color: ${theme.palette.common.black}!important;
      color: ${theme.palette.common.white}!important;
    }
  `}
`;

export const Header = styled(Wrapper)`
  ${() => css`
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Divider = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background-color: ${theme.palette.background.default};
    margin: 15px 0;
  `}
`;

export const Title = styled(Typography)`
  ${() => css`
    font-size: 1.5rem;
    font-weight: 600;
  `}
`;

export const SectionTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.heading.fontSize};
    font-weight: 600;
    margin-bottom: 10px;
  `}
`;

export const Subtitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.base.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
  `}
`;

export const Explanation = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.default.fontSize};
    margin: 8px 0 0 0;
  `}
`;

export const Label = styled('td')<{ $width?: number }>`
  ${({ theme, $width }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: 600;
    background-color: ${theme.palette.common.white};
    width: ${$width ? `${$width}px` : 'auto'};
    word-wrap: break-word;
  `}
`;

export const Value = styled('td')`
  ${({ theme }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    background-color: ${theme.palette.common.white};
  `}
`;

export const DealsValue = styled('td')`
  ${({ theme }) => css`
    font-size: ${theme.typography.medium.fontSize};
    font-weight: ${theme.typography.fontWeightBold};
    background-color: ${theme.palette.gray.light};
  `}
`;

export const Chart = styled('div')`
  ${({ theme }) => css`
    height: 200px;

    .recharts-responsive-container {
      font-size: ${theme.typography.medium.fontSize};
    }
  `}
`;

export const SubRowIcon = styled(Icon)`
  ${() => css`
    margin-right: 4px;
    width: 12px;
    height: '12px;
  `}
`;
