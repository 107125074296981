export enum EChallenge {
  Slave = 'SLAVE',
  Master = 'MASTER',
  NonAssigned = 'NON_ASSIGNED',
}

export enum EChallengeStatus {
  Active = 'ACTIVE',
  Disable = 'DISABLED',
}

export enum EChallengeResult {
  InProgress = 'In Progress',
  Failed = 'Failed',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export enum EChallengeEvaluationType {
  Evaluation = 'evaluation',
  Instant = 'instant',
}

export enum EChallengeMode {
  Real = 'REAL',
  Practice = 'PRACTICE',
}

export enum EChallengeFrequencyChoice {
  Offer = 'OFFER',
  Popular = 'MOST_POPULAR',
  Hot = 'HOT',
}
