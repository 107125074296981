import { useEffect, useState, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { IAccountStatementBE, IGetAccountStatementParams } from '@trader/api';
import {
  EmptyData,
  Progress,
  AccountStatementHtmlReport,
} from '@trader/components';
import { useMst } from '@trader/store';
import { useI18next } from '@trader/services';

export const AccountStatementReport: FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const [searchParams] = useSearchParams();

  const [accountStatement, setAccountStatement] = useState<
    IAccountStatementBE | undefined
  >();

  const fetchAccountStatement = async () => {
    const params: IGetAccountStatementParams = {
      from: searchParams.get('from') || '',
      to: searchParams.get('to') || '',
      platformLogin: searchParams.get('platformLogin') || '',
      accountType: searchParams.get('accountType') || '',
    };

    const resp = await store.pages.trading.getAccountStatementAsync.run(params);

    if (resp) {
      setAccountStatement(resp);
    }
  };

  useEffect(() => {
    fetchAccountStatement();
  }, []);

  if (
    (!accountStatement &&
      !store.pages.trading.getAccountStatementAsync.isError) ||
    store.pages.trading.getAccountStatementAsync.inProgress
  ) {
    return <Progress />;
  }

  if (store.pages.trading.getAccountStatementAsync.isError) {
    return <EmptyData title={translate('WARNINGS.FETCHING_DATA_FAILED')} />;
  }

  if (!accountStatement) {
    return;
  }

  return (
    <AccountStatementHtmlReport
      from={searchParams.get('from') || ''}
      to={searchParams.get('to') || ''}
      currency={searchParams.get('currency') || ''}
      platformLogin={searchParams.get('platformLogin') || ''}
      product={searchParams.get('product') || ''}
      accountType={searchParams.get('accountType') || ''}
      accountStatement={accountStatement}
    />
  );
});
