import { Instance, SnapshotIn, types } from 'mobx-state-tree';

import { TPlaceOrderSide, TPlaceOrderType } from '@trader/types';
import { runInAction } from '@trader/utils';
import {
  cancelAllOrdersAsync,
  cancelOrderAsync,
  editOrderAsync,
  getOrdersMetricsAsync,
  orderOptimisticCreateAsync,
  orderOptimisticUpdateAsync,
  orderOptimisticDeleteAsync,
} from './actions';

import { createCollectionModel } from '../../utils/collectionModel';

export const orderMetricModel = types
  .model('orderMetricModel', {
    symbol: types.string,
    iconUrl: types.maybeNull(types.string),
    pipSize: types.number,
    orderId: types.identifier,
    strategyId: types.optional(types.maybeNull(types.number), null),
    type: types.frozen<TPlaceOrderType>('Market'),
    side: types.frozen<TPlaceOrderSide>('Buy'),
    quantity: types.number,
    margin: types.number,
    limitPrice: types.maybeNull(types.number),
    stopPrice: types.maybeNull(types.number),
    takeProfit: types.maybeNull(types.number),
    stopLoss: types.maybeNull(types.number),

    // FE only
    showOnTradingViewChart: true,

    editOrderAsync,
    cancelOrderAsync,
  })
  .views(() => ({}))
  .actions(store => ({
    runInAction,
    toggleShowOnChart() {
      store.showOnTradingViewChart = !store.showOnTradingViewChart;
    },
  }));

interface IOrderMetricsAsync {
  getOrdersMetricsAsync: typeof getOrdersMetricsAsync;
  cancelAllOrdersAsync: typeof cancelAllOrdersAsync;
  orderOptimisticCreateAsync: typeof orderOptimisticCreateAsync;
  orderOptimisticUpdateAsync: typeof orderOptimisticUpdateAsync;
  orderOptimisticDeleteAsync: typeof orderOptimisticDeleteAsync;
}

export const ordersMetrics = createCollectionModel<
  typeof orderMetricModel,
  IOrderMetricsAsync
>(orderMetricModel, {
  getOrdersMetricsAsync,
  cancelAllOrdersAsync,
  orderOptimisticCreateAsync,
  orderOptimisticUpdateAsync,
  orderOptimisticDeleteAsync,
});

export type TOrdersMetricsEntity = Instance<typeof ordersMetrics>;
export type TOrderMetricEntity = Instance<typeof orderMetricModel>;
export type TOrderMetricIn = SnapshotIn<typeof orderMetricModel>;
