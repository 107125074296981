import { IHandleSubscriptionParams, TInstrumentsIds } from './types';
import { getServerGroupMap } from './subscribedInstruments';

export const beforeUnsubscribe = ({
  onSubscribe,
  subscription,
  instrumentsIds,
  serverName,
}: IHandleSubscriptionParams) => {
  const instrumentsToUnsubscribe: TInstrumentsIds = [];
  const serverGroup = getServerGroupMap(serverName);

  for (const instrument of instrumentsIds) {
    if (!instrument) continue;

    const item = serverGroup.instruments.get(instrument);
    if (!item) continue;

    const currentCount = item.subscriptionsAmount.get(subscription);
    if (!currentCount) continue;

    if (currentCount === 1) {
      item.subscriptionsAmount.delete(subscription);
      if (item.subscriptionsAmount.size === 0) {
        instrumentsToUnsubscribe.push(instrument);
      }
    } else {
      item.subscriptionsAmount.set(subscription, currentCount - 1);
    }
  }
  onSubscribe(instrumentsToUnsubscribe);
};
