import { useCallback, useState } from 'react';

import { Datepicker, Stack } from '@trader/components';
import { useI18next } from '@trader/services';
import { convertDateToEndOfDay } from '@trader/utils';

import * as Styled from './styled';

interface IProps {
  initialValues?: {
    from: Date | null;
    to: Date | null;
  };
  onApply: (from: Date | null, to: Date | null) => void;
}

export const CustomDateSelection = ({ initialValues, onApply }: IProps) => {
  const [interval, setInterval] = useState({
    from: initialValues?.from || null,
    to: initialValues?.to || null,
  });
  const { translate } = useI18next();

  const updateInterval = useCallback(
    (value: Partial<typeof interval>) => {
      const updatedInterval = {
        ...interval,
        ...value,
      };
      setInterval(updatedInterval);
    },
    [interval]
  );

  const handleApply = useCallback(() => {
    onApply(
      interval.from,
      interval.to ? convertDateToEndOfDay(interval.to) : null
    );
  }, [interval.from, interval.to, onApply]);

  return (
    <Styled.Wrapper spacing='10px'>
      <Stack>
        <Styled.Label>{translate('COMMON.LABELS.FROM')}</Styled.Label>
        <Datepicker
          value={interval.from}
          maxValue={interval.to || new Date()}
          onChange={event => updateInterval({ from: event.target.value })}
        />
      </Stack>
      <Stack>
        <Styled.Label>{translate('COMMON.LABELS.TO')}</Styled.Label>
        <Datepicker
          value={interval.to}
          minValue={interval.from}
          maxValue={new Date()}
          onChange={event => updateInterval({ to: event.target.value })}
        />
      </Stack>
      <Styled.ApplyButton onClick={handleApply}>
        {translate('COMMON.LABELS.APPLY')}
      </Styled.ApplyButton>
    </Styled.Wrapper>
  );
};
