import { FC, useEffect } from 'react';
import { TableContainer } from '@mui/material';
import { IAnyStateTreeNode } from 'mobx-state-tree';

import { TCreateTableModelInstance } from '@trader/store';
import { EFilterType } from '@trader/constants';
import { ITableColumn } from '@trader/types';

import { TableBody } from './components/tableBody';
import { Pagination } from './components/pagination';
import { TableHeader } from './components/tableHeader';

import { TablePaper, MuiTable } from './styled';

interface ITable {
  store: IAnyStateTreeNode;
  columns: ITableColumn[];
  emptyMessage: string;
  hasPagination?: boolean;
  isLastItemSticky?: boolean;
  isFirstItemSticky?: boolean;
}

export const Table: FC<ITable> = ({
  store,
  columns,
  emptyMessage,
  hasPagination,
  isLastItemSticky,
  isFirstItemSticky,
}) => {
  const tableStore = store.table as TCreateTableModelInstance;

  const tableContainerMaxHeight = hasPagination ? 'calc(100% - 35px)' : '100%';

  useEffect(() => {
    tableStore.setColumns(
      columns.map(col => ({
        option: {
          id: col.id,
          title: typeof col.header === 'string' ? col.header : col.id,
          value: col.id,
        },
        type: col.type ?? EFilterType.STRING,
      }))
    );
  }, [columns]);

  return (
    <TablePaper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: tableContainerMaxHeight }}>
        <MuiTable
          stickyHeader
          $isLastItemSticky={isLastItemSticky}
          $isFirstItemSticky={isFirstItemSticky}
        >
          <TableHeader tableStore={tableStore} columns={columns} />
          <TableBody
            tableStore={tableStore}
            columns={columns}
            emptyMessage={emptyMessage}
            hasPagination={hasPagination}
          />
        </MuiTable>
      </TableContainer>
      <Pagination store={store} hasPagination={hasPagination} />
    </TablePaper>
  );
};
