import { useCallback, useEffect } from 'react';

import { useMst } from '@trader/store';
import { ICustomerAccountMessage } from '@trader/types';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { useStartConnection } from './core';

/**
 * Custom hook that handles customer account updates.
 * It subscribes to the customer account hub and updates the user's profile based on incoming messages.
 */
export const useCustomerAccount = () => {
  const store = useMst();

  const { connection } = useStartConnection(
    EConnectionHub.Events,
    EConnectionSubscription.CustomerAccount,
    'events'
  );

  const idToken = store.auth.tokens.idToken;

  const handleMessage = useCallback((message: ICustomerAccountMessage) => {
    store.user.runInAction(() => {
      store.user.profile.isRegistrationCompleted =
        message.isRegistrationCompleted;
    });
  }, []);

  useEffect(() => {
    if (store.auth.isAuth) {
      connection.subscribe(hub => {
        hub.on('OnCustomerAccount', handleMessage);
      });
      return () => {
        connection.unsubscribe(hub => {
          hub.off('OnCustomerAccount', handleMessage);
        });
      };
    }
  }, [store.auth.isAuth, idToken]);
};
