import { Instance, types } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

import { createTableModel } from '../../utils/tableModel';
import { positionMetricModel } from '../../entities/positionsMetrics';

export const positionsTableModel = types
  .model('positionsTableModel', {
    table: createTableModel(positionMetricModel, { idAttribute: 'positionId' }),
  })
  .views(() => ({}))
  .actions(() => ({
    runInAction,
  }));

export const positionsTable = types.optional(positionsTableModel, {});

export type TPositionsTableModel = Instance<typeof positionsTableModel>;
