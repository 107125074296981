import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { NAVIGATE_TO } from '@trader/constants';
import { useI18next } from '@trader/services';

import { MobileUserDetailsLayout } from './mobile';
import { DesktopUserDetailsLayout } from './desktop';

export const UserDetailsLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { translate } = useI18next();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const titles = {
    [NAVIGATE_TO.main.myAccount]: translate('COMMON.LABELS.MY_ACCOUNT'),
    [NAVIGATE_TO.main.myHistory]: translate('COMMON.LABELS.MY_HISTORY'),
    [NAVIGATE_TO.main.settings]: translate('COMMON.LABELS.SETTINGS'),
    [NAVIGATE_TO.main.copier]: translate('COPIER.TITLE'),
    [NAVIGATE_TO.main.purchaseChallenges]: translate(
      'PURCHASE_CHALLENGES.TITLE'
    ),
    [NAVIGATE_TO.main.muliBands]: translate(
      'COMMON.LABELS.MULI_BANDS_STRATEGY'
    ),
    [NAVIGATE_TO.main.alerts]: translate('COMMON.LABELS.ALERTS'),
    [NAVIGATE_TO.main.positions]: translate('COMMON.LABELS.MY_POSITIONS'),
  };

  return isMobile ? (
    <MobileUserDetailsLayout titles={titles} children={children} />
  ) : (
    <DesktopUserDetailsLayout titles={titles} />
  );
};
