import React from 'react';
import { TypographyProps, useTheme } from '@mui/material';

import * as Styled from './styled';

export const Typography: React.FC<TypographyProps> = ({
  children,
  fontWeight,
  ...props
}) => {
  const theme = useTheme();

  const fontWeightMapping: Record<string, number> = {
    regular: theme.typography.fontWeightRegular as number,
    medium: theme.typography.fontWeightMedium as number,
    bold: theme.typography.fontWeightBold as number,
  };
  const resolvedFontWeight =
    typeof fontWeight === 'string' && fontWeightMapping[fontWeight]
      ? fontWeightMapping[fontWeight]
      : fontWeight;

  return (
    // We manually apply spacing to ensure correct alignment in both LTR & RTL.
    <Styled.Typography
      {...props}
      fontWeight={resolvedFontWeight || fontWeightMapping.regular}
      variant={props.variant || 'default'}
      component={props.component || 'p'}
      $margin={props.margin}
      $marginTop={props.marginTop}
      $marginRight={props.marginRight}
      $marginBottom={props.marginBottom}
      $marginLeft={props.marginLeft}
      $padding={props.padding}
      $paddingTop={props.paddingTop}
      $paddingRight={props.paddingRight}
      $paddingBottom={props.paddingBottom}
      $paddingLeft={props.paddingLeft}
      margin={undefined}
      marginTop={undefined}
      marginRight={undefined}
      marginBottom={undefined}
      marginLeft={undefined}
      padding={undefined}
      paddingTop={undefined}
      paddingRight={undefined}
      paddingBottom={undefined}
      paddingLeft={undefined}
    >
      {children}
    </Styled.Typography>
  );
};
