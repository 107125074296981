import styled, { css } from 'styled-components';
import { Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 408px;

    ${theme.breakpoints.down('sm')} {
      min-width: 100%;
    }
  `}
`;

export const Position = styled(Wrapper)`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
  `}
`;

export const ActionButtons = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 0 ${modalHorizontalPadding}px;
  `}
`;

export const Spread = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.typography.xl.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    min-width: max-content;
    color: ${theme.palette.gray.dark};
  `}
`;

export const SwitchStrategyModeTitle = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.light};
    font-size: ${theme.typography.default.fontSize};
    text-wrap: initial;
    text-align: center;
    margin-top: 5px;
  `}
`;

export const Protection = styled(Wrapper)`
  ${() => css`
    width: 100%;
    padding: 0 12px;
    margin-bottom: 12px;
    margin-top: 22px;
  `}
`;
