import { StackProps as MuiStackProps } from '@mui/material';

import * as Styled from './styled';

export function Stack(props: MuiStackProps) {
  return (
    // We manually apply spacing to ensure correct alignment in both LTR & RTL.
    <Styled.Stack
      {...props}
      $spacing={props.spacing}
      $margin={props.margin}
      $marginTop={props.marginTop}
      $marginRight={props.marginRight}
      $marginBottom={props.marginBottom}
      $marginLeft={props.marginLeft}
      $padding={props.padding}
      $paddingTop={props.paddingTop}
      $paddingRight={props.paddingRight}
      $paddingBottom={props.paddingBottom}
      $paddingLeft={props.paddingLeft}
      spacing={undefined}
      margin={undefined}
      marginTop={undefined}
      marginRight={undefined}
      marginBottom={undefined}
      marginLeft={undefined}
      padding={undefined}
      paddingTop={undefined}
      paddingRight={undefined}
      paddingBottom={undefined}
      paddingLeft={undefined}
    >
      {props.children}
    </Styled.Stack>
  );
}
