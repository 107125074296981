import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import {
  IScrollableListItemDetail,
  ScrollableList,
  ScrollableListItem,
  Typography,
  Wrapper,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { TChallengeEntity, TTradingAccountEntity, useMst } from '@trader/store';
import { calcEquity, getGoalReached, getOverAllPl } from '@trader/utils';
import { useTradingAccountPositions } from '@trader/hooks';

import { NoMastersAccount, NoNonAssignedAccounts } from './components';
import { useChallengesList, useRenderCellActions } from './hooks';
import { IChallenges } from './index';
import * as Styled from './mobile.styled';
import { EChallengeStatus } from '@trader/types';

export const MobileChallenges: React.FC<IChallenges> = observer(
  ({
    shouldTriggerGetChallenges = true,
    dividedChallenges,
    shouldDisplayAccTypeIcon,
    isMasterEmpty,
  }) => {
    const { translate } = useI18next();

    const { challenges, sortedChallenges } = useChallengesList({
      dividedChallenges,
      shouldTriggerGetChallenges,
    });

    if (!challenges.length && isMasterEmpty) {
      return <NoMastersAccount />;
    }

    if (!challenges.length && !isMasterEmpty) {
      return <NoNonAssignedAccounts />;
    }

    return (
      <ScrollableList<TChallengeEntity>
        data={sortedChallenges}
        renderItem={c => (
          <ChallengesListItem
            challenge={c}
            shouldDisplayAccTypeIcon={shouldDisplayAccTypeIcon}
          />
        )}
        emptyMessage={translate('COMMON.LABELS.NO_RECORDS_FOUND')}
      />
    );
  }
);

const ChallengesListItem: FC<{
  challenge: TChallengeEntity;
  shouldDisplayAccTypeIcon?: boolean;
}> = observer(({ challenge, shouldDisplayAccTypeIcon }) => {
  const { translate } = useI18next();

  const store = useMst();
  const account = store.entities.tradingAccounts.get<TTradingAccountEntity>(
    challenge.accountId
  );
  const portfolio = account.portfolio;

  const { positionsPl } = useTradingAccountPositions(challenge.accountId);
  const overallPl = getOverAllPl(
    portfolio.balance,
    challenge.challengeAmount,
    positionsPl
  );
  const equity = calcEquity(overallPl, portfolio.balance, 0);

  const {
    renderAccountIcon,
    renderProfitLoss,
    renderStatus,
    renderChangeAcc,
    renderResult,
  } = useRenderCellActions(challenge, account, shouldDisplayAccTypeIcon);

  const detailsItems: IScrollableListItemDetail[] = [
    {
      id: 'payout',
      header: translate('COMMON.LABELS.PAYOUT'),
      value: `${challenge.payout}%`,
    },
    {
      id: 'balance',
      header: translate('COMMON.LABELS.EQUITY'),
      value: (
        <Typography>
          {account.currencySymbol}
          {equity % 2 !== 0 ? equity.toFixed(2) : equity}
        </Typography>
      ),
    },
    {
      id: 'dailyProfitLoss',
      header: translate('COMMON.LABELS.P_L'),
      value: renderProfitLoss(positionsPl),
    },
    {
      id: 'overallProfitLoss',
      header: translate('COMMON.LABELS.OVERALL_PL'),
      value: renderProfitLoss(overallPl),
    },
    {
      id: 'dailyTargetProfitReached',
      header: translate('COMMON.LABELS.MAXIMUM_PROFIT_REACHED'),
      value: challenge.blockedDueToDailyTarget
        ? translate('COMMON.LABELS.YES')
        : translate('COMMON.LABELS.NO'),
    },
    {
      id: 'tradingDays',
      header: translate('COMMON.LABELS.MIN_TRADING_DAYS'),
      value: `${challenge.tradingDays}/${challenge.minTradingDays}`,
    },
    {
      id: 'goalReached',
      header: translate('COMMON.LABELS.GOAL_REACHED'),
      value: `${getGoalReached(
        challenge.profitTarget,
        overallPl,
        challenge.challengeAmount
      ).toFixed(2)}%`,
    },
    {
      id: 'result',
      header: translate('COMMON.LABELS.RESULT'),
      value: renderResult(),
    },
  ];

  return (
    <ScrollableListItem
      leadingContent={
        <Wrapper>
          <Typography margin='4px 4px 0 0'>{challenge.accountId}</Typography>
          {renderAccountIcon()}
        </Wrapper>
      }
      trailingContent={
        <Wrapper flexDirection='column' alignItems='flex-end'>
          <Typography>
            {account.currencySymbol}
            {challenge.challengeAmount}
          </Typography>
          {renderStatus()}
        </Wrapper>
      }
      detailsItems={detailsItems}
      underDetailsContent={
        challenge.status !== EChallengeStatus.Disable ? (
          <Styled.Buttons>{renderChangeAcc()}</Styled.Buttons>
        ) : undefined
      }
    />
  );
});
