import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { isChallengeAccountType } from '@trader/constants';
import { Tabs } from '@trader/trading';
import { useI18next } from '@trader/services';

import { AccountInformation } from './components/accountInformation';
import { ChallengesList } from './components/challenges';
import * as Styled from './mobile.styled';

export const MobileMyAccount = observer(() => {
  const { translate } = useI18next();
  const store = useMst();

  useEffect(() => {
    store.entities.positionsMetrics.getPositionsMetricsAsync.run();
  }, []);

  if (isChallengeAccountType) {
    return (
      <Styled.Root $withTabs>
        <Tabs
          tabs={[
            {
              value: 'challenges',
              label: translate('COMMON.LABELS.CHALLENGES'),
              children: <ChallengesList />,
              isDefault: true,
            },
            {
              value: 'accountInformation',
              label: translate('COMMON.LABELS.ACCOUNT_INFORMATION'),
              children: <AccountInformation />,
            },
          ]}
        />
      </Styled.Root>
    );
  }

  return (
    <Styled.Root>
      <AccountInformation />
    </Styled.Root>
  );
});
