import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)<{ $withTabs?: boolean }>`
  ${({ $withTabs }) => css`
    width: 100%;
    height: 100%;
    padding: 4px 0;
    flex-direction: column;

    ${conditionApply(
      [$withTabs],
      css`
        padding: 0;
        border-radius: 5px;
        .trading-tabs {
          padding-top: 6px;
        }
      `
    )};
  `};
`;
