import { IChartingLibraryWidget, StudyInputId } from '../../charting_library';
import React, { useCallback, useEffect } from 'react';
import { useMst } from '@trader/store';
import { isMuliBandsEnv, strategyName } from '@trader/constants';

export const useUpdateMultipliers = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>,
  isMuliBands?: boolean
) => {
  const store = useMst();

  const updateMultipliers = useCallback(() => {
    const studyId = widget?.current
      ?.activeChart()
      ?.getAllStudies()
      .find(x => x.name === strategyName)?.id;

    if (studyId) {
      const muliBands = widget?.current?.activeChart().getStudyById(studyId);
      muliBands &&
        muliBands.setInputValues([
          {
            id: 'topMultiplier' as StudyInputId,
            value: store.pages.muliBands.floatTopMultiplier,
          },
          {
            id: 'bottomMultiplier' as StudyInputId,
            value: store.pages.muliBands.floatBottomMultiplier,
          },
        ]);
    }
  }, [widget?.current]);

  useEffect(() => {
    if (isMuliBandsEnv && isMuliBands) {
      widget?.current?.onChartReady(() => {
        updateMultipliers();
        widget?.current?.subscribe('study_event', () => {
          updateMultipliers();
        });

        widget?.current
          ?.activeChart()
          .onDataLoaded()
          .subscribe(null, () => {
            updateMultipliers();
          });
      });
    }
  }, [
    widget?.current,
    store.pages.muliBands.floatTopMultiplier,
    store.pages.muliBands.floatBottomMultiplier,
    store.pages.muliBands.multiplier,
  ]);
};
