import * as yup from 'yup';

import {
  checkOnTickSize,
  adjustByTickSize,
  getMaxStopLoss,
  getMaxTakeProfitRate,
  getMinStopLoss,
  getMinTakeProfitRate,
  getOrderBasePrice,
  handleIndicesPipSize,
} from '@trader/utils';
import { useI18next } from '@trader/services';
import { defaultPipSize } from '@trader/constants';
import { TInstrumentEntity, useMst } from '@trader/store';
import { TCommonOrderType } from '@trader/types';

export const useProtectionSchema = (type: TCommonOrderType) => {
  const store = useMst();

  const trading = store.trading.getTrading(type);
  const instrument = trading.instrument as TInstrumentEntity;

  const actualPipSize = instrument.pipSize;
  const tickSize = instrument.tickSize;
  const stopsLevel = instrument.stopsLevel;
  const category = instrument?.category;
  const minOrderSizeIncrement = instrument.minOrderSizeIncrement;

  const pipSize = handleIndicesPipSize(
    actualPipSize ?? defaultPipSize,
    category || undefined,
    minOrderSizeIncrement ?? 1
  );

  const pipsOrRate = yup.string().oneOf(['Rate', 'Pips']);

  const { translate } = useI18next();
  const protectionSchema = yup.object().shape({
    isTakeProfit: yup.boolean(),
    takeProfitType: pipsOrRate,
    ask: yup.number(),
    bid: yup.number(),
    takeProfitPips: yup
      .number()
      .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD')),
    takeProfit: yup
      .string()
      .when(
        [
          'isTakeProfit',
          'bid',
          'ask',
          'side',
          'takeProfitType',
          'price',
          'orderType',
          'takeProfitPips',
        ],
        (
          [isTakeProfit, bid, ask, side, takeProfitType, price, orderType],
          schema
        ) => {
          const isPendingOrder = orderType !== 'Market';
          const max = adjustByTickSize(
            getMaxTakeProfitRate(
              stopsLevel,
              tickSize,
              pipSize,
              side,
              isPendingOrder ? price : getOrderBasePrice(side, ask, bid),
              actualPipSize ?? defaultPipSize
            ).toString(),
            pipSize,
            instrument.tickSize,
            side,
            'tp'
          );

          const min = adjustByTickSize(
            getMinTakeProfitRate(
              stopsLevel,
              tickSize,
              pipSize,
              side,
              isPendingOrder ? price : getOrderBasePrice(side, ask, bid),
              actualPipSize ?? defaultPipSize
            ).toString(),
            pipSize,
            instrument.tickSize,
            side,
            'tp'
          );

          const path =
            takeProfitType === 'Rate' ? 'takeProfit' : 'takeProfitPips';
          const messagePrefix = takeProfitType === 'Pips' ? 'Rate ' : '';

          return schema.test('tick_diff', '', (value, context) => {
            if (isTakeProfit) {
              if (!value) {
                return context.createError({
                  path,
                  message:
                    messagePrefix + translate('COMMON.ERRORS.REQUIRED_FIELD'),
                });
              }

              if (checkOnTickSize(value, tickSize)) {
                return context.createError({
                  path,
                  message:
                    messagePrefix +
                    translate('COMMON.ERRORS.TICK_SIZE', { tickSize }),
                });
              }

              if (+value > +max) {
                return context.createError({
                  path,
                  message:
                    messagePrefix +
                    translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
                      amount: max,
                    }),
                });
              }

              if (+value < +min) {
                return context.createError({
                  path,
                  message:
                    messagePrefix +
                    translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
                      amount: min,
                    }),
                });
              }
            }
            return true;
          });
        }
      )
      .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD')),
    isStopLoss: yup.boolean(),
    stopLossType: pipsOrRate,
    stopLossPips: yup
      .number()
      .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD')),
    stopLoss: yup
      .string()
      .when(
        [
          'isStopLoss',
          'ask',
          'bid',
          'side',
          'stopLossType',
          'price',
          'orderType',
          'stopLossPips',
        ],
        (
          [isStopLoss, ask, bid, side, stopLossType, price, orderType],
          schema
        ) => {
          const isPendingOrder = orderType !== 'Market';

          const min = adjustByTickSize(
            getMinStopLoss(
              stopsLevel,
              tickSize,
              pipSize,
              side,
              isPendingOrder ? price : getOrderBasePrice(side, ask, bid),
              actualPipSize ?? defaultPipSize
            ).toString(),
            pipSize,
            instrument.tickSize,
            side,
            'sl'
          );
          const max = adjustByTickSize(
            getMaxStopLoss(
              stopsLevel,
              tickSize,
              pipSize,
              side,
              isPendingOrder ? price : getOrderBasePrice(side, ask, bid),
              actualPipSize ?? defaultPipSize
            ).toString(),
            pipSize,
            instrument.tickSize,
            side,
            'sl'
          );

          const path = stopLossType === 'Rate' ? 'stopLoss' : 'stopLossPips';
          const messagePrefix =
            stopLossType === 'Pips'
              ? `${translate('COMMON.LABELS.RATE')} `
              : '';

          return schema.test('tick_diff', '', (value, context) => {
            if (isStopLoss) {
              if (!value) {
                return context.createError({
                  path,
                  message:
                    messagePrefix + translate('COMMON.ERRORS.REQUIRED_FIELD'),
                });
              }

              if (checkOnTickSize(value, tickSize)) {
                return context.createError({
                  path,
                  message:
                    messagePrefix +
                    translate('COMMON.ERRORS.TICK_SIZE', { tickSize }),
                });
              }

              if (+value > +max) {
                return context.createError({
                  path,
                  message:
                    messagePrefix +
                    translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
                      amount: max,
                    }),
                });
              }

              if (+value < +min) {
                return context.createError({
                  path,
                  message:
                    messagePrefix +
                    translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
                      amount: min,
                    }),
                });
              }
            }
            return true;
          });
        }
      )
      .typeError(translate('COMMON.ERRORS.REQUIRED_FIELD')),
  });

  return { protectionSchema };
};
