import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { IMessage } from '@trader/types';
import { IInstrumentBE } from '@trader/api';
import { useInstruments } from '@trader/hooks';
import { defaultIcon } from '@trader/constants';
import { ActionButtons } from '@trader/components';
import { formatByPipSize, getInstrumentDifference } from '@trader/utils';

import * as Styled from './styled';
import { EConnectionSubscription } from '@trader/services';

interface IState {
  ask: number;
  bid: number;
  currentPrice: number;
}

interface IInstrument {
  instrument: IInstrumentBE;
}

export const Instrument: React.FC<IInstrument> = observer(({ instrument }) => {
  const [realTimePrice, setRealTimePrice] = useState<IState>({
    ask: instrument.ask,
    bid: instrument.bid,
    currentPrice: instrument.ask,
  });

  const { isGrow, openCloseDifferenceInPercent } = getInstrumentDifference(
    realTimePrice.ask || instrument.ask,
    instrument.close,
    instrument.pipSize
  );

  const updateRealTimePrice = (message: IMessage) => {
    const ask = formatByPipSize(message.a, instrument.pipSize);
    const bid = formatByPipSize(message.b, instrument.pipSize);

    setRealTimePrice({
      ask: Number(ask),
      bid: Number(bid),
      currentPrice: Number(ask),
    });
  };

  useInstruments({
    instrumentsIds: [instrument.symbol],
    onUpdate: updateRealTimePrice,
    subscription: EConnectionSubscription.Instrument,
  });

  return (
    <Styled.Root>
      <img
        src={instrument?.iconUrl || defaultIcon}
        alt='instrument icon'
        width={36}
        height={36}
      />
      <Styled.Symbol>{instrument.displayName}</Styled.Symbol>
      <Styled.Main>
        <Styled.CurrentPrice>{realTimePrice.currentPrice}</Styled.CurrentPrice>
        <Styled.Difference $isAboveZero={isGrow}>
          {openCloseDifferenceInPercent}%
        </Styled.Difference>
      </Styled.Main>
      <Styled.Buttons>
        <ActionButtons
          layout={'single'}
          buttonVariant='text'
          symbol={instrument.symbol}
          ask={realTimePrice.ask}
          bid={realTimePrice.bid}
          pipSize={instrument.pipSize}
        />
      </Styled.Buttons>
    </Styled.Root>
  );
});
